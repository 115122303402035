import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Menu,
    Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Checkbox} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import {authFetch, logout} from '../../auth';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3,
      '&:nth-of-type(1)': {
        width: "7%"
      },
      '&:nth-of-type(2)': {
        width: "10%"
      },
      '&:nth-of-type(n+3)': {
        width: "15%"
      },
    },
    body: {
      fontSize: 13,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },     
    },
  }))(TableRow);

  const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    accordionList: {
        width: '100%',                
    },
    cellRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    cellText: {
        fontSize: 13,
        color: theme.palette.primary.dark,
        textAlign: "center",
        //marginLeft: theme.spacing(1)
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    componentPrincipalTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        padding: theme.spacing(1),
        border: `solid 1px ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    }
}));


export default function Reliquidations(props){

    const classes = useStyles();
    const [publicationDialog, setPublicationDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [reliqUploadIdx, setReliqUploadIdx] = useState(-1)
    const [reliquidationState, setReliquidationState] = useState([])
    const [reliquidationIds, setReliquidationIds] = useState([])

    const handlePublicationDialogOpen = () => {
        setPublicationDialog(true);
    };
    
    const handlePublicationDialogClose = () => {
        setPublicationDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handleCellValueChange = (reliqIdx, pidx, sidx) => (e) => {                
        setReliquidationState([...reliquidationState], reliquidationState[reliqIdx]['CompValues'][pidx]['Values'][sidx] = parseFloat(e.target.value))
        setReliquidationState([...reliquidationState], reliquidationState[reliqIdx]['CompValues'][pidx]['Values'][4] = Number(parseFloat(reliquidationState[reliqIdx]['CompValues'][pidx]['Values'][2] + parseFloat(e.target.value)).toFixed(2)) )        
        if (pidx > 1 && pidx < 5){  
            let sum = parseFloat(reliquidationState[reliqIdx]['CompValues'][2]['Values'][3])+parseFloat(reliquidationState[reliqIdx]['CompValues'][3]['Values'][3])+parseFloat(reliquidationState[reliqIdx]['CompValues'][4]['Values'][3])      
            setReliquidationState([...reliquidationState], reliquidationState[reliqIdx]['CompValues'][5]['Values'][3] = sum.toFixed(2) )
            setReliquidationState([...reliquidationState], reliquidationState[reliqIdx]['CompValues'][5]['Values'][4] = Number(parseFloat(reliquidationState[reliqIdx]['CompValues'][5]['Values'][2] + sum).toFixed(2)) ) 
        }
    }

    const handleApvButtonClick = (idx) => (e) => {        
        let auxState = reliquidationState
        let auxObject = reliquidationState[idx]
        let newObject = {...auxObject, Approved: true}
        auxState[idx] = newObject
        setReliquidationState([...auxState])
    }

    const reliquidationTable = (reliqLabels, reliqValues, reliqIdx, reliqPub) => (
        <Grid container spacing={4} justify="center">
            <Grid item xs={12}>                
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>                            
                            {
                                reliqLabels.map( (label, idx) => (
                                    <StyledTableCell align="center" key={idx}>{label}</StyledTableCell>
                                ))
                            }                                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reliqValues.map((obj, pidx) => (
                                <StyledTableRow key={pidx}>                                    
                                   
                                        <StyledTableCell align="center" style={{height: '60px'}}>{obj.From}</StyledTableCell>
                                        <StyledTableCell align="center" style={{height: '60px'}}>{obj.Concept}</StyledTableCell>
                                        {
                                            obj.Values.map((value, sidx) => (
                                            sidx !== 3 ?
                                            <StyledTableCell align="center" key={sidx} style={{height: '60px'}}>{value.toLocaleString('es-ES')}</StyledTableCell>                                            
                                            :
                                                                                 
                                            <StyledTableCell>
                                                <TextField
                                                    InputProps={{                                                    
                                                        classes: {
                                                            input: classes.cellText
                                                        },                                                                                                                                           
                                                    }}                                                                                                                                        
                                                    variant="standard"
                                                    className={classes.cellRow}
                                                    value={ 
                                                        obj.Values[sidx]                                         
                                                    } 
                                                    onChange={handleCellValueChange(reliqIdx, pidx, sidx)}  
                                                    type={'number'}
                                                    disabled={reliqPub}                                                                                                                                                
                                                />
                                            </StyledTableCell>
                                            ))
                                        }                                          
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>       
    )

    const inputsStateList = (files, filesState, filesDate, params, paramsState, paramsDate) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Archivos</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                    <Grid container justify='space-evenly'>           
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                           
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                    {filename}
                                    </Grid>                                    
                                    <Grid item xs={6}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item xs={10}>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }
                                                                                                    
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Parámetros</Typography>
                </Grid>
                {
                    params.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify='space-evenly'>
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>     
                                              
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                params.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={6}>
                                    { paramsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item xs={10}>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }                    
            </Grid>             
        </Fragment>            
    )

    const handleReliquidationCalcUpload = (idx) => {   
        let auxState = reliquidationState
        let auxObject = reliquidationState[idx]
        let newObject = {...auxObject, Published: true}
        auxState[idx] = newObject        
        setReliquidationState([...auxState])        
        
        let deltas_calc = {}
        deltas_calc['CompValues'] = newObject['CompValues']

        let opts = {
            'reliquidation_id': reliquidationIds[idx],         
            'deltas_calc': JSON.stringify(deltas_calc),
            'finished': true,
            'create': false      
        }       
                                                  
        authFetch(
            "/api/update-reliquidations",
            {
                method: 'post',
                body: JSON.stringify(opts),
            }
        ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response.Upload === 'OK'){
                setFeedbackMsg('Valor de la reliquidación publicado.')
                handleFeedbackDialogOpen()                                              
            }
            else {
                setFeedbackMsg('Error al guardar los datos.')
                handleFeedbackDialogOpen()
            }
        })
            
    }

    let principalComponent = () => (
        <Grid container spacing={2} style={{marginBottom: 20}}>
            {
                reliquidationState.map((reliquidation, idx) => (
                    <Grid item xs={12} key={idx}>
                    <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} >
                        <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                            <Typography>{reliquidation.Name}</Typography>
                            {
                                reliquidation.Published ? 
                                <DoneIcon fontSize="small" color="success" style={{marginLeft:10}}/>
                                :
                                <HourglassFullIcon fontSize="small" color="disabled" style={{marginLeft:10}}/>                                    
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container justify="space-evenly">                                                                
                                <Grid item xs={12}>
                                    <List className={classes.accordionList}>
                                        <ListItem>   
                                            <Grid container justify="space-evenly">
                                                <Grid item xs={8}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Lista de insumos</Typography>                                                                                            
                                                    {inputsStateList(reliquidation.Files, reliquidation.FilesState, reliquidation.FilesDate, reliquidation.Parameters, reliquidation.ParametersState, reliquidation.ParametersDate)}
                                                </Grid>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                            </Grid>
                                        </ListItem>                                        
                                        <ListItem>
                                            <Grid container spacing={3} justify="space-evenly">
                                                <Grid item xs={11}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Control de publicación</Typography>                                                                                            
                                                </Grid>
                                                <Grid item xs={12}>  
                                                    {reliquidationTable(reliquidation.CompLabels, reliquidation.CompValues, idx, reliquidation.Published)}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {                       
                                                        true ?                                                                                         
                                                        <Grid container justify="space-evenly">                                                    
                                                            <Grid item>
                                                                <Button onClick={handleApvButtonClick(idx)} disabled={reliquidation.Approved} color='primary' variant='contained'>Aprobar</Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button onClick={(e) => {handlePublicationDialogOpen(); setReliqUploadIdx(idx);}} color='primary' disabled={!reliquidation.Approved || reliquidation.Published} variant='contained'>Publicar</Button>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        null
                                                    }                                                    
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>                                    
                                </Grid>
                            </Grid> 
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                ))
            }
        </Grid>
    )

    const showPublicationDialog = (idx) => (
        <Dialog
            open={publicationDialog}
            onClose={handlePublicationDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
            ¿Desea publicar los valores de la reliquidación?.
            </DialogContentText>
        </DialogContent>
        <DialogActions>   
          <Button onClick={handlePublicationDialogClose} color="primary" autoFocus>
            Cancelar
          </Button>       
          <Button onClick={(e) => {handlePublicationDialogClose(); handleReliquidationCalcUpload(idx);}} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    useEffect (() => {
        document.title ="Reliquidaciones";        
        authFetch("/api/calc-reliquidations?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()
            ).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.Reliquidations){
                    ////console.log('Componentes', response.Components)
                    setReliquidationState(response.Reliquidations)    
                    setReliquidationIds(response.Ids)                                  
                }
            })
    }, [props.periodDate, props.tariffCalcVersion, props.market])

    return (
        <div className={classes.root}>
            <Grid container justify="center">
                <Zoom in timeout={700}>
                    <Grid item xs={12}>
                    {showPublicationDialog(reliqUploadIdx)}
                    {showFeedbackDialog()}
                    {
                        props.tariffCalcVersion === '' ?                                                        
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            No se ha generado una versión de cálculo para el periodo actual.</Typography>
                        :                                                                          
                        props.tariffCalcVersion !== '' ? 
                            <Fragment>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>                        
                            Lista de periodos reliquidados.</Typography> 
                            {principalComponent()}
                            {
                                (! props.reliqSelectedPeriod || props.reliqSelectedPeriod.getTime() !== props.periodDate.getTime()) && ! props.tariffsPublisheds.includes(true) ?                             
                                <Grid container justify="space-evenly">                                  
                                    <Grid item xs={4}> 
                                        <Fab
                                            color="primary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"                        
                                            classes={{root: classes.uploadButton}}
                                            onClick={(e) => {props.setReliqSelectedPeriod(props.periodDate);}}
                                            style={ {marginTop: 40} }
                                        > 
                                            <Fragment><AddIcon /> {' Establecer como periodo de aplicación '}</Fragment>                                                          
                                        </Fab>  
                                    </Grid>                                                                
                                </Grid>  
                                :
                                null
                            }                        
                            </Fragment>     
                        :
                        null                                                                     
                    }                    
                    </Grid>
                </Zoom>
            </Grid>            
        </div>
    )
}