import {React, useEffect, useState, Fragment, useRef} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Menu,
    Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Checkbox} from '@material-ui/core/'
import {authFetch, logout} from '../../auth';
import {useReactToPrint} from "react-to-print";
import ReactToPrint from 'react-to-print';
import getPeriods from '../../const/yearPeriods';
import PrintIcon from '@material-ui/icons/Print';
import renoLogo from '../../renolog1.jpg'
import specLogo from '../../speclogo.jpg'

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5,
      border: `1px solid ${theme.palette.secondary.dark}`
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      padding:0
    },
  }))(TableRow);

const StyledTableCellHeader = withStyles((theme) => ({
head: {
    backgroundColor: 'white',
    color: 'black',
    padding: 2,
    fontFamily: 'arial',
    border: '1px solid black',
    fontSize: 7,
    minHeight: 15
},
body: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    padding: 5,
    fontFamily: 'arial',
    border: '1px solid black'
},
}))(TableCell);

const StyledTableCellHeaderComp = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'black',
        padding: 2,
        fontFamily: 'arial',
        border: '1px solid black',
        fontSize: 8, 
        textAlign: 'center'       
    },
    body: {
        fontSize: 14,
        color: theme.palette.primary.dark,
        padding: 5,
        fontFamily: 'arial',
        border: '1px solid black'
    },
    }))(TableCell);

const StyledTableCellHeaderTitle = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'black',
        padding: 2,
        fontFamily: 'arial',
        border: '1px solid black',
        fontSize: 7,               
    },
    body: {
        fontSize: 14,
        color: theme.palette.primary.dark,
        padding: 5,
        fontFamily: 'arial',
        border: '1px solid black'
    },
    }))(TableCell);

const StyledTableCellBody = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'black',
        padding: 0,
        fontFamily: 'arial',
        border: '1px solid black',
        fontSize: 8,    
        textAlign:'center'                   
    },
    body: {
        backgroundColor: 'white',
        color: 'black',
        padding: 0,
        fontFamily: 'arial',
        border: '1px solid black',
        fontSize: 8,    
        textAlign:'center'        
    },
    }))(TableCell);

const StyledTableCellHeaderTitleCedenar = withStyles((theme) => ({
    head: {
        backgroundColor: 'white',
        color: 'black',
        padding: 2,
        fontFamily: 'arial',
        border: '1px solid black',
        fontSize: 11, 
        textAlign: 'center',
        fontWeight: 'bold'             
    },
    body: {
        fontSize: 10,
        color: 'black',
        padding: 2,
        fontFamily: 'arial',
        border: '1px solid black',
        textAlign: 'center'
    },
    }))(TableCell);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    opTariffBackground:
    {
        backgroundColor: theme.palette.secondary.main
    }
}));

const levelsNames = {n1or: '1 OR', n1comp: '1 COMP', n1us: '1 US', n2: '2', n3: '3'}
const levelsIdx = {n1or: 0, n1comp: 1, n1us: 2, n2: 3, n3: 4}

export default function TariffTree(props){
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const classes = useStyles();
    const [cuPublicationTree, setCuPublicationTree] = useState({})  
    const [cuNoCotValues, setCuNoCotValues] = useState({})
    const [cuOTTree, setCuOTTree] = useState({}) 
    const [trCompValues, setTrCompValues] = useState({})
    const [rsCompValues, setRsCompValues] = useState({}) 
    const [cvCompValues, setCvCompValues] = useState({})
    const [cvNoCotValues, setCvNoCotValues] = useState({})
    const [grCompValues, setGrCompValues] = useState({})
    const [prCompValues, setPrCompValues] = useState({})
    const [dtCompValues, setDtCompValues] = useState({})
    const [cfmj, setCfmj] = useState({})
    const [cvr, setCvr] = useState({})
    const [cot, setCot] = useState({})
    const [sellLevels, setSellLevels] = useState({})
    const [subsidy, setSubsidy] = useState({})
    const monthPeriods = getPeriods(0)
    const publicationPdfRef = useRef(null);
    const monthIdx = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth()) + 1: 0
    const year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1;    

    const publicationTree = (markets, trCompValues, rsCompValues, grCompValues, sellLevels, cuPublicationTree, marketsOpTariff, cuOTTree, cfmj) => (
        <div ref={publicationPdfRef} style={{padding: 10}}>        
        <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={3}>                
                <img src={specLogo} style={{width: '85.5%', padding: 0, marginLeft: 10}} title="" alt=""/>  
                <Typography align="center" variant="body2" style={{fontWeight: 'bold', fontSize: 9, fontFamily: 'arial'}}>SPECTRUM RENOVAVEIS S.A.S. E.S.P</Typography>  
                <Typography align="center" variant="body2" style={{fontWeight: 'bold', fontSize: 9, fontFamily: 'arial'}}>NIT: 900.589.503-6</Typography>                                                              
            </Grid>
            <Grid item xs={6}>
                <Table>
                    <TableHead>                    
                        <StyledTableCellHeaderTitle colSpan={11}>COMPONENTES DEL COSTO UNITARIO DE PRESTACION DEL SERVICIO (CU)</StyledTableCellHeaderTitle>                        
                    </TableHead>
                    <TableHead>
                        <StyledTableCellHeader colSpan={11}>Tarifas Reguladas Res. CREG 119/07, 97/08, 105/09, 156/09, 180/14, 191/14 189/15, 199/16, 015/18, 010/20 012/20, 058/20. </StyledTableCellHeader>
                    </TableHead>
                    <TableHead>
                        <StyledTableCellHeaderComp>Tmax:</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '10%'}}>{trCompValues['UNI'][0].toLocaleString('en-US',{minimumFractionDigits: 4})}</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp>Tmed:</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '10%'}}>{trCompValues['UNI'][1].toLocaleString('en-US',{minimumFractionDigits: 4})}</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp>Tmin:</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '10%'}}>{trCompValues['UNI'][2].toLocaleString('en-US',{minimumFractionDigits: 4})}</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp>Tmon:</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '10%'}}>{trCompValues['UNI'][3].toLocaleString('en-US',{minimumFractionDigits: 4})}</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp>Rm:</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '10%'}}>{rsCompValues['UNI'][0].toLocaleString('en-US',{minimumFractionDigits: 4})}</StyledTableCellHeaderComp>
                        <StyledTableCellHeaderComp style={{width: '16%'}}>{monthPeriods[monthIdx] + ' ' + year}</StyledTableCellHeaderComp>
                    </TableHead>
                </Table>
            </Grid>
            <Grid item xs={3}>
                <Table>
                <TableHead>
                        <StyledTableCellHeader colSpan={9} style={{height: 29}}></StyledTableCellHeader>
                    </TableHead>
                    <TableHead>
                        <StyledTableCellHeader colSpan={9} style={{height: 29}}></StyledTableCellHeader>
                    </TableHead>
                    <TableHead>
                        <StyledTableCellHeader colSpan={3}>Cu Opc. Tarifaria Res. CREG 012 y 058 de 2020 </StyledTableCellHeader>                        
                    </TableHead>
                </Table>
            </Grid>
            <Grid item xs={9}>
                <Table>
                    <TableHead>
                        <StyledTableCellBody style={{width: '14%'}}>MERCADO</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '6.5%'}}>Niv. Tensión</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Gm</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cvm</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Dm</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>PR MAX</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>PR MED</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>PR MIN</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>PR MON</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MAX</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MED</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MIN</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MON</StyledTableCellBody>                        
                    </TableHead>
                    <TableBody>
                        {                            
                            markets.map((activeMarketName, activeMarketIdx) => (
                                
                                sellLevels[activeMarketName].map((propertyLevel, propertyLevelIdx) => (
                                <TableRow key={propertyLevelIdx}>
                                    <StyledTableCellBody style={{width: '14%', fontWeight: propertyLevelIdx < 1 ? 'bold':'normal', height: 16}}>
                                        {
                                            propertyLevelIdx < 1 ?
                                                activeMarketName === 'BOGOTA - CUNDINAMARCA' ?
                                                'BOGOTA (Cund.)'
                                                :
                                                activeMarketName === 'ANTIOQUIA' ?
                                                'MEDELLIN (Anti.)'
                                                :
                                                activeMarketName.includes('CALI') ?
                                                'CALI YUMBO'
                                                :
                                                activeMarketName
                                            : propertyLevelIdx === 1 ?
                                            'Cfm,j: ' + cfmj[activeMarketName].toLocaleString('en-US',{minimumFractionDigits: 4})
                                            :
                                            ''
                                        }
                                    </StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '6.5%', height: 16}}>{levelsNames[propertyLevel]}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{grCompValues[activeMarketName][0].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{cvCompValues[activeMarketName][0].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{dtCompValues[activeMarketName][levelsIdx[propertyLevel]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{prCompValues[activeMarketName].slice(0,3)[levelsIdx[propertyLevel] <= 2 ? 0 : levelsIdx[propertyLevel]-2].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{prCompValues[activeMarketName].slice(3,6)[levelsIdx[propertyLevel] <= 2 ? 0 : levelsIdx[propertyLevel]-2].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{prCompValues[activeMarketName].slice(6,9)[levelsIdx[propertyLevel] <= 2 ? 0 : levelsIdx[propertyLevel]-2].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{prCompValues[activeMarketName].slice(9,12)[levelsIdx[propertyLevel] <= 2 ? 0 : levelsIdx[propertyLevel]-2].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{cuPublicationTree[activeMarketName]['max'][propertyLevel].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{cuPublicationTree[activeMarketName]['med'][propertyLevel].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{cuPublicationTree[activeMarketName]['min'][propertyLevel].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{cuPublicationTree[activeMarketName]['mon'][propertyLevel].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellBody>
                                </TableRow>
                                ))
                                                                  
                            ))
                        } 
                        
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={3}>
                <Table>
                    <TableHead>                        
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MAX</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MED</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MIN</StyledTableCellBody>
                        <StyledTableCellBody style={{width: '5.5%'}}>Cu MON</StyledTableCellBody>                        
                    </TableHead>
                    <TableBody>
                        {                            
                            markets.map((activeMarketName, activeMarketIdx) => (
                                
                                sellLevels[activeMarketName].map((propertyLevel, propertyLevelIdx) => (
                                <TableRow key={activeMarketIdx}>                                    
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>
                                        {
                                            JSON.parse(marketsOpTariff[activeMarketIdx])['levels'][levelsIdx[propertyLevel]] || activeMarketName in cuOTTree ?
                                                cuOTTree[activeMarketName]['max'][propertyLevel] > 0 ?
                                                cuOTTree[activeMarketName]['max'][propertyLevel].toFixed(2).toLocaleString('en-US',{minimumFractionDigits: 2})
                                                :
                                                ''
                                            :
                                            ''
                                        }
                                    </StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{
                                            JSON.parse(marketsOpTariff[activeMarketIdx])['levels'][levelsIdx[propertyLevel]] || activeMarketName in cuOTTree ?
                                                cuOTTree[activeMarketName]['med'][propertyLevel] > 0 ?
                                                cuOTTree[activeMarketName]['med'][propertyLevel].toFixed(2).toLocaleString('en-US',{minimumFractionDigits: 2})
                                            :
                                                ''
                                            :
                                            ''
                                        }</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>{
                                            JSON.parse(marketsOpTariff[activeMarketIdx])['levels'][levelsIdx[propertyLevel]] ||  activeMarketName in cuOTTree ?
                                                cuOTTree[activeMarketName]['min'][propertyLevel] > 0 ?
                                                cuOTTree[activeMarketName]['min'][propertyLevel].toFixed(2).toLocaleString('en-US',{minimumFractionDigits: 2})
                                            :
                                                ''
                                            :
                                            ''
                                        }</StyledTableCellBody>
                                    <StyledTableCellBody style={{width: '5.5%', height: 16}}>
                                        {
                                            JSON.parse(marketsOpTariff[activeMarketIdx])['levels'][levelsIdx[propertyLevel]] || activeMarketName in cuOTTree?
                                                cuOTTree[activeMarketName]['mon'][propertyLevel] > 0 ?
                                                cuOTTree[activeMarketName]['mon'][propertyLevel].toFixed(2).toLocaleString('en-US',{minimumFractionDigits: 2})
                                            :
                                                ''
                                            :
                                            ''
                                        }</StyledTableCellBody>
                                </TableRow>
                                ))
                                                                  
                            ))
                        }
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <StyledTableCellHeaderTitle rowSpan={2} colSpan={11} style={{textAlign: 'center', fontSize: 10, fontWeight: 'bold', padding:0}}>NOTA:  A  los  clientes  sujetos  al  pago  de  contribución  de  solidaridad  se  le  aplicara  un  porcentaje  sobre  la  tarifa  del  CU:20%:  clientes  comerciales  e  industriales  con
                        contribución; 0%: clientes exentos de contribución</StyledTableCellHeaderTitle>
                        
                    </TableHead>
                    <TableHead>
                    <StyledTableCellHeaderTitle colSpan={11}>{''}</StyledTableCellHeaderTitle>
                    </TableHead>
                </Table>
            </Grid>
        </Grid>
        </div>
    )

    const cedenarPublicationTree = () => (
        <div ref={publicationPdfRef} style={{padding: 10, marginTop: 40}}>
         <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={3}>                                        
                <img style={{padding: 0, width: '85.5%'}} src={"https://disponibilidad.cedenar.com.co/Imagenes/s5_logo.png"} title="" alt=""/>                                                                
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>    
                        <TableRow>
                            <StyledTableCellHeaderTitleCedenar colSpan={8} style={{fontSize: 12, fontWeight: 'bold'}}>Costo Unitario Según Resolucion CREG-119-07</StyledTableCellHeaderTitleCedenar>                        
                            <StyledTableCellHeaderTitleCedenar rowSpan={year < 2024 ? 2 : 1} colSpan={3} style={{color: 'green', fontSize: 12}}>
                                {
                                year < 2024 ?
                                'Cu Opción tarifaria (Res. 12/2020)'
                                :
                                'Cu (Res. 101-028 de 2023)'
                                }
                            </StyledTableCellHeaderTitleCedenar>
                        </TableRow>                
                        <TableRow>
                            <StyledTableCellHeaderTitleCedenar style={{width: '24%'}}>Nivel de Tension y Tipo Propiedad</StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Gm,i,j </StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Tm</StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Dn,m</StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Cvm,i,j
                                {
                                year < 2024 ?
                                ''
                                :
                                ' (SIN COT)'
                                }
                            </StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>PR</StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Rm,i</StyledTableCellHeaderTitleCedenar>     
                            <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>CU 119-07                                
                            </StyledTableCellHeaderTitleCedenar>
                            {
                                year < 2024 ?
                                null
                                :
                                <Fragment>
                                    <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>CvR <br/> (CON COT)</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Cvm,i,j (CON COT)</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>Cu</StyledTableCellHeaderTitleCedenar>
                                </Fragment>
                            }
                        </TableRow> 
                    </TableHead>                    
                    <TableBody>
                            {
                                ['Nivel Tension 1- Propiedad:CEDENAR','Nivel Tension 1- Propiedad:COMPARTIDA','Nivel Tension 1- Propiedad:USUARIO',
                                'Nivel Tension 2', 'Nivel Tension 3'].map((level,idx) => (
                                    <TableRow key={idx}>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '24%'}}>{level}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{grCompValues['NARIÑO'][0].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{trCompValues['UNI'][3].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{dtCompValues['NARIÑO'][idx].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{year < 2024 ? cvCompValues['NARIÑO'][0].toLocaleString('en-US',{minimumFractionDigits: 2}) :
                                                                                                 cvCompValues['NARIÑO'].length > 1 && Object.keys(cot).includes('NARIÑO') ?
                                                                                                 idx < 3 ? 
                                                                                                 cvNoCotValues['NARIÑO'][0].toLocaleString('en-US',{minimumFractionDigits: 2})
                                                                                                 :
                                                                                                 cvNoCotValues['NARIÑO'][idx-2].toLocaleString('en-US',{minimumFractionDigits: 2})
                                                                                                :
                                                                                                '-'
                                                                                                }</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{prCompValues['NARIÑO'][idx < 3 ? 9 : 9+(idx-2)].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{rsCompValues['UNI'][0].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>     
                                        <StyledTableCellHeaderTitleCedenar style={{width: '7%'}}>{
                                            year < 2024 ?
                                            cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2})                                            
                                            :
                                            Object.keys(cot).includes('NARIÑO') ?                                                
                                                cuNoCotValues['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2})
                                            :
                                            '-'
                                        }</StyledTableCellHeaderTitleCedenar>
                                        {
                                            year < 2024 ?
                                            <StyledTableCellHeaderTitleCedenar>{Object.keys(cuOTTree).length > 0 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2}) : '-'}</StyledTableCellHeaderTitleCedenar> 
                                            :
                                            <Fragment>
                                                <StyledTableCellHeaderTitleCedenar>{
                                                    cvCompValues['NARIÑO'].length > 1 && Object.keys(cvr).includes('NARIÑO') ?
                                                    idx < 3 ? cvr['NARIÑO'][0].toLocaleString('en-US',{minimumFractionDigits: 2}) : cvr['NARIÑO'][idx-2].toLocaleString('en-US',{minimumFractionDigits: 2})
                                                    :
                                                    '-'    
                                                }</StyledTableCellHeaderTitleCedenar>
                                                <StyledTableCellHeaderTitleCedenar>{
                                                    cvCompValues['NARIÑO'].length > 1 ?
                                                    idx < 3 ? cvCompValues['NARIÑO'][0].toLocaleString('en-US',{minimumFractionDigits: 2}) : cvCompValues['NARIÑO'][idx-2].toLocaleString('en-US',{minimumFractionDigits: 2})
                                                    :
                                                    '-'    
                                                }</StyledTableCellHeaderTitleCedenar>
                                                <StyledTableCellHeaderTitleCedenar>{
                                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2})  
                                                }</StyledTableCellHeaderTitleCedenar>
                                            </Fragment>
                                        }
                                        
                                    </TableRow>
                                ))
                            }                                               
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Typography style={{margin: 20, fontSize: 12, fontWeight: 'bold', fontFamily: 'arial'}}>TARIFAS RESIDENCIALES</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 12, width: '24%', fontWeight: 'bold', borderLeftColor: 'white', borderTopColor: 'white'}}></StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar colSpan={2} style={{fontSize: 12, width: '15%', fontWeight: 'bold'}}>Estrato 1</StyledTableCellHeaderTitleCedenar>   
                            <StyledTableCellHeaderTitleCedenar colSpan={2} style={{fontSize: 12, width: '15%', fontWeight: 'bold'}}>Estrato 2</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar colSpan={2} style={{fontSize: 12, width: '15%', fontWeight: 'bold'}}>Estrato 3</StyledTableCellHeaderTitleCedenar>                      
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 12, width: '15%', fontWeight: 'bold'}}>Estrato 4</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 12, width: '15%', fontWeight: 'bold'}}>Estratos 5 y 6</StyledTableCellHeaderTitleCedenar> 
                        </TableRow> 
                        <TableRow>                            
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11,  width: '24%', fontWeight: 'bold'}}>Propiedad del activo</StyledTableCellHeaderTitleCedenar>   
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>% de subsidio</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>Tarifa con subsidio</StyledTableCellHeaderTitleCedenar>                      
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>% de subsidio</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>Tarifa con subsidio</StyledTableCellHeaderTitleCedenar>
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>% de subsidio</StyledTableCellHeaderTitleCedenar>   
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>Tarifa con subsidio</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>Igual al costo unitario</StyledTableCellHeaderTitleCedenar>                      
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 11, fontWeight: 'bold'}}>Costo Unit. más 20% de Contribución</StyledTableCellHeaderTitleCedenar>                             
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                ['De CEDENAR','Compartida','Del usuario'].map((level,idx) => (
                                    <TableRow key={idx}>
                                        <StyledTableCellHeaderTitleCedenar>{level}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res']['prc_'+sellLevels['NARIÑO'][idx]+'_es1'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][idx]+'_es1'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res']['prc_'+sellLevels['NARIÑO'][idx]+'_es2'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][idx]+'_es2'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res']['prc_'+sellLevels['NARIÑO'][idx]+'_es3'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][idx]+'_es3'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>     
                                        <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2})
                                        :cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][idx]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar> 
                                        <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][idx]+'_es56'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar> 
                                    </TableRow>
                                ))
                            }                                               
                    </TableBody>
                </Table>
            </Grid>
             <Grid item xs={12} style={{textAlign: 'center'}}>
            <Typography style={{margin: 20, fontSize: 12, fontWeight: 'bold', fontFamily: 'arial'}}>TARIFAS NO RESIDENCIALES</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'center'}}>                
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCellHeaderTitleCedenar colSpan={6} style={{fontSize: 11, width: '24%', fontWeight: 'bold'}}>OFICIAL Y ESPECIAL</StyledTableCellHeaderTitleCedenar>                         
                        </TableRow> 
                        <TableRow>                                                         
                            <StyledTableCellHeaderTitleCedenar colSpan={3} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 1</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar rowSpan={2} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 2</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar rowSpan={2} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 3</StyledTableCellHeaderTitleCedenar>                                                                              
                        </TableRow>
                        <TableRow>                                                         
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>CEDENAR</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>COMPARTIDA</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>USUARIO</StyledTableCellHeaderTitleCedenar>                                                                              
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {                                
                                <TableRow>                                        
                                    <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][0]].toLocaleString('en-US',{minimumFractionDigits: 2}):
                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][0]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][1]].toLocaleString('en-US',{minimumFractionDigits: 2}):
                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][1]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][2]].toLocaleString('en-US',{minimumFractionDigits: 2}):
                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][2]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][3]].toLocaleString('en-US',{minimumFractionDigits: 2}):
                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][3]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                    <StyledTableCellHeaderTitleCedenar>{year < 2024 ? cuOTTree['NARIÑO']['mon'][sellLevels['NARIÑO'][4]].toLocaleString('en-US',{minimumFractionDigits: 2}):
                                    cuPublicationTree['NARIÑO']['mon'][sellLevels['NARIÑO'][4]].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>                                       
                                </TableRow>                                
                            }                                               
                    </TableBody>
                </Table>
            </Grid>
           
            <Grid item xs={6} style={{textAlign: 'center'}}>                
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCellHeaderTitleCedenar colSpan={6} style={{fontSize: 11, width: '24%', fontWeight: 'bold'}}>COMERCIAL - INDUSTRIAL y PROVISIONAL</StyledTableCellHeaderTitleCedenar>                         
                        </TableRow> 
                        <TableRow>                                                         
                            <StyledTableCellHeaderTitleCedenar colSpan={3} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 1</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar rowSpan={2} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 2</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar rowSpan={2} style={{fontSize: 10, fontWeight: 'bold'}}>NIVEL DE TENSION 3</StyledTableCellHeaderTitleCedenar>                                                                              
                        </TableRow>
                        <TableRow>                                                         
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>CEDENAR</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>COMPARTIDA</StyledTableCellHeaderTitleCedenar> 
                            <StyledTableCellHeaderTitleCedenar style={{fontSize: 10, fontWeight: 'bold'}}>USUARIO</StyledTableCellHeaderTitleCedenar>                                                                              
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            
                            <TableRow>                                        
                                <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][0]+'_es56'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][1]+'_es56'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res'][sellLevels['NARIÑO'][2]+'_es56'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res']['esp_n2'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>
                                <StyledTableCellHeaderTitleCedenar>{subsidy['NARIÑO']['res']['esp_n3'].toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCellHeaderTitleCedenar>                                       
                            </TableRow>
                                                                            
                    </TableBody>
                </Table>
            </Grid>
         </Grid>
        
        </div>
    )
    
    useEffect (() => {
        let year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1
        let period = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1
        let version = props.tariffCalcVersion
        let usernameCode = props.username.slice(0,3)
        document.title ="Publicación tarifaria " + String(year) + '-' + (String(period).length > 1 ? String(period) : '0'+String(period));        
        
        if (props.tariffsPublished){
            authFetch("/api/get-publication-tree?electric_tariff_id=" + String( usernameCode + String( String(year) + String(period) + version )) +
            "&username=" + props.username + "&market=" + props.market             
            ).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cuGeneral){
                    setCuPublicationTree(response.cuGeneral)
                    setCuNoCotValues(response.cuNoCot)
                    setCuOTTree(response.cuOpTariff)
                    setTrCompValues(response.trValues)
                    setRsCompValues(response.rsValues)
                    setCvCompValues(response.cvValues)
                    setCvNoCotValues(response.cvNoCot)
                    setGrCompValues(response.grValues)
                    setPrCompValues(response.prValues)
                    setDtCompValues(response.dtValues)
                    setCfmj(response.cfmj)
                    setCvr(response.cvrValues)
                    setCot(response.cotValues)
                    setSellLevels(response.levels)
                    if (props.username === 'Cedenar'){
                        setSubsidy(response.subsidy)
                        //console.log('sub', response.subsidy)
                    }
                    ////console.log(response.tariffTree)
                    //setTariffTree(response.tariffTree)                    
                }
            })
        }

        
    }, [props.periodDate, props.tariffCalcVersion, props.market, props.tariffsPublished])

    return (
        <div className={classes.root}>            
            <Grid container justify="center">
                <Zoom in timeout={700}>
                    <Grid item xs={12}>
                    {   props.tariffCalcVersion === '' ?                                                        
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            No se ha generado una versión de cálculo para el periodo actual.</Typography>
                        :
                            //props.tariffsPublished
                            props.tariffsPublished && Object.keys(trCompValues).length > 0 && Object.keys(rsCompValues).length > 0 
                            && Object.keys(cvCompValues).length > 0 && Object.keys(dtCompValues).length > 0 && Object.keys(grCompValues).length > 0
                            && Object.keys(prCompValues).length > 0 && Object.keys(sellLevels).length > 0 && Object.keys(cuPublicationTree).length > 0 
                            && props.markets.length > 0 && Object.keys(cuOTTree).length > 0 && Object.keys(cfmj).length > 0 && props.username === 'SPECTRUM'
                            ?
                            
                            <Fragment>
                                <Typography variant="subtitle1" className={classes.sectionTitle}>
                                Publicación tarifaria - SPECTRUM</Typography>
                                                    
                                {publicationTree(props.markets, trCompValues, rsCompValues, grCompValues, sellLevels, cuPublicationTree, props.marketsOpTariff, cuOTTree, cfmj)}                                                                                                                    
                                
                                <Fragment style={{marginTop: 20}}>
                                    <ReactToPrint
                                        trigger={() => 
                                            <Button color='primary' variant='contained' startIcon={<PrintIcon/>}>Imprimir</Button>
                                        }
                                        content={() => publicationPdfRef.current}
                                    /> 
                                </Fragment>
                            </Fragment>
                            :
                            props.tariffsPublished && Object.keys(trCompValues).length > 0 && Object.keys(rsCompValues).length > 0 
                            && Object.keys(cvCompValues).length > 0 && Object.keys(dtCompValues).length > 0 && Object.keys(grCompValues).length > 0
                            && Object.keys(prCompValues).length > 0 && Object.keys(sellLevels).length > 0 && Object.keys(cuPublicationTree).length > 0 
                            && (Object.keys(cuOTTree).length > 0 || Number(year) >= 2024 ) && props.username === 'Cedenar' && Object.keys(subsidy).length > 0
                            ?
                            <Fragment>
                                <Typography variant="subtitle1" className={classes.sectionTitle}>
                                Publicación tarifaria - Cedenar</Typography>
                                                    
                                {cedenarPublicationTree(trCompValues, rsCompValues, grCompValues, cvCompValues, dtCompValues, prCompValues, cuPublicationTree)}                                                                                                                    
                                
                                <Fragment style={{marginTop: 20}}>
                                    <ReactToPrint
                                        trigger={() => 
                                            <Button color='primary' variant='contained' startIcon={<PrintIcon/>}>Imprimir</Button>
                                        }
                                        content={() => publicationPdfRef.current}
                                    /> 
                                </Fragment>
                            </Fragment>
                            :                            
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            Las tarifas de todos los mercados no han sido publicadas.</Typography>                        
                    }          
                              
                    </Grid>
                    
                </Zoom>
            </Grid>                            
        </div>
    )
}