import {React, useEffect, useState, Fragment, useMemo, createContext, useContext, createElement} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DataGrid from 'react-data-grid';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    filterCell:{
        lineHeight: 35,
        padding: 0,
        '> div':{
            paddingBlock: 0,
            paddingInline: 8,
            '&:first-child': {
                borderBlockEnd: '1px solid black'
            }
        }
    },   
    tableText:{
        fontSize: 11,
        textAlign: 'left'
    },    
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 8,
    },
    dataTable:{
        color: '#606B6A',
        ['--rdg-background-color']: '#D6EEEC',
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 90          
    },
    filterInput200: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",        
    },
    filterText: {
        fontSize: 11,
        color: 'white',
        textAlign: "left",        
        background: 'black'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    itemText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",         
    },
  }));


export default function ClientControl (props) {
    const classes = useStyles();    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")        
    const [isLoading, setIsLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)

    const [selectedYearInfo, setSelectedYearInfo] = useState(null)
    const [yearInformation, setYearInformation] = useState([])
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedRange, setSelectedRange] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState(null)
    let monthCmp = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    let trimCmp = ['1er Trimestre', '2do Trimestre', '3er Trimestre', '4to Trimestre']
    let semCmp = ['1er Semestre', '2do Semestre']
       
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    
    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const downloadClients = (type) => {
        setFileLoading(true)
        authFetch("/api/get-liquidation-internal-file-report?username=" + String(props.username)
            +"&year="+String( selectedYear )
            +"&range="+String( selectedRange )
            +"&period="+String( selectedPeriod )
            +"&type="+type
            + "&timestamp=" + new Date().toISOString().slice(0,19),  { responseType: 'blob' }
            ).then(response => {
                setFileLoading(false) 
                if (response.status === 422){
                    logout()
                }
                else if (response.status === 200){
                    return response.blob()
                } 
                }).then(blob => {                              
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    let ext = type === 'csv' ? '.csv' : '.xlsx'
                    a.download = 'Reporte_facturacion_agpe_' + selectedYear + '_' + selectedPeriod + ext;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);                                               
                })        
    }

    
    useEffect (() => {
        setIsLoading(true)
        document.title ="Informe de facturación | Autogeneradores Cedenar";        

        authFetch("/api/get-liquidation-date-limits?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    setIsLoading(false)
                    return null
                }
                return response.json()
            }).then(response => {
                    if (response && response.years_information){   
                        //console.log(response.years_information)                                                         
                        setYearInformation(response.years_information)
                        //setLoadingInformation(false)
                    }
                    setIsLoading(false)
            })
        
    }, [])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}            
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly" spacing={1}>                    
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle} color="primary">Informe de facturación autogeneradores
                        </Typography>
                    </Grid>                                                                                               
                    <Grid item xs={12}>
                        {
                            isLoading ?
                            <CircularProgress/>
                            :                            
                            <Grid container justify="space-evenly" spacing={1} alignItems="center">               
                                <Grid item xs={2}>                 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>                                            
                                            <Grid item xs={8}>
                                                <TextField 
                                                    variant="standard" 
                                                    select
                                                    label="Año"
                                                    size="small"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.formText
                                                        }
                                                    }}
                                                    className={classes.formRow} 
                                                    value={selectedYear}
                                                    onChange={(e) => {                                                        
                                                        setSelectedYear(e.target.value);
                                                        let idx = yearInformation.findIndex(obj => obj.year === e.target.value)
                                                        setSelectedYearInfo(yearInformation[idx]);
                                                        setSelectedRange(null);
                                                        setSelectedPeriod(null);
                                                    }}                                                    
                                                >
                                                    {
                                                        yearInformation.map((info, idx) => (
                                                            <MenuItem idx={idx} value={info.year} className={classes.itemText}>
                                                            {info.year}
                                                            </MenuItem>
                                                        ))
                                                    }                                                                                                       
                                                </TextField>
                                            </Grid>                                            
                                        </Grid>                           
                                    </Paper> 
                                </Grid>          
                                <Grid item xs={3}>                 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                                            <Grid item xs={9}>
                                                <TextField 
                                                    variant="standard" 
                                                    select
                                                    label="Rango"
                                                    size="small"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.formText
                                                        }
                                                    }}
                                                    className={classes.formRow} 
                                                    value={selectedRange}
                                                    onChange={(e) => {setSelectedRange(e.target.value)}}                                                
                                                >
                                                    <MenuItem value={"men"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.months_flag.includes(true) : true}>
                                                    Mensual
                                                    </MenuItem>                                                    
                                                    <MenuItem value={"tri"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.quarters_flag.includes(true) : true}>
                                                    Trimestral
                                                    </MenuItem>
                                                    <MenuItem value={"sem"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.halfs_flag.includes(true) : true}>
                                                    Semestral
                                                    </MenuItem>
                                                    <MenuItem value={"com"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.year_flag : true}>
                                                    Anual
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>                           
                                    </Paper> 
                                </Grid> 
                                {
                                    selectedRange !== 'com' ?
                                    <Grid item xs={3}>                 
                                        <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                            <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                                                <Grid item xs={9}>
                                                    <TextField 
                                                        variant="standard" 
                                                        select
                                                        label="Periodo"
                                                        size="small"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                        value={selectedPeriod}
                                                        onChange={(e) => {setSelectedPeriod(e.target.value)}}                                                
                                                    >
                                                        {
                                                            selectedRange ? 
                                                            
                                                                selectedRange === 'men' ? 
                                                                monthCmp.map((month, midx) => (
                                                                    <MenuItem value={"m" + String(midx+1)} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.months_flag[midx] : true}>
                                                                        {month}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                selectedRange === 'tri' ? 
                                                                trimCmp.map((trim, tidx) => (
                                                                    <MenuItem value={"t" + String(tidx+1)} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.quarters_flag[tidx] : true}>
                                                                        {trim}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                selectedRange === 'sem' ? 
                                                                semCmp.map((sem, sidx) => (
                                                                    <MenuItem value={"s" + String(sidx+1)} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.halfs_flag[sidx] : true}>
                                                                        {sem}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                null
                                                            :
                                                            null
                                                        }
                                                                                                                                                            
                                                    </TextField>
                                                </Grid>
                                            </Grid>                           
                                        </Paper> 
                                    </Grid> 
                                    :
                                    null
                                }                                
                                <Grid item xs={3}>                 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container justify="space-evenly" alignItems="center" spacing={2}>
                                            {
                                                fileLoading ?
                                                <CircularProgress style={{marginTop: 10, marginBottom: 10}}/>
                                                :  
                                                <Fragment>
                                                <Grid item xs={6}>
                                                    <Fab
                                                        color="secondary"
                                                        size="small"
                                                        component="span"
                                                        aria-label="add"
                                                        variant="extended"                        
                                                        classes={{root: classes.uploadButton}}
                                                        onClick={(e) => {downloadClients('excel')}}                                                   
                                                        disabled={!selectedPeriod || !selectedRange}
                                                        style={{width: '100%'}}
                                                    > 
                                                        <Fragment><ArrowDropDownCircleIcon style={{marginRight: 10}}/> {'Excel'}</Fragment>                         
                                                    </Fab>
                                                </Grid>
                                                <Grid item xs={12}>

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fab
                                                        color="secondary"
                                                        size="small"
                                                        component="span"
                                                        aria-label="add"
                                                        variant="extended"                        
                                                        classes={{root: classes.uploadButton}}
                                                        onClick={(e) => {downloadClients('csv')}}                                                    
                                                        disabled={!selectedPeriod || !selectedRange}
                                                        style={{width: '100%'}}
                                                    > 
                                                        <Fragment><ArrowDropDownCircleIcon style={{marginRight: 10}}/> {'CSV'}</Fragment>                         
                                                    </Fab>
                                                </Grid>
                                                </Fragment>
                                            }
                                        </Grid>                           
                                    </Paper> 
                                </Grid>                      
                            </Grid>                            
                        }
                        
                    </Grid>                                                     
                </Grid>
            </Zoom>
        </div>
    )
}