import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../auth';
import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Incum from '@material-ui/icons/Layers';
import Agent from '@material-ui/icons/SortByAlpha';
import ORCode from '@material-ui/icons/SettingsEthernet';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date()
    var actualYear = actualDate.getFullYear()
    var actualMonth = actualDate.getMonth()
    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [marketDialog, setMarketDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [marketList, setMarketList] = useState([])
    const [cmcList, setCmcList] = useState([])
   
    const [marketID, setMarketID] = useState(0)
    const [marketName, setMarketName] = useState("")
    const [marketArea, setMarketArea] = useState("CENTRO")
    const [marketState, setMarketState] = useState(false)
    const [marketCmcInc, setMarketCmcInc] = useState(0)
    const [marketAgent, setMarketAgent] = useState("")
    const [marketNetOp, setMarketNetOp] = useState("")
    const [marketEntryDate, setMarketEntryDate] = useState(new Date()); 
    const [marketResDate, setMarketResDate] = useState(null); 
    const [marketLossPlanDate, setMarketLossPlanDate] = useState(null)
    const [marketCode, setMarketCode] = useState("")   
    const [maxKey, setMaxKey] = useState(-1)

    const handleMarketDialogOpen = () => {
        setMarketDialog(true);
      };
    
    const handleMarketDialogClose = () => {
        setMarketDialog(false);
    };
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesForCmc = (value) => {
        let cmc = cmcList.filter(cmc => cmc.cmc_name === value)[0]
        setMarketAgent(cmc.cmc_inc_code)
        setMarketNetOp(cmc.cmc_no_code)
    }

    const updateValuesWithSelectedMarket = (idx) => {
        setMarketID(marketList[idx].market_id)
        setMarketName(marketList[idx].name)
        setMarketArea(marketList[idx].area)
        setMarketState(marketList[idx].state)
        setMarketEntryDate(new Date( String(marketList[idx].entry_date).slice(0,4),  parseInt(String(marketList[idx].entry_date).slice(5,7)) - 1 ))
        setMarketResDate( marketList[idx].res_date ? new Date( String(marketList[idx].res_date).slice(0,4),  parseInt(String(marketList[idx].res_date).slice(5,7)) - 1 ) : null)
        setMarketLossPlanDate( marketList[idx].loss_plan_date ? new Date( String(marketList[idx].loss_plan_date).slice(0,4),  parseInt(String(marketList[idx].loss_plan_date).slice(5,7)) - 1 ) : null)
        setMarketCode(marketList[idx].siu_code)
        let cmcName =  marketList[idx].name_comer_inc
        let actualCmc = cmcList.filter(cmc => cmc.cmc_name === cmcName)[0]          
        setMarketCmcInc(actualCmc.cmc_name)
        setMarketAgent(actualCmc.cmc_inc_code)
        setMarketNetOp(actualCmc.cmc_no_code)
    }
 
    const handleMarketUpload = () => {        
        if (marketName.length === 0 || marketCode.length === 0){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {
            let opts = {
                'marketID': marketID,
                'username': props.username,
                'name': marketName,
                'area': marketArea,
                'state': marketState,
                'entryDate': marketEntryDate.getTime(),
                'resDate': (marketResDate ? marketResDate.getTime() : null),
                'lossPlanDate': (marketLossPlanDate ? marketLossPlanDate.getTime() : null),
                'siuCode': marketCode,
                'nameComerInc': marketCmcInc,
                'codeComerInc': marketAgent,
                'codeNetOp': marketArea === "SIN ADD" ? marketNetOp : marketArea
              }
              authFetch(
                "/api/update-markets",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                    handleFeedbackDialogOpen()
                    handleMarketDialogClose()
                    authFetch("/api/check-markets?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.markets){        
                                let activeMarkets = response.markets.flatMap((market) => market.state ? market.name : [])
                                let marketsDemandPerc = response.markets.flatMap((market) => market.state ? market.perc_energy_demand : [])    
                                props.setMarkets( activeMarkets )        
                                props.setMarketsDemand ( marketsDemandPerc )   
                                setMaxKey(response.max_key)                 
                                setMarketList(response.markets)                                
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showMarketDialog = () => (
        <Dialog
            open={marketDialog}
            onClose={handleMarketDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes para el mercado."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Nombre:</Typography>
                        </Grid>
                        <Grid item>
                            <MarketName/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={marketName}
                                placeholder={"Ingrese el nombre del mercado."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setMarketName(e.target.value)}                         
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Área de distribución:</Typography>
                        </Grid>
                        <Grid item>
                            <Area/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={marketArea}
                                placeholder={"Seleccione el área de distribución."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}     
                                select   
                                onChange={(e) => setMarketArea(e.target.value)}                                                       
                            >
                                <MenuItem value={"CENTRO"}>Centro</MenuItem>
                                <MenuItem value={"SUR"}>Sur</MenuItem>
                                <MenuItem value={"ORIENTE"}>Oriente</MenuItem>
                                <MenuItem value={"OCCIDENTE"}>Occidente</MenuItem>
                                <MenuItem value={"SIN ADD"}>Sin ADD</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Comercializador incumbente:</Typography>
                        </Grid>
                        <Grid item>
                            <Incum/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={marketCmcInc}                                                             
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}     
                                select   
                                onChange={ (e) => {setMarketCmcInc(e.target.value);updateValuesForCmc(e.target.value);} }                       
                            >
                                {
                                    cmcList.map((cmc, idx) => (
                                        <MenuItem value={cmc.cmc_name}>{cmc.cmc_name}</MenuItem>
                                    ))
                                }                                                              
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código SUI:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={marketCode}
                                placeholder={"Ingrese el código del mercado."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setMarketCode(e.target.value)}                            
                            />
                        </Grid>
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>                                                 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Agente comercializador:</Typography>
                        </Grid>
                        <Grid item>
                            <Agent/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={marketAgent}                                
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}    
                                disabled                                                                                     
                            />
                        </Grid>
                        {
                            marketArea === "SIN ADD" ?
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left">Código OR:</Typography>
                                </Grid>
                                <Grid item>
                                    <ORCode/>
                                </Grid>                
                                <Grid item xs={9}>                   
                                    <TextField
                                        value={marketNetOp}                                
                                        InputProps={{
                                            readOnly: true,
                                            className: classes.formDefined,
                                            classes: {
                                                input: classes.formText,
                                            
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}                                                          
                                    />
                                </Grid>
                            </Fragment>
                            :
                            <Fragment></Fragment>
                        }  
                    </Grid>                                       
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo de ingreso:</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={marketEntryDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={setMarketEntryDate}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid>                                                                      
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo asig. cargos (R 015/18):</Typography>                    
                        </Grid>                        
                        <Grid item >
                            <EventIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                emptyLabel={"Sin asig. cargos R 015/18"}
                                openTo="month"
                                format="MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={marketResDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={setMarketResDate}                                
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo aprob. plan pérdidas:</Typography>                    
                        </Grid>                        
                        <Grid item >
                            <EventIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                emptyLabel={"Plan pérdidas no aprob."}
                                openTo="month"
                                format="MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={marketLossPlanDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={setMarketLossPlanDate}                                
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Estado:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch
                                checked={marketState}
                                onChange={(e) => setMarketState(e.target.checked)}
                                color="primary"                                                 
                            />
                            {marketState? 'Activo':'Inactivo'}
                        </Grid> 
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleMarketUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleMarketDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Mercados";
        authFetch("/api/check-markets?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.markets && response.cmc){
                    setMarketList(response.markets)
                    setMaxKey(response.max_key)
                    ////console.log(response)
                    setCmcList(response.cmc)
                }
            })
    }, [])

    return (
        <div className={classes.root}>
            {showMarketDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de mercados actuales:</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "5%"}}>No.</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "20%"}}>Nombre</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Área de distribución</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Comercializador Inc./OR</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Estado</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Periodo de ingreso</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Periodo asig. cargos (R 015/18)</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Periodo aprob. plan de pérdidas</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "5%"}}>Código SUI</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {marketList.map((row,idx) => (
                                            <StyledTableRow key={idx} onClick={() => {updateValuesWithSelectedMarket(idx); handleMarketDialogOpen();}}>
                                                <StyledTableCell align="center">{idx + 1}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.name}</StyledTableCell>
                                                <StyledTableCell align="center">{row.area}</StyledTableCell>
                                                <StyledTableCell align="center">{row.name_comer_inc}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.state ? 'Activo':'Inactivo'}</StyledTableCell>
                                                <StyledTableCell align="center" >{String(row.entry_date).slice(4,7)+'/'+String(row.entry_date).slice(0,4)}</StyledTableCell>
                                                <StyledTableCell align="center">{row.res_date ? String(row.res_date).slice(4,7)+'/'+String(row.res_date).slice(0,4) : '-'}</StyledTableCell>
                                                <StyledTableCell align="center">{row.loss_plan_date ? String(row.loss_plan_date).slice(4,7)+'/'+String(row.loss_plan_date).slice(0,4) : '-'}</StyledTableCell>
                                                <StyledTableCell align="center">{row.siu_code}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{ handleMarketDialogOpen(); setMarketID(maxKey + 1); setMarketResDate(null); setMarketLossPlanDate(null);}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>      
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}