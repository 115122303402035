import {React, useEffect, useState, Fragment, useContext, createContext, useMemo} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';
import DataGrid from 'react-data-grid';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    filterInput100: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        maxWidth: 100          
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));


export default function Summary(props) {
    const classes = useStyles();   
    //let billingDate = new Date(auxDate.setMonth(auxDate.getMonth() + 1)) 
    let billingDate = props.billingDate    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    
    const [cycles, setCycles] = useState([])
    const [cycleUsers, setCycleUsers] = useState([])
    const [cycleLiqConsumption, setCycleLiqConsumption] = useState({})
    const [cycleLiqAgreement, setCycleLiqAgreement] = useState({})
    const [cycleBillParams, setCycleBillParams] = useState({})
    const [cycleBillGenerated, setCycleBillGenerated] = useState({})
    const [selectedCycle, setSelectedCycle] = useState("")
    const [generationInProgress, setGenerationInProgress] = useState(false)
    const [generationMsg, setGenerationMsg] = useState("")
    const [downloadInProgress, setDownloadInProgress] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    // Deberian cambiar si se cambia el period Date    
    const [cycleLiqConsumptionFlag, setCycleLiqConsumptionFlag] = useState(false)
    const [cycleLiqAgreementFlag, setCycleLiqAgreementFlag] = useState(false)
    const [cycleBillParamsFlag, setCycleBillParamsFlag] = useState(false)
    const [cycleBillGeneratedFlag, setCycleBillGeneratedFlag] = useState(false)
   

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const inputsStateList = (files, filesState) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Liquidación y parámetros</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                    <Grid container justify="flex-start">           
                        <Grid item xs={7} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={7}>
                                    {filename}
                                    </Grid>                                          
                                    <Grid item xs={5}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }
                           
            </Grid>             
        </Fragment>            
    )     

    const callBillingGeneration = () => {
        setGenerationInProgress(true)             
        setGenerationMsg("Procesando datos para las facturas... ")  
        authFetch("/api/generate-cycle-consumption-images?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+selectedCycle).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setGenerationInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {             
                if (response && response.Upload === 'OK'){
                    setGenerationMsg("Construyendo archivo... ") 
                    authFetch("/api/generate-cycle-billing?username=" + String(props.username)+
                        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                        "&cycle="+selectedCycle).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                    else if (response.status !== 200){
                        handleFeedbackDialogOpen()
                        setFeedbackMsg("Error en el proceso.") 
                        setGenerationMsg("")
                        setGenerationInProgress(false)
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.Upload === 'OK'){                                                      
                            handleFeedbackDialogOpen()
                            setFeedbackMsg("Facturas generadas.") 
                            setGenerationMsg("")
                            setGenerationInProgress(false)                                                
                        }
                        else{
                            handleFeedbackDialogOpen()
                            setFeedbackMsg("Error en el proceso.") 
                            setGenerationMsg("")
                            setGenerationInProgress(false)
                        }
                    })
                }                                                                 
            })                 
         
        
    }
 
    const downloadBilling = () => {
        setDownloadInProgress(true)
        authFetch("/api/download-cycle-billing-file?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+selectedCycle + "&timestamp=" + new Date().toISOString().slice(0,19),{ responseType: 'blob' }).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setDownloadInProgress(false)             
                return null
            }
                return response.blob()
            }).then(blob => {                 
                setDownloadInProgress(false)            
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;    
                a.download = 'Facturas_'+props.username +'_'+ billingDate.getFullYear() +'_'+String( billingDate.getMonth() + 1 ) +
                '_'+'Ciclo'+'_'+selectedCycle+'.pdf' 
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);                                               
            })
    }

    const test = () => {
        authFetch("/api/generate-cycle-consumption-images?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+selectedCycle).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setDownloadInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {                                                                              
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const updateFlags = (value) => {
        setCycleLiqAgreementFlag(cycleLiqAgreement[value])        
        setCycleLiqConsumptionFlag(cycleLiqConsumption[value])
        setCycleBillParamsFlag(cycleBillParams[value])
        setCycleBillGeneratedFlag(cycleBillGenerated[value])
    }
    

    useEffect (() => {
        document.title ="Generar facturas - Legacy | Facturador";        
        
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){                                                      
                    setCycles(response.cycles)                                                          
                }
            })      
        authFetch("/api/get-cycle-users?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycle_users){   
                    let cycleUsers = response.cycle_users               
                    setCycleUsers(response.cycle_users)
                    let opts = {
                        users_by_cycle: cycleUsers,
                        year: billingDate.getFullYear(),
                        month: String( billingDate.getMonth() + 1 )
                    }  
                    authFetch("/api/check-billing-generation-params?username=" + String(props.username) +
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                    {
                        method: 'post',
                        body: JSON.stringify(opts)
                    }
                    ).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {                                          
                            if (response && response.params_flags){                                                  
                                setCycleLiqConsumption(response.consumption_flags)
                                setCycleLiqAgreement(response.munip_tax_flags)    
                                setCycleBillParams(response.params_flags)
                                setCycleBillGenerated(response.bill_generated_flags)
                                if (selectedCycle != ""){
                                    setCycleLiqAgreementFlag(response.munip_tax_flags[selectedCycle])        
                                    setCycleLiqConsumptionFlag(response.consumption_flags[selectedCycle])
                                    setCycleBillParamsFlag(response.params_flags[selectedCycle])
                                    setCycleBillGeneratedFlag(response.bill_generated_flags[selectedCycle])
                                }                            
                            }
                        })                                     
                }
            })  
           
        // if (selectedCycle !== ""){
        //     ////console.log("consultando clientes")
        //     setLoadingData(true)           
            
        //     authFetch("/api/check-cycle-mun-agreement-liquidation?username=" + String(props.username)+
        //     "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
        //     "&cycle="+selectedCycle).then(response => {
        //         if (response.status === 401){
        //             ////console.log("No autorizado.")
        //             return null
        //         }
        //             return response.json()
        //         }).then(response => {
        //             setLoadingData(false)
        //             if (response && response.mun_agreement_liquidation){                            
        //                 let liquidation = response.mun_agreement_liquidation
        //                 liquidation.forEach((obj, idx) => {liquidation[idx] = {...obj, 
        //                                             stratum_or_conn_level: obj['use_type'] != 'RESIDENCIAL' ? obj['conn_level'] : obj['stratum'], 
        //                                             collect_liquidation: obj['APL'] ? parseFloat(obj['APL']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) : '-', 
        //                                         }; })                                                 
        //                 setRows(liquidation)                                                                                           
        //             }
        //         })
        // }
    }, [props.billingDate, selectedCycle, generationInProgress])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                                
                <Grid item xs={12} style={{marginTop: 10}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Generar facturas por ciclo</Typography>
                    <Grid container justify="space-evenly" spacing={1} alignItems="flex-start">
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Ciclo:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                select
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={selectedCycle}
                                onChange={(e) => {setSelectedCycle(e.target.value); updateFlags(e.target.value);}}
                            >
                                {
                                    cycles.map((cycle,idx) => (
                                        cycleUsers[cycle.code] > 0 ?
                                        <MenuItem key={idx} value={cycle.code}>{cycle.code}</MenuItem>
                                        :
                                        null
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6} className={classes.darkText}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Insumos necesarios</Typography>
                            {
                                cycles.length > 0 && selectedCycle !== ""?
                                inputsStateList(['Liquidación aprobada consumos ciclo '+selectedCycle, 
                                                 'Liquidación aprobada impuestos ciclo '+selectedCycle, 
                                                 'Parametrización mensual facturas ciclo '+selectedCycle,],[cycleLiqConsumptionFlag, cycleLiqAgreementFlag, cycleBillParamsFlag])
                                :
                                null
                            }                            
                        </Grid>                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Control de aprobación</Typography>
                            <Grid container justify="space-evenly" spacing={1} style={{marginTop:40}}>
                                <Grid item>
                                    {
                                        generationInProgress ?
                                        <Grid container spacing={2} justify="center">
                                            <CircularProgress></CircularProgress>
                                            <Grid item xs={12}></Grid>
                                            <Typography variant="subtilte2" color="primary">{generationMsg}</Typography>
                                        </Grid>
                                        
                                        :
                                        <Button 
                                            onClick={(e) => {callBillingGeneration();}} 
                                            color='primary' 
                                            disabled={!cycleLiqConsumptionFlag || !cycleLiqAgreementFlag || !cycleBillParamsFlag || cycleBillGeneratedFlag}  
                                            variant='contained'
                                        >
                                            Generar
                                        </Button>
                                    }                                    
                                </Grid>
                                <Grid item>
                                    {
                                        downloadInProgress ? 
                                        <CircularProgress></CircularProgress>
                                        :
                                        <Button 
                                            onClick={(e) => {downloadBilling();}} 
                                            color='primary' 
                                            disabled={!cycleBillGeneratedFlag} 
                                            variant='contained'
                                        >
                                            Descargar
                                        </Button>
                                    }                                    
                                </Grid>                                         
                            </Grid>
                        </Grid>                        
                    </Grid>                                                           
                </Grid>                 
            </Grid>   
            </Zoom>    
        </div>
    );
}