import {React, useEffect, useState, Fragment, useRef} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress,} from '@material-ui/core/'
import {authFetch, logout} from '../../auth';
import ReactToPrint from 'react-to-print';
import getPeriods from '../../const/yearPeriods';
import PrintIcon from '@material-ui/icons/Print';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import cedenarLogo from '../../cedenar_logo.png'
import EventIcon from '@material-ui/icons/Event';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {DatePicker} from '@material-ui/pickers';
import Plot from 'react-plotly.js';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#173866',
      color: 'white',
      padding: 3,
      borderRadius: 5
    },
    body: {
      backgroundColor: '#E6E3D8',
      fontSize: 12,
      color: '#173866',
      padding: 3,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {            
        borderRadius: 5
    },
  }))(TableRow);

const StyledTableCellHeader = withStyles((theme) => ({
    head: {
        backgroundColor: '#173866',
        color: 'white',
        padding: 2,
        borderRadius: 0,
        fontSize: 12,
      },
      body: {
        backgroundColor: '#E6E3D8',
        fontSize: 12,
        color: '#173866',
        padding: 2,
      },
}))(TableCell);

const StyledTableCellMatrix = withStyles((theme) => ({
    head: {
      backgroundColor: '#173866',
      color: 'white',
      padding: 3,
      borderRadius: 8,
      fontSize: 11,
    },
    body: {
      backgroundColor: '#E6E3D8',
      fontSize: 12,
      color: '#173866',
      padding: 3,
      borderBottom: '1px solid #173866',
      fontSize: 10,
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    calendarPaper:{
        background: theme.palette.dashboard.main,
        borderColor: theme.palette.dashboard.border,
        padding: 7,
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    darkText:{
        color: '#173866',
        fontWeight: 'bold',
    },
    infoNumberSmall:{
      background: '#173866', 
      padding:4, 
      borderRadius: 5,      
      color: 'white',
      fontSize: 10,
      textAlign: 'center'
    },
    typoStyle1:{
        background: '#173866',
        padding: 8,
        color: 'white',
        borderRadius: 5
    },
    typoStyle2:{
        background: '#E6E3D8',
        padding: 5,
        color: '#173866',
        borderRadius: 5
    },
    typoStyle3:{
        background: '#173866',
        padding: 3,
        color: 'white',
        borderRadius: 5
    },
    typoStyle3Gray:{
        background: '#E6E3D8',
        padding: 3,
        color: '#173866',
        borderRadius: 5
    },
     contentPaperWithPadding:{
        background: '#E6E3D8',
        paddingTop: 5,
        paddingBottom: 5
    },  
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    infoNumber:{
      background: '#173866', 
      padding:4, 
      borderRadius: 5,
      fontWeight: 'bold',
      color: 'white',
      fontsize: 7
    },
    infoText:{      
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",    
        padding: 5
    },  
    dialogContent: {
        fontSize: 14,
        color: theme.palette.secondary.main,        
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        fontWeight: 'normal'
    },  
    feedbackButton: {
        backgroundColor: theme.palette.dashboard.background,
        color: theme.palette.primary.main,        
        borderRadius: 0,     
        "&:hover": {        
            backgroundColor: theme.palette.dashboard.background,   
            color: theme.palette.primary.main,    
        }, 
        minHeight: '30px'
    },  
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    opTariffBackground:
    {
        backgroundColor: theme.palette.secondary.main
    }
}));

const pageStyle = `
  @page {
    size: landscape;
  }
`;

let calendarTheme =  createMuiTheme({
    palette: {        
        primary: {
            main: "#2E2B3E",            
        },
        secondary: {
            main: "#FFFFFF",                    
        },         
    },
    typography: {
        fontFamily: "Roboto Condensed"
    },
    overrides: {
        MuiCssBaseline: {
        '@global': {         
            '*::-webkit-scrollbar': {
            width: '9px',    
                    
            },
            '::-webkit-scrollbar-track': {
            background: "#f1f1f1",     
            marginTop: 10,
            marginBottom: 10,       
            },
            '::-webkit-scrollbar-thumb': {
            background: '#888',           
            }
        }
        }
    }
})

export default function TariffTree(props){
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null)
    let billingDate = selectedDate ? selectedDate : new Date()
    let monthIdx = billingDate.getMonth()
    let year = billingDate.getFullYear()
    let firstDay = '01'
    let lastDay = new Date(year, monthIdx+1, 0).getDate()    
    let monthAbr = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
    let monthCmp = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    let period = firstDay + ' ' + monthAbr[monthIdx] + ' - ' + lastDay + ' ' + monthAbr[monthIdx] + ' ' + year
    if (year === 2022 && (monthIdx === 6 ||  monthIdx === 7)) {
        period = '01 Jul - 31 Ago 2022'
    }
    var layout = {
        title: '',
        xaxis: {tickfont: {
            size: 10,
            color: 'rgb(107, 107, 107)'
          }},
        yaxis: {          
          tickfont: {
            size: 10,
            color: 'rgb(107, 107, 107)'
          }
        },
        legend: {
          x: 0,
          y: 5.0,
          bgcolor: 'rgba(255, 255, 255, 0)',
          bordercolor: 'rgba(255, 255, 255, 0)',
          font: {
            size: 10,            
          },
        },
        margin: {
            l: 30,
            r: 10,
            b: 20,
            t: 10,
            pad: 4
          },
        barmode: 'group',
        bargap: 0.5,
        bargroupgap: 0.2,
        width: 280,
        height: 220,        
      };

    let impEne = {
        x: [],
        y: [],
        name: 'Energía importada [kWh]',
        marker: {color: 'rgb(55, 83, 109)'},
        type: 'bar'
    }
    
    let expEne = {
        x: [],
        y: [],
        name: 'Energía exportada [kWh]',
        marker: {color: 'rgb(26, 118, 255)'},
        type: 'bar'
    }

    const [liquidationApprovedFlag, setLiquidationApprovedFlag] = useState(false)
    const publicationPdfRef = useRef(null);
    const [clientCode, setClientCode] = useState(props.user !== 'consulta-cedenar' ? props.user : '')    
    const [clientInfo, setClientInfo] = useState([])
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [importedEnergyObj, setImportedEnergyObj] = useState([])
    const [exportedEnergyObj, setExportedEnergyObj] = useState([])
    const [reportLowerLimit, setReportLowerLimit] = useState(null)
    const [reportUpperLimit, setReportUpperLimit] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [searchFlag, setSearchFlag] = useState(false)

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
    
    const checkUserData = (code) => {
        if (year === 2022 && (monthIdx === 6 ||  monthIdx === 7)) {
            authFetch("/api/check-user-report-data?username=" + String(props.username) + '&client_code=' + code
            +"&month="+String( 7 )+                              
            "&year="+ String( 2022 )).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                        if (response && response.user_data){                                                            
                            if ( Object.keys(response.user_data).length === 0){
                                setFeedbackMsg('Usuario no encontrado.')                                                
                                handleFeedbackDialogOpen()
                            }
                            else{
                                let prevUserData = response.user_data                                 
                                authFetch("/api/check-user-report-data?username=" + String(props.username) + '&client_code=' + code
                                +"&month="+String( 8 )+                              
                                "&year="+ String( 2022 )).then(response => {
                                    if (response.status === 422){
                                        logout()
                                        return null
                                    }
                                        return response.json()
                                    }).then(response => {
                                            if (response && response.user_data){                                                            
                                                if ( Object.keys(response.user_data).length === 0){
                                                    setFeedbackMsg('Usuario no encontrado.')                                                
                                                    handleFeedbackDialogOpen()
                                                }
                                                else{
                                                    setClientInfo([prevUserData, response.user_data])      
                                                    let auxImported1 = Object.create(impEne)
                                                    auxImported1.x = prevUserData.historic_periods
                                                    auxImported1.y = prevUserData.historic_consumption
                                                    
                                                    let auxImported2 = Object.create(impEne)
                                                    auxImported2.x = response.user_data.historic_periods
                                                    auxImported2.y = response.user_data.historic_consumption
                                                    
                                                    setImportedEnergyObj([auxImported1, auxImported2])

                                                    let auxExported1 = Object.create(expEne)
                                                    auxExported1.x = prevUserData.historic_periods
                                                    auxExported1.y = prevUserData.historic_generation
                                                    let auxExported2 = Object.create(expEne)
                                                    auxExported2.x = response.user_data.historic_periods
                                                    auxExported2.y = response.user_data.historic_generation
                                                    setExportedEnergyObj([auxExported1, auxExported2])
                                                }
                                        }
                                    })
                            }
                    }
                })             
        } 
        else {
            authFetch("/api/check-user-report-data?username=" + String(props.username) + '&client_code=' + code
            +"&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear()).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                        if (response && response.user_data){                                                            
                            if ( Object.keys(response.user_data).length === 0){
                                setFeedbackMsg('Usuario no encontrado.')                                                
                                handleFeedbackDialogOpen()
                            }
                            else{
                                setClientInfo([response.user_data])      
                                let auxImported = impEne
                                auxImported.x = response.user_data.historic_periods
                                auxImported.y = response.user_data.historic_consumption
                                setImportedEnergyObj([auxImported])

                                let auxExported = expEne
                                auxExported.x = response.user_data.historic_periods
                                auxExported.y = response.user_data.historic_generation
                                setExportedEnergyObj([auxExported])
                            }
                    }
                })
        }
    }

    const userReport = () => (
        <div ref={publicationPdfRef} style={{padding: 20, marginTop: 0}}>
         <style type="text/css" media="print">
         {"@page { size: landscape; }; \
           @media all { .pagebreak { display: block;}} \
           @media print { html, body { height: initial !important; overflow: initial !important; -webkit-print-color-adjust: exact;} .page-break { margin-top: 10px; display: block; page-break-before: auto;}}"
         }
        </style>
            { clientInfo.map((clientInfo, cidx) => (
            <Fragment>
            <Grid container spacing={1} alignItems="flex-start" justify="center" style={{marginTop: ((cidx === 0) ? -20 : 200)}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center" className={classes.darkText} style={{marginBottom: 0}}>ANEXO FACTURACIÓN AUTOGENERADORES</Typography>
                </Grid>
                <Grid item xs={7}>   
                    <Grid container justify="center">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <img 
                                src={cedenarLogo} 
                                style={{width: '100%', marginBottom: 0, marginTop: -10}} 
                                title="" 
                                alt=""
                            />                    
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>                                                                                                                    
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">Comportamiento energías: {' ' + monthCmp[monthAbr.indexOf(clientInfo.historic_periods[clientInfo.historic_periods.length-1].slice(0,3))] + ' ' + year}</Typography>
                        <Grid container style={{marginTop: 5}} spacing={1} justify="center">                   
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>                            
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Energía importada</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.active_energy.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>
                            {
                                clientInfo.measuring_type !== 'Consumos' ?
                                <Grid container>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        
                                        Lect. anterior: <br/>  
                                        {clientInfo.prev_lecture_act ? clientInfo.prev_lecture_act.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh' : ''}
                                                                
                                    </Grid>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        Lect. actual: <br/>  
                                        {clientInfo.last_lecture_act.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}
                                    </Grid>
                                </Grid>
                                :
                                null
                            }                        
                            </Grid>
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    {/* <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                                    </Grid> */}
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Energía inductiva</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.reactive_ind_pen_energy.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>  
                            {
                                clientInfo.measuring_type !== 'Consumos' ?
                                <Grid container>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        
                                        Lect. anterior: <br/>  
                                        {clientInfo.prev_lecture_act ? clientInfo.prev_lecture_ind.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh' : ''}
                                                                
                                    </Grid>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        Lect. actual: <br/>  
                                        {clientInfo.last_lecture_ind.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh'}
                                    </Grid>
                                </Grid>
                                :
                                null
                            }  
                            </Grid>
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    {/* <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                                    </Grid> */}
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Energía capacitiva</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.reactive_cap_energy.toLocaleString('en-US', {maximumFractionDigits: 2})+ ' kVArh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>  
                            {
                                clientInfo.measuring_type !== 'Consumos' ?
                                <Grid container>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        
                                        Lect. anterior: <br/>  
                                        {clientInfo.prev_lecture_act ? clientInfo.prev_lecture_cap.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh': ''}
                                                                
                                    </Grid>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        Lect. actual: <br/>  
                                        {clientInfo.last_lecture_cap.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh'}
                                    </Grid>
                                </Grid>
                                :
                                null
                            }   
                            </Grid>     
                                        
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    {/* <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                                    </Grid> */}
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Energía exportada</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.gen_exct.toLocaleString('en-US', {maximumFractionDigits: 2})+ ' kWh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>  
                            {
                                clientInfo.measuring_type !== 'Consumos' ?
                                <Grid container>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        
                                        Lect. anterior: <br/>  
                                        {clientInfo.prev_lecture_act ? clientInfo.prev_lecture_gen.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh': ''}
                                                                
                                    </Grid>
                                    <Grid item xs={6} className={classes.infoNumberSmall}>
                                        Lect. actual: <br/>  
                                        {clientInfo.last_lecture_gen.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}
                                    </Grid>
                                </Grid>
                                :
                                null
                            }   
                            </Grid>                    
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    {/* <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                                    </Grid> */}
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Excedentes 1</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.gen_exc1.toLocaleString('en-US', {maximumFractionDigits: 2})+ ' kWh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>    
                            </Grid>
                            <Grid item xs={4}>
                            <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    {/* <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                                    </Grid> */}
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.darkText}>Excedentes 2</Typography>                          
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" align="center" className={classes.infoNumber}>{clientInfo.gen_exc2.toLocaleString('en-US', {maximumFractionDigits: 2})+ ' kWh'}</Typography>                          
                                    </Grid>                                                  
                                </Grid>                
                            </Paper>    
                            </Grid>
                            
                        </Grid> 
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center" style={{marginTop: 10}}>Comportamiento histórico</Typography>
                    <Grid container alignItems="center" spacing={1} justify="center">
                        <Grid item xs={5}>
                        <TableContainer style={{marginTop: 10}}>
                            <Table>
                                <TableHead>
                                    <StyledTableCellHeader align="center" style={{width: '25%'}}>Mes</StyledTableCellHeader>
                                    <StyledTableCellHeader align="center" >Energía Importada kWh</StyledTableCellHeader>
                                    <StyledTableCellHeader align="center" >Energía Exportada kWh</StyledTableCellHeader>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(clientInfo).length > 0 ?
                                        clientInfo.historic_periods.map((month, idx) => (
                                            <StyledTableRow>
                                                <StyledTableCellHeader align="center" style={idx === clientInfo.historic_periods.length - 1 ? {background: '#173866', color: '#E6E3D8'} : null}>{month}</StyledTableCellHeader>
                                                <StyledTableCellHeader align="center" style={idx === clientInfo.historic_periods.length - 1 ? {background: '#173866', color: '#E6E3D8'} : null}>{clientInfo.historic_consumption[idx]}</StyledTableCellHeader>
                                                <StyledTableCellHeader align="center" style={idx === clientInfo.historic_periods.length - 1 ? {background: '#173866', color: '#E6E3D8'} : null}>{clientInfo.historic_generation[idx]}</StyledTableCellHeader>
                                            </StyledTableRow>
                                        ))
                                        :
                                        null
                                    }                                                                
                                </TableBody>
                            </Table>
                        </TableContainer> 
                        </Grid>
                        <Grid item xs={6}> 
                        <Plot
                            data={[importedEnergyObj[cidx], exportedEnergyObj[cidx]]}
                            layout={layout}
                            config={{displayModeBar: false, staticPlot: true}}
                        />                       
                        </Grid>
                    </Grid>         
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TableContainer style={{marginTop: 0}}>
                            <Table>
                                <TableHead>
                                    <StyledTableCell align="center" colSpan={2}>Pago de excedentes</StyledTableCell>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{fontWeight: 'bold'}} align="center">FORMA {clientInfo['exceed_payment'] ? clientInfo.exceed_payment : ''}</StyledTableCell>                                
                                    </StyledTableRow>                                                           
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <TableContainer style={{marginTop: 0}}>
                            <Table>
                                <TableHead>
                                    <StyledTableCell align="center" colSpan={2}>Saldos acumulados</StyledTableCell>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{fontWeight: 'bold'}} align="center">Saldo a favor del usuario</StyledTableCell>                                
                                    </StyledTableRow>          
                                    <StyledTableRow>                                
                                    <StyledTableCell style={{fontWeight: 'bold'}} align="center">{clientInfo.positive_balance > 0 ? clientInfo.positive_balance + ' $' : ''}</StyledTableCell>                                
                                    </StyledTableRow>                  
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>       
                </Grid>    
                <Grid item xs={5}>                
                    <Grid container>
                        <Grid item xs={4}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Código interno</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Nombre</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.client_code}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.client_name}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Dirección</Typography>  
                        </Grid>
                        <Grid item xs={3}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Capacidad</Typography>  
                        </Grid>
                        <Grid item xs={9}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.address}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.spec_capacity + ' kW'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">FNCER</Typography>  
                        </Grid>
                        <Grid item xs={4}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Tipo</Typography>  
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Medición</Typography>  
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.fncer}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{'FOTOVOLTAICO'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.measuring_type === 'Consumos' ? 'CONSUMOS' : 'LECTURAS ACUMULATIVAS'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">No. factura</Typography>  
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3} align="center">Periodo de facturación</Typography>  
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.bill_number}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{period}</Typography>
                        </Grid>
                        {
                            clientInfo.no_lecture_cause !== 'NA' ?
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.typoStyle3} align="center">CAUSA DE NO LECTURA</Typography>  
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.typoStyle3Gray} align="center">{clientInfo.no_lecture_cause}</Typography>
                            </Grid>
                            </Fragment>
                            :
                            null
                        }
                    </Grid>                        
                                    
                    <TableContainer style={{marginTop: 10}}>
                        <Table>
                            <TableHead>
                                <StyledTableCell align="center" colSpan={4}>Detalle de conceptos facturados</StyledTableCell>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell style={{fontWeight: 'bold', width: "35%"}} align="left">CONCEPTO</StyledTableCell>
                                    <StyledTableCell style={{fontWeight: 'bold', width: "21%"}} align="right">CONSUMO</StyledTableCell>
                                    <StyledTableCell style={{fontWeight: 'bold', width: "22%"}} align="right">TARIFA</StyledTableCell>
                                    <StyledTableCell style={{fontWeight: 'bold', width: "22%"}} align="right">VALOR</StyledTableCell>
                                </StyledTableRow>
                                {
                                    Object.keys(clientInfo).length > 0 ?
                                    Object.keys(clientInfo.energy_concepts).map((key) => (
                                        
                                        parseFloat(clientInfo['energy_concepts'][key]['value']) !== 0 ?
                                        <StyledTableRow>
                                            <StyledTableCell align="left">{clientInfo.energy_concepts[key].name}</StyledTableCell>
                                            <StyledTableCell align="right">{
                                                key !== '800' && key !== 'TOT' && key !== '753' && key !== '752' && key !== '730' && key !== '1' && key  !== '9000'?
                                                clientInfo.energy_concepts[key].energy + ((key !== '11' && key !== '12') ?  ' kWh' : ' kVArh')
                                                :
                                                '-'
                                            }
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{clientInfo.energy_concepts[key].tariff}</StyledTableCell>
                                            <StyledTableCell align="right">{parseFloat(clientInfo.energy_concepts[key].value).toLocaleString('en-US',{maximumFractionDigits: 2})} $</StyledTableCell>
                                        </StyledTableRow>
                                        :
                                        null
                                                                            
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>  
                    {                    
                        <TableContainer style={{marginTop: 10}}>
                        <Table>
                            <TableHead>
                                <StyledTableCell align="center" colSpan={2}>Fecha de pago</StyledTableCell>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                <StyledTableCell style={{fontWeight: 'bold'}} align="center">Pago de Cedenar al usuario. Fecha: {clientInfo.positive_balance > 0 ? new Date(clientInfo.p_balance_payment_date).toISOString().slice(0,10) : ''}</StyledTableCell>                               
                                </StyledTableRow>                                                       
                            </TableBody>
                        </Table>
                    </TableContainer>                   
                    }                                                        
                </Grid>         
            </Grid>
            {/* Hoja 2 */}
            <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Exportación total: ' + clientInfo.gen_exct.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}</Typography>
                    <TableContainer style={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                    [...Array(24).keys()].map((hour) => (
                                        <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                    ))
                                }
                            </TableHead>                   
                            <TableBody>
                                {
                                    'dates' in clientInfo ?
                                    clientInfo.dates.map((date, pidx) => (
                                        <StyledTableRow>
                                            <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                            {
                                                clientInfo.gen_matrix[pidx].map((val, sidx) => (
                                                    <StyledTableCellMatrix style={
                                                        clientInfo.change_row ? 
                                                            val !== 0 && pidx < clientInfo.change_row ? {background: '#88F25E50'}
                                                            :
                                                            val !== 0 && pidx === clientInfo.change_row  && sidx < clientInfo.change_column ? {background: '#88F25E50'}
                                                            : 
                                                            val !== 0 && pidx === clientInfo.change_row  && sidx === clientInfo.change_column ? {background: '#F5DB5C50'}
                                                            : 
                                                            val !== 0 && pidx === clientInfo.change_row  && sidx > clientInfo.change_column ? {background: '#7CE9F550'}
                                                            :
                                                            val !== 0 && pidx > clientInfo.change_row ? {background: '#7CE9F550'}
                                                            :
                                                            null
                                                        :
                                                        val !== 0 ? {background: '#88F25E50'}
                                                        :
                                                        null
                                                        }>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                ))
                                            }
                                        </StyledTableRow>
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Hoja 3 */}
            <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Excedentes 1: ' + clientInfo.gen_exc1.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}</Typography>
                    <TableContainer style={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                    [...Array(24).keys()].map((hour) => (
                                        <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                    ))
                                }
                            </TableHead>                   
                            <TableBody>
                                {
                                    'dates' in clientInfo ?
                                    clientInfo.dates.map((date, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                            {
                                                clientInfo.gen1_matrix[idx].map((val) => (
                                                    <StyledTableCellMatrix style={val !== 0 ? {background: '#88F25E50'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                ))
                                            }
                                        </StyledTableRow>
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Hoja 4 */}
            <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                <Grid item xs={12}>                                
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Excedentes 2: ' + clientInfo.gen_exc2.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kWh'}</Typography>
                    <TableContainer style={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                    [...Array(24).keys()].map((hour) => (
                                        <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                    ))
                                }
                            </TableHead>                   
                            <TableBody>
                                {
                                    'dates' in clientInfo ?
                                    clientInfo.dates.map((date, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                            {
                                                clientInfo.gen2_matrix[idx].map((val) => (
                                                    <StyledTableCellMatrix style={val !== 0 ? {background: '#7CE9F550'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                ))
                                            }
                                        </StyledTableRow>
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Hoja 5 y 6 */}
            {
                clientInfo.gen_exc2 > 0 ?
                <Fragment>
                <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                    <Grid item xs={12}>                                
                        <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'PBNA (h)'}</Typography>
                        <TableContainer style={{marginTop: 3}}>
                            <Table>
                                <TableHead>
                                    <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                        [...Array(24).keys()].map((hour) => (
                                            <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                        ))
                                    }
                                </TableHead>                   
                                <TableBody>
                                    {
                                        'dates' in clientInfo ?
                                        clientInfo.dates.map((date, idx) => (
                                            <StyledTableRow>
                                                <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                                {
                                                    clientInfo.pb[idx].map((val) => (
                                                        <StyledTableCellMatrix style={val !== 0 ? {background: '#F5C56050'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                    ))
                                                }
                                            </StyledTableRow>
                                        ))
                                        :
                                        null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                
                <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                    <Grid item xs={12}>                                
                        <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Valores Excedentes 2 $'}</Typography>
                        <TableContainer style={{marginTop: 3}}>
                            <Table>
                                <TableHead>
                                    <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                        [...Array(24).keys()].map((hour) => (
                                            <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                        ))
                                    }
                                </TableHead>                   
                                <TableBody>
                                    {
                                        'dates' in clientInfo ?
                                        clientInfo.dates.map((date, idx) => (
                                            <StyledTableRow>
                                                <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                                {
                                                    clientInfo.exc2_value[idx].map((val) => (
                                                        <StyledTableCellMatrix style={val !== 0 ? {background: '#F5C56050'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                    ))
                                                }
                                            </StyledTableRow>
                                        ))
                                        :
                                        null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                </Fragment>
                :
                null
            }
            {/* Hoja 7 */}
            <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                <Grid item xs={12}>                                
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Inductiva penalizada: ' + clientInfo.reactive_ind_pen_energy.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh'}</Typography>
                    <TableContainer style={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                    [...Array(24).keys()].map((hour) => (
                                        <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                    ))
                                }
                            </TableHead>                   
                            <TableBody>
                                {
                                    'dates' in clientInfo ?
                                    clientInfo.dates.map((date, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                            {
                                                clientInfo.ind_matrix[idx].map((val) => (
                                                    <StyledTableCellMatrix style={val !== 0 ? {background: '#D680EB50'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                ))
                                            }
                                        </StyledTableRow>
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Hoja 8 */}
            <Grid container spacing={2} alignItems="flex-start" justify="center" style={{marginTop: 200}}>
                <Grid item xs={12}>                                
                    <Typography variant="subtitle2" className={classes.typoStyle3} align="center">{'Capacitiva: ' + clientInfo.reactive_cap_energy.toLocaleString('en-US', {maximumFractionDigits: 2}) + ' kVArh'}</Typography>
                    <TableContainer style={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <StyledTableCellMatrix align="center">Fecha/Hora</StyledTableCellMatrix>{
                                    [...Array(24).keys()].map((hour) => (
                                        <StyledTableCellMatrix align="center" style={{width: '3.75%'}}>{String(hour).length === 1 ? '0' + hour : hour}</StyledTableCellMatrix>
                                    ))
                                }
                            </TableHead>                   
                            <TableBody>
                                {
                                    'dates' in clientInfo ?
                                    clientInfo.dates.map((date, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCellMatrix align="center">{date}</StyledTableCellMatrix>
                                            {
                                                clientInfo.cap_matrix[idx].map((val) => (
                                                    <StyledTableCellMatrix style={val !== 0 ? {background: '#D680EB50'}:null}>{val !== 0 ? val : '  '}</StyledTableCellMatrix>
                                                ))
                                            }
                                        </StyledTableRow>
                                    ))
                                    :
                                    null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </Fragment>))}
        </div>
    )

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} className={classes.feedbackButton} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
    
    useEffect (() => {
        setClientInfo([])       
        if (selectedDate){
            setIsLoading(true)
            if (props.user !== 'consulta-cedenar'){
                authFetch(
                    "/api/check-ag-liquidation-status?username="+props.username+
                                    "&month="+String( billingDate.getMonth() + 1 )+                              
                                    "&year="+ billingDate.getFullYear()
                )
                .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                    if (response && response.liquidation_approved[0]){                                     
                        setLiquidationApprovedFlag(response.liquidation_approved[0])
                        checkUserData(props.user)
                        setIsLoading(false)
                    }    
                    else{
                        setLiquidationApprovedFlag(false)
                        setFeedbackMsg('Información no encontrada.')                                                
                        handleFeedbackDialogOpen()
                        setIsLoading(false)
                    }         
                })
            }
            else {
                authFetch(
                    "/api/check-ag-liquidation-status?username="+props.username+
                                    "&month="+String( billingDate.getMonth() + 1 )+                              
                                    "&year="+ billingDate.getFullYear()
                )
                .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                    if (response && response.liquidation_approved[0]){                                     
                        setLiquidationApprovedFlag(response.liquidation_approved[0])
                        checkUserData(clientCode)
                        setIsLoading(false)
                    }    
                    else{
                        setLiquidationApprovedFlag(false)
                        setFeedbackMsg('Información no encontrada.')                                                
                        handleFeedbackDialogOpen()
                        setIsLoading(false)
                    }         
                })
            }
        }                                
        
    }, [selectedDate])

    useEffect (() => {        
        if (props.user !== 'consulta-cedenar'){
            authFetch(
                "/api/check-user-report-limits?client_code="+props.user
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.lower_limit){  
                    let lowLimit = new Date(response.lower_limit)                                   
                    let upLimit = new Date(response.upper_limit)
                    setReportLowerLimit(lowLimit)
                    setReportUpperLimit(upLimit)
                    setSelectedDate(upLimit)
                }                    
            })
        }
        else if (clientCode !== '') {
            authFetch(
                "/api/check-user-report-limits?client_code="+clientCode
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.lower_limit){  
                    let lowLimit = new Date(response.lower_limit)                                   
                    let upLimit = new Date(response.upper_limit)
                    setReportLowerLimit(lowLimit)
                    setReportUpperLimit(upLimit)
                    setSelectedDate(upLimit)
                }       
                else{
                    setFeedbackMsg('No se encontró información para el código proporcionado.')                                                                            
                    handleFeedbackDialogOpen()
                }             
            })
        }
        document.title ="Reporte usuario | Autogeneradores Cedenar"                               
    }, [searchFlag])

    return (
        <div className={classes.root}> 
            {showFeedbackDialog()}                 
            <Zoom in timeout={700}>     
            <Grid container justify="center" spacing={1}>     
                {
                    props.user === 'consulta-cedenar' ? 
                    <Grid item xs={3}>
                        <Paper className={classes.calendarPaper} elevation={0} variant="outlined">
                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>                            
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código de producto)</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField 
                                    variant="outlined"                                    
                                    size="small"
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    className={classes.formRow} 
                                    value={clientCode}
                                    onChange={(e) => {setClientCode(e.target.value); setClientInfo([]); setLiquidationApprovedFlag(false);}}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter'){
                                            let aux = searchFlag; 
                                            setSearchFlag(!aux);
                                        }
                                    }}
                                >

                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton color="primary" onClick={(e) => {let aux = searchFlag; setSearchFlag(!aux);}}>
                                    <SearchOutlinedIcon className={classes.searchButton} />
                                </IconButton>                                        
                            </Grid>                             
                        </Grid> 
                        </Paper>
                    </Grid> 
                    :
                    null
                }
                {
                    isLoading ?
                    <Grid item xs={12}>
                    <CircularProgress/> 
                    </Grid>                   
                    :
                    <Fragment>
                    {
                        clientInfo.length === 0 && selectedDate === null ?
                        null
                        :
                        <Fragment>
                        <Grid item xs={12}>                            
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={classes.calendarPaper} elevation={0} variant="outlined">                                
                                <Grid container justify="center" spacing={0} alignItems="flex-start">
                                    <Grid item>
                                        <EventIcon color="primary"/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <ThemeProvider theme={calendarTheme}>
                                        <DatePicker    
                                            variant="dialog"    
                                            inputVariant="filled"                                                                                                                            
                                            okLabel={""}
                                            cancelLabel={""}
                                            openTo="month"
                                            format="MMMM-yyyy"
                                            minDate={reportLowerLimit}
                                            maxDate={reportUpperLimit}
                                            views={["year","month"]}                                
                                            value={selectedDate}
                                            InputLabelProps={{                                            
                                                        classes: {
                                                            root: classes.labelText,                                    
                                                        }
                                                    }}
                                            onChange={setSelectedDate}
                                            error={false}
                                            InputProps={{                                            
                                                        classes: {
                                                            input: classes.formText,                                    
                                                        }
                                                    }}
                                        
                                            autoOk                                                                                  
                                        />        
                                        </ThemeProvider>
                                    </Grid>                                                         
                                </Grid>                                
                            </Paper> 
                        </Grid>
                        </Fragment>
                    }                    
                    {   
                        clientInfo.length === 0 ?
                            liquidationApprovedFlag ? 
                            <Grid item xs={12}>
                            <Typography color="primary">Cargando...</Typography>
                            </Grid>
                            :
                            null
                        :
                        <Fragment>                                      
                            <Grid item xs={12}>
                                <div style={{display: 'none'}}>
                                <Paper elevation={2}>
                                {userReport()}
                                </Paper>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                            <Fragment style={{marginTop: 20}}>                    
                                <ReactToPrint
                                    trigger={() => 
                                        <Button color='primary' variant='contained' startIcon={<PrintIcon/>}>Imprimir reporte</Button>
                                    }
                                    content={() => publicationPdfRef.current}
                                    documentTitle={clientCode}
                                    //pageStyle={pageStyle}
                                /> 
                            </Fragment>  
                            </Grid>
                        </Fragment>
                    }  
                    </Fragment>
                }                                                                                                                              
            </Grid>      
            </Zoom>                      
        </div>
    )
}