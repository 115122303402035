import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';
import { FormatColorResetOutlined } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    let billingDate = props.billingDate    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [clientCode, setClientCode] = useState("")    
    const [sicCode, setSicCode] = useState("")    
    const [clientCycle, setClientCycle] = useState("")
    const [billingState, setBillingState] = useState({})
    const [energyConcepts, setEnergyConcepts] = useState({})
    const [otherConcepts, setOtherConcepts] = useState({})
    const [taxConcepts, setTaxConcepts] = useState({})
    const [creditConcepts, setCreditConcepts] = useState({})
    const [downloading, setDownloading] = useState(false)

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
   
    const checkUserConcepts = (clientCode) => {
        authFetch("/api/check-client-liquidation-concepts?username=" + String(props.username) + '&client_code=' + clientCode+
        "&month="+String( billingDate.getMonth() + 1 )+                                                    
        "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.client_liquidation){                                        
                    if (Object.keys(response.client_liquidation).length === 0){
                        setFeedbackMsg('No se encontró liquidación para el código proporcionado.')
                        handleFeedbackDialogOpen()
                    }                    
                    else if (Object.keys(response.client_liquidation).length > 1){
                        
                        let energyConcepts = response.client_liquidation.energy_concepts
                        //console.log(energyConcepts)
                        energyConcepts['ST_C'] = energyConcepts.CO
                        energyConcepts['EA_Z'] = energyConcepts.EA
                        delete energyConcepts.CO
                        delete energyConcepts.EA
                        setEnergyConcepts(energyConcepts)
                        setOtherConcepts(response.client_liquidation.other_concepts)
                        setTaxConcepts(response.client_liquidation.tax_collect_concepts)
                        setCreditConcepts(response.client_liquidation.credit_concepts)
                        setSicCode(response.client_liquidation.sic_code)
                        setClientCycle(response.client_liquidation.cycle)
                        let cycle = response.client_liquidation.cycle                        
                        authFetch("/api/check-billing-status?username=" + String(props.username) + '&cycle=' + cycle+
                        "&month="+String( billingDate.getMonth() + 1 )+                                                    
                        "&year="+ billingDate.getFullYear()).then(response => {
                            if (response.status === 422){
                                logout()
                                return null
                            }
                                return response.json()
                            }).then(response => {
                                if (response){                                                                                                                
                                    setBillingState(response)
                                }
                            })                        
                    }
                }
            })
    }

    const downloadBilling = () => {
        setDownloading(true)
        authFetch("/api/download-user-billing-file?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+clientCycle + "&client_code="+clientCode + "&timestamp=" + new Date().toISOString().slice(0,19),{ responseType: 'blob' }).then(response => {
            if (response.status === 422){
                logout()
                setDownloading(false)
                return null
            }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")   
                setDownloading(false)                       
                return null
            }
                return response.blob()
            }).then(blob => {             
                setDownloading(false)                                
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;    
                a.download = 'FACTURA_RNV_'+ sicCode +'_'+ billingDate.getFullYear() +'_'+String( billingDate.getMonth() + 1 ) +'.pdf' 
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);                                               
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 

    useEffect (() => {
        document.title ="Liquidación por cliente - Legacy | Facturador";                        
    }, [])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                                
                <Grid item xs={12} style={{marginTop: 10}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Consultar conceptos liquidados por cliente</Typography>
                    <Grid container justify="center" spacing={1} alignItems="flex-start">
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={clientCode}
                                onChange={(e) => {setClientCode(e.target.value)}}
                            >

                            </TextField>
                        </Grid>
                        <Grid item>
                            <SearchOutlinedIcon color="primary" fontSize={'large'} onClick={(e) => {checkUserConcepts(clientCode)}}/>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop: 10}} spacing={1} justify="space-evenly">
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Conceptos de energía</Typography>                                
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        {
                                            ['Concepto', 'Energía', 'Valor $'].map((label, idx)=>(
                                                <StyledTableCell key={idx} align="left">{label}</StyledTableCell>
                                            ))
                                        }                       
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            Object.keys(energyConcepts).sort().map((concept, cidx) => (
                                                <StyledTableRow key={cidx}>
                                                    <StyledTableCell>{energyConcepts[concept].name}</StyledTableCell>
                                                    <StyledTableCell>{energyConcepts[concept].energy !== '-' && energyConcepts[concept].energy !== 'NA'? Number(energyConcepts[concept].energy).toLocaleString('en-US', {maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                                    <StyledTableCell>{Number(energyConcepts[concept].value).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Otros conceptos</Typography>                                
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        {
                                            ['Concepto', 'Valor $'].map((label, idx)=>(
                                                <StyledTableCell key={idx} align="left">{label}</StyledTableCell>
                                            ))
                                        }                       
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            Object.keys(otherConcepts).sort().map((concept, cidx) => (
                                                <StyledTableRow key={cidx}>
                                                    <StyledTableCell>{otherConcepts[concept].name}</StyledTableCell>                                                    
                                                    <StyledTableCell>{Number(otherConcepts[concept].value).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Impuestos</Typography>                                
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        {
                                            ['Concepto', 'Valor $'].map((label, idx)=>(
                                                <StyledTableCell key={idx} align="left">{label}</StyledTableCell>
                                            ))
                                        }                       
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            Object.keys(taxConcepts).sort().map((concept, cidx) => (
                                                <StyledTableRow key={cidx}>
                                                    <StyledTableCell>{taxConcepts[concept].name}</StyledTableCell>                                                    
                                                    <StyledTableCell>{Number(taxConcepts[concept].value).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                        </Grid>                        
                        {
                            Object.keys(creditConcepts).length > 0 ?
                            <Grid item xs={10}>
                                <Typography variant="subtitle2" className={classes.sectionTitle}>Créditos</Typography>                                
                                <Table>
                                    <TableHead>
                                        <StyledTableRow>
                                            {
                                                ['Descripción', 'Valor total', 'Saldo final periodo', 'Pago a capital', 'Interés Cte.', 'Vr. cuota mes',
                                                'Tasa interés', 'No. cuota'].map((label, idx)=>(
                                                    <StyledTableCell key={idx} align="left">{label}</StyledTableCell>
                                                ))
                                            }                       
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                            {
                                                Object.keys(creditConcepts).sort().map((concept, cidx) => (
                                                    concept !== 'TOT'?
                                                    <StyledTableRow key={cidx}>
                                                        <StyledTableCell>{concept}</StyledTableCell>                                                    
                                                        <StyledTableCell>{Number(creditConcepts[concept].total).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{Number(creditConcepts[concept].balance).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{Number(creditConcepts[concept].capital_pay).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{Number(creditConcepts[concept].interest_pay).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{Number(creditConcepts[concept].month_pay).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{Number(creditConcepts[concept].interest_rate).toLocaleString('en-US', {maximumFractionDigits: 2})}</StyledTableCell>
                                                        <StyledTableCell>{creditConcepts[concept].fee_number}</StyledTableCell>
                                                    </StyledTableRow>
                                                    :
                                                    []                                                   
                                                ))
                                            }
                                    </TableBody>
                                </Table>
                            </Grid>
                            :
                            null
                        }      
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>SUBTOTAL factura</Typography>  
                            {
                                Object.keys(taxConcepts).length > 0 ?
                                <Typography variant="subtitle2" className={classes.sectionTitle}>{
                                         (Number(energyConcepts['TOT'].value) +
                                         Number(taxConcepts['TOT'].value) + 
                                         Number(otherConcepts['TOT'].value)).toLocaleString('en-US', {minimumFractionDigits: 2})}</Typography>
                                :                                
                                null
                            }                                                          
                        </Grid>  
                        <Grid item xs={9}>                             
                            {
                                Object.keys(billingState).length > 0 ?
                                    downloading ?
                                    <CircularProgress/>
                                    :
                                    <Button 
                                        onClick={(e) => {downloadBilling();}} 
                                        color='primary' 
                                        disabled={
                                            !billingState.billing_generated || 
                                            !billingState.historic_consumption_active
                                        } 
                                        variant='contained'
                                    >
                                        Descargar
                                    </Button>
                                :
                                null
                            }                                                          
                        </Grid>                 
                    </Grid>                    
                </Grid> 
                                                                                                    
            </Grid>   
            </Zoom>    
        </div>
    );
}