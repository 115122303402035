import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import PublishIcon from '@material-ui/icons/Publish';
import TimelineIcon from '@material-ui/icons/Timeline';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import TuneIcon from '@material-ui/icons/Tune';
import GetAppIcon from '@material-ui/icons/GetApp';

const mainNav = [
    {
        label: "tarifario",
        href: "/webcedenar/principal/",
        idx: 0,
        disabled: false,
        sideNav: [            
            {
                title: 'Cálculo de tarifas',
                icon: <ViewListIcon/>,
                nav: [
                    {
                        label: "Consola de componentes",
                        href: "/webcedenar/principal/consola-componentes",
                    },                      
                    {
                        label: "Opción tarifaria",
                        href: "/webcedenar/principal/opcion-tarifaria",
                    },  
                    {
                        label: "Reliquidaciones",
                        href: "/webcedenar/principal/reliquidaciones",
                    }, 
                    {
                        label: "Arbol tarifario",
                        href: "/webcedenar/principal/arbol-tarifario",
                    },        
                    {
                        label: "Publicación",
                        href: "/webcedenar/principal/publicar-tarifas",
                    },    
                    {
                        label: "Alumbrado público",
                        href: "/webcedenar/principal/alumbrado-publico",
                    },                         
                ]
            },
            {
                title: 'Ajustes internos',
                icon: <SettingsIcon/>,
                nav: [
                    //  {
                    //     label: "Configuración principal",
                    //     href: "/webspectrum/principal/administracion",                        
                    //  },
                    {
                        label: "Mercados",
                        href: "/webcedenar/principal/mercados",
                    },
                    {
                        label: "Contratos",
                        href: "/webcedenar/principal/contratos",
                    },
                    {
                        label: "Actores financieros",
                        href: "/webcedenar/principal/actores-financieros",
                    },
                ]
            },
            {
                title: 'Insumos',
                icon: <PublishIcon/>,
                nav: [
                    {
                        label: "Archivos",
                        href: "/webcedenar/principal/validacion-archivos",
                    },
                    {
                        label: "Parámetros",
                        href: "/webcedenar/principal/validacion-parametros",
                    }
                ]
            },
            {
                title: 'Reportes',
                icon: <TimelineIcon/>,
                nav: [
                    {
                        label: "SUI",
                        href: "/webcedenar/principal/reportes-sui",
                    }, 
                    {
                        label: "Informe comercialización",
                        href: "/webcedenar/principal/informe-comercializacion",
                    },
                    {
                        label: "Balance energético",
                        href: "/webcedenar/principal/balance-energetico",
                    },              
                    {
                        label: "Liquidación STR",
                        href: "/webcedenar/principal/liquidacion-str",
                    },       
                ]
            },
            // {
            //     title: 'Componentes',
            //     icon: <ViewListIcon/>,
            //     nav: [
            //         {
            //             label: "Consola de estado",
            //             href: "/webspectrum/principal/consola-componentes",
            //         },                    
            //     ]
            // }
        ]
    },
    {
        label: "Fotovoltaicos",
        href: "/webcedenar/autogeneradores/",
        idx: 1,
        disabled: false,
        sideNav: [            
            {
                title: 'Parametrización',
                icon:  <TuneIcon/>,
                nav: [          
                    {
                        label: "Ciclos",
                        href: "/webcedenar/autogeneradores/parametrizacion-ciclos",
                    },                
                    {
                        label: "Precios pactados",
                        href: "/webcedenar/autogeneradores/parametrizacion-precios-pactados",
                    },                              
                    {
                        label: "Conceptos",
                        href: "/webcedenar/autogeneradores/parametrizacion-conceptos",
                    },   
                    {
                        label: "Causas no lectura",
                        href: "/webcedenar/autogeneradores/parametrizacion-causas-no-lectura",
                    },                             
                ],
            },
            {
                title: 'Usuarios',
                icon:  <GroupIcon/>,
                nav: [
                    {
                        label: "Control principal",
                        href: "/webcedenar/autogeneradores/usuarios",
                    },                                                          
                ]
            }, 
            {
                title: 'Consumos',
                icon:  <AssessmentIcon/>,
                nav: [
                    {
                        label: "Control de archivos",
                        href: "/webcedenar/autogeneradores/control-archivos",
                    },   
                    {
                        label: "Información por cliente",
                        href: "/webcedenar/autogeneradores/consumos-cliente",
                    },                                                      
                ]
            },
            {
                title: 'Liquidación de consumos',
                icon:  <LocalAtmIcon/>,
                nav: [
                    {
                        label: "Liquidar",
                        href: "/webcedenar/autogeneradores/liquidacion-consumos",
                    },                                                     
                ]
            }, 
            {
                title: 'Exportar información',
                icon:  <GetAppIcon/>,
                nav: [
                    {
                        label: "Anexo para usuarios",
                        href: "/webcedenar/autogeneradores/reporte-usuario",
                    },
                    {
                        label: "Archivo plano",
                        href: "/webcedenar/autogeneradores/exportar-liquidacion",
                    },                                                     
                ]
            },
            {
                title: 'Reportes internos',
                icon:  <TimelineIcon/>,
                nav: [
                    {
                        label: "Informe de facturación por archivo",
                        href: "/webcedenar/autogeneradores/informe-facturacion",
                    },
                    {
                        label: "Tendencias",
                        href: "/webcedenar/autogeneradores/tendencia-facturacion",
                    },                                                     
                ]
            },
            {
                title: 'Información de energías',
                icon:  <TimelineIcon/>,
                nav: [
                    {
                        label: "Panel principal",
                        href: "/webcedenar/autogeneradores/consultas/panel-principal",
                    },                                                                       
                ]
            },
            {
                title: 'Reportes facturación',
                icon:  <AssessmentIcon/>,
                nav: [
                    {
                        label: "Descargar anexo de factura",
                        href: "/webcedenar/autogeneradores/consultas/descargar-reporte",
                    },                                                                       
                ]
            },
            {
                title: 'Pago de excedentes',
                icon:  <LocalAtmIcon/>,
                nav: [
                    {
                        label: "Estado",
                        href: "/webcedenar/autogeneradores/consultas/estado-pago-excedentes",
                    },                                                                       
                ]
            },
            // {
            //     title: 'Simulación',
            //     icon:  <GetAppIcon/>,
            //     nav: [
            //         {
            //             label: "Estimación de comportamiento",
            //             href: "/webcedenar/autogeneradores/consultas/comportamiento-estimado",
            //         },                                                                       
            //     ]
            // },
        ]
    },

]

export default mainNav