import {React, useEffect, useState, Fragment, useRef, PureComponent} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tabs, Tab, CircularProgress} from '@material-ui/core/'
import {authFetch, logout} from '../../auth';
import ReactToPrint from 'react-to-print';
import getPeriods from '../../const/yearPeriods';
import PrintIcon from '@material-ui/icons/Print';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import renoLogo from '../../renolog1.jpg'
import specLogo from '../../speclogo.jpg'
import Plot from 'react-plotly.js';
import {DatePicker} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { ThemeProvider, createStyles  } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {getColors} from "../../styles/theme"
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Minus from '@material-ui/icons/IndeterminateCheckBox';
import Add from '@material-ui/icons/AddBox';
// Week views
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import getWeekOfMonth from 'date-fns/getWeekOfMonth';
import getWeeksInMonth from 'date-fns/getWeeksInMonth';
import addDays from 'date-fns/addDays';
import clsx from "clsx";
import esLocale from "date-fns/locale/es";
import * as localeDictionary from 'plotly.js/lib/locales/es.js'


let sumOfArray = (array) => {
    let sum = array.reduce((a,b) => (a+b),0)
    return sum
}

const weekStyles = createStyles( (theme) => ({
    dayWrapper: {
      position: "relative",
    },
    formText: {
        fontSize: 14,
        color: 'white',
        textAlign: "center",    
        padding: 5
    }, 
    day: {      
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: "#676767",
    },
    highlight: {
      background: theme.palette.dashboard.selected,
      color: 'white',
    },
    firstHighlight: {
      extend: "highlight",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    },
    endHighlight: {
      extend: "highlight",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  }))

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    darkText:{
        color: '#173866',
        fontWeight: 'bold',
    },
    typoStyle1:{
        background: '#173866',
        padding: 8,
        color: 'white',
        borderRadius: 5
    },
    typoStyle2:{
        background: '#E6E3D8',
        padding: 5,
        color: '#173866',
        borderRadius: 5
    },
    typoStyle3:{
        background: '#173866',
        padding: 3,
        color: 'white',
        borderRadius: 5
    },
    contentPaperWithPadding:{
        background: '#E6E3D8',
        paddingTop: 20,
        paddingBottom: 20
    },   
    contentPaper:{
        background: theme.palette.dashboard.main,
        borderColor: theme.palette.dashboard.border,
        padding: 7,
    },
    contentPaperNoBottomPadding:{
        background: theme.palette.dashboard.main,
        borderColor: theme.palette.dashboard.border,
        padding: 0,        
    },
    infoNumber:{
      background: '#173866', 
      padding:8, 
      borderRadius: 5,
      fontWeight: 'bold',
      color: theme.palette.secondary.light
    },
    infoText:{      
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    labelTextDialog: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.secondary.main,        
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",    
        padding: 5
    },    
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    periodButtonSelected: {
        backgroundColor: theme.palette.dashboard.selected,
        color: theme.palette.primary.main,
        "&:hover": {        
            backgroundColor: theme.palette.dashboard.selected,   
            color: theme.palette.dashboard.background,     
        },             
        borderRadius: 0,     
        minHeight: '30px'
    },
    periodButtonDisabled: {
        backgroundColor: theme.palette.dashboard.background,
        color: theme.palette.primary.main,
        "&:hover": {            
            backgroundColor: theme.palette.dashboard.background,
            color: theme.palette.dashboard.selected,      
        }, 
        borderRadius: 0,     
        minHeight: '30px'
    },
    tabSelected:{
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.dashboard.selected,
        color: theme.palette.primary.main,
        "&:hover": {                    
            color: theme.palette.primary.main,     
        },                     
        borderRadius: 0,     
        minHeight: '25px'
    },
    tabs:{
        minHeight: '30px',
        color: theme.palette.primary.main,        
        padding: 0,
        //backgroundColor: theme.palette.dashboard.background,
    },
    tabItem: {
        minHeight: '30px',
        color: theme.palette.primary.main,
        padding: 0,
        minWidth: '90px',
        "&:hover": {                       
            borderBottom: `4px solid ${theme.palette.primary.main}`,  
        },
        //backgroundColor: theme.palette.dashboard.main,
    },
    feedbackButton: {
        backgroundColor: theme.palette.dashboard.background,
        color: theme.palette.primary.main,        
        borderRadius: 0,     
        "&:hover": {        
            backgroundColor: theme.palette.dashboard.background,   
            color: theme.palette.primary.main,    
        }, 
        minHeight: '30px'
    },
    typoInfo: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.dashboard.background, 
        padding: 4,
        fontWeight: 'bold',
        fontSize: 15
    },
    typoInfo2: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.dashboard.background, 
        padding: 4,
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: 3,
        border: `1px solid ${theme.palette.primary.main}`,
    }
}));

let calendarTheme =  createMuiTheme({
    palette: {        
        primary: {
            main: "#2E2B3E",            
        },
        secondary: {
            main: "#FFFFFF",                    
        },         
        dashboard: {
            main: "#2E2B3E",
            selected: "#E06021",
        }
    },
    typography: {
        fontFamily: "Roboto Condensed"
    },
    overrides: {
        MuiCssBaseline: {
        '@global': {         
            '*::-webkit-scrollbar': {
            width: '9px',    
                    
            },
            '::-webkit-scrollbar-track': {
            background: "#f1f1f1",     
            marginTop: 10,
            marginBottom: 10,       
            },
            '::-webkit-scrollbar-thumb': {
            background: '#888',           
            }
        }
        }
    }
})

const getWeekLimits = (startDate) => {
    let startAux = new Date(startDate)
    const endDate = new Date( new Date(startAux.setMonth(startAux.getMonth() + 1)).setDate(0) )   
    let limits = []
    let startWeek = startDate 
    let endWeek = endOfWeek(startWeek, {locale: esLocale})
    let currentWeek = 1
    let numberOfWeeks = getWeeksInMonth(startDate, {locale: esLocale})
    while ( isWithinInterval(endWeek, { start: startDate, end: endDate }) || currentWeek <= numberOfWeeks ) {
        
        limits.push(format(startWeek, "yyyy-MM-dd"))
        if ( isWithinInterval(endWeek, { start: startDate, end: endDate }) ){
            limits.push(format(endWeek, "yyyy-MM-dd"))
        }
        else{
            limits.push(format(endDate, "yyyy-MM-dd"))
        }
        currentWeek = currentWeek + 1
        startWeek = addDays(endWeek,1)
        endWeek = endOfWeek(startWeek, {locale: esLocale})        
    }    
    return limits
}

export default function TariffTree(props){
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const classes = useStyles();
    let prevDate = new Date( new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1) )    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    // Consulta cedenar
    const [clientCode, setClientCode] = useState("")
    const [searchFlag, setSearchFlag] = useState(false)
    const [monthViewSelected, setMonthViewSelected] = useState(true)
    const [monthLowerLimit, setMonthLowerLimit] = useState(null)
    const [monthUpperLimit, setMonthUpperLimit] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState("1")
    const [selectedDate, setSelectedDate] = useState(null)    
    // Week vars
    const [selectedWeek, setSelectedWeek] = useState(null)
    const [weekLimits, setWeekLimits] = useState([])
    const [weekIdx, setWeekIdx] = useState(0)
    const [weekDates, setWeekDates] = useState([])
    const [weekConsumption, setWeekConsumption] = useState([])
    const [weekGeneration, setWeekGeneration] = useState([])
    const [weekExcess, setWeekExcess] = useState([])
    const [weekTotals, setWeekTotals] = useState([])
    // Day vars
    const [selectedDay, setSelectedDay] = useState(null)
    const [dayIdx, setDayIdx] = useState(0)
    const [dayConsumption, setDayConsumption] = useState([])
    const [dayGeneration, setDayGeneration] = useState([])
    const [dayExcess, setDayExcess] = useState([])
    const [dayTotals, setDayTotals] = useState([])
    //const [selectedDate, setSelectedDate] = useState(new Date().setMonth(monthIdx - 1))
    const [userData, setUserData] = useState({})
    const [historicData, setHistoricData] = useState({})
    const [horizontalBarLayout, setHorizontalBarLayout] = useState({
        xaxis:{           
            gridcolor: "#C0C7BF15",            
            tickfont: {
                size: 11    
            },
            fixedrange: true
        },
        yaxis:{            
            fixedrange: true
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: "#FFFFFF"
        },
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: 40,
            t: 20,
            l: 70,
            r: 20,
            pad: 10
        },        
        showlegend: false,
        // paper_bgcolor: '#7f7f7f',
        // plot_bgcolor: '#c7c7c7',                          
        width: 310,
        height: 250,
        annotations: [                       
        ]
    })
    const [isLoading, setIsLoading] = useState(false)
    const [donutLayout, setDonutLayout] = useState({        
        xaxis:{
            visible: false
        },
        yaxis:{
            visible: false
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: "#FFFFFF"
        },
        showlegend: false,        
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: 50,
            t: 20,
            l: 10,
            r: 10,
            pad: 0
        },       
        // paper_bgcolor: '#7f7f7f',
        // plot_bgcolor: '#c7c7c7', 
        // height: 270,                         
        width: 310,
        height: 250,    
        annotations: [
            {
                x: 0,
                y: -0.25,
                xref: 'paper',
                yref: 'paper',                
                text: "Consumo: ",       
                showarrow: false,        
                font:{
                    family: 'Roboto Condensed',
                    size: 14,
                    color: "#D9950D"
                },
            },
            {
                x: 0.5,
                y: -0.25,
                xref: 'paper',
                yref: 'paper', 
                text: "Intercambio: ",       
                showarrow: false,        
                font:{
                    family: 'Roboto Condensed',
                    size: 14,
                    color: "#30F02F"
                },
            },       
            {
                x: 1,
                y: -0.25,
                xref: 'paper',
                yref: 'paper', 
                text: "Intercambio: ",       
                showarrow: false,        
                font:{
                    family: 'Roboto Condensed',
                    size: 14,
                    color: "#58EAFF"
                },
            },      
        ]
    })
 
    // let firstDay =  new Date (selectedDate.getFullYear(), selectedDate.getMonth(), 1)
    // let lastDay =  new Date (selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)    
    let hours = [...Array(24).keys()].map((hour) => (
        String(hour).length === 1 ? '0' + hour : String(hour)
    ))
    let meridHours = [...Array(24).keys()].map((hour) => (
        hour < 12 ? hour === 0 ? '12:00 AM' :   hour + ':00 AM'
        : hour === 12 ? '12:00 PM' :  (hour-12) + ':00 PM'
    ))    
    let days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

    let plotLayout = {
        xaxis: monthViewSelected ? selectedPeriod !== "3" ?  {            
                    type: 'date',
                    tickformat: '%x',
                    gridcolor: "#C0C7BF15",
                    autorange: true,
                    dtick: selectedPeriod === "1" ?  3*86400000 :
                        86400000,
                    fixedrange: true                              
                }
                :
                {
                    autorange: true,
                    gridcolor: "#C0C7BF15",            
                    tickfont: {
                        size: 11,                
                    },
                    fixedrange: true
                }
            :
            {
                autorange: true,
                gridcolor: "#C0C7BF15",            
                tickfont: {
                    size: 12,                
                },
                fixedrange: true
            }
        ,
        yaxis: monthViewSelected ?{
            zerolinecolor: "#E5EDE4",
            zerolinewidth: 1,
            gridcolor: "#C0C7BF15",
            ticksuffix: " kWh",
            fixedrange: true            
        } 
        :
        {
            zerolinecolor: "#E5EDE4",
            zerolinewidth: 1,
            gridcolor: "#C0C7BF15",
            ticksuffix: monthViewSelected ? " kWh" : "kWh",
            tickfont: {
                size: 11,                
            },
            fixedrange: true           
        } ,
        hovermode: "closest",
        hoverlabel:{
            font:{
                family: 'Roboto Condensed',
                size: 12,
                color: "#2E2B3E"
            },
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: "#FFFFFF"
        },
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: 30,
            t: 20,
            l: 50,
            r: 20,
            pad: 10
        },
        legend: {
            x: 0,
            y: 1.15,
            xanchor: 'left'
        },        
        // paper_bgcolor: '#7f7f7f',
        // plot_bgcolor: '#c7c7c7',
        height: 300,        
        width: 1085
    }

    let verticalBarLayout = {
        xaxis: monthViewSelected ? selectedPeriod !== "3" ? {
                //range: [firstDay.toISOString().split('T')[0], lastDay.toISOString().split('T')[0]],
                type: 'date',
                tickformat: '%x', 
                autorange: true,
                dtick: selectedPeriod === "1" ? 5*86400000 : 86400000,
                fixedrange: true                           
            }
            :
            {
                autorange: true,
                gridcolor: "#C0C7BF15",            
                tickfont: {
                    size: 11,                
                },
                fixedrange: true
            }
        :
        {
            autorange: true,
            gridcolor: "#C0C7BF15",            
            tickfont: {
                size: 12,                
            },
            fixedrange: true
        },
        yaxis:{
            zerolinecolor: "#E5EDE4",
            zerolinewidth: 1,
            gridcolor: "#C0C7BF15",
            ticksuffix: monthViewSelected ? " kWh" : "kWh",
            tickfont: {
                size: 11,                
            },
            fixedrange: true
        },
        hoverlabel:{
            font:{
                family: 'Roboto Condensed',
                size: 12,
                color: "#ffffff"
            },
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: "#FFFFFF"
        },
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: selectedPeriod !== "3" ? 30 : 60,
            t: 10,
            l: 50,
            r: 20,
            pad: 10
        },
        legend: {     
            y: 1.15,        
            x: 0.2,    
            "orientation": "h",            
        },                  		     
        barmode: 'stack',
        width: 630,
        height: selectedPeriod !== "3" ? 300 : 320,
        // paper_bgcolor: '#7f7f7f',
        // plot_bgcolor: '#c7c7c7',
    }
   
    let heatmapLayout = {        
        xaxis: {
            ticks: '',
            side: 'top',
            dtick: 1,
            fixedrange: true
          },
        yaxis: {
            ticks: '',
            ticksuffix: ' ',
            fixedrange: true         
        },
        font:{
            family: 'Roboto Condensed',
            size: 11,
            color: "#FFFFFF"
        },
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: 10,
            t: 25,
            l: 45,
            r: 10,
            pad: 0
        },
        width: 430,
        height: 260,
        coloraxis:{
            colorbar: {
                showticklabels: false
            }
        },
        annotations:[
            {
                x: 0.84,
                y: -0.265,
                xref: 'paper',
                yref: 'paper',                
                text: "Max. importación",       
                showarrow: false,        
                font:{
                    family: 'Roboto Condensed',
                    size: 13,
                    color: "#D9950D"
                },
            },
            {
                x: 0.16,
                y: -0.265,
                xref: 'paper',
                yref: 'paper', 
                text: "Max. exportación",       
                showarrow: false,        
                font:{
                    family: 'Roboto Condensed',
                    size: 13,
                    color: "#58EAFF"
                },
            }, 
        ]
    }

    let verticalBarLayoutRight =  {
        xaxis:{
            //range: [firstDay.toISOString().split('T')[0], lastDay.toISOString().split('T')[0]],
            //type: 'date',
            //tickformat: '%x', 
            //autorange: true   ,
            fixedrange: true                        
        },
        yaxis:{
            zerolinecolor: "#E5EDE4",
            zerolinewidth: 1,
            gridcolor: "#C0C7BF15",
            ticksuffix: " kWh",
            tickfont: {
                size: 11,                
            },
            fixedrange: true
        },
        hoverlabel:{
            font:{
                family: 'Roboto Condensed',
                size: 12,
                color: "#ffffff"
            },
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: "#FFFFFF"
        },
        paper_bgcolor: "#2E2B3E",
        plot_bgcolor: "#2E2B3E",
        margin: {
            b: 30,
            t: 10,
            l: 50,
            r: 20,
            pad: 10
        },
        legend: {     
            y: 1.15,        
            x: 0.2,    
            "orientation": "h",            
        },                  		     
        barmode: 'group',
        width: 420,
        height: 300,
        // paper_bgcolor: '#7f7f7f',
        // plot_bgcolor: '#c7c7c7',
    }

    const fillHorizontalBarText = (clientInfo) => {
        let auxBar = horizontalBarLayout
        auxBar.annotations = []                
        let labels = ['Capacitiva', 'Inductiva', 'Exportación', 'Importación']
        let names = ['reactive_cap_energy','reactive_ind_pen_energy', 'gen_exct', 'active_energy']
        let maxValue = Math.max(clientInfo['reactive_cap_energy'], clientInfo['reactive_ind_pen_energy'], clientInfo['gen_exct'], clientInfo['active_energy'])
        let units = [' kVArh', ' kVArh', ' kWh', ' kWh']
        for (var i = 0; i < 4; i++){
            var annot = {
                xref: 'x1',
                yref: 'y1',
                x: clientInfo[names[i]] + maxValue/4,
                y: labels[i],
                text: clientInfo[names[i]].toFixed(2) + units[i],
                font: {
                    family: 'Roboto Condensed',
                    size: 12,
                    color: 'white'
                  },
                showarrow: false,
            }
            auxBar.annotations.push(annot)
        }
        setHorizontalBarLayout(auxBar)
    }

    const fillDonutText = (clientInfo) => {
        let aux = donutLayout
        for (var i = 0; i < aux.annotations.length; i++){
            aux.annotations[i].text = ""
        }               
        aux.annotations[0].text = 'Importación: ' + "<br>" + clientInfo['active_energy'].toFixed(2) + ' kWh'
        aux.annotations[1].text = 'Exportación: ' + "<br>" + clientInfo['gen_exct'].toFixed(2) + ' kWh'
        aux.annotations[2].text = 'Excedentes: ' + "<br>" + clientInfo['gen_exc2'].toFixed(2) + ' kWh'
        setDonutLayout(aux)
    }

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
    
    const handleGlobalViewChange = (monthView) => {
        if (monthView){
            handlePeriodViewChange(null, "1")
        }
        else {
            if (Object.keys(historicData).length > 0){                               
                fillHorizontalBarText(historicData)
                fillDonutText(historicData)
            }
        }
    }

    const handlePeriodViewChange = (e, value) => {        
        setSelectedPeriod(value)                
        if (value === "1"){
            if (Object.keys(userData).length > 0){                               
                fillHorizontalBarText(userData)
                fillDonutText(userData)
            }
        }
        else if (value  === "2"){
            if (Object.keys(userData).length > 0){
                let startIdx = userData['dates'].indexOf(weekLimits[2*weekIdx])
                let endIdx = userData['dates'].indexOf(weekLimits[2*weekIdx + 1]) + 1            
                let auxDates = userData['dates'].slice(startIdx, endIdx)
                setWeekDates(auxDates)
                let auxConsumption = userData['month_consumption'].slice(startIdx, endIdx)
                setWeekConsumption(auxConsumption)
                let auxGeneration = userData['month_generation'].slice(startIdx, endIdx)
                setWeekGeneration(auxGeneration)
                let auxExcess = userData['diary_exc'].slice(startIdx, endIdx)
                setWeekExcess(auxExcess)
                let auxInd = userData['month_inductive'].slice(startIdx, endIdx)
                let auxCap = userData['month_capacitive'].slice(startIdx, endIdx)
                let capEne = sumOfArray(auxCap)
                let indEne = sumOfArray(auxInd)
                let genEne = sumOfArray(auxGeneration)
                let actEne = sumOfArray(auxConsumption)
                let excEne = sumOfArray(auxExcess)
                setWeekTotals([capEne, indEne, genEne, actEne, excEne])
                let auxUserData = {}
                auxUserData['reactive_cap_energy'] = capEne
                auxUserData['reactive_ind_pen_energy'] = indEne
                auxUserData['gen_exct'] = genEne
                auxUserData['active_energy'] = actEne
                auxUserData['gen_exc2'] = excEne
                fillHorizontalBarText(auxUserData)
                fillDonutText(auxUserData)
            }
        }
        else if (value === "3"){
            if (Object.keys(userData).length > 0){
                let auxDayConsumption = userData['hour_consumption'][dayIdx]
                let auxDayGeneration = userData['hour_generation'][dayIdx]
                let auxDayExcess = userData['hour_excess'][dayIdx]
                let auxDayInductive = userData['hour_inductive'][dayIdx]
                let auxDayCapacitive = userData['hour_capacitive'][dayIdx]
                setDayConsumption(auxDayConsumption)            
                setDayGeneration(auxDayGeneration)            
                setDayExcess(auxDayExcess)

                let totalDayConsumption = sumOfArray(auxDayConsumption)
                let totalDayGeneration = sumOfArray(auxDayGeneration)
                let totalDayInductive = sumOfArray(auxDayInductive)
                let totalDayCapacitive = sumOfArray(auxDayCapacitive)
                let totalDayExcess = sumOfArray(auxDayExcess)
                setDayTotals([totalDayCapacitive, totalDayInductive, totalDayGeneration, totalDayConsumption, totalDayExcess])

                let auxUserData = {}
                auxUserData['reactive_cap_energy'] = totalDayCapacitive
                auxUserData['reactive_ind_pen_energy'] = totalDayInductive
                auxUserData['gen_exct'] = totalDayGeneration
                auxUserData['active_energy'] = totalDayConsumption
                auxUserData['gen_exc2'] = totalDayExcess
                fillHorizontalBarText(auxUserData)
                fillDonutText(auxUserData)
            }
        }
    }

    const handleWeekChange = (idx) => {
        if (Object.keys(userData).length > 0){
            let startIdx = userData['dates'].indexOf(weekLimits[2*idx])
            let endIdx = userData['dates'].indexOf(weekLimits[2*idx + 1]) + 1        
            let auxDates = userData['dates'].slice(startIdx, endIdx)  
            setWeekDates(auxDates) 
            let auxConsumption = userData['month_consumption'].slice(startIdx, endIdx)
            setWeekConsumption(auxConsumption)
            let auxGeneration = userData['month_generation'].slice(startIdx, endIdx)
            setWeekGeneration(auxGeneration)
            let auxExcess = userData['diary_exc'].slice(startIdx, endIdx)
            setWeekExcess(auxExcess)
            let auxInd = userData['month_inductive'].slice(startIdx, endIdx)
            let auxCap = userData['month_capacitive'].slice(startIdx, endIdx)
            let capEne = sumOfArray(auxCap)
            let indEne = sumOfArray(auxInd)
            let genEne = sumOfArray(auxGeneration)
            let actEne = sumOfArray(auxConsumption)
            let excEne = sumOfArray(auxExcess)
            setWeekTotals([capEne, indEne, genEne, actEne, excEne])
            let auxUserData = {}
            auxUserData['reactive_cap_energy'] = capEne
            auxUserData['reactive_ind_pen_energy'] = indEne
            auxUserData['gen_exct'] = genEne
            auxUserData['active_energy'] = actEne
            auxUserData['gen_exc2'] = excEne
            fillHorizontalBarText(auxUserData)
            fillDonutText(auxUserData)
        }             
    }

    const handleDayChange = (idx) => {
        if (Object.keys(userData).length > 0){
            let auxDayConsumption = userData['hour_consumption'][idx]
            let auxDayGeneration = userData['hour_generation'][idx]
            let auxDayExcess = userData['hour_excess'][idx]
            let auxDayInductive = userData['hour_inductive'][idx]
            let auxDayCapacitive = userData['hour_capacitive'][idx]
            setDayConsumption(auxDayConsumption)            
            setDayGeneration(auxDayGeneration)            
            setDayExcess(auxDayExcess)

            let totalDayConsumption = sumOfArray(auxDayConsumption)
            let totalDayGeneration = sumOfArray(auxDayGeneration)
            let totalDayInductive = sumOfArray(auxDayInductive)
            let totalDayCapacitive = sumOfArray(auxDayCapacitive)
            let totalDayExcess = sumOfArray(auxDayExcess)
            setDayTotals([totalDayCapacitive, totalDayInductive, totalDayGeneration, totalDayConsumption, totalDayExcess])

            let auxUserData = {}
            auxUserData['reactive_cap_energy'] = totalDayCapacitive
            auxUserData['reactive_ind_pen_energy'] = totalDayInductive
            auxUserData['gen_exct'] = totalDayGeneration
            auxUserData['active_energy'] = totalDayConsumption
            auxUserData['gen_exc2'] = totalDayExcess
            fillHorizontalBarText(auxUserData)
            fillDonutText(auxUserData)
        }
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} className={classes.feedbackButton} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    function WeekPicker (props) {
        let classes = props.classes
        const RenderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {                    
            let dateClone = new Date(Date.parse(date));
            let selectedDateClone = new Date(Date.parse(selectedDate));
        
            const start = startOfWeek(selectedDateClone, {locale: esLocale});
            const end = endOfWeek(selectedDateClone, {locale: esLocale});
        
            const dayIsBetween = isWithinInterval(dateClone, { start, end });
            const isFirstDay = isSameDay(dateClone, start);
            const isLastDay = isSameDay(dateClone, end);
        
            const wrapperClassName = clsx({
              [classes.highlight]: dayIsBetween,
              [classes.firstHighlight]: isFirstDay,
              [classes.endHighlight]: isLastDay,
             });
        
            const dayClassName = clsx(classes.day, {
              [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
              [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
            });
        
            return (
              <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                  <span> {format(dateClone, "d")} </span>
                </IconButton>
              </div>
            );
        };
    
        const formatWeekSelectLabel = (date, invalidLabel) => {
            let dateClone = new Date(Date.parse(date));
            let week = getWeekOfMonth(dateClone, {locale: esLocale})
            return dateClone && isValid(dateClone)
              ? `Semana ${week} de ${format(dateClone, "MMMM", {locale: esLocale})}`
              : invalidLabel;
        };

        return(
            <DatePicker    
                variant="dialog"    
                inputVariant="filled"                                                                                                                            
                okLabel="OK"
                cancelLabel="CANCELAR"
                minDate={new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)}
                maxDate={new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)}
                //views={["year","date","month"]}                                
                value={selectedWeek}
                
                onChange={(d) => {
                    let weekIdxAux = getWeekOfMonth(d, {locale: esLocale}) - 1
                    setSelectedWeek(d); 
                    setWeekIdx(weekIdxAux); 
                    handleWeekChange(weekIdxAux);}
                }
                
                InputProps={{                                            
                            classes: {
                                input: classes.formText,                                    
                            }
                        }}
                renderDay={RenderWrappedWeekDay}
                labelFunc={formatWeekSelectLabel}                                                                                                  
            /> 
        )
    }

    let WeekDP = withStyles(weekStyles)(WeekPicker)
    
    useEffect (() => {
        if (selectedDate){
            // Barra de carga
            setIsLoading(true)
            // Limites de semanas
            let limits  = getWeekLimits(selectedDate)
            setWeekLimits(limits)
            // Limpiar barra horizontal
            // let aux = horizontalBarLayout
            // aux.annotations = []
            // setHorizontalBarLayout(aux)
            // Limpiar Dona
            // let aux2 = donutLayout
            // for (var i = 0; i < aux2.annotations.length; i++){
            //     aux2.annotations[i].text = ""
            // }       
            // setDonutLayout(aux2)
            // Limpiar datos principales
            setUserData({})
            authFetch(
                "/api/check-ag-liquidation-status?username="+props.username+
                                "&month="+String( selectedDate.getMonth() + 1 )+                              
                                "&year="+ selectedDate.getFullYear()
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                setIsLoading(false)
                if (response && response.liquidation_approved[2]){  
                    if (props.user !== 'consulta-cedenar'){                                                   
                        authFetch("/api/check-user-panel-data?username=" + String(props.username) + '&client_code=' + props.user
                        +"&month="+String( selectedDate.getMonth() + 1 )+                              
                            "&year="+ selectedDate.getFullYear()).then(response => {
                                if (response.status === 422){
                                    logout()
                                    return null
                                }
                                    return response.json()
                                }).then(response => {
                                        if (response && response.user_data){                                                            
                                            if ( Object.keys(response.user_data).length === 0){
                                                setFeedbackMsg('No se encontró información para el periodo seleccionado.')                                                                            
                                                handleFeedbackDialogOpen()
                                            }
                                            else{                                    
                                                setUserData(response.user_data)
                                                //console.log(response.user_data)
                                                fillHorizontalBarText(response.user_data)
                                                fillDonutText(response.user_data)                                    
                                            }
                                    }
                                })
                    }  
                    else {
                        authFetch("/api/check-user-panel-data?username=" + String(props.username) + '&client_code=' + clientCode
                        +"&month="+String( selectedDate.getMonth() + 1 )+                              
                            "&year="+ selectedDate.getFullYear()).then(response => {
                                if (response.status === 422){
                                    logout()
                                    return null
                                }
                                    return response.json()
                                }).then(response => {
                                        if (response && response.user_data){                                                            
                                            if ( Object.keys(response.user_data).length === 0){
                                                setFeedbackMsg('No se encontró información para el código proporcionado.')                                                                            
                                                handleFeedbackDialogOpen()
                                            }
                                            else{                                    
                                                setUserData(response.user_data)
                                                //console.log(response.user_data)
                                                fillHorizontalBarText(response.user_data)
                                                fillDonutText(response.user_data)                                    
                                            }
                                    }
                                })
                    }
                } 
                else{                
                    setFeedbackMsg('Información no encontrada.')                                                
                    handleFeedbackDialogOpen()
                }         
                })
        }
        document.title ="Panel principal | Autogeneradores Cedenar"    
        

    }, [selectedDate])

    useEffect (() => {        
        if (props.user !== 'consulta-cedenar'){
            setIsLoading(true)     
            authFetch("/api/check-user-panel-historic-data?username=" + String(props.username) + '&client_code=' + props.user).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                        if (response && response.historic_data){     
                            let lowLimit = new Date(response.begin_date)       
                            let upLimit = new Date(response.finish_date)                               
                            setSelectedDay(upLimit)           
                            setSelectedWeek(upLimit)    
                            setSelectedDate(upLimit)   
                            setMonthLowerLimit(lowLimit)                   
                            setMonthUpperLimit(upLimit)   
                            setHistoricData(response.historic_data)
                    }
                })
        }          
        else if (clientCode !== '') {
            authFetch("/api/check-user-panel-historic-data?username=" + String(props.username) + '&client_code=' + clientCode).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                                if (response && response.historic_data){     
                                    let lowLimit = new Date(response.begin_date)       
                                    let upLimit = new Date(response.finish_date)                               
                                    setSelectedDay(upLimit)           
                                    setSelectedWeek(upLimit)    
                                    setSelectedDate(upLimit)   
                                    setMonthLowerLimit(lowLimit)                   
                                    setMonthUpperLimit(upLimit)   
                                    setHistoricData(response.historic_data)
                                }
                                else {
                                    setFeedbackMsg('No se encontró información para el código proporcionado.')                                                                            
                                    handleFeedbackDialogOpen()
                                }
                        })
        }        
    }, [searchFlag])

    return (
        <div className={classes.root}> 
            <script src="plotly-locale-de-ch.js"></script>
            {showFeedbackDialog()}                 
            <Zoom in timeout={700}>     
            <Grid container justify="center" spacing={1}>   
                {
                    props.user === 'consulta-cedenar' ? 
                    <Grid item xs={3}>
                        <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>                            
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código de producto)</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField 
                                    variant="outlined" 
                                    color="primary"
                                    size="small"
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    className={classes.formRow} 
                                    value={clientCode}
                                    onChange={(e) => {setClientCode(e.target.value); setUserData({});}}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter'){
                                            let aux = searchFlag; 
                                            setSearchFlag(!aux);
                                        }
                                    }}
                                >

                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton color="primary" onClick={(e) => {let aux = searchFlag; setSearchFlag(!aux);}}>
                                    <SearchOutlinedIcon className={classes.searchButton} />
                                </IconButton>                                        
                            </Grid>                             
                        </Grid> 
                        </Paper>
                    </Grid> 
                    :
                    null
                }
                
                {
                    isLoading ?
                    <Grid item xs={12}>
                    <CircularProgress style={{marginTop: "20%"}}/>
                    </Grid>
                    :
                    <Fragment>
                    {
                        Object.keys(userData).length === 0 && selectedDate === null?
                        null
                        :
                        <Grid item xs={11}>
                            <Grid container spacing={2} alignItems="center" justify="space-evenly">
                                <Grid item xs={3}>
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                
                                        <Grid container spacing={3} justify="space-evenly">
                                            <Grid item>
                                                <Button 
                                                    variant="contained" 
                                                    disableElevation 
                                                    className={monthViewSelected ? classes.periodButtonSelected : classes.periodButtonDisabled}
                                                    onClick={() => {setMonthViewSelected(true); handleGlobalViewChange(true);}}
                                                >
                                                    Mensual
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button 
                                                    variant="contained" 
                                                    disableElevation 
                                                    className={monthViewSelected ? classes.periodButtonDisabled : classes.periodButtonSelected}
                                                    onClick={() => {setMonthViewSelected(false); handleGlobalViewChange(false);}}
                                                >
                                                    Histórico
                                                </Button>
                                            </Grid>                                                                  
                                        </Grid>                                
                                    </Paper>                                                                                      
                                </Grid> 
                                {
                                    monthViewSelected ?      
                                    <Grid item xs={4}>
                                        
                                            <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">                                
                                                <Tabs
                                                    value={selectedPeriod}
                                                    onChange={handlePeriodViewChange}
                                                    //indicatorColor="primary"
                                                    //textColor="primary"
                                                    classes={{root: classes.tabs}}
                                                    centered
                                                >
                                                    <Tab classes={{root: classes.tabItem, selected: classes.tabSelected}} value="1" label="Mes" />
                                                    <Tab classes={{root: classes.tabItem, selected: classes.tabSelected}} value="2" label="Semana" />
                                                    <Tab classes={{root: classes.tabItem, selected: classes.tabSelected}} value="3" label="Día" />                                    
                                                </Tabs>                                
                                            </Paper>    
                                            
                                                                                                                        
                                    </Grid> 
                                    :
                                    null
                                }
                                { 
                                    monthViewSelected ?
                                    <Grid item xs={3}>
                                        {                                    
                                            selectedPeriod === "1" ? 
                                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                
                                                <Grid container justify="center" spacing={0} alignItems="flex-start">
                                                    <Grid item>
                                                        <EventIcon color="primary"/>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <ThemeProvider theme={calendarTheme}>
                                                        <DatePicker    
                                                            variant="dialog"    
                                                            inputVariant="filled"                                                                                                                            
                                                            okLabel={""}
                                                            cancelLabel={""}
                                                            openTo="month"
                                                            format="MMMM-yyyy"
                                                            minDate={monthLowerLimit}
                                                            maxDate={monthUpperLimit}
                                                            views={["year","month"]}                                
                                                            value={selectedDate}
                                                            InputLabelProps={{                                            
                                                                        classes: {
                                                                            root: classes.labelText,                                    
                                                                        }
                                                                    }}
                                                            onChange={(d) => {
                                                                let startWeek = new Date(d.getFullYear(), d.getMonth(), 1);
                                                                setSelectedDate(d); 
                                                                setSelectedWeek(startWeek);
                                                                setSelectedDay(startWeek);
                                                            }}
                                                            error={false}
                                                            InputProps={{                                            
                                                                        classes: {
                                                                            input: classes.formText,                                    
                                                                        }
                                                                    }}
                                                        
                                                            autoOk                                                                                  
                                                        />        
                                                        </ThemeProvider>
                                                    </Grid>                                                         
                                                </Grid>                                
                                            </Paper>
                                            : selectedPeriod === "2" ?
                                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                                                    
                                                <Grid container justify="center" spacing={0} alignItems="flex-start">                                            
                                                    <Grid item>
                                                        <EventIcon color="primary"/>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <ThemeProvider theme={calendarTheme}>
                                                            <WeekDP classes={weekStyles}/>
                                                        </ThemeProvider>
                                                    </Grid>                                                         
                                                </Grid>                                
                                            </Paper> 
                                            :
                                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                
                                                <Grid container justify="center" spacing={0} alignItems="flex-start">
                                                    <Grid item>
                                                        <EventIcon color="primary"/>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <ThemeProvider theme={calendarTheme}>
                                                        <DatePicker    
                                                            variant="dialog"    
                                                            inputVariant="filled"                                                                                                                            
                                                            okLabel={""}
                                                            cancelLabel={""}
                                                            openTo="date"
                                                            format="dd-MMMM-yyyy"
                                                            minDate={new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)}
                                                            maxDate={new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)}
                                                            views={["date"]}                                
                                                            value={selectedDay}
                                                            InputLabelProps={{                                            
                                                                        classes: {
                                                                            root: classes.labelText,                                    
                                                                        }
                                                                    }}
                                                            onChange={(d) => {
                                                                let auxDayIdx = d.getDate() - 1
                                                                setSelectedDay(d);
                                                                setDayIdx(auxDayIdx);
                                                                handleDayChange(auxDayIdx);
                                                            }}
                                                            error={false}
                                                            InputProps={{                                            
                                                                        classes: {
                                                                            input: classes.formText,                                    
                                                                        }
                                                                    }}
                                                        
                                                            autoOk                                                                                  
                                                        />        
                                                        </ThemeProvider>
                                                    </Grid>                                                         
                                                </Grid>                                
                                            </Paper>
                                        }                                                                                                                    
                                    </Grid> 
                                    :
                                    null
                                }
                                                                            
                            </Grid>                            
                        </Grid>
                    }
                    {
                        Object.keys(userData).length === 0 ?
                        null
                        :
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems="flex-start" justify="space-evenly">
                                <Grid item xs={12}>
                                    <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                        <Grid container style={{padding: 10}}>
                                            <Grid item>
                                                <Typography className={classes.typoInfo2}>
                                                    Energía importada vs exportada
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Plot
                                                    data={[                                        
                                                        {
                                                            x:  monthViewSelected ?                                                                 
                                                                    selectedPeriod === "1" ? userData['dates'] 
                                                                    : selectedPeriod === "2" ?
                                                                    weekDates
                                                                    :
                                                                    meridHours
                                                                :
                                                                    historicData['dates'],

                                                            y:  monthViewSelected ?   
                                                                    selectedPeriod === "1" ? userData['month_consumption']
                                                                    :
                                                                    selectedPeriod === "2" ?
                                                                    weekConsumption
                                                                    :
                                                                    dayConsumption
                                                                :
                                                                    historicData['imported_energy'],
                                                            marker: {
                                                                color: '#FFAF0F',
                                                                size: 8
                                                            },              
                                                            line: {                                                   
                                                                width: 2
                                                            },
                                                            name: 'Energía importada',
                                                            fill: monthViewSelected ?
                                                                    selectedPeriod === "1" ? 
                                                                    userData['active_energy'] >= userData['gen_exct'] ? 'tonexty' : 'tozeroy'
                                                                    : selectedPeriod === "2" ?
                                                                    weekTotals[3] >= weekTotals[2] ? 'tonexty' : 'tozeroy'
                                                                    :
                                                                    'tozeroy'
                                                                  :
                                                                  historicData['active_energy'] >= historicData['gen_exct'] ? 'tonexty' : 'tozeroy',
                                                            fillcolor: '#D9950D30',                                          
                                                            line: {shape: 'spline'},
                                                            type: 'scatter',
                                                            hoverinfo: monthViewSelected ? 'x+y' : 'text',
                                                            hovertemplate: monthViewSelected ? '' : '%{x|%b %Y}, %{y}<extra></extra>'
                                                        },
                                                        {
                                                            x:  monthViewSelected ? 
                                                                    selectedPeriod === "1" ? userData['dates'] 
                                                                    : selectedPeriod === "2" ?
                                                                    weekDates
                                                                    :
                                                                    meridHours
                                                                :
                                                                    historicData['dates'],
                                                            y:  monthViewSelected ?
                                                                    selectedPeriod === "1" ? userData['month_generation']
                                                                    : selectedPeriod === "2" ?
                                                                    weekGeneration
                                                                    :
                                                                    dayGeneration
                                                                :
                                                                    historicData['exported_energy'],
                                                            marker: {
                                                                color: '#42F02F',
                                                                size: 8
                                                            },              
                                                            line: {                                                    
                                                                width: 2
                                                            },
                                                            name: 'Energía exportada',
                                                            fill:  monthViewSelected ?
                                                                    selectedPeriod === "1" ?
                                                                    userData['active_energy'] >= userData['gen_exct'] ? 'tozeroy' : 'tonexty'
                                                                    :  selectedPeriod === "2" ?
                                                                    weekTotals[3] >= weekTotals[2] ? 'tozeroy' : 'tonexty'
                                                                    :
                                                                    'tozeroy'
                                                                   :
                                                                   historicData['active_energy'] >= historicData['gen_exct'] ? 'tozeroy' : 'tonexty',
                                                            fillcolor: '#30F02F40',
                                                            line: {shape: 'spline'},
                                                            type: 'scatter',                                                            
                                                            hoverinfo: monthViewSelected ? 'x+y' : 'text',
                                                            hovertemplate: monthViewSelected ? '' : '%{x|%b %Y}, %{y}<extra></extra>'
                                                        },
                                                    ]}
                                                    layout={plotLayout}
                                                    //style={{border: '1px solid black'}}
                                                    config={{displayModeBar: false, locales: {'es-ES': localeDictionary}, locale: 'es-ES'}}
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                    </Paper>
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid container spacing={0}>                                    
                                        <Grid item xs={6} style={{maxHeight: '300px'}}>
                                            <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                                <Grid container style={{padding: 10}}>
                                                <Grid item>
                                                    <Typography className={classes.typoInfo2}>
                                                        Totales
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                <Plot
                                                    data={[                                        
                                                        {
                                                            type: 'bar',
                                                            x:  monthViewSelected ? 
                                                                    selectedPeriod === "1" ?
                                                                    [userData['reactive_cap_energy'], userData['reactive_ind_pen_energy'], 
                                                                    userData['gen_exct'], userData['active_energy']]
                                                                    : selectedPeriod === "2" ?
                                                                    weekTotals.slice(0,4)
                                                                    :
                                                                    dayTotals.slice(0,4)
                                                                :   [historicData['reactive_cap_energy'], historicData['reactive_ind_pen_energy'], 
                                                                    historicData['gen_exct'], historicData['active_energy']]
                                                                    ,
                                                            y: ['Capacitiva', 'Inductiva', 'Exportación', 'Importación'],
                                                            width: [0.5,0.5,0.5,0.5],
                                                            orientation: 'h',
                                                            marker: {
                                                                color: ['#C9514930','#D638C930', '#30F02F30', '#D9950D30'],
                                                                line: {
                                                                    color: ['#FF675C', '#F740E8', '#42F02F', '#FFAF0F'],
                                                                    width: 2
                                                                }
                                                            },                                                    
                                                            textposition: 'auto',
                                                            hoverinfo: 'none',
                                                        }
                                                    ]}
                                                    layout={horizontalBarLayout}                                            
                                                    config={{displayModeBar: false, locale:'es'}}
                                                />
                                                </Grid>
                                                </Grid>
                                            </Paper> 
                                        </Grid>
                                        <Grid item xs={6} style={{maxHeight: '300px'}}>
                                            <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                                <Grid container style={{padding: 10}}>
                                                <Grid item>
                                                    <Typography className={classes.typoInfo2}>
                                                        Distribución importación vs exportación
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                <Plot
                                                    data={[                                        
                                                            {
                                                                type: 'pie',
                                                                labels: ['e1', 't1'],
                                                                values: monthViewSelected ?
                                                                        selectedPeriod === "1" ?
                                                                        [userData['active_energy'], userData['gen_exct']]
                                                                        : selectedPeriod === "2" ?
                                                                        [weekTotals[3], weekTotals[2]]
                                                                        :
                                                                        [dayTotals[3], dayTotals[2]]
                                                                        :
                                                                        [historicData['active_energy'], historicData['gen_exct']],
                                                                hole: 0,
                                                                direction: 'clockwise',         
                                                                domain: {
                                                                    x: [0, 1],
                                                                    y: [0, 1]
                                                                },                                                                                               
                                                                hoverinfo: 'none',      
                                                                //texttemplate: "%{percent}",
                                                                textfont:{
                                                                    color: '#FFFFFF', 
                                                                    size: 14
                                                                },                                                        
                                                                marker: {
                                                                    colors: ['#D9950D30','#30F02F30'],
                                                                    line: {
                                                                        color: ['#FFAF0F','#42F02F'],
                                                                        width: [2,2]
                                                                    }
                                                                },
                                                                sort: false
                                                            },
                                                            // {
                                                            //     type: 'pie',
                                                            //     labels: ['e2', 't2'],
                                                            //     values: [userData['gen_exc1']/(userData['active_energy'] + userData['gen_exct']),1 - (userData['gen_exc1']/(userData['active_energy'] + userData['gen_exct']))],
                                                            //     domain: {
                                                            //         x: [0.12, 0.88],
                                                            //         y: [0.12, 0.88]
                                                            //     },
                                                            //     hole: 0.75,                                                        
                                                            //     name: 'pie 2',
                                                            //     hoverinfo: 'none',
                                                            //     textinfo: 'none',
                                                            //     marker: {
                                                            //         colors: ['#30F02F30', '#22202E70'],
                                                            //         line: {
                                                            //             color: ['#42F02F', '#22202E70'],
                                                            //             width: [2,0]
                                                            //         }
                                                            //     },
                                                            //     direction: 'clockwise',
                                                            //     sort: false
                                                            // },
                                                            // {
                                                            //     type: 'pie',
                                                            //     labels: ['e3', 't3'],
                                                            //     values: [userData['active_energy']/(userData['active_energy'] + userData['gen_exct']),1 - (userData['active_energy']/(userData['active_energy'] + userData['gen_exct']))],
                                                            //     domain: {
                                                            //         x: [0.24, 0.76],
                                                            //         y: [0.24, 0.76]
                                                            //     },
                                                            //     hole: 0.6,
                                                            //     name: 'pie 3',
                                                            //     hoverinfo: 'none',
                                                            //     textinfo: 'none',
                                                            //     marker: {
                                                            //         colors: ['#D9950D30', '#22202E70'],
                                                            //         line: {
                                                            //             color: ['#FFAF0F', '#22202E70'],
                                                            //             width: [2,0]
                                                            //         }
                                                            //     },
                                                            //     direction: 'clockwise',
                                                            //     sort: false
                                                            // }
                                                        ]}
                                                    layout={donutLayout}                                            
                                                    config={{displayModeBar: false, locale:'es'}}
                                                />
                                                </Grid>
                                                </Grid>
                                            </Paper> 
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                            <Grid container style={{padding: 10}}>
                                            <Grid item>
                                                <Typography className={classes.typoInfo2}>
                                                    Comparación y excedentes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Plot
                                                data={[                                        
                                                    {
                                                        x:  monthViewSelected ?
                                                                selectedPeriod === "1" ? userData['dates'] 
                                                                : selectedPeriod === "2" ?
                                                                weekDates
                                                                :
                                                                meridHours
                                                            :
                                                            historicData['dates']
                                                            ,
                                                        y:  monthViewSelected ?
                                                            selectedPeriod === "1" ? userData['month_consumption']
                                                            : selectedPeriod === "2" ?
                                                            weekConsumption
                                                            :
                                                            dayConsumption
                                                            :
                                                            historicData['imported_energy'],
                                                        type: 'bar',
                                                        name: 'Importación',
                                                        marker: {
                                                            color: '#D9950D60',
                                                            line: {
                                                                width: 2,
                                                                color: '#FFAF0F'
                                                            }   
                                                        },
                                                                                                        
                                                        hoverinfo: monthViewSelected ? 'x+y' : 'text',
                                                        hovertemplate: monthViewSelected ? '' : '%{x|%b %Y}, %{y}<extra></extra>'
                                                        
                                                    },
                                                    {
                                                        x:  monthViewSelected ?
                                                            selectedPeriod === "1" ? userData['dates'] 
                                                            : selectedPeriod === "2" ?
                                                            weekDates
                                                            :
                                                            meridHours
                                                            :
                                                            historicData['dates']
                                                            ,
                                                        y:  monthViewSelected ?
                                                            selectedPeriod === "1" ? userData['month_generation']
                                                            : selectedPeriod === "2" ?
                                                            weekGeneration
                                                            :
                                                            dayGeneration
                                                            :
                                                            historicData['exported_energy'],
                                                        type: 'bar',
                                                        name: 'Exportación',
                                                        marker: {
                                                            color: '#30F02F60',                                                    
                                                            line: {
                                                                width: 2,
                                                                color: '#42F02F'
                                                            } 
                                                        },
                                                        hoverinfo: monthViewSelected ? 'x+y' : 'text',
                                                        hovertemplate: monthViewSelected ? '' : '%{x|%b %Y}, %{y}<extra></extra>'
                                                    },
                                                    {
                                                        x:  monthViewSelected ?
                                                            selectedPeriod === "1" ? userData['dates'] 
                                                            : selectedPeriod === "2" ? 
                                                            weekDates
                                                            :
                                                            meridHours
                                                            :
                                                            historicData['dates']
                                                            ,
                                                        y:  monthViewSelected ?
                                                            selectedPeriod === "1" ? userData['diary_exc']
                                                            : selectedPeriod === "2" ?
                                                            weekExcess
                                                            :
                                                            dayExcess
                                                            :
                                                            historicData['exchange_energy'],
                                                        type: 'bar',
                                                        name: 'Excedentes',
                                                        marker: {
                                                            color: "#48BDCF60",
                                                            line:{
                                                                color: "#59E9FF",
                                                                width: 2,
                                                            }
                                                        },
                                                        hoverinfo: monthViewSelected ? 'x+y' : 'text',
                                                        hovertemplate: monthViewSelected ? '' : '%{x|%b %Y}, %{y}<extra></extra>'
                                                    },
                                                ]}
                                                layout={verticalBarLayout}                                            
                                                config={{displayModeBar: false, locales: {'es-ES': localeDictionary}, locale: 'es-ES'}}
                                            />
                                            </Grid>
                                            </Grid>
                                        </Paper>
                                        </Grid>
                                    {/* <Grid item xs={6}>
                                        <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                            <Plot
                                                data={[                                        
                                                    {
                                                        values: userData['liquidation_values'],
                                                        labels: ['Activa', 'Inductiva', 'Capacitiva', 'Generada', 'Contribución', 'Subsidio'],                                            
                                                        name: 'Valores de liquidación',
                                                        textinfo: "label+percent",
                                                        textposition: "outside",
                                                        automargin: true,
                                                        hole: .7,
                                                        type: 'pie'
                                                    }
                                                ]}
                                                layout={donutLayout}
                                                //style={{border: '1px solid black'}}
                                                config={{displayModeBar: false, locale:'es'}}
                                            />
                                        </Paper> 
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                                {
                                    monthViewSelected ? 
                                    <Grid item xs={5}>                            
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container style={{padding: 10}}>
                                        <Grid item>
                                            <Typography className={classes.typoInfo2}>
                                                Promedios diarios importación y exportación
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Plot
                                            data={[                                        
                                                {
                                                    x: days,
                                                    y: userData['diary_prom'],
                                                    type: 'bar',
                                                    name: 'Importación',
                                                    marker: {
                                                        color: '#D9950D60',
                                                        line: {
                                                            width: 2,
                                                            color: '#FFAF0F'
                                                        }   
                                                    },
                                                    hoverinfo: 'x+y'
                                                    
                                                },
                                                {
                                                    x: days,
                                                    y: userData['diary_prom_g'],
                                                    type: 'bar',
                                                    name: 'Exportación',
                                                    marker: {
                                                        color: '#30F02F60',                                                    
                                                        line: {
                                                            width: 2,
                                                            color: '#42F02F'
                                                        } 
                                                    },
                                                    hoverinfo: 'x+y'
                                                },                                        
                                            ]}
                                            layout={verticalBarLayoutRight}                                            
                                            config={{displayModeBar: false, locale:'es'}}
                                        />
                                        </Grid>
                                        </Grid>
                                    </Paper> 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container style={{padding: 10}}>
                                        <Grid item>
                                            <Typography className={classes.typoInfo2}>
                                                Estadísticas
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} style={{marginTop: 10}} justify="center">                                            
                                                <Grid item xs={3}>
                                                    <Grid container alignItems="center" justify="center" spacing={0}>
                                                        <Grid item>
                                                            <Minus color="error" size="large"/>
                                                        </Grid>
                                                        <Grid item>
                                                        <Typography className={classes.typoInfo}>
                                                            Día con mayor importación promedio:
                                                            <br/>
                                                            {
                                                                Object.keys(userData).length > 0 ? 
                                                                days[userData.diary_prom.indexOf(Math.max(...userData['diary_prom']))]
                                                                :
                                                                null
                                                            }
                                                        </Typography>
                                                        </Grid>
                                                    </Grid>
                                                
                                                </Grid>                                           
                                                <Grid item xs={3}>
                                                    <Grid container alignItems="center" justify="center" spacing={0}>
                                                        <Grid item>
                                                            <Add style={{color: 'green'}} size="large"/>
                                                        </Grid>
                                                        <Grid item>
                                                        <Typography className={classes.typoInfo}>
                                                            Día con mayor exportación promedio:
                                                            <br/>
                                                            {
                                                                Object.keys(userData).length > 0 ? 
                                                                days[userData.diary_prom_g.indexOf(Math.max(...userData['diary_prom_g']))]
                                                                :
                                                                null
                                                            }
                                                        </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container alignItems="center" justify="center" spacing={0}>
                                                        <Grid item>
                                                            <ImportExportIcon color="error" size="large"/>
                                                        </Grid>
                                                        <Grid item>
                                                        <Typography className={classes.typoInfo}>
                                                            Hora con mayor importación promedio:
                                                            <br/>
                                                            {'c_hour_idx' in userData ? userData['c_hour_idx'] + ':00 ' : '-'}
                                                            {'c_hour_idx' in userData ? userData['c_hour_idx'] > 12 ? 'PM' : 'AM' : ''}                                            
                                                        </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container alignItems="center" justify="center" spacing={0}>
                                                        <Grid item>
                                                            <ImportExportIcon style={{color: 'green'}} size="large"/>
                                                        </Grid>
                                                        <Grid item>
                                                        <Typography className={classes.typoInfo}>
                                                            Hora con mayor exportación promedio:
                                                            <br/>
                                                            {'g_hour_idx' in userData ? userData['g_hour_idx'] + ':00 ' : '-'}
                                                            {'g_hour_idx' in userData ? userData['g_hour_idx'] >= 12 ? 'PM' : 'AM' : ''}
                                                        </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>                                            
                                            </Grid>
                                        </Grid>
                                        </Grid>                            
                                    </Paper> 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container style={{padding: 10}}>
                                        <Grid item>
                                            <Typography className={classes.typoInfo2}>
                                                Mapa de calor horario importación/exportación 
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Plot
                                                data={[                                        
                                                    {
                                                        x: hours,
                                                        y: days,
                                                        z: userData['diary_diff'],
                                                        type: 'heatmap',
                                                        colorbar: {
                                                            //showticklabels: false,                                                
                                                            len: 0.7,                                                
                                                            thickness: 20,
                                                            
                                                            tickfont:{
                                                                color: "#2E2B3E",
                                                                size: 1
                                                            },
                                                            //showticklabels: false,
                                                            //dtick: 0,
                                                            orientation: "h",
                                                            ypad: 1,
                                                            x: 0.5,
                                                            y: -0.2,
                                                            
                                                        },
                                                        hoverinfo: 'none',
                                                        colorscale: [[0, "#00BDFF"], [0.17, "#59E9FF"], [0.34, "#60FFAB"], [0.51,'#30F02F'], [0.68, "#DAF05D"], [0.85, '#EDD75A'], [1,'#FFAF0F']]
                                                        //colorscale: colorscaleValue,
                                                        //showscale: false
                                                    }
                                                ]}
                                                layout={heatmapLayout}                                            
                                                config={{displayModeBar: false, locale:'es'}}
                                            />
                                        </Grid>
                                        </Grid>
                                    </Paper>                            
                                    </Grid>
                                    :
                                    null
                                }

                            </Grid>
                        </Grid>
                    } 
                    </Fragment>
                }                                                                                                                                     
            </Grid>      
            </Zoom>                      
        </div>
    )
}