import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [cycleDialog, setCycleDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [cyclesList, setCyclesList] = useState([])
    const [monthParamsObj, setMonthParamsObj] = useState({})
    const [paramID, setParamID] = useState(-1)
    const [cycleCode, setCycleCode] = useState("")    
    let billingDate = props.billingDate
    var firstDay = new Date(billingDate.getFullYear(), billingDate.getMonth(), 1);
    var lastDay = new Date(billingDate.getFullYear(), billingDate.getMonth() + 1, 0);
    const [expdDate, setExpdDate] = useState(null)
    const [exprDate, setExprDate] = useState(null)
    const [suspDate, setSuspDate] = useState(null)    
    const [firstBillNumber, setFirstBillNumber] = useState(0)
    const [lastBillNumber, setLastBillNumber] = useState(0)
    const [prevLastBillNumber, setPrevLastBillNumber] = useState(0)
    const [usersArray, setUsersArray] = useState([])
     
    const [usersInCycle, setUsersInCycle] = useState({})
    const [billingStatus, setBillingStatus] = useState({})

    const handleCycleDialogOpen = () => {
        setCycleDialog(true);
      };
    
    const handleCycleDialogClose = () => {
        setCycleDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedCycle = (code, idx) => {
        if (code in monthParamsObj){
            setExpdDate( new Date( Date.parse(monthParamsObj[code]['expd_date']) ) )
            setExprDate( new Date( Date.parse(monthParamsObj[code]['expr_date']) ) )
            setSuspDate( new Date( Date.parse(monthParamsObj[code]['susp_date']) ) )
            setFirstBillNumber(monthParamsObj[code]['first_bill_number'])          
            setLastBillNumber(monthParamsObj[code]['last_bill_number']) 
            setParamID(monthParamsObj[code]['id'])          
        }
        else {
            setParamID(-1)
            if (idx > 0){
                let arrSum = usersArray.slice(0, idx).reduce((acc,curr) => acc+curr)
                let offset = prevLastBillNumber + arrSum + 1
                setFirstBillNumber(offset)
                setLastBillNumber(offset + usersInCycle[code] - 1)
            }
            else {
                let offset = prevLastBillNumber + 1
                setFirstBillNumber(offset)
                setLastBillNumber(offset + usersInCycle[code] - 1)
            }
        }
    }
    
    const handleCycleUpload = () => {        
        if (!expdDate || !exprDate || !suspDate || !firstBillNumber || firstBillNumber === ""){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {                        
            let opts = {                                                
                'username': props.username,                                
                'billing_year': billingDate.getFullYear(),
                'billing_period': billingDate.getMonth() + 1,
                'cycle_code': cycleCode,
                'start_date': firstDay,
                'end_date': lastDay,                
                'expd_date': expdDate,
                'expr_date': exprDate,
                'susp_date': suspDate,                
                'first_bill_number': firstBillNumber,
                'last_bill_number': lastBillNumber
              }             
              paramID === -1 ? setParamID(-1) : opts['m_params_id'] = paramID
              //console.log('opts mp', opts)
              authFetch(
                "/api/update-billing-month-params",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                    handleFeedbackDialogOpen()
                    handleCycleDialogClose()
                    authFetch("/api/check-billing-month-parameters?username=" + String(props.username)+
                                                  "&month="+String( billingDate.getMonth() + 1 ) +
                                                  "&year="+ billingDate.getFullYear()).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.month_params){
                                setMonthParamsObj(response.month_params)  
                                setPrevLastBillNumber(response.last_bill_number)                                      
                            }
                        }) 
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showCycleDialog = () => (
        <Dialog
            open={cycleDialog}
            onClose={handleCycleDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Inicio periodo de facturación</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="dd-MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={firstDay}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}                                
                                error={false}
                                InputProps={{    
                                            className: classes.formDefined,                                        
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                disabled                                                                                
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Fin periodo de facturación</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="dd-MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={lastDay}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}                                
                                error={false}
                                InputProps={{    
                                            className: classes.formDefined,                                        
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                disabled                                                                                
                            /> 
                        </Grid>                                              
                    </Grid>                                       
                </Grid>                    
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Fecha de expedición:</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}                                
                                format={"dd-MMMM-yyyy"}                                                                                            
                                value={expdDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={(date) => {let auxDate = new Date(date.setHours(0,0,0,0));setExpdDate(auxDate);}}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Fecha de vencimiento:</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                format="dd-MMMM-yyyy"                                                               
                                value={exprDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={(date) => {let auxDate = new Date(date.setHours(0,0,0,0));setExprDate(auxDate);}}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Fecha de suspensión:</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}                                
                                format="dd-MMMM-yyyy"                                                          
                                value={suspDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={(date) => {let auxDate = new Date(date.setHours(0,0,0,0));setSuspDate(auxDate);}}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}                            
                                autoOk                                                                                  
                            /> 
                        </Grid>
                    </Grid>                    
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Primer No. factura:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField    
                                type={'number'}                            
                                value={firstBillNumber}                                
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setFirstBillNumber(e.target.value)}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Último No. factura:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField       
                                type={'number'}                             
                                value={lastBillNumber}                               
                                InputProps={{
                                    disabled: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                   
                            />
                        </Grid>
                    </Grid>
                </Grid>                      
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleCycleUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleCycleDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const updateUsersArray = (cycles, cycleUsers) => {        
        let auxArray = cycles.flatMap(cycle => (cycleUsers[cycle['code']]) )
        //console.log('u arr', auxArray)
        setUsersArray([...auxArray])
    }

    useEffect (() => {
        document.title ="Parametrización mensual";
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){                    
                    let cycles = response.cycles                    
                    setCyclesList(response.cycles)                     
                    authFetch("/api/get-cycle-liq-users?username=" + String(props.username)+
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.cycle_users){
                                let cycleUsers = response.cycle_users
                                updateUsersArray (cycles, cycleUsers)
                                setUsersInCycle(response.cycle_users)     
                                let opts = {
                                    users_by_cycle: cycleUsers,                    
                                } 
                                authFetch("/api/check-billing-status-by-cycle?username=" + String(props.username) +
                                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                                {
                                    method: 'post',
                                    body: JSON.stringify(opts)
                                }
                                ).then(response => {
                                    if (response.status === 422){
                                        logout()
                                        return null
                                    }
                                        return response.json()
                                    }).then(response => {
                                        if (response && response.billing_status){    
                                            //console.log(response.billing_status)              
                                            setBillingStatus(response.billing_status)                                                                                            
                                        }
                                    })                                                                    
                            }
                        })                                        
                }
            })
               
        authFetch("/api/check-billing-month-parameters?username=" + String(props.username)+
                                                  "&month="+String( billingDate.getMonth() + 1 ) +
                                                  "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.month_params){                    
                    setMonthParamsObj(response.month_params)  
                    setPrevLastBillNumber(response.last_bill_number)                                      
                }
            })        
    }, [props.billingDate])

    return (
        <div className={classes.root}>
            {showCycleDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Parametrización mensual por ciclo <br/>
                            Último no. de factura: {' '}
                            {prevLastBillNumber}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Código ciclo</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Inicio periodo facturación</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Fin periodo facturación</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Fecha de expedición</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Fecha de vencimiento</StyledTableCell>      
                                        <StyledTableCell align="center" style={{width: "12%"}}>Fecha de suspensión</StyledTableCell>  
                                        <StyledTableCell align="center" style={{width: "12%"}}>Primer No. factura</StyledTableCell>           
                                        <StyledTableCell align="center" style={{width: "12%"}}>Último No. factura</StyledTableCell>                         
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {cyclesList.map((row,idx) => (
                                            <Fragment>
                                                {
                                                    Number(usersInCycle[row.code]) > 0 ?
                                                    <StyledTableRow key={idx} onClick={() => {updateValuesWithSelectedCycle(row.code, idx); if(! billingStatus[row.code]){handleCycleDialogOpen();}; setCycleCode(row.code);}}>                                                        
                                                        {
                                                            Object.keys(monthParamsObj).length > 0 && row.code in monthParamsObj ?
                                                            <Fragment>
                                                            <StyledTableCell align="center">{row.code}</StyledTableCell>
                                                            <StyledTableCell align="center" >{new Date(monthParamsObj[row.code]['start_date']).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell>
                                                            <StyledTableCell align="center">{new Date(monthParamsObj[row.code]['end_date']).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell>
                                                            <StyledTableCell align="center">{new Date(monthParamsObj[row.code]['expd_date']).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell>
                                                            <StyledTableCell align="center" >{new Date(monthParamsObj[row.code]['expr_date']).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell> 
                                                            <StyledTableCell align="center" >{new Date(monthParamsObj[row.code]['susp_date']).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell>   
                                                            <StyledTableCell align="center" >{monthParamsObj[row.code]['first_bill_number']}</StyledTableCell> 
                                                            <StyledTableCell align="center" >{monthParamsObj[row.code]['last_bill_number']}</StyledTableCell> 
                                                            </Fragment>
                                                            :
                                                            [row.code,'-','-','-','-','-','-','-'].map(val => (
                                                                <StyledTableCell align="center">{val}</StyledTableCell>
                                                            ))                                                           
                                                        }                                                                                                                                                     
                                                    </StyledTableRow>
                                                    :
                                                    null
                                                }
                                            </Fragment>
                                            
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer>                                   
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}