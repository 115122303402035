import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Menu,
        Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import InfoIcon from '@material-ui/icons/Info';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: 3,
      width: '30%',
      borderRadius: 5,
      height: '80%'
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      borderRadius: 5,
      padding: 0
    },
  }))(TableCell);

  const StyledTableCell2 = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: 3,
      maxWidth: '20%',
      borderRadius: 5,
      height: '80%'
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      borderRadius: 5,
      padding: 0
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.client.main.light,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    tableTitle: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 13 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    componentPrincipalTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        padding: theme.spacing(1),
        border: `solid 1px ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    avatarStyle: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: theme.palette.primary.dark,
    },
    accordionList: {
        width: '100%',                
    },
    dividerStyle: {
        backgroundColor: theme.palette.client.contrastText
    },    
    listInputsContentStyle: {
        color:  theme.palette.primary.dark,  
        marginLeft: theme.spacing(2)      
    }
  }));

const selectTxf = [
    {label: ".tx1"}, {label: ".tx2"}, {label: ".txr"} , {label: ".txf"}
]

const reliqTxf = [
    {label: ".tx3"}, {label: ".tx4"}, {label: ".tx5"} , {label: ".tx6"}, {label: ".tx7"}, {label: ".tx8"}
]
  
export default function StateConsole(props) {
    const classes = useStyles();    
    var actualDate = new Date()        
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [publicationDialog, setPublicationDialog] = useState(false) 
    const [internalCalcDialog, setInternalCalcDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [componentState, setComponentState] = useState([])
    const [actualComponentIdx, setActualComponentIdx] = useState(-1)
    const [newVersionDialog, setNewVersionDialog] = useState(false)
    const [reliqDialog, setReliqDialog] = useState(false)
    const [reliqObs, setReliqObs] = useState("")
    const [calcVersion, setCalcVersion] = useState("")
    const [calcTxfVersion, setCalcTxfVersion] = useState("")
    const [calcType, setCalcType] = useState("") 
    const [defVersion, setDefVersion] = useState(false)  
    const [selectedInternalCalc, setSelectedInternalCalc] = useState({})

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handlePublicationDialogOpen = () => {
        setPublicationDialog(true);
    };
    
    const handlePublicationDialogClose = () => {
        setPublicationDialog(false);
    };

    const handleInternalCalcDialogOpen = () => {
        setInternalCalcDialog(true);
      };
    
    const handleInternalCalcDialogClose = () => {
        setInternalCalcDialog(false);
    };


    const handleNewVersionDialogOpen = () => {
        setNewVersionDialog(true);
      };
    
    const handleReliqDialogClose = () => {
        setReliqDialog(false);
    };

    const handleReliqDialogOpen = () => {
        setReliqDialog(true);
      };
    
    const handleNewVersionDialogClose = () => {
        setNewVersionDialog(false);
    };

    const handleApvButtonClick = (idx) => (e) => {        
        let auxState = componentState
        let auxObject = componentState[idx]
        let newObject = {...auxObject, Approved: true}
        auxState[idx] = newObject
        setComponentState([...auxState])
    }

    const handleCalcVersionUpload = () => {      
          
        if (calcVersion === "" || calcTxfVersion === "" || calcType === ""){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {
            let year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1
            let period = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1
            let version = calcVersion
            let usernameCode = props.username.slice(0,3)
            let pubDict = {}
            let dateDict = {}
            for (let market of props.markets){
                pubDict[market] = false
                dateDict[market] = '-'
            }
            let opts = {
                'electric_tariff_id': String( usernameCode + String( String(year) + String(period) + version ) ),
                'username': props.username,
                'period': Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1,
                'year': Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1,
                'tariffs_calc_version': calcVersion,
                'xm_files_version': calcTxfVersion,  
                'calc_type': calcType,    
                'published': JSON.stringify(pubDict),
                'pub_dates': JSON.stringify(dateDict),       
                'definitive_ver': calcVersion === 'def' ? true : false,
                'copy':  calcVersion !== 'def' ? true : false,
              }
              authFetch(
                "/api/update-components",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Nueva versión creada.')
                    handleFeedbackDialogOpen()
                    handleNewVersionDialogClose()
                    authFetch("/api/get-version-info?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
                        + "&year=" + props.periodDate.getFullYear()).then(response => {
                            if (response.status === 422){
                                logout()
                                return null
                            }
                                return response.json()
                            }).then(response => {
                                if (response && response.txfVersions){
                                    if (response.txfVersions.length > 0){
                                        props.setTxfVersions(response.txfVersions)
                                        props.setTxfVersion(response.txfVersions[0])    
                                        props.setTariffCalcVersions(response.versions)
                                        props.setTariffCalcVersion(calcVersion)
                                        props.setTariffsPublisheds(response.tariffsPublished)
                                        props.setTariffsPublished(response.tariffsPublished[0])  
                                        props.setCalcTypes(response.calcTypes)         
                                        props.setCalcType(response.calcTypes[0])                 
                                    }                                          
                                }
                                
                            })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const handleComponentUpload = (idx) => {   
        let auxState = componentState
        let auxObject = componentState[idx]
        let newObject = {...auxObject, Published: true}
        auxState[idx] = newObject
        let published = !(auxState.map(state => state.Published).includes(false))
        let pubDict = {}
        pubDict[props.market] = published
        setComponentState([...auxState])        
        let component = {...newObject}
        let cmpDbName = component.DbName
        let year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1
        let period = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1
        let version = props.tariffCalcVersion
        let usernameCode = props.username.slice(0,3)
        let opts = {
            'electric_tariff_id': String( usernameCode + String( String(year) + String(period) + version ) ),
            'published': pubDict,   
                               
        }       
        if (defVersion){
            opts['definitive_ver'] = defVersion
            opts['tariffs_calc_version'] = 'def'
        }
        let marketComponent = {}  
        delete component.Name
        delete component.Files
        delete component.FilesState
        delete component.Parameters
        delete component.ParametersState
        delete component.CalcVarNames
        delete component.CompLabels    
        delete component.Approved
        delete component.Published
        delete component.DbName
        delete component.ReliqConcepts
        marketComponent[idx === 0 || idx === 2 ? 'UNI':props.market] = component          
        opts[cmpDbName] = marketComponent                                   
            authFetch(
            "/api/update-components",
            {
                method: 'post',
                body: JSON.stringify(opts),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                setFeedbackMsg('Valor de componente publicado.')
                handleFeedbackDialogOpen()               
                authFetch("/api/get-version-info?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
                    + "&year=" + props.periodDate.getFullYear()).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.txfVersions){
                                handleFeedbackDialogClose() 
                                if (response.txfVersions.length > 0){
                                    props.setTxfVersions(response.txfVersions)
                                    props.setTxfVersion(response.txfVersions[0])    
                                    props.setTariffCalcVersions(response.versions)
                                    props.setTariffCalcVersion(version)
                                    props.setTariffsPublisheds(response.tariffsPublished)
                                    props.setTariffsPublished(response.tariffsPublished[0])  
                                    props.setCalcTypes(response.calcTypes)         
                                    props.setCalcType(response.calcTypes[0])   
                                    props.setDefinitiveVersion(response.definitiveVersion)              
                                }      
                                if ( published && props.username !== 'SPECTRUM'){
                                    authFetch("/api/calc-cu?electric_tariff_id=" + String( usernameCode + String( String(year) + String(period) + (defVersion ? 'def' : version )) ) 
                                    + "&market=" + props.market +
                                    "&username="+ props.username
                                    ).then(response => {
                                        if (response.status === 422){
                                            logout()
                                            return null
                                        }
                                            return response.json()
                                        }).then(response => {
                                            if (response && response.Upload === 'OK'){
                                                handleFeedbackDialogOpen() 
                                                setFeedbackMsg('Tarifas del mercado calculadas.') 
                                                // Calculo de subsidios sin publicar opcion tarifaria CASO ESPECIAL CEDENAR  
                                                if (props.username === 'Cedenar' && Number(year) >= 2024){
                                                    let opts = {
                                                        'electric_tariff_id': String( usernameCode + String( String(year) + String(period) + (defVersion ? 'def' : version ) ) ), 
                                                        'optariff_status': JSON.stringify({}),
                                                        'cu_optariff_form': JSON.stringify({}),
                                                        'sa_optariff_form': JSON.stringify({})
                                                    }  
                                                    authFetch(
                                                        "/api/calc-cu-op-tariff?username="+props.username+
                                                        "&period="+String( props.periodDate.getMonth() + 1 )+    
                                                        "&year="+ props.periodDate.getFullYear(),
                                                        {
                                                            method: 'post',
                                                            body: JSON.stringify(opts),
                                                        }
                                                    ).then(r => {
                                                          if (r.status === 422){
                                                            logout()
                                                            return null
                                                          }
                                                          else{
                                                            return r.json()
                                                          }
                                                        })
                                                    .then(response => {
                                                        if (response.Upload === 'OK'){
                                                            setFeedbackMsg('Valor de Cu con subisidios calculado.')
                                                            handleFeedbackDialogOpen()   
                                                            props.setOpTariffPub(true)                            
                                                        }
                                                        else {
                                                            setFeedbackMsg('Error al guardar los datos.')
                                                            handleFeedbackDialogOpen()
                                                        }
                                                    })
                                                }                            
                                            }
                                        })
                                }                                    
                            }
                        })
                }
                else {
                setFeedbackMsg('Error al guardar los datos.')
                handleFeedbackDialogOpen()
                }
            })
            
    }

    const handleReliquidationUpload = () => {      
          
        if (props.reliqTxfVersion === ""){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {
            let app_date =  new Date(props.reliqSelectedPeriod)
            app_date = new Date(app_date.setMonth(props.reliqSelectedPeriod.getMonth() + 1))                 
            let opts = {                
                'username': props.username,
                'rlq_year': props.periodDate.getFullYear(),
                'rlq_month': props.periodDate.getMonth() + 1,
                'app_year': app_date.getFullYear(),
                'app_month': app_date.getMonth() + 1,  
                'entry_date': new Date(),    
                'initial_xm_ver': props.reliqLastTxfVersion !== "" ? props.reliqLastTxfVersion : props.txfVersion,
                'final_xm_ver': props.reliqTxfVersion,       
                'finished': false,     
                'observation': reliqObs,
                'final_calc_ver': 'r'+String(props.usedXmVersions.length+1),
                'create': true        
              }
              authFetch(
                "/api/update-reliquidations",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Nueva reliquidación creada.')
                    handleFeedbackDialogOpen()
                    handleReliqDialogClose()
                    authFetch("/api/get-version-info?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
                        + "&year=" + props.periodDate.getFullYear()).then(response => {
                            if (response.status === 422){
                                logout()
                                return null
                            }
                                return response.json()
                            }).then(response => {
                                if (response && response.txfVersions){
                                    if (response.txfVersions.length > 0){
                                        props.setTxfVersions(response.txfVersions)
                                        props.setTxfVersion(response.txfVersions[0])    
                                        props.setTariffCalcVersions(response.versions)
                                        props.setTariffCalcVersion(response.versions[0])
                                        props.setTariffsPublisheds(response.tariffsPublished)
                                        props.setTariffsPublished(response.tariffsPublished[0])  
                                        props.setCalcTypes(response.calcTypes)         
                                        props.setCalcType(response.calcTypes[0])  
                                        props.setReliqTxfVersion(response.rlqVersion)
                                        props.setReliqLastTxfVersion(response.lastVersion)
                                        props.setReliqFinished(response.rlqFinished)
                                        props.setUsedXmVersions(response.usedXmVersions)               
                                    }                                          
                                }                                
                            })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const showPublicationDialog = () => (
        <Dialog
            open={publicationDialog}
            onClose={handlePublicationDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
            ¿Desea publicar el valor de la componente?. Esta acción no se puede deshacer.
            </DialogContentText>
            {
                componentState.length > 0 ?
                    componentState.filter((state) => !state.Published).length === 1 && !props.definitiveVersion? 
                    <DialogContentText>
                    ¿Es una versión de cálculo definitiva?
                    <Checkbox
                        checked={defVersion}
                        color="primary"
                        onChange={(e) => {setDefVersion(e.target.checked)}}                        
                    />
                    </DialogContentText>
                    :
                    null
                    :
                    null
            }
        </DialogContent>
        <DialogActions>   
          <Button onClick={handlePublicationDialogClose} color="primary" autoFocus>
            Cancelar
          </Button>       
          <Button onClick={(e) => {handlePublicationDialogClose(); handleComponentUpload(actualComponentIdx);}} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const showNewVersionDialog = () => (
        <Dialog
            open={newVersionDialog}
            onClose={handleNewVersionDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'sm'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos de la nueva versión de cálculo."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={8}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Versión de archivos XM:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                select
                                value={calcTxfVersion}
                                onChange={(e) => setCalcTxfVersion(e.target.value)}                                        
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                            >
                                {selectTxf.map((option, idx) => (
                                    <MenuItem key={idx} value={option.label}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Versión de cálculo:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={calcVersion}                            
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}     
                                select   
                                onChange={(e) => setCalcVersion(e.target.value)}                                                       
                            >
                                {
                                    <MenuItem value={String(props.tariffsPublisheds.length + 1) === '1' ? 'def' : String(props.tariffsPublisheds.length + 1)}>{String(props.tariffsPublisheds.length + 1) === '1' ? 'def' : String(props.tariffsPublisheds.length + 1)}</MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de cálculo:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={calcType}                            
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}     
                                select   
                                onChange={(e) => setCalcType(e.target.value)}                                                       
                            >                            
                                <MenuItem value={'MONOMIO'}>{'MONOMIO'}</MenuItem>
                                <MenuItem value={'FRANJAS HORARIAS'}>{'FRANJAS HORARIAS'}</MenuItem>                                
                            </TextField>
                        </Grid>
                    </Grid>                                       
                </Grid>   
                <Grid item xs={12}>                    
                </Grid>  
                <Grid item>
                    <Fab
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"                        
                    classes={{root: classes.uploadButton}}
                    onClick={handleCalcVersionUpload}
                    style={{marginTop: 20}}
                > 
                    <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleNewVersionDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>                            
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const showReliqDialog = () => (
        <Dialog
            open={reliqDialog}
            onClose={handleReliqDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'sm'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los parámetros de la reliquidación a aplicar."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={8}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo a reliquidar (mes de insumos):</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"                                            
                                views={["year","month"]}                                           
                                value={props.periodDate}                                                                      
                                error={false}
                                InputProps={{              
                                            className: classes.formDefined,                              
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk    
                                fullWidth
                                disabled                                                                              
                            /> 
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo de aplicación (cálculo cu):</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                           <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"                                            
                                views={["year","month"]}                                           
                                value={props.reliqSelectedPeriod ? new Date(props.reliqSelectedPeriod).setMonth(props.reliqSelectedPeriod.getMonth() + 1) : new Date()}                                                                      
                                error={false}
                                InputProps={{              
                                            className: classes.formDefined,                              
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk    
                                fullWidth
                                disabled                                                                              
                            /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Versión XM inicial:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField                                                    
                                value={props.reliqLastTxfVersion !== "" ? props.reliqLastTxfVersion : props.txfVersion}
                                //onChange={handleTxfVersionChange}                                        
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                disabled
                            >                                                    
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Versión XM final:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={props.reliqTxfVersion}                            
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}     
                                select   
                                onChange={(e) => props.setReliqTxfVersion(e.target.value)}                                                       
                            >                            
                                {reliqTxf.filter((obj) => !props.usedXmVersions.includes(obj.label)).map((option, idx) => (
                                    <MenuItem key={idx} value={option.label}>
                                        {option.label}
                                    </MenuItem>
                                ))}                              
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Observación:</Typography>
                        </Grid>
                        <Grid item>
                            <AddIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField                                                    
                                value={reliqObs}
                                multiline
                                onChange={(e) => setReliqObs(e.target.value)}                                        
                                InputProps={{                                   
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                            >                                                    
                            </TextField>
                        </Grid>
                    </Grid>                                       
                </Grid>   
                <Grid item xs={12}>                    
                </Grid>  
                <Grid item>
                    <Fab
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"                        
                    classes={{root: classes.uploadButton}}
                    onClick={handleReliquidationUpload}
                    style={{marginTop: 20}}
                > 
                    <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleReliqDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>                            
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const showInternalCalcDialog = () => (
        <Dialog
            open={internalCalcDialog}
            onClose={handleInternalCalcDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'sm'}
        >        
        <DialogTitle onClose={handleInternalCalcDialogClose}></DialogTitle>
        <DialogContent className={classes.dialogContent}>  
            {
                Object.keys(selectedInternalCalc).length > 0 ?
                <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" align="center">{selectedInternalCalc.Name}</Typography>                                      
                    </Grid>   
                    <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table>                                               
                                {
                                    selectedInternalCalc.Vars.map((name, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCell key={idx} align="center" variant="head">{name}</StyledTableCell>                                                                                                                                                                                                                                              
                                            <StyledTableCell key={idx} align="center">{selectedInternalCalc.Values[idx].toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>                                                                                                                                                            
                                        </StyledTableRow>
                                    ))
                                }                                                  
                        </Table>
                    </TableContainer> 
                    </Grid>   
                    <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table>                                               
                                {
                                    selectedInternalCalc.FinalName.map((name, idx) => (
                                        <StyledTableRow>
                                            <StyledTableCell key={idx} align="center" variant="head">{name}</StyledTableCell>                                                                                                                                                                                                                                              
                                            <StyledTableCell key={idx} align="center">{selectedInternalCalc.Final[idx].toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>                                                                                                                                                            
                                        </StyledTableRow>
                                    ))
                                }                                                  
                        </Table>
                    </TableContainer> 
                    </Grid>                        
                </Grid> 
                :
                null                            
            }                   
                       
        </DialogContent>        
      </Dialog>
    )

    const inputsStateList = (files, filesState, filesDate, params, paramsState, paramsDate) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Archivos</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                    <Grid container>           
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>     
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Fecha de ingreso
                        </Grid>
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={4}>
                                    {filename}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {filesDate[idx]}
                                    </Grid>
                                    <Grid item xs={4}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item xs={10}>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }
                                                                                                    
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Parámetros</Typography>
                </Grid>
                {
                    params.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container>
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>     
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Fecha de ingreso
                        </Grid>
                        <Grid item xs={4} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                params.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={4}>
                                        {param}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {paramsDate[idx]}
                                    </Grid>
                                    <Grid item xs={4}>
                                    { paramsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item xs={10}>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }                    
            </Grid>             
        </Fragment>            
    )

    const checkCalc = (cmpIdx, idx) => (e) => {        
        if ('InternalCalc' in componentState[cmpIdx]){
            if ( Object.keys(componentState[cmpIdx]['InternalCalc'][idx]).length > 0 && (
                componentState[cmpIdx]['InternalCalc'][idx]['Values'].reduce((acc, curr) => acc+curr) != 0
                || componentState[cmpIdx]['InternalCalc'][idx]['Final'] != 0
                )){
                setSelectedInternalCalc(componentState[cmpIdx]['InternalCalc'][idx])
                handleInternalCalcDialogOpen()                      
            }
        }
    }

    const checkCalc2 = (cmpIdx, idx) => {        
        if ('InternalCalc' in componentState[cmpIdx]){
            if ( Object.keys(componentState[cmpIdx]['InternalCalc'][idx]).length > 0 && (
                componentState[cmpIdx]['InternalCalc'][idx]['Values'].reduce((acc, curr) => acc+curr) != 0
                || componentState[cmpIdx]['InternalCalc'][idx]['Final'] != 0
                )){               
                return true        
            }
        }
        else{
            return false
        }
    }

    const componentCalcTable = (calcNames, calcValues, cmpIdx) => (
        <Grid container justify="space-evenly">
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.componentTitle}>Variables involucradas</Typography>
            </Grid>
            <Grid item xs={10}>
                <TableContainer component={Paper}>
                    <Table>                                               
                            {
                                calcNames.map((name, idx) => (
                                    <Fragment>
                                    <StyledTableRow onClick={checkCalc(cmpIdx, idx)}>
                                         
                                         <StyledTableCell key={idx} align="center" variant="head">{name}
                                         {
                                            checkCalc2(cmpIdx, idx) ?
                                            <InfoIcon size="small"/>
                                            :
                                            null
                                         }
                                         </StyledTableCell>
                                         {                                                                                                         
                                            calcValues.length > 0 ?                                                
                                                <StyledTableCell key={idx} align="center">{calcValues[idx].toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>                                                
                                                :                  
                                                <StyledTableCell key={idx} align="center">-</StyledTableCell>                 
                                         }
                                    </StyledTableRow>
                                   
                                    </Fragment>
                                ))
                            }                                                  
                    </Table>
                </TableContainer> 
            </Grid>                      
        </Grid>
    )

    const componentValuesTable = (calcTitles, calcValues, calcType, idx) => (
        <Grid container justify="space-evenly">
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.componentTitle}>Cálculo resultante</Typography>
            </Grid>
            {
                (calcType === 'FRANJAS HORARIAS' && idx === 5) ?   
                <Grid item>
                {
                    ['Máxima','Media','Mínima','Monomia'].map( (title, idx) =>(
                        <TableContainer component={Paper} style={{marginTop: 10}}>
                        <Typography className={classes.tableTitle}>{title}</Typography>
                        <Table style={{ tableLayout: 'fixed'}}>           
                                <TableHead>
                                    <TableRow>
                                    {
                                            calcTitles.slice(3*idx, 3*idx + 3).map( (title,idx) =>(
                                                <StyledTableCell2 align="center" key={idx}>{title}</StyledTableCell2>       
                                            ))  
                                        }                                                            
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                    {
                                        calcValues.slice(3*idx, 3*idx + 3).map((val, idx) => (                                        
                                            <StyledTableCell2 key={idx} align="center">{val.toLocaleString('en-US', { maximumFractionDigits: 6 })}</StyledTableCell2>                                                                                 
                                        ))
                                    } 
                                    </StyledTableRow>   
                                </TableBody>
                        </Table>
                    </TableContainer> 
                    ))
                }                
                
                </Grid>                             
                :
                (calcType === 'MONOMIO' && idx === 0) ?                   
                <Grid item>
                <TableContainer component={Paper}>
                    <Table style={{ tableLayout: 'fixed'}}>           
                            <TableHead>
                                <TableRow>
                                    {
                                        calcTitles.slice(3,4).map( (title,idx) =>(
                                            <StyledTableCell2 align="center" key={idx}>{title}</StyledTableCell2>       
                                        ))  
                                    }                                                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                {
                                    calcValues.slice(3,4).map((val, idx) => (                                        
                                        <StyledTableCell2 key={idx} align="center">{val.toLocaleString('en-US', { maximumFractionDigits: 6 })}</StyledTableCell2>                                                                                 
                                    ))
                                } 
                                </StyledTableRow>   
                            </TableBody>
                    </Table>
                </TableContainer> 
                </Grid>
                :
                (calcType === 'MONOMIO' && idx === 5) ?                   
                <Grid item>
                <TableContainer component={Paper}>
                    <Table style={{ tableLayout: 'fixed'}}>           
                            <TableHead>
                                <TableRow>
                                    {
                                        calcTitles.slice(9,12).map( (title,idx) =>(
                                            <StyledTableCell2 align="center" key={idx}>{title}</StyledTableCell2>       
                                        ))  
                                    }                                                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                {
                                    calcValues.slice(9,12).map((val, idx) => (                                        
                                        <StyledTableCell2 key={idx} align="center">{val.toLocaleString('en-US', { maximumFractionDigits: 6 })}</StyledTableCell2>                                                                                 
                                    ))
                                } 
                                </StyledTableRow>   
                            </TableBody>
                    </Table>
                </TableContainer> 
                </Grid>
                :
                <Grid item>
                <TableContainer component={Paper}>
                    <Table style={{ tableLayout: 'fixed'}}>           
                            <TableHead>
                                <TableRow>
                                    {
                                        calcTitles.map( (title,idx) =>(
                                            <StyledTableCell2 align="center" key={idx}>{title}</StyledTableCell2>       
                                        ))  
                                    }                                                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                {
                                    calcValues.map((val, idx) => (                                        
                                        <StyledTableCell2 key={idx} align="center">{val.toLocaleString('en-US', { maximumFractionDigits: 6 })}</StyledTableCell2>                                                                                 
                                    ))
                                } 
                                </StyledTableRow>   
                            </TableBody>
                    </Table>
                </TableContainer> 
                </Grid> 
            }
                                 
        </Grid>
    )

    const reliquidationsTable = (reliqValues, reliqDates) => (
        <Grid container spacing={0} justify="center">
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.componentTitle}>Conceptos reliquidados</Typography>
            </Grid>
            <Grid item xs={12}>                
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>                            
                            {
                                ['Periodo','Concepto','Cambio'].map( (label, idx) => (
                                    <StyledTableCell align="center" key={idx}>{label}</StyledTableCell>
                                ))
                            }                                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reliqValues.map((reliqConceptArray, pidx) => (
                                <Fragment>
                                {
                                    reliqConceptArray.map((reliq, sidx) => (
                                    <StyledTableRow key={sidx}>                                                                       
                                            <StyledTableCell align="center">{reliqDates[sidx]}</StyledTableCell>
                                            {
                                                sidx === 0 ?
                                                <StyledTableCell align="center" rowSpan={reliqConceptArray.length}>{reliq.Concept}</StyledTableCell>
                                                :
                                                null
                                            }
                                            <StyledTableCell align="center">{reliq.Values[4]}</StyledTableCell>                                                                                 
                                    </StyledTableRow>
                                ))
                                }
                                <StyledTableRow key={pidx}>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center">{'TOTAL'}</StyledTableCell>
                                    <StyledTableCell align="center">{reliqConceptArray.reduce((tot, current) => tot + Number(current.Values[4]),0)}</StyledTableCell>
                                </StyledTableRow>
                                
                                <StyledTableRow style={{height: 20}}>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                </StyledTableRow>
                                </Fragment>
                            ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>       
    )

    const affectedVarsTable = (reliqAffectedVars, cmpIdx, calcType) => (
        <Grid container spacing={0} justify="center">
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.componentTitle}>Variables afectadas</Typography>
            </Grid>
            <Grid item xs={12}>                
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>                            
                            {
                                ['Variable','Valor inicial','Valor con delta'].map( (label, idx) => (
                                    <StyledTableCell align="center" key={idx}>{label}</StyledTableCell>
                                ))
                            }                                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reliqAffectedVars.map((affectedVarObj, pidx) => (
                                <StyledTableRow>           
                                    {
                                        cmpIdx < 4 ? 
                                        <Fragment>
                                        <StyledTableCell align="center">{affectedVarObj.var}</StyledTableCell>
                                        <StyledTableCell align="center">{affectedVarObj.ini.toLocaleString('en-US', { minimumFractionDigits: pidx === reliqAffectedVars.length -1? 6:2 })}</StyledTableCell>
                                        <StyledTableCell align="center">{affectedVarObj.final.toLocaleString('en-US', { minimumFractionDigits: pidx === reliqAffectedVars.length -1? 6:2 })}</StyledTableCell> 
                                        </Fragment>
                                        :
                                        cmpIdx === 5 ?
                                        <Fragment>
                                            {
                                                calcType === 'MONOMIO' ?
                                                pidx > 9 || pidx === 0?
                                                    <Fragment>
                                                    <StyledTableCell align="center">{affectedVarObj.var}</StyledTableCell>
                                                    <StyledTableCell align="center">{affectedVarObj.ini.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>
                                                    <StyledTableCell align="center">{affectedVarObj.final.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell> 
                                                    </Fragment>
                                                    :
                                                    null
                                                :
                                                <Fragment>
                                                <StyledTableCell align="center">{affectedVarObj.var}</StyledTableCell>
                                                <StyledTableCell align="center">{affectedVarObj.ini.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>
                                                <StyledTableCell align="center">{affectedVarObj.final.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell> 
                                                </Fragment>
                                            }                                          
                                        </Fragment>                                        
                                        :                                        
                                        <Fragment>
                                        <StyledTableCell align="center">{affectedVarObj.var}</StyledTableCell>
                                        <StyledTableCell align="center">{affectedVarObj.ini.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell>
                                        <StyledTableCell align="center">{affectedVarObj.final.toLocaleString('en-US', { minimumFractionDigits: 6 })}</StyledTableCell> 
                                        </Fragment>
                                    }                                                                                                                       
                                                                   
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>       
    )

    useEffect (() => {
        document.title ="Consola de estado";
        if (props.tariffCalcVersion !== "" && props.market !== ""){
            authFetch("/api/calc-components?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear() + "&market=" + String( props.market ) + "&version=" + String( props.tariffCalcVersion )
            + "&user_type=" + props.userType
            ).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.Components){
                    // //console.log('Componentes mercado: ', props.market)
                    //console.log(response.Components)
                    setComponentState(response.Components)
                }
            })
        }        
    }, [props.market, props.periodDate, props.tariffCalcVersion])

    let principalComponent = () => (
        <Grid container spacing={4} justify="space-evenly">                            
            {
                componentState.map((cmpState, idx) =>(
                    <Grid key={idx} item xs={12}>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} >
                            <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                                <Typography>{cmpState.Name}</Typography>
                                {
                                    cmpState.Published ? 
                                    <DoneIcon fontSize="small" color="success" style={{marginLeft:10}}/>
                                    :
                                    <HourglassFullIcon fontSize="small" color="disabled" style={{marginLeft:10}}/>                                    
                                }
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid container justify="space-evenly">
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Mercado : {props.market}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Periodo insumos: {props.periodDate.toISOString('MM-YYYY').slice(0, 7)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <List className={classes.accordionList}>
                                        <ListItem>   
                                            <Grid container justify="space-evenly">
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Lista de insumos</Typography>                                                                                            
                                                    {inputsStateList(cmpState.Files, cmpState.FilesState, cmpState.FilesDate, cmpState.Parameters, cmpState.ParametersState, cmpState.ParametersDate)}
                                                </Grid>                                                                                                                                                                                                                                                                           
                                                <Grid item xs={5}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Información procesada</Typography>                                                                                            
                                                    {componentCalcTable(cmpState.CalcVarNames, cmpState.CalcVarValues, idx)}
                                                </Grid>                                                                                                                                                                                                                
                                            </Grid>
                                        </ListItem>
                                        {/* <Divider variant="middle" classes={{middle: classes.dividerStyle}} /> */}
                                        {
                                            cmpState.ReliqConcepts.length > 0 ?
                                                cmpState.ReliqValues[0].length > 0 ?
                                                <ListItem>
                                                <Grid container spacing={1} justify="space-evenly">
                                                    <Grid item xs={11}>
                                                        <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Reliquidaciones</Typography>                                                                                            
                                                    </Grid>
                                                    <Grid item xs={5}>  
                                                        {reliquidationsTable(cmpState.ReliqValues, cmpState.ReliqDates)}                                                                                                   
                                                    </Grid>   
                                                    <Grid item xs={5}>  
                                                        {
                                                            cmpState.ReliqAffectedVars.length > 0 ?
                                                            affectedVarsTable(cmpState.ReliqAffectedVars, idx, props.calcType)
                                                            :
                                                            null
                                                        }                                                                                                   
                                                    </Grid>                                              
                                                </Grid>
                                                </ListItem>
                                                :
                                                null
                                            :
                                            null
                                        }
                                        
                                        <ListItem>
                                            <Grid container spacing={1} justify="space-evenly">
                                                <Grid item xs={11}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Control de publicación</Typography>                                                                                            
                                                </Grid>
                                                <Grid item xs={8}>  
                                                    {componentValuesTable(cmpState.CompLabels, cmpState.CompValues, props.calcType, idx)}                                                  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {                       
                                                        !cmpState.CompValues.includes(0) ?                                                                                         
                                                        <Grid container justify="space-evenly">                                                    
                                                        <Grid item>
                                                            <Button onClick={handleApvButtonClick(idx)} disabled={cmpState.Approved} color='primary' variant='contained'>Aprobar</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={(e) => {setActualComponentIdx(idx);handlePublicationDialogOpen();}} color='primary' disabled={!cmpState.Approved || cmpState.Published} variant='contained'>Publicar</Button>
                                                        </Grid>
                                                        </Grid>
                                                        :
                                                        null
                                                    }                                                    
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>                                    
                                </Grid>
                            </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>                            
                ))
            }
        </Grid>  
    )

    return (
        <div className={classes.root}>            
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                    <Grid item xs={12}>      
                        {showFeedbackDialog()}
                        {showNewVersionDialog()}  
                        {showPublicationDialog()}  
                        {showReliqDialog()}     
                        {showInternalCalcDialog()}                                   
                        {
                            (!props.tariffsPublished && props.txfVersion === "" && props.tariffCalcVersion === "" && props.markets.length > 0)  ? 
                                    <Grid container style={{'marginTop': '20%'}} justify="space-evenly">
                                        <Grid item xs={4}> 
                                            <Fab
                                                color="primary"
                                                size="small"
                                                component="span"
                                                aria-label="add"
                                                variant="extended"                        
                                                classes={{root: classes.uploadButton}}
                                                onClick={handleNewVersionDialogOpen}
                                                style={{marginTop: 20}}
                                            > 
                                                <Fragment><AddIcon /> {' Crear versión de cálculo '}</Fragment>                                                          
                                            </Fab>  
                                        </Grid>
                                    </Grid>                                                                
                                : (props.tariffsPublisheds.some(el => el === false) && props.txfVersion !== "" && props.tariffCalcVersion !== "") ? 
                                    principalComponent()
                                : (!props.tariffsPublisheds.some(el => el === false) && props.txfVersion !== "" && props.tariffCalcVersion !== "") ? 
                                    <Fragment>                                     
                                    {principalComponent()}
                                    <Grid container justify="space-evenly">
                                        {                                            
                                            <Grid item xs={4}>                                                                                         
                                                <Fab
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    aria-label="add"
                                                    variant="extended"                        
                                                    classes={{root: classes.uploadButton}}
                                                    onClick={handleNewVersionDialogOpen}
                                                    style={ {marginTop: 40} }
                                                > 
                                                    <Fragment><AddIcon /> {' Crear versión de cálculo '}</Fragment>                                                          
                                                </Fab>  
                                            </Grid>
                                        }                                        
                                        {props.tariffCalcVersion === 'def' && props.reliqSelectedPeriod && props.reliqFinished ?
                                            <Grid item xs={4}>                                            
                                                <Fab
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    aria-label="add"
                                                    variant="extended"                        
                                                    classes={{root: classes.uploadButton}}
                                                    onClick={handleReliqDialogOpen}
                                                    style={{marginTop: 40, padding: 10}}
                                                > 
                                                    <Fragment><UpdateIcon /> &nbsp; {' Reliquidar periodo '}</Fragment>                                                          
                                                </Fab>                                                                                        
                                            </Grid>
                                            :
                                            null
                                        }
                                    </Grid> 
                                    </Fragment>
                                :
                                 null
                        }                                                                                                                              
                    </Grid>            
                </Zoom>                
            </Grid>       
        </div>
    );
}