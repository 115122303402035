import React from 'react'
import {useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Stepper, Step, StepButton, StepLabel, Collapse, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Grid, Paper, IconButton, Button, TextField,
    Box, Typography, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Zoom, CircularProgress} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {authFetch, logout} from '../auth';
import getPeriods from '../const/yearPeriods';
import getMonths from '../const/monthPeriods';
import getAbbMonths from '../const/abbMonthsPeriods';
import validateInput from '../const/validation';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: theme.spacing(2),
      color: theme.palette.primary.main
    },
    componentTitle: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(1),
      marginTop: '20%'
    },
    componentTitleNoMargin: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(3),      
    },
    rlqMsg: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(1),
      paddingTop: 0
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    modalPaper: {
      padding: theme.spacing(3),
      marginTop: '10%'
    },
    stepper: {
        padding: theme.spacing(3)
    },
    stepText: {
        color: theme.palette.secondary.dark,
    },
    stepTextActive: {
        color: theme.palette.primary.main,
    }, 
    uploadButton : {
      fontSize: 13,
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
    }
}));

const useRowStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    hoverBackground:{
      background: theme.palette.action.hover
    },
}));

const StyledTableCell3StepInfo = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.primary.main}`
  },
  body: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    color: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.light}`
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:nth-of-type(1)': {
        width: "20%"
      },
      '&:nth-of-type(4)': {
        width: "35%"
      },
      width: "15%",
      padding: 10
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.dark,
      padding: 10
    },
  }))(TableCell);

const StyledTableCellSecondStep = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:nth-of-type(5)': {
        width: "30%"
      },
      '&:nth-of-type(2)': {
        width: "10%"
      },
      '&:nth-of-type(3)': {
        width: "10%"
      },
      width: '21%',
      //width: "15%",
      padding: 10
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
    },
      color: theme.palette.primary.dark,
      padding: 10
    },
  }))(TableCell);

const StyledTableCellCollapse = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:nth-of-type(1)': {
        width: "5%"
      },
      '&:nth-of-type(2)': {
        width: "25%"
      },
      '&:nth-of-type(3)': {
        width: "15%"
      },
      width: "55%",
      padding: 10
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.dark
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

/* Validacion */

/*Data de las tablas*/
function getSteps() {
    return ['Verifique los archivos que debe ingresar.', 'Suba los archivos correspondientes.', 'Compruebe la información procesada.'];
}

const exportFile = (name, username, periodDate, version, setIsLoading, xmVersion) => (e) => {
  setIsLoading(true)
  authFetch("/api/download-input-file?username=" + username +
                                           '&period=' + String( periodDate.getMonth() + 1 )
                                           +"&year="+ periodDate.getFullYear() + 
                                           "&version="+ version +
                                           "&xmVersion="+ xmVersion +
                                           "&filename="+ name + "&timestamp=" + new Date().toISOString().slice(0,19),
                                           { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {     
            let period = periodDate.getMonth() + 1
            let filePeriod = String(period).length <= 1 ? '0'+period:period                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = name+'_'+filePeriod+'_'+periodDate.getFullYear()+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
}
  
function ThirdStepRow(props) {
    const row  = props.row;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const [tLoading, tSetLoading] = useState(false)
  
    return (
      <React.Fragment>
        <StyledTableRow className={classes.root}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.Name}
          </StyledTableCell>
          <StyledTableCell align="left">{ String(props.period.getMonth() + 1) + '/' + String(props.period.getFullYear())}</StyledTableCell>
          <StyledTableCell align="left">{row.Use}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className={classes.hoverBackground}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div" color="primary">
                  Información asociada
                </Typography>
                <Table size="small">
                  <TableHead>
                    <StyledTableRow>
                    {row.Process.map((label, idx) => (
                      <StyledTableCell3StepInfo key={idx}>{label}</StyledTableCell3StepInfo>
                    ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                  {
                    row.Name !== 'TCTR' && row.Name !== 'DSPCTTOS'? 
                    <StyledTableRow>
                    {row.Calc_Values.map((value, idx) => (
                      <StyledTableCell3StepInfo key={idx}>{value}</StyledTableCell3StepInfo>
                    ))}
                    </StyledTableRow>
                    :
                    row.Calc_Values.map((list, idx)=>(
                      <StyledTableRow key={idx}>
                        {
                          list.map( (value,idx) => (
                            <StyledTableCell3StepInfo key={idx}>{value}</StyledTableCell3StepInfo>
                          ))
                        }
                      </StyledTableRow>
                    ))
                  }
                  
                  </TableBody>
                </Table>
                {
                  row.Exportable ? 
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    {
                      tLoading ? 
                      <CircularProgress style={{marginTop: 15}}/>
                      :
                      <Button color='primary' variant='contained' startIcon={<GetAppIcon/>} style={{marginTop: 15}} onClick={exportFile(row.Name, props.username, props.period, props.version, tSetLoading, props.xmVersion)}> Exportar </Button>
                    }                    
                  </div>                  
                  :
                  null
                }                
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
}

export default function InputVerification (props) {
    const classes  = useStyles();
    var actualYear = new Date().getFullYear()
    const periods  = getPeriods(actualYear)
    const months   = getMonths()
    const abbMonths = getAbbMonths()
    const [modificationDialog, setModificationDialog] = useState(false)
    const [modificationDesignation, setModificationDesignation] = useState([])
    const [uploadDialog, setUploadDialog] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('')
    const [activeStep, setActiveStep] = useState(0);
    const [inputFilesStatus, setInputFilesStatus] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [clickIndex, setClickIndex] = useState(-1)
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleBack = () => {
        ////console.log('Click back', activeStep)
        if (activeStep > 0){
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleModificationDialogClose = () => {
      setModificationDialog(false)
    }

    const handleUploadDialogClose = () => {
      setUploadDialog(false)
    }

    const handleUploadFile = (e, filename, idx, quantity)  => {      
      e.preventDefault()      
      if (e.target.files.length > 0){
        let validationFlags = validateInput(  filename.includes('CDI') ? 'Cargo_Cobro_Uso': filename.includes('ADD') ? 'Liquidacion': filename, 
                                          e.target.files,  
                                          inputFilesStatus.filter(status => status.Name === filename)[0].Validations, 
                                          months[props.periodDate.getMonth()],
                                          abbMonths[props.periodDate.getMonth()],
                                          quantity,
                                          props.periodDate,
                                          months[props.periodDate.getMonth() + 1 > 11 ? 0 : props.periodDate.getMonth() + 1]
        )        
        let validationConfirm = false
        let validation = ""
        for (validation of inputFilesStatus.filter(status => status.Name === filename)[0].Validations){
          if (validationFlags[validation].includes(false)){
            setUploadDialog(true)
            validationConfirm = false            
            switch(validation){
              case 'Name':
                if ( quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el nombre designado: ' + filename + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el nombre designado: ' + filename + ' para ser procesado/s.')
                }               
                break;
              case 'Month':
                if ( quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado/s.')
                }
                break;
              case 'abbMonth':
                if ( quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  abbMonths[props.periodDate.getMonth()] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  abbMonths[props.periodDate.getMonth()] + ' para ser procesado/s.')
                }
                break;
              case 'finalMonth':
                if ( quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado/s.')
                }
                break;
              case 'actualMonth':
                if ( quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  months[props.periodDate.getMonth() + 1 > 11 ? 0 : props.periodDate.getMonth() + 1] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  months[props.periodDate.getMonth() + 1 > 11 ? 0 : props.periodDate.getMonth() + 1] + ' para ser procesado/s.')
                }
                break;
              case 'Quantity':
                setUploadMessage('Cantidad incorrecta de archivos seleccionados.')
                break;
              case 'Levels':
                setUploadMessage('Los archivos seleccionados no contienen todos los niveles.')
                break;
              case 'ADDMonth':
                setUploadMessage('Los archivos seleccionados no contienen el periodo correcto anterior a: ' +  months[props.periodDate.getMonth()] + ' para ser procesados.')
                break;
            }
            break
          }
          else{
            validationConfirm = true
          }
        }
        if ( validationConfirm ) {  
          setIsLoading(true)   
          let qty = Number(e.target.files.length)         
          if (qty > 10){
            const formData1 = new FormData()
            const formData2 = new FormData()
            const formData3 = new FormData()            
            let file = {}            
            for (var i = 0; i < 10; i++){
              formData1.append('file' + i, e.target.files[i])              
            }
            let opts = {
              'filename': e.target.files[0].name,                  
            }
            authFetch(
              "/api/upload-files?username="+props.username+
                                "&period="+String( props.periodDate.getMonth() + 1 )+                              
                                "&year="+ props.periodDate.getFullYear() +                                
                                "&part="+"1",
              {
                method: 'post',
                body: formData1,
              }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
              if (response.Upload === 'OK'){
                for (var i = 10; i < 20; i++){
                  formData2.append('file' + i, e.target.files[i])              
                }
                authFetch(
                  "/api/upload-files?username="+props.username+
                                    "&period="+String( props.periodDate.getMonth() + 1 )+                              
                                    "&year="+ props.periodDate.getFullYear()+
                                    "&part="+"2",
                  {
                    method: 'post',
                    body: formData2,
                  }
                ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    for (var i = 20; i < e.target.files.length; i++){
                      formData3.append('file' + i, e.target.files[i])              
                    }
                    authFetch(
                      "/api/upload-files?username="+props.username+
                                        "&period="+String( props.periodDate.getMonth() + 1 )+                              
                                        "&year="+ props.periodDate.getFullYear()+
                                        "&part="+"3",
                      {
                        method: 'post',
                        body: formData3,
                      }
                    ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                    .then(response => {                    
                      if (response.Upload === 'OK'){                                            
                        authFetch(
                          "/api/upload-input?username="+props.username+
                                            "&period="+String( props.periodDate.getMonth() + 1 )+
                                            "&filename="+ filename +       
                                            "&year="+ props.periodDate.getFullYear() +                                     
                                            "&agent="+ props.agent + 
                                            "&market="+ props.market +
                                            "&version="+ props.tariffCalcVersion +
                                            "&reliq=" + props.reliqFinished +
                                            "&reliquidations="+ props.usedXmVersions.length +
                                            "&user=" + props.user,
                          {
                            method: 'post',   
                            body: JSON.stringify(opts)                 
                          }
                        ).then(r => r.status !== 200 ? r.status === 422 ?  logout() : null : r.json())
                          .then(response => {
                            setIsLoading(false)
                            if ( response && response.Upload === 'OK'){
                              setUploadMessage('Archivos subidos. Verifique la información procesada en el Paso 3.')
                              inputFilesStatus.filter(status => status.Name === filename)[0].Entry = !inputFilesStatus.filter(status => status.Name === filename)[0].Entry
                              setInputFilesStatus([...inputFilesStatus])
                              setUploadDialog(true)
                              let extension =  props.reliqFinished ? props.txfVersion : props.reliqTxfVersion
                              authFetch("/api/check-inputs?username=" + props.username +
                                                        '&period=' + String( props.periodDate.getMonth() + 1 ) 
                                                        +"&year="+ props.periodDate.getFullYear() + 
                                                        "&market="+ props.market + "&version=" + props.tariffCalcVersion
                                                        + "&reliq=" + props.reliqFinished + "&extension=" + extension
                                                        + "&reliquidations="+ props.usedXmVersions.length
                              ).then(response => {
                                if (response.status === 422){
                                    logout()
                                    return null
                                }
                                    return response.json()
                                }).then(response => {
                                    if (response && response.files_state){
                                        ////console.log('Response', response)
                                        setInputFilesStatus(response.files_state)
                                        ////console.log('Periodo', props.period)
                                    }
                              })
                            }
                            else if (!response) {
                              setUploadDialog(true)
                              setUploadMessage('Error al subir archivos.')
                            }
                          })
                      }
                      else {
                        setUploadDialog(true)
                        setUploadMessage('Error al subir archivos.')
                      }
                    })  
                  }
                }
                )
              }
            }
            )
              
          }
          else {
            const formData = new FormData()
            let i = 0
            let file = {}
            for (file of e.target.files){
              formData.append('file' + i, file)
              i = i + 1
            }
            let opts = {
              'filename': e.target.files[0].name,                  
            }
            authFetch(
              "/api/upload-files?username="+props.username+
                                "&period="+String( props.periodDate.getMonth() + 1 )+                              
                                "&year="+ props.periodDate.getFullYear() +
                                "&filename="+ filename +
                                "&part="+"0",
              {
                method: 'post',
                body: formData,
              }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
              if (response.Upload === 'OK'){                
                authFetch(
                  "/api/upload-input?username="+props.username+
                                    "&period="+String( props.periodDate.getMonth() + 1 )+
                                    "&filename="+ filename +       
                                    "&year="+ props.periodDate.getFullYear() +                                     
                                    "&agent="+ props.agent + 
                                    "&market="+ props.market +
                                    "&version="+ props.tariffCalcVersion +
                                    "&reliq=" + props.reliqFinished +
                                    "&reliquidations="+ props.usedXmVersions.length+
                                    "&user=" + props.user,
                  {
                    method: 'post',            
                    body: JSON.stringify(opts)          
                  }
                ).then(r => r.status !== 200 ? r.status === 422 ?  logout() : null : r.json())
                  .then(response => {
                    setIsLoading(false)                    
                    if (response && response.Upload === 'OK'){
                      setUploadMessage('Archivos subidos. Verifique la información procesada en el Paso 3.')
                      inputFilesStatus.filter(status => status.Name === filename)[0].Entry = !inputFilesStatus.filter(status => status.Name === filename)[0].Entry
                      setInputFilesStatus([...inputFilesStatus])
                      setUploadDialog(true)
                      let extension =  props.reliqFinished ? props.txfVersion : props.reliqTxfVersion
                      authFetch("/api/check-inputs?username=" + props.username +
                                                '&period=' + String( props.periodDate.getMonth() + 1 ) 
                                                +"&year="+ props.periodDate.getFullYear() + 
                                                "&market="+ props.market + "&version=" + props.tariffCalcVersion
                                                + "&reliq=" + props.reliqFinished + "&extension=" + extension
                                                + "&reliquidations="+ props.usedXmVersions.length
                      ).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.files_state){
                                ////console.log('Response', response)
                                setInputFilesStatus(response.files_state)
                                ////console.log('Periodo', props.period)
                            }
                      })
                    }
                    else if (!response) {
                      setUploadDialog(true)
                      setUploadMessage('Error al subir archivos.')
                    }
                  })
              }
              else {
                setUploadDialog(true)
                setUploadMessage('Error al subir archivos.')
              }
            }) 
            }                                              
        } 
      } 
      else {
        setUploadDialog(true)
        setUploadMessage('No ha seleccionado ningún archivo.')
      }        
    }

    const handleSwitchChange = (event) => {
      setModificationDialog(true)
      setModificationDesignation([event.target.name, event.target.id])
    }

    const deleteFromDialog = () => {
      setModificationDialog(false)      
      inputFilesStatus.filter(status => modificationDesignation[0] === status.Name)[0].Entry = !inputFilesStatus.filter(status => modificationDesignation[0] === status.Name)[0].Entry
      setInputFilesStatus([...inputFilesStatus])
      let extension =  props.reliqFinished ? props.txfVersion : props.reliqTxfVersion
      let opts = {
        'username': props.username,
        'period': String( props.periodDate.getMonth() + 1 ),
        'filename': modificationDesignation[0],
        'year': props.periodDate.getFullYear(),
        'market': props.market,
        'version': props.tariffCalcVersion,
        "reliq": props.reliqFinished,
        'reliquidations': props.usedXmVersions.length,
        'extension': extension,
        'user': props.user
      }
      authFetch(
        "/api/delete-input",
        {
          method: 'post',
          body: JSON.stringify(opts)
        }
       ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
          if (response){
           ////console.log('Archivo borrado', response)
          }
          else {
            alert("Error en el proceso")
          }
      })
    }

    

    useEffect (() => {
        document.title ="Validación de archivos";
        let extension =  props.reliqFinished ? props.txfVersion : props.reliqTxfVersion
        authFetch("/api/check-inputs?username=" + props.username +
                                           '&period=' + String( props.periodDate.getMonth() + 1 )
                                           +"&year="+ props.periodDate.getFullYear() + 
                                           "&market="+ props.market + "&version=" + props.tariffCalcVersion
                                           + "&reliq=" + props.reliqFinished + "&extension=" + extension
                                           + "&reliquidations="+ props.usedXmVersions.length
        ).then(response => {
          if (response.status === 422){
              logout()
          }
              return response.json()
          }).then(response => {
              if (response && response.files_state){                                    
                  ////console.log('Files state', response.files_state)
                  setInputFilesStatus(response.files_state)
                  ////console.log('Periodo', props.period)
              }
        })
    }, [props.market, props.periodDate, props.tariffCalcVersion, props.reliqFinished, props.usedXmVersions])

    const modificationConfirm = () => (
      <Dialog
        open={modificationDialog}
        onClose={handleModificationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea eliminar la información relacionada al insumo?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si desea eliminar la información relacionada a {modificationDesignation[0]}, haga click en ACEPTAR.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModificationDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteFromDialog} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    )
    
    const uploadConfirm = () => (
      <Dialog
        open={uploadDialog}
        onClose={handleUploadDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Información"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    )

    const uploadForm = (filename, extensions, idx, quantity) => (
      <form action={"#"}>
         <label htmlFor={filename}>
          <input
            hidden
            id={filename}
            name={filename}
            type="file"   
            accept={extensions} 
            onClick={(e) => {setClickIndex(idx); e.target.value = null;}}   
            onChange={(e) => {handleUploadFile(e, filename, idx, quantity); }}            
            multiple={quantity === "1" ? false : true}  
          />
          {
            isLoading && idx === clickIndex?
            <CircularProgress/>
            :
            <Fab
              color="primary"
              size="small"
              component="span"
              aria-label="add"
              variant="extended"                        
              classes={{root: classes.uploadButton}}              
            >
              <AddIcon /> { quantity === "1" ? 'Subir archivo' : 'Subir archivos' }
            </Fab>
          }
                  
        </label>
      </form>     
    )

    const firstStepContent = () => (
      <TableContainer component={Paper}>
        {modificationConfirm()}
        <Table className={classes.table} stickyHeader>
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Tipo</StyledTableCell>
                    <StyledTableCell align="left">Ingreso</StyledTableCell>
                    <StyledTableCell align="left">Uso</StyledTableCell>
                    <StyledTableCell align="left">Modificar</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { inputFilesStatus.length > 0 ?
                    inputFilesStatus.filter(file => props.userType === 'Regulados' ? true : 
                    props.txfVersion === '.txf' ? 
                    ['Cálculo de Componente T','Cálculo de Componentes: R','Cálculo de Componentes: D',"Cálculo de Componentes: G, Pr","Cálculo de la componente Pr","Balance energético"].includes(file.Use):
                    ['TRSD','PrecioEscasez'].includes(file.Name)).map((file, idx) => (
                      <StyledTableRow key={file.Name}>
                          <StyledTableCell component="th" scope="row" align="left">
                              {file.Name}
                          </StyledTableCell>
                          <StyledTableCell align="left">{file.Type}</StyledTableCell>
                          <StyledTableCell align="left">
                            {
                              file.Entry ? 
                                <Grid container justify="space-evenly" alignItems="center">
                                  <Grid item>
                                    <CheckCircleOutlineIcon color="success"/>         
                                  </Grid> 
                                  <Grid item>
                                    Listo
                                  </Grid>                                                                                          
                                </Grid>                                
                              :
                              <Grid container justify="space-evenly" alignItems="center">
                                  <Grid item>
                                    <HourglassEmptyIcon color="error"/>         
                                  </Grid> 
                                  <Grid item>
                                    Pendiente
                                  </Grid>                                                                                          
                              </Grid>  
                            }
                          </StyledTableCell>  
                          <StyledTableCell align="left">{file.Use}</StyledTableCell>
                          <StyledTableCell align="left">
                            <Switch disabled={(!file.Entry) || (props.tariffsPublished && props.opTariffPub && props.reliqFinished)} checked={modificationDialog && modificationDesignation[0] === file.Name} key={idx}  id={String(idx)} color="primary" name={file.Name} onChange={handleSwitchChange}/>
                          </StyledTableCell>
                      </StyledTableRow>
                    ))
                  :
                  <StyledTableRow>
                          <StyledTableCell component="th" scope="row" align="left">
                              -
                          </StyledTableCell>
                          <StyledTableCell align="left">-</StyledTableCell>
                          <StyledTableCell align="left">
                            -
                          </StyledTableCell>
                          <StyledTableCell align="left">-</StyledTableCell>
                  </StyledTableRow>
                }                
            </TableBody>
        </Table>
      </TableContainer>
    )

    const secondStepContent = () => (
      <TableContainer component={Paper}>
        {uploadConfirm()}
        <Table className={classes.table} stickyHeader>
            <TableHead>
                <TableRow>
                    <StyledTableCellSecondStep align="left">Nombre</StyledTableCellSecondStep>
                    <StyledTableCellSecondStep align="left">Extensión</StyledTableCellSecondStep>
                    <StyledTableCellSecondStep align="left">Cantidad</StyledTableCellSecondStep>
                    <StyledTableCellSecondStep align="left">Ingreso</StyledTableCellSecondStep>
                    <StyledTableCellSecondStep align="left">Uso</StyledTableCellSecondStep>
                </TableRow>
            </TableHead>
            <TableBody>
                { inputFilesStatus.length > 0 ? 
                  inputFilesStatus.filter(file => props.userType === 'Regulados' ? true : 
                    props.txfVersion === '.txf' ? 
                    ['Cálculo de Componente T','Cálculo de Componentes: R','Cálculo de Componentes: D',"Cálculo de Componentes: G, Pr","Cálculo de la componente Pr","Balance energético"].includes(file.Use):
                    ['TRSD','PrecioEscasez'].includes(file.Name)).map((file, idx) => (
                    file.Entry ? 
                    <React.Fragment></React.Fragment> 
                    :
                    <StyledTableRow key={idx}>
                      <StyledTableCellSecondStep component="th" scope="row" align="left">
                          {file.Name}
                      </StyledTableCellSecondStep>
                      <StyledTableCellSecondStep align="left">{ file.Extension !== '.txf' ? file.Extension : props.reliqFinished ? props.txfVersion : props.reliqTxfVersion}
                                                             </StyledTableCellSecondStep>
                      <StyledTableCellSecondStep align="left">{file.Quantity === 'Month' ? new Date( props.periodDate.getFullYear(), props.periodDate.getMonth()+1, 0 ).getDate() :  file.Quantity }</StyledTableCellSecondStep>
                      <StyledTableCellSecondStep align="left">
                          {
                              file.Type === 'Archivo' || file.Type === 'Grupo de archivos'? 
                              uploadForm(file.Name, file.Extension !== '.txf' ? file.Extension : props.reliqFinished ? props.txfVersion : props.reliqTxfVersion, idx, file.Quantity) : 
                              <TextField></TextField>
                          }                                
                      </StyledTableCellSecondStep>                      
                      <StyledTableCellSecondStep align="left">{file.Use}</StyledTableCellSecondStep>
                    </StyledTableRow>
                    ))
                    :
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row" align="left">
                          -
                      </StyledTableCell>
                      <StyledTableCell align="left">-</StyledTableCell>
                      <StyledTableCell align="left">
                        -
                      </StyledTableCell>
                      <StyledTableCell align="left">-</StyledTableCell>
                    </StyledTableRow>
                }
            </TableBody>
        </Table>
      </TableContainer>
    )

    const thirdStepContent = () => (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
        <TableHead>
              <TableRow>
                  <StyledTableCellCollapse/>
                  <StyledTableCellCollapse align="left">Nombre</StyledTableCellCollapse>
                  <StyledTableCellCollapse align="left">Periodo</StyledTableCellCollapse>
                  <StyledTableCellCollapse align="left">Uso</StyledTableCellCollapse>
              </TableRow>
          </TableHead>
          <TableBody>
            {inputFilesStatus.length > 0 ?
              inputFilesStatus.filter(file => props.userType === 'Regulados' ? true : 
                    props.txfVersion === '.txf' ? 
                    ['Cálculo de Componente T','Cálculo de Componentes: R','Cálculo de Componentes: D',"Cálculo de Componentes: G, Pr","Cálculo de la componente Pr","Balance energético"].includes(file.Use):
                    ['TRSD','PrecioEscasez'].includes(file.Name)).map((file, idx) => (
              ! file.Entry ? 
                <React.Fragment key={idx}></React.Fragment>
                :
                <ThirdStepRow key={idx} row={file} period={props.periodDate} username={props.username} version={props.tariffCalcVersion} xmVersion={props.txfVersion}/>
                ))
              :
              <StyledTableRow>
                      <StyledTableCell component="th" scope="row" align="left">
                          -
                      </StyledTableCell>
                      <StyledTableCell align="left">-</StyledTableCell>
                      <StyledTableCell align="left">-</StyledTableCell>
              </StyledTableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    )

    function getStepContent(step) {
        switch (step) {
          case 0:
            return (
              firstStepContent()
            )
          case 1:
            return (
              secondStepContent()
            )          
          case 2:
            return (
              thirdStepContent()
            );
          default:
            return 'Unknown step';
        }
    }

    return (
        <div className={classes.root}>
          {
             props.market !== '' ?
              !props.tariffsPublished && props.tariffCalcVersion === '' ?
              <Grid container justify="center" alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="subtitle2" className={classes.componentTitle}>Debe crear una versión de cálculo para ingresar archivos.</Typography>
                </Grid>
              </Grid>
        
              :
              (!props.tariffsPublished || !props.opTariffPub || !props.reliqFinished) && props.tariffCalcVersion !== '' ?
              <Grid container spacing={3} justify="center">
                  <Zoom in timeout={800}>                                   
                    <Grid item xs={11}>
                        
                        <Paper elevation={2}>
                            <Stepper nonLinear activeStep={activeStep} classes={{root: classes.stepper}}>
                                {steps.map((label, index) => {
                                return (
                                    <Step key={index} >
                                        <StepButton onClick={handleStep(index)}>
                                            <StepLabel classes={{label: classes.stepText, active: classes.stepTextActive}}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                                })}
                            </Stepper>                        
                        </Paper>                    
                    </Grid>
                  </Zoom>
                  <Zoom in timeout={800}>
                    <Grid item xs={3}>
                        <div>
                            <IconButton disabled={activeStep === 0} onClick={handleBack} className={classes.button} color={"primary"}>
                                <ArrowBackIosIcon/>
                            </IconButton>    
                            <IconButton disabled={activeStep === steps.length-1} onClick={handleNext} className={classes.button} color={"primary"}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                  </Zoom>
                  <Zoom in style={{transitionDelay: '600ms'}} timeout={800}>
                    <Grid item xs={11}>
                      {
                        !props.reliqFinished ? 
                        <Typography variant="subtitle2" className={classes.rlqMsg}>Reliquidación activa.</Typography>
                        :
                        null
                      }                                 
                        <Paper elevation={2}>
                            {getStepContent(activeStep)}            
                        </Paper>
                    </Grid>
                  </Zoom>
              </Grid>
              :
              props.tariffsPublished && props.opTariffPub && props.reliqFinished && props.tariffCalcVersion !== '' ?
              <Fragment>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="subtitle2" className={classes.componentTitleNoMargin}>Tarifas publicadas. No se pueden modificar los insumos de esta versión.</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} justify="center">
                  <Zoom in timeout={800}>                                   
                    <Grid item xs={11}>
                        
                        <Paper elevation={2}>
                            <Stepper nonLinear activeStep={activeStep} classes={{root: classes.stepper}}>
                                {steps.map((label, index) => {
                                return (
                                    <Step key={index} >
                                        <StepButton onClick={handleStep(index)}>
                                            <StepLabel classes={{label: classes.stepText, active: classes.stepTextActive}}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                                })}
                            </Stepper>                        
                        </Paper>                    
                    </Grid>
                  </Zoom>
                  <Zoom in timeout={800}>
                    <Grid item xs={3}>
                        <div>
                            <IconButton disabled={activeStep === 0} onClick={handleBack} className={classes.button} color={"primary"}>
                                <ArrowBackIosIcon/>
                            </IconButton>    
                            <IconButton disabled={activeStep === steps.length-1} onClick={handleNext} className={classes.button} color={"primary"}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                  </Zoom>
                  <Zoom in style={{transitionDelay: '600ms'}} timeout={800}>
                    <Grid item xs={11}>
                      {
                        !props.reliqFinished ? 
                        <Typography variant="subtitle2" className={classes.rlqMsg}>Reliquidación activa.</Typography>
                        :
                        null
                      }                                 
                        <Paper elevation={2}>
                            {getStepContent(activeStep)}            
                        </Paper>
                    </Grid>
                  </Zoom>
              </Grid>
              </Fragment>
              :
              null
            :
            null
          }            
        </div>
    );
}