import {React, useEffect, useState, Fragment, useRef} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress,} from '@material-ui/core/'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import {authFetch, logout} from '../../auth';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {DatePicker} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SaveIcon from '@material-ui/icons/Save';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TodayIcon from '@material-ui/icons/Today';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GetAppIcon from '@material-ui/icons/GetApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ReportIcon from '@material-ui/icons/Report';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      padding: 8
    },
    body: {
      fontSize: 14,
      color: theme.palette.secondary.dark,
      padding: 6
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    calendarPaper:{
        background: theme.palette.dashboard.main,
        borderColor: theme.palette.dashboard.border,
        padding: 7,
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    darkText:{
        color: '#173866',
        fontWeight: 'bold',
    },
    infoNumberSmall:{
      background: '#173866', 
      padding:4, 
      borderRadius: 5,      
      color: 'white',
      fontSize: 10,
      textAlign: 'center'
    },
    typoStyle1:{
        background: '#173866',
        padding: 8,
        color: 'white',
        borderRadius: 5
    },
    typoStyle2:{
        background: '#E6E3D8',
        padding: 5,
        color: '#173866',
        borderRadius: 5
    },
    typoStyle3:{
        background: '#173866',
        padding: 3,
        color: 'white',
        borderRadius: 5
    },
    typoStyle3Gray:{
        background: '#E6E3D8',
        padding: 3,
        color: '#173866',
        borderRadius: 5
    },
     contentPaperWithPadding:{
        background: '#E6E3D8',
        paddingTop: 5,
        paddingBottom: 5
    },  
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    infoNumber:{
      background: '#173866', 
      padding:4, 
      borderRadius: 5,
      fontWeight: 'bold',
      color: 'white',
      fontsize: 7
    },
    infoText:{      
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    labelTextDialog: {
        color: theme.palette.secondary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",    
        padding: 5
    },  
    formTextDialog: {
        fontSize: 14,
        color: theme.palette.secondary.main,
        textAlign: "center",    
        padding: 5
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.secondary.main,        
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        fontWeight: 'normal'
    },  
    feedbackButton: {
        backgroundColor: theme.palette.dashboard.background,
        color: theme.palette.primary.main,        
        borderRadius: 0,     
        "&:hover": {        
            backgroundColor: theme.palette.dashboard.background,   
            color: theme.palette.primary.main,    
        }, 
        minHeight: '30px'
    },  
    formDefined: {
        backgroundColor: '#F7F3F5',        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    opTariffBackground:
    {
        backgroundColor: theme.palette.secondary.main
    }
}));

let calendarTheme =  createMuiTheme({
    palette: {        
        primary: {
            main: "#2E2B3E",            
        },
        secondary: {
            main: "#FFFFFF",                    
        },         
    },
    typography: {
        fontFamily: "Roboto Condensed"
    },
    overrides: {
        MuiCssBaseline: {
        '@global': {         
            '*::-webkit-scrollbar': {
            width: '9px',    
                    
            },
            '::-webkit-scrollbar-track': {
            background: "#f1f1f1",     
            marginTop: 10,
            marginBottom: 10,       
            },
            '::-webkit-scrollbar-thumb': {
            background: '#888',           
            }
        }
        }
    }
})

export default function BalancePayment(props){

    const classes = useStyles();
    let monthCmp = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    const [liquidationApprovedFlag, setLiquidationApprovedFlag] = useState(false)   
    const [clientCode, setClientCode] = useState(props.user !== 'consulta-cedenar' ? props.user : '')        
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("") 
    const [documentStateDialog, setDocumentStateDialog] = useState(false)   
    const [isLoading, setIsLoading] = useState(false)
    const [fileUploading, setFileUploading] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [searchFlag, setSearchFlag] = useState(false)
    const [userPaymentState, setUserPaymentState] = useState([])
    const [userPaymentObj, setUserPaymentObj] = useState({})
    const [reportLowerLimit, setReportLowerLimit] = useState(null)
    const [reportUpperLimit, setReportUpperLimit] = useState(null)

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handleDocumentDialogOpen = () => {
        setDocumentStateDialog(true);
      };
    
    const handleDocumentDialogClose = () => {
        setDocumentStateDialog(false);
    };

    const handleUserPaymentStateUpload = () => {     
        ////console.log(conceptData)   
        if ( userPaymentObj.p_balance_payment_state && userPaymentObj.p_balance_payment_state[0] === '3' && (!userPaymentObj.p_balance_payment_date || !userPaymentObj.positive_balance)){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }          
        else {
            let uploadObj = {...userPaymentObj}                
            delete uploadObj.id_number
            delete uploadObj.id_type
            delete uploadObj.client_name
            delete uploadObj.telephone                   
            delete uploadObj.email 
            authFetch(
            "/api/update-user-payment-state?origin="+(props.user !== 'consulta-cedenar' ? 'user' : 'op'),
            {
                method: 'post',
                body: JSON.stringify(uploadObj),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados.')
                    handleFeedbackDialogOpen()                    
                    let aux = searchFlag; 
                    setSearchFlag(!aux);
                }
                else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                }
            })
        }     
    }

    const handleUploadFile = (e)  => {      
        e.preventDefault()   
        let correctFile = props.user + '.pdf'
        if (e.target.files[0].name === correctFile) {
            if (e.target.files.length > 0){                 
                setFileUploading(true)                     
                const formData = new FormData()
                let i = 0
                let file = {}
                for (file of e.target.files){
                    formData.append('file' + i, file)
                    i = i + 1
                }
                let opts = {
                    'filename': e.target.files[0].name,      
                    'contentType': e.target.files[0].type,              
                }
                authFetch(
                    "/api/get-gcs-signed-url?username="+props.username+
                                    "&period="+String( userPaymentObj.month)+                              
                                    "&year="+ userPaymentObj.year+                              
                                    "&files_from=ag",
                    {
                        method: 'post',                
                        //body: formData,
                        body: JSON.stringify(opts)
                    }
                ).then(r => {
                      if (r.status === 422){
                        setFileUploading(false)
                        logout()
                        return null
                      }
                      else{
                        return r.json()
                      }
                    })
                .then(response => {
                    if (response.url){   
                        let gcs_signed_url = response.url
                        fetch(
                            gcs_signed_url,               
                            {
                                method: 'put',            
                                body: e.target.files[0],
                                headers: {
                                    'Content-Type': e.target.files[0].type
                                }
                            }
                        ).then(r => {if (r.status === 200){ return true } else {return false}})
                            .then(response => {
                                if (response){              
                                    userPaymentObj.pb_document_uploaded = true                    
                                    authFetch(
                                        "/api/update-user-payment-state",
                                        {
                                            method: 'post',
                                            body: JSON.stringify(userPaymentObj),
                                        }
                                        ).then(r => {
                                        if (r.status === 422){
                                            setFileUploading(false)
                                            logout()
                                            return null
                                        }
                                        else{
                                            return r.json()
                                        }
                                        })
                                        .then(response => {
                                            if (response.Upload === 'OK'){
                                                setFileUploading(false)
                                                setFeedbackMsg('Archivo subido con éxtio.')
                                                handleFeedbackDialogOpen()                    
                                                let aux = searchFlag; 
                                                setSearchFlag(!aux);
                                            }
                                            else {
                                                setFileUploading(false)
                                                setFeedbackMsg('Error al subir archivo.')
                                                handleFeedbackDialogOpen()
                                            }
                                    })
                                }                       
                                else {
                                    setFileUploading(false)
                                    setFeedbackDialog(true)
                                    setIsLoading(false)
                                    setFeedbackMsg('Error al subir archivo.')
                                }                              
                            })
                    }
                    else {
                        setFileUploading(false)
                        setFeedbackDialog(true)
                        setIsLoading(false)
                        setFeedbackMsg('Error al subir archivo.')
                    }
                }) 
                                                                  
              
              } 
            else {                
                setFeedbackDialog(true)
                setFeedbackMsg('No ha seleccionado ningún archivo.')
            }
        }
        else{
            setFeedbackDialog(true)
            setFeedbackMsg('Archivo incorrecto. El archivo debe tener por nombre el código de usuario asignado')
        }     
    }

    const downloadFile = () => {
        setFileUploading(true)
        authFetch("/api/download-user-payment-document?username=" +props.username+
                "&month="+String( userPaymentObj.month)+                              
                "&year="+ userPaymentObj.year+
                "&client_code="+ clientCode +
                "&timestamp=" + new Date().toISOString().slice(0,19),{ responseType: 'blob' }).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setFileUploading(false)             
                return null
            }
                return response.blob()
            }).then(blob => {                 
                setFileUploading(false)            
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;    
                a.download = 'Doc_'+clientCode+'_'+ userPaymentObj.year +'_'+String( userPaymentObj.month ) + '.pdf' 
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);                                               
            })
    }

    const uploadForm = (filename, extensions) => (
        <form action={"#"}>
           <label htmlFor={filename}>
            <input
              hidden
              id={filename}
              name={filename}
              type="file"   
              accept={extensions}              
              onChange={(e) => {handleUploadFile(e); }}            
              multiple={false}  
            />
            {
              fileUploading?
              <CircularProgress color="secondary"/>
              :
              <Fab
                color="secondary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"                        
                classes={{root: classes.uploadButton}}              
              >
                <AddCircleOutlineIcon style={{marginRight: 3}}/> { userPaymentObj.pb_document_uploaded ? 'Modificar archivo' : 'Subir archivo' }
              </Fab>
            }
                    
          </label>
        </form>     
    )

    const downloadForm = (disabled) => (
        fileUploading ?
        <CircularProgress color="secondary"/>
        :
        <Fab
            color="secondary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"                        
            classes={{root: classes.uploadButton}}   
            disabled={disabled}  
            onClick={downloadFile}         
        >
            <GetAppIcon style={{marginRight: 3}}/> { 'Descargar' }
        </Fab>
    )

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} className={classes.feedbackButton} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const showDocumentStateDialog = () => (
        <Dialog
            open={documentStateDialog}
            onClose={handleDocumentDialogClose}       
            className={classes.formText}  
            fullWidth
            scroll={'paper'}
            maxWidth={'md'}          
        >
            <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Diligenciar y observar estado de documento/pago</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">
                    <Grid item xs={5}>
                        <Grid container spacing={1} justify="space-evenly">
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Usuario</Typography>
                            </Grid>
                            <Grid item>
                                <AccountBoxIcon/>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">{userPaymentObj.client_name ? userPaymentObj.client_name : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">{userPaymentObj.id_type && userPaymentObj.id_number ? 
                                            userPaymentObj.id_type + ' ' + userPaymentObj.id_number : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">{userPaymentObj.email ? userPaymentObj.email : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">{userPaymentObj.telephone ? userPaymentObj.telephone : ''}</Typography>
                                    </Grid>
                                </Grid>                               
                            </Grid>                               
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Documento</Typography>
                            </Grid>                               
                            <Grid item xs={8}>                                                                    
                                {
                                    props.user !== 'consulta-cedenar' ? 
                                    uploadForm('file.pdf', '.pdf')   
                                    :
                                    downloadForm(!userPaymentObj.pb_document_uploaded)                                 
                                }
                            </Grid>                                                                
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Observación del usuario</Typography>
                            </Grid>
                            <Grid item>
                                <ReportIcon/>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField          
                                    disabled={props.user === 'consulta-cedenar'}                    
                                    value={userPaymentObj.pb_client_obs}
                                    placeholder={props.user === 'consulta-cedenar' ? '' : 'Ingresar observación (no es obligatorio.)'}
                                    InputProps={{
                                        readOnly: props.user === 'consulta-cedenar',  
                                        className: props.user === 'consulta-cedenar' ? classes.formDefined : {},                                      
                                        classes: {
                                            input: classes.formTextDialog
                                        }
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    multiline
                                    className={classes.formRow}   
                                    onChange={(e) => setUserPaymentObj({...userPaymentObj, pb_client_obs: e.target.value})}                            
                                />                                
                            </Grid>
                            {
                                props.user !== 'consulta-cedenar' ?
                                <Grid item xs={12}>
                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="cancel"
                                        variant="extended"                        
                                        classes={{root: classes.uploadButton}}
                                        onClick={handleUserPaymentStateUpload}
                                        style={{marginTop: 20}}
                                    > 
                                        <Fragment><SaveIcon style={{marginRight: 3}}/> {'Guardar'}</Fragment>                         
                                    </Fab>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1} justify="space-evenly">
                            
                            {
                                props.user !== 'consulta-cedenar' ?
                                null
                                :
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Estado del pago</Typography>
                                    </Grid>
                                    <Grid item>
                                        <AssistantPhotoIcon/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField   
                                            select                                                                       
                                            value={userPaymentObj.p_balance_payment_state}
                                            placeholder={'Seleccione estado.'}
                                            InputProps={{                                                                                      
                                                classes: {
                                                    input: classes.formTextDialog
                                                }
                                            }}
                                            variant="outlined"
                                            color="secondary"                                    
                                            className={classes.formRow}   
                                            onChange={(e) => setUserPaymentObj({...userPaymentObj, p_balance_payment_state: e.target.value})}                            
                                        >
                                            <MenuItem value={"1 - Pendiente"}>{"1 - Pendiente"}</MenuItem>     
                                            <MenuItem value={"2 - En revisión"}>{"2 - En revisión"}</MenuItem> 
                                            <MenuItem value={"3 - Pago realizado"}>{"3 - Pago realizado"}</MenuItem> 
                                        </TextField>                           
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Valor del pago</Typography>
                                    </Grid>
                                    <Grid item>
                                        <AttachMoneyIcon/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField                 
                                            type={'number'}                                                 
                                            value={userPaymentObj.positive_balance}
                                            placeholder={''}
                                            InputProps={{                                                                                    
                                                classes: {
                                                    input: classes.formTextDialog
                                                }
                                            }}
                                            variant="outlined"
                                            color="secondary"                                    
                                            className={classes.formRow}   
                                            onChange={(e) => setUserPaymentObj({...userPaymentObj, positive_balance: e.target.value})}                            
                                        />                                
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Fecha de pago</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TodayIcon/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <ThemeProvider theme={calendarTheme}>
                                        <DatePicker    
                                            variant="dialog"    
                                            inputVariant="outlined"                                                                                                                            
                                            okLabel={""}
                                            cancelLabel={""}                                                      
                                            openTo="date"
                                            format="dd-MMMM-yyyy"
                                            //helperText=""
                                            //minDate={reportLowerLimit}
                                            //maxDate={new Date()}
                                            views={["date"]}                                
                                            value={userPaymentObj.p_balance_payment_date}
                                            InputLabelProps={{                                            
                                                        classes: {
                                                            root: classes.labelTextDialog,                                    
                                                        }
                                                    }}
                                            onChange={(d) => {
                                                setUserPaymentObj({...userPaymentObj, p_balance_payment_date: d})
                                            }}
                                            error={false}
                                            InputProps={{                                            
                                                        classes: {
                                                            input: classes.formTextDialog,                                    
                                                        }
                                            }}                                        
                                            autoOk  
                                            fullWidth                                                                                
                                        />  
                                        </ThemeProvider>                              
                                    </Grid>
                                </Fragment>
                            }
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="left" style={{fontWeight: 'normal'}}>Observación del operador</Typography>
                            </Grid>
                            <Grid item>
                                <SupervisorAccountIcon/>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    multiline          
                                    disabled={props.user !== 'consulta-cedenar'}                    
                                    value={userPaymentObj.pb_enterprise_obs}
                                    placeholder={''}
                                    InputProps={{
                                        readOnly: props.user !== 'consulta-cedenar',  
                                        className: props.user !== 'consulta-cedenar' ? classes.formDefined : {},                                      
                                        classes: {
                                            input: classes.formTextDialog
                                        }
                                    }}
                                    variant="outlined"
                                    color="secondary"                                    
                                    className={classes.formRow}   
                                    onChange={(e) => setUserPaymentObj({...userPaymentObj, pb_enterprise_obs: e.target.value})}                            
                                />                                
                            </Grid>
                            {
                                props.user === 'consulta-cedenar' ?
                                <Grid item xs={12}>
                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="cancel"
                                        variant="extended"                        
                                        classes={{root: classes.uploadButton}}
                                        onClick={handleUserPaymentStateUpload}
                                        style={{marginTop: 20}}
                                    > 
                                        <Fragment><SaveIcon style={{marginRight: 3}}/> {'Guardar'}</Fragment>                         
                                    </Fab>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Fab
                            color="secondary"
                            size="small"
                            component="span"
                            aria-label="cancel"
                            variant="extended"                        
                            classes={{root: classes.uploadButton}}
                            onClick={handleDocumentDialogClose}
                            style={{marginTop: 20}}
                        > 
                            <Fragment><CancelIcon style={{marginRight: 3}}/> {'Cerrar'}</Fragment>                         
                        </Fab>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )

    useEffect (() => {
        setUserPaymentState([])       
        if (selectedDate){
            setIsLoading(true)
            if (props.user !== 'consulta-cedenar'){
                authFetch(
                    "/api/check-user-balance-payment?client_code="+props.user+                                                                  
                                    "&year="+ selectedDate.getFullYear()
                )
                .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                    if (response && response.user_data){                                     
                        setUserPaymentState(response.user_data)
                        setIsLoading(false)
                    }    
                    else{                        
                        setFeedbackMsg('Información no encontrada.')                                                
                        handleFeedbackDialogOpen()
                        setIsLoading(false)
                    }         
                })
            }
            else {
                authFetch(
                    "/api/check-user-balance-payment?client_code="+clientCode+                                   
                                    "&year="+ selectedDate.getFullYear()
                )
                .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                    if (response && response.user_data){                                     
                        setUserPaymentState(response.user_data)                        
                        setIsLoading(false)
                    }    
                    else{                        
                        setFeedbackMsg('Información no encontrada.')                                                
                        handleFeedbackDialogOpen()
                        setIsLoading(false)
                    }         
                })
            }
        }                                
        
    }, [selectedDate])

    useEffect (() => {        
        if (props.user !== 'consulta-cedenar'){
            authFetch(
                "/api/check-user-report-limits?client_code="+props.user
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.lower_limit){  
                    let lowLimit = new Date(response.lower_limit)                                   
                    let upLimit = new Date(response.upper_limit)
                    setReportLowerLimit(lowLimit)
                    setReportUpperLimit(upLimit)
                    setSelectedDate(upLimit)
                }                    
            })
        }
        else if (clientCode !== '') {
            authFetch(
                "/api/check-user-report-limits?client_code="+clientCode
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.lower_limit){  
                    let lowLimit = new Date(response.lower_limit)                                   
                    let upLimit = new Date(response.upper_limit)
                    setReportLowerLimit(lowLimit)
                    setReportUpperLimit(upLimit)
                    setSelectedDate(upLimit)
                }       
                else{
                    setFeedbackMsg('No se encontró información para el código proporcionado.')                                                                            
                    handleFeedbackDialogOpen()
                }             
            })
        }        
        document.title ="Estado pago excedentes | Autogeneradores Cedenar"                               
    }, [searchFlag])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}
            {showDocumentStateDialog()}
            <Zoom in timeout={700}> 
            <Grid container justify="center" spacing={1}>   
                {
                    props.user === 'consulta-cedenar' ? 
                    <Grid item xs={3}>
                        <Paper className={classes.calendarPaper} elevation={0} variant="outlined">
                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>                            
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código de producto)</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField 
                                    variant="outlined" 
                                    color="primary"
                                    size="small"
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    className={classes.formRow} 
                                    value={clientCode}
                                    onChange={(e) => {setClientCode(e.target.value); setUserPaymentState([]);}}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter'){
                                            let aux = searchFlag; 
                                            setSearchFlag(!aux);
                                        }
                                    }}
                                >

                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton color="primary" onClick={(e) => {let aux = searchFlag; setSearchFlag(!aux);}}>
                                    <SearchOutlinedIcon className={classes.searchButton} />
                                </IconButton>                                        
                            </Grid>                             
                        </Grid> 
                        </Paper>
                    </Grid> 
                    :
                    null
                }
                {
                    isLoading ?
                        <Grid item xs={12}>
                        <CircularProgress/> 
                        </Grid>                   
                    :
                        !selectedDate || userPaymentState.length === 0 ?
                        null
                        :
                        <Fragment>
                            <Grid item xs={12}>                            
                            </Grid>
                            <Grid item xs={3}>
                                <Paper className={classes.calendarPaper} elevation={0} variant="outlined">                                
                                    <Grid container justify="center" spacing={0} alignItems="flex-start">
                                        <Grid item>
                                            <EventIcon color="primary"/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ThemeProvider theme={calendarTheme}>
                                            <DatePicker    
                                                variant="dialog"    
                                                inputVariant="filled"                                                                                                                            
                                                okLabel={""}
                                                cancelLabel={""}                                                      
                                                openTo="year"
                                                format="yyyy"
                                                helperText=""
                                                minDate={reportLowerLimit}
                                                maxDate={reportUpperLimit}
                                                views={["year"]}                                
                                                value={selectedDate}
                                                InputLabelProps={{                                            
                                                            classes: {
                                                                root: classes.labelText,                                    
                                                            }
                                                        }}
                                                onChange={setSelectedDate}
                                                error={false}
                                                InputProps={{                                            
                                                            classes: {
                                                                input: classes.formText,                                    
                                                            }
                                                        }}
                                            
                                                autoOk                                                                                  
                                            />        
                                            </ThemeProvider>
                                        </Grid>                                                         
                                    </Grid>                                
                                </Paper> 
                            </Grid>
                            <Grid item xs={11}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <StyledTableRow>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Periodo</StyledTableCell>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Valor liq. excedentes</StyledTableCell>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Tipo de pago</StyledTableCell>  
                                            <StyledTableCell align="center" style={{width: "10%"}}>Documentos</StyledTableCell>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Estado</StyledTableCell>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Valor pagado</StyledTableCell>
                                            <StyledTableCell align="center" style={{width: "15%"}}>Fecha de pago</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                userPaymentState.map( (userPayment, idx) => (
                                                    <StyledTableRow key={idx} style={{height: 75}}>
                                                        <StyledTableCell align="center">{monthCmp[userPayment.month-1] + '-' + userPayment.year}</StyledTableCell>
                                                        <StyledTableCell align="center">{userPayment.p_balance_value.length > 1 ? userPayment.p_balance_value.slice(1,) + ' $' :  userPayment.p_balance_value + ' $'}</StyledTableCell>
                                                        <StyledTableCell align="center">{userPayment.exceed_payment}</StyledTableCell>
                                                        <StyledTableCell align="center">{
                                                            Number(userPayment.p_balance_value) === 0 ?
                                                            "-"
                                                            :                                                            
                                                            <Grid container alignItems="flex-start" justify="center" spacing={0}>
                                                                <Grid item xs={12}>    
                                                                    <IconButton disabled={
                                                                        userPayment.exceed_payment[0] === '1' ||                                                                       
                                                                        (userPayment.p_balance_payment_state ? (userPayment.p_balance_payment_state[0] === '3') : false)                                                                         
                                                                        }
                                                                        style={{padding: -5}}
                                                                        onClick={() =>{
                                                                            handleDocumentDialogOpen();
                                                                            let auxObj = userPaymentState[idx];
                                                                            setUserPaymentObj(auxObj);
                                                                        }}
                                                                    >                          
                                                                        <AssignmentIcon fontSize="small" color={
                                                                            (
                                                                                userPayment.exceed_payment[0] === '1' ||                                                                       
                                                                                (userPayment.p_balance_payment_state ? (userPayment.p_balance_payment_state[0] === '3') : false)                                                                                
                                                                            ) ? 
                                                                            "gray"
                                                                            :
                                                                            userPayment.pb_document_uploaded ?
                                                                            "success"
                                                                            :
                                                                            "error"
                                                                        }/>
                                                                    </IconButton>             
                                                                </Grid>                                                                
                                                                <Grid item style={{paddingRight: 5}}>
                                                                    {
                                                                        (
                                                                            userPayment.exceed_payment[0] === '1' 
                                                                            //||                                                                       
                                                                            //(userPayment.p_balance_payment_state ? (userPayment.p_balance_payment_state[0] === '3') : false)                                                                            
                                                                        ) ?
                                                                        <ClearIcon fontSize="small" color="gray"/>
                                                                        :
                                                                        (userPayment.p_balance_payment_state ? (userPayment.p_balance_payment_state[0] === '3') : false) ?
                                                                            null
                                                                        :
                                                                            userPayment.pb_document_uploaded ?
                                                                            <DoneIcon fontSize="small" color="success"/>
                                                                            :
                                                                            <HourglassEmptyIcon fontSize="small" color="error"/>
                                                                    }
                                                                    
                                                                </Grid>
                                                                <Grid item>                                            
                                                                    {
                                                                        (
                                                                            userPayment.exceed_payment[0] === '1'                                                                            
                                                                        ) ?
                                                                        "No aplica"
                                                                        : (userPayment.p_balance_payment_state ? (userPayment.p_balance_payment_state[0] === '3') : false) ?
                                                                        "Cerrado"
                                                                        :
                                                                        userPayment.pb_document_uploaded ?
                                                                        "Listo"
                                                                        :
                                                                        "Por subir"
                                                                    }
                                                                </Grid>
                                                                
                                                            </Grid>                                                            
                                                        }</StyledTableCell>
                                                        <StyledTableCell align="center">{userPayment.p_balance_payment_state ? userPayment.p_balance_payment_state : '-'}</StyledTableCell>
                                                        <StyledTableCell align="center">{userPayment.positive_balance ? userPayment.positive_balance +' $': '-'}</StyledTableCell>
                                                        <StyledTableCell align="center">{userPayment.p_balance_payment_date ? new Date(userPayment.p_balance_payment_date ).toISOString().slice(0,10): '-'}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }
                                                                                    
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Fragment>
                }
            </Grid>
            </Zoom>
        </div>
    )
}