import {React, useEffect, useState, Fragment, useMemo, createContext, useContext, createElement} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';
import Plot from 'react-plotly.js';
import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DataGrid from 'react-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import esLocale from "date-fns/locale/es";
import * as localeDictionary from 'plotly.js/lib/locales/es.js'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    filterCell:{
        lineHeight: 35,
        padding: 0,
        '> div':{
            paddingBlock: 0,
            paddingInline: 8,
            '&:first-child': {
                borderBlockEnd: '1px solid black'
            }
        }
    },   
    tableText:{
        fontSize: 11,
        textAlign: 'left'
    }, 
    contentPaperNoBottomPadding:{        
        padding: 0,        
    },   
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 8,
    },
    dataTable:{
        color: '#606B6A',
        ['--rdg-background-color']: '#D6EEEC',
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 90          
    },
    filterInput200: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",        
    },
    filterText: {
        fontSize: 11,
        color: 'white',
        textAlign: "left",        
        background: 'black'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    itemText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",         
    },
    typoInfo2: {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.light,
        padding: 4,
        //fontWeight: 'bold',
        fontSize: 14,
        borderRadius: 3,
        //border: `1px solid ${theme.palette.primary.main}`,
    },
  }));

let sumOfArray = (array) => {
    let sum = array.reduce((a,b) => ((Number(a)+Number(b)).toFixed(2)),0)
    return sum
}


export default function ClientControl (props) {
    const classes = useStyles();    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")        
    const [isLoading, setIsLoading] = useState(false)
    const [trendLoading, setTrendLoading] = useState(false)

    const [trendData, setTrendData] = useState(null)
    const [barData, setBarData] = useState(null)
    const [heightUnits, setHeightUnits] = useState(0)
    const [maxBarData, setMaxBarData] = useState(0)
    const [selectedYearInfo, setSelectedYearInfo] = useState(null)
    const [yearInformation, setYearInformation] = useState([])
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedRange, setSelectedRange] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState(null)
    let monthCmp = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    let trimCmp = ['1er Trimestre', '2do Trimestre', '3er Trimestre', '4to Trimestre']
    let semCmp = ['1er Semestre', '2do Semestre']
       
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    // PLOT VARS
    let vw = Math.round(window.innerWidth/100)
    let vh = Math.round(window.innerHeight/100)
    let theme = useTheme()
    let scatterLayout = (suffix) => ({
        xaxis: 
            {
                type: 'date',
                tickformat: '%b',  
                tickmode: 'array',
                tickvals: trendData ? trendData[0].x : [],                                                      
                tickfont: {
                    size: 12,                
                },                
                fixedrange: true,
                showgrid: false
            }
        ,
        yaxis: {
            zerolinecolor: theme.palette.primary.main,
            zerolinewidth: 2,
            showgrid: false,
            ticksuffix: suffix,
            tickfont: {
                size: 11,                
            },
            tickformat: "digit",
            fixedrange: true 
        },        
        hovermode: "closest",
        hoverlabel:{
            font:{
                family: 'Roboto Condensed',
                size: 12,
                color: "#2E2B3E"
            },
        },
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: theme.palette.primary.dark
        },
        // paper_bgcolor: "#2E2B3E",
        // plot_bgcolor: "#2E2B3E",
        margin: {
            b: 30,
            t: 0,
            l: 50,
            r: 10,
            pad: 10
        },
        legend: {
            x: 0.18,
            y: 1.1,
            orientation: "h",
            xanchor: 'left',
            //entrywidthmode: "fraction",
            //entrywidth: 200
        },        
        paper_bgcolor: theme.palette.background.default,
        plot_bgcolor: theme.palette.background.default,
        width: 30*vw, 
        height: 35*vh
    })

    let barLayout = {    
		xaxis: {zeroline: false, ticksuffix:'M COP$', range: [-maxBarData, maxBarData]},
        yaxis: 
            {
                type: 'date',
                tickformat: '%b',  
                tickmode: 'array',
                tickvals: trendData ? trendData[0].x : [],                                                      
                tickfont: {
                    size: 12,                
                },                
                fixedrange: true,
                showgrid: true
            }
        ,
		//yaxis: {title: 'Y-axis'},
		barmode: 'overlay',		 
        font:{
            family: 'Roboto Condensed',
            size: 12,
            color: theme.palette.primary.dark
        },
        // paper_bgcolor: "#2E2B3E",
        // plot_bgcolor: "white",
        margin: {
            b: 40,
            t: 0,
            l: 50,
            r: 10,
            pad: 10
        },
        legend: {
            x: 0,
            y: 1.25,
            orientation: "h",
            xanchor: 'left',
            //entrywidthmode: "fraction",
            //entrywidth: 200
        },       
        paper_bgcolor: theme.palette.background.default,
        plot_bgcolor: theme.palette.background.default,
        width: 30*vw, 
        height: heightUnits <= 3 ? 37*vh : 8*heightUnits*vh
    }
            
    useEffect (() => {
        setIsLoading(true)
        document.title ="Tendencias de facturación | Autogeneradores Cedenar";        

        authFetch("/api/get-liquidation-date-limits?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    setIsLoading(false)
                    return null
                }
                return response.json()
            }).then(response => {
                    if (response && response.years_information){   
                        //console.log(response.years_information)                                                         
                        setYearInformation(response.years_information)
                        //setLoadingInformation(false)
                    }
                    setIsLoading(false)
            })
        
    }, [])

    useEffect (() => {
        if (selectedPeriod !== null){
            setTrendLoading(true)               
            authFetch("/api/get-liquidation-internal-trends?username=" + String(props.username)
                +"&year="+String( selectedYear )
                +"&range="+String( selectedRange )
                +"&period="+String( selectedPeriod )).then(response => {
                if (response.status === 422){
                        logout()
                        setIsLoading(false)
                        return null
                    }
                    return response.json()
                }).then(response => {
                        if (response && response.trend_data){  
                            setHeightUnits(response.trend_data.length)
                            let modData = []      
                            let energyTrend = [
                                {
                                    name: 'Activa kWh',
                                    label: 'Activa',
                                    marker: {
                                        color: '#FFAF0F',
                                        size: 8
                                    },              
                                    line: {                                                   
                                        width: 2,
                                        shape: 'spline'
                                    },
                                    fillcolor: '#D9950D30',                                                                             
                                    type: 'scatter',
                                    hoverinfo: 'text',
                                    hovertemplate: '%{x|%b %Y}, %{y}<extra></extra>'
                                },
                                {
                                    name: 'Excedentes 1 kWh',
                                    label: 'Exc 1',
                                    marker: {
                                        color: '#42F02F',
                                        size: 8
                                    },              
                                    line: {                                                   
                                        width: 2,
                                        shape: 'spline'
                                    },
                                    fillcolor: '#45F73130',                                                                              
                                    type: 'scatter',
                                    hoverinfo: 'text',
                                    hovertemplate: '%{x|%b %Y}, %{y}<extra></extra>'
                                },
                                {
                                    name: 'Excedentes 2 kWh',
                                    label: 'Exc 2',
                                    marker: {
                                        color: '#52F791',
                                        size: 8
                                    },              
                                    line: {                                                   
                                        width: 2,
                                        shape: 'spline'
                                    },
                                    fillcolor: '#4BE08340',                                                                              
                                    type: 'scatter',
                                    hoverinfo: 'text',
                                    hovertemplate: '%{x|%b %Y}, %{y}<extra></extra>'
                                },
                                {
                                    name: 'Capacitiva kVArh',
                                    label: 'Capacitiva',
                                    marker: {
                                        color: '#8C69EB',
                                        size: 8
                                    },              
                                    line: {                                                   
                                        width: 2,
                                        shape: 'spline'
                                    },
                                    fillcolor: '#9873FF30',                                                                              
                                    type: 'scatter',
                                    hoverinfo: 'text',
                                    hovertemplate: '%{x|%b %Y}, %{y}<extra></extra>'
                                },
                                {
                                    name: 'Inductiva kVArh',
                                    label: 'Inductiva',
                                    marker: {
                                        color: '#FFF227',
                                        size: 8
                                    },              
                                    line: {                                                   
                                        width: 2,
                                        shape: 'spline'
                                    },
                                    fillcolor: '#EBDD2330',                                                                              
                                    type: 'scatter',
                                    hoverinfo: 'text',
                                    hovertemplate: '%{x|%b %Y}, %{y}<extra></extra>'
                                },                          
                            ]
                            let activeObj = {
                                x: [],
                                y: [],
                                //width: [],
                                type: 'bar',
                                orientation: 'h',
                                name: 'Ingresos (activa, inductiva y capacitiva)',
                                label: 'Ingresos',
                                marker: {
                                    color: '#42FA1B30',   
                                    line: {                                                   
                                        width: 2,
                                        color: '#207A0D',
                                    },                                 
                                },                                                                                                                                     
                                hoverinfo: 'text',
                                hovertemplate: '%{y|%b %Y}, %{x}<extra></extra>'
                            }                        
                            let pasiveObj = {
                                x: [],
                                y: [],
                                //width: [],
                                type: 'bar',
                                orientation: 'h',
                                name: 'Egresos (exc1 y exc2)',
                                label: 'Egresos',
                                marker: {
                                    color: '#FA835530',   
                                    line: {                                                   
                                        width: 2,
                                        color: '#BA3220',
                                    },                                 
                                },                                                                                                             
                                hoverinfo: 'text',
                                hovertemplate: '%{y|%b %Y}, %{x}<extra></extra>'
                            }                            
                            for (var energy of energyTrend){
                                let trendObj = {}                                
                                trendObj['x'] = []
                                trendObj['y'] = []
                                trendObj.stackgroup = 'one'
                                trendObj.name = energy.label
                                trendObj.marker = energy.marker
                                trendObj.line = energy.line
                                trendObj.fillcolor = energy.fillcolor                     
                                trendObj.type = energy.type
                                trendObj.hoverinfo = energy.hoverinfo
                                trendObj.hovertemplate = energy.hovertemplate
                                for (var periodData of response.trend_data){                                                                        
                                    trendObj['x'].push(periodData.Periodo)                                    
                                    trendObj['y'].push((periodData[energy.name]/1000).toFixed(2))
                                    if (energy.label === 'Inductiva'){
                                        activeObj['x'].push(((periodData['Activa $'] + periodData['Inductiva $'] + periodData['Capacitiva $'])/1000000).toFixed(2))
                                        pasiveObj['x'].push(((periodData['T. Excedentes 1'] + periodData['Excedentes 2 $'])/1000000).toFixed(2))       
                                        activeObj['y'].push(periodData.Periodo)
                                        pasiveObj['y'].push(periodData.Periodo)
                                    }                                                                 
                                }                                                              
                                modData.push(trendObj)
                            }                            
                            let pasiveAbs = pasiveObj['x'].map((val) => Math.abs(val)) 
                            let activeAbs = activeObj['x'].map((val) => Number(val))                                
                            let barValues = activeAbs.concat(pasiveAbs)                                                           
                            setMaxBarData(Math.max(...barValues))                                                                           
                            setTrendData(modData)
                            setBarData([activeObj, pasiveObj])
                            //setLoadingInformation(false)
                        }
                        setTrendLoading(false)
                })
        }
        
    }, [selectedPeriod])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}            
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly" spacing={1}>                    
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle} color="primary">Tendencias de facturación autogeneradores
                        </Typography>
                    </Grid>                                                                                               
                    <Grid item xs={12}>
                        {
                            isLoading ?
                            <CircularProgress/>
                            :                            
                            <Grid container spacing={1} alignItems="center" justify="space-evenly">               
                                <Grid item xs={2}>                 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>                                            
                                            <Grid item xs={8}>
                                                <TextField 
                                                    variant="standard" 
                                                    select
                                                    label="Año"
                                                    size="small"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.formText
                                                        }
                                                    }}
                                                    className={classes.formRow} 
                                                    value={selectedYear}
                                                    onChange={(e) => {                                                        
                                                        setSelectedYear(e.target.value);
                                                        let idx = yearInformation.findIndex(obj => obj.year === e.target.value)
                                                        setSelectedYearInfo(yearInformation[idx]);
                                                        setSelectedRange(null);
                                                        setSelectedPeriod(null);
                                                        setTrendData(null);
                                                    }}                                                    
                                                >
                                                    {
                                                        yearInformation.map((info, idx) => (
                                                            <MenuItem idx={idx} value={info.year} className={classes.itemText}>
                                                            {info.year}
                                                            </MenuItem>
                                                        ))
                                                    }                                                                                                       
                                                </TextField>
                                            </Grid>                                            
                                        </Grid>                           
                                    </Paper> 
                                </Grid>          
                                <Grid item xs={3}>                 
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                                            <Grid item xs={9}>
                                                <TextField 
                                                    variant="standard" 
                                                    select
                                                    label="Rango"
                                                    size="small"
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.formText
                                                        }
                                                    }}
                                                    className={classes.formRow} 
                                                    value={selectedRange}
                                                    onChange={(e) => {setSelectedRange(e.target.value); setTrendData(null);}}                                                
                                                >                                                                                                        
                                                    <MenuItem value={"tri"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.quarters_flag.includes(true) : true}>
                                                    Trimestral
                                                    </MenuItem>
                                                    <MenuItem value={"sem"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.halfs_flag.includes(true) : true}>
                                                    Semestral
                                                    </MenuItem>
                                                    <MenuItem value={"com"} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.year_flag : true}>
                                                    Anual
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>                           
                                    </Paper> 
                                </Grid> 
                                {
                                    selectedRange !== 'com' ?
                                    <Grid item xs={3}>                 
                                        <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                            <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                                                <Grid item xs={9}>
                                                    <TextField 
                                                        variant="standard" 
                                                        select
                                                        label="Periodo"
                                                        size="small"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                        value={selectedPeriod}
                                                        onChange={(e) => {setSelectedPeriod(e.target.value)}}                                                
                                                    >
                                                        {
                                                            selectedRange ?                                                                                                                            
                                                                selectedRange === 'tri' ? 
                                                                trimCmp.map((trim, tidx) => (
                                                                    <MenuItem value={"t" + String(tidx+1)} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.quarters_flag[tidx] : true}>
                                                                        {trim}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                selectedRange === 'sem' ? 
                                                                semCmp.map((sem, sidx) => (
                                                                    <MenuItem value={"s" + String(sidx+1)} className={classes.itemText} disabled={selectedYearInfo ? !selectedYearInfo.halfs_flag[sidx] : true}>
                                                                        {sem}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                null
                                                            :
                                                            null
                                                        }
                                                                                                                                                            
                                                    </TextField>
                                                </Grid>
                                            </Grid>                           
                                        </Paper> 
                                    </Grid> 
                                    :
                                    null
                                }   
                                {
                                    trendLoading ?
                                    <Grid item xs={12}>
                                    <CircularProgress style={{marginTop: 20}}/>
                                    </Grid>
                                    :
                                    trendData && barData?
                                        <Fragment>
                                        <Grid item xs={6}>
                                        <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                            <Grid container style={{padding: 10}} spacing={1} justify="center">
                                                <Grid item xs={12}>
                                                    <Typography className={classes.typoInfo2} align="center">
                                                        Acumulados de energía activa y energía generada 
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Typography variant="h6" style={{color: "#4BE083"}} display="inline">{sumOfArray(trendData[2].y)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#4BE083"}} display="inline">{" MWh"}</Typography>
                                                </Grid>
                                                <Grid item style={{margin: '0 10px 0 10px'}}>
                                                    <Typography variant="h6" style={{color: "#45F731"}} display="inline">{sumOfArray(trendData[1].y)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#45F731"}} display="inline">{" MWh"}</Typography>
                                                </Grid>
                                                <Grid item style={{margin: '0 10px 0 10px'}}>
                                                    <Typography variant="h6" style={{color: "#D9950D"}} display="inline">{sumOfArray(trendData[0].y)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#D9950D"}} display="inline">{" MWh"}</Typography>                                                                                                
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Plot
                                                        layout={scatterLayout(' MWh')}
                                                        config={{displayModeBar: false, locales: {'es-ES': localeDictionary}, locale: 'es-ES'}}          
                                                        data={trendData.slice(0,3)}                                                                                                
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                            <Grid container style={{padding: 10}} spacing={1} justify="center">
                                                <Grid item xs={12}>
                                                    <Typography className={classes.typoInfo2} align="center">
                                                        Acumulados de energías reactivas 
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Typography variant="h6" style={{color: "#EBDD23"}} display="inline">{sumOfArray(trendData[4].y)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#EBDD23"}} display="inline">{" MVARh"}</Typography>
                                                </Grid>
                                                
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Typography variant="h6" style={{color: "#9873FF"}} display="inline">{sumOfArray(trendData[3].y)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#9873FF"}} display="inline">{" MVARh"}</Typography>                                                                                                
                                                </Grid>
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Plot
                                                        layout={scatterLayout(' MVARh')}
                                                        config={{displayModeBar: false, locales: {'es-ES': localeDictionary}, locale: 'es-ES'}}          
                                                        data={trendData.slice(3)}                                                                                                
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        </Grid>                                             
                                        <Grid item xs={6}>
                                        <Paper className={classes.contentPaperNoBottomPadding} elevation={0} variant="outlined">
                                            <Grid container style={{padding: 10}} spacing={1} justify="center">
                                                <Grid item xs={12}>
                                                    <Typography className={classes.typoInfo2} align="center">
                                                        Totales de liquidación 
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Typography variant="h6" style={{color: "#207A0D"}} display="inline">{sumOfArray(barData[0].x)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#207A0D"}} display="inline">{"M COP$"}</Typography>
                                                </Grid>
                                                
                                                <Grid item style={{margin: '0px 10px 0px 10px'}}>
                                                    <Typography variant="h6" style={{color: "#BA3220"}} display="inline">{sumOfArray(barData[1].x)}</Typography>
                                                    <Typography variant="subtitle2" style={{color: "#BA3220"}} display="inline">{"M COP$"}</Typography>                                                                                                
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Plot
                                                        layout={barLayout}
                                                        config={{displayModeBar: false, locales: {'es-ES': localeDictionary}, locale: 'es-ES'}}          
                                                        data={barData}                                                                                                
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        </Grid> 
                                        </Fragment>
                                        :
                                        null
                                    
                                }   
                                                                         
                            </Grid>                            
                        }
                        
                    </Grid>                                                     
                </Grid>
            </Zoom>
        </div>
    )
}