import getMonths from '../const/monthPeriods';

const months = getMonths()

export default function validateInput( filename, inputFiles, validations, period2S, period3S, quantity, selectedPeriod, actualMonth ){
    let validationFlags = {}
    let validation = ""
    let file = {}
    for (validation of validations) {
      validationFlags[validation] = []
      switch (validation) {
        case "Name":
          for (file of inputFiles) {
            if ( file.name.toLowerCase().includes(filename.toLowerCase()) ) { 
              validationFlags[validation].push(true)
            }
            else{
              validationFlags[validation].push(false)
              break;
            }
          }
          break;
        case "Month":
          let regexp = /(\d{2})/
          for (file of inputFiles) {
            if ( file.name.match(regexp) != null){
                if ( file.name.match(regexp)[0] === period2S ) { 
                    validationFlags[validation].push(true)
                }
                else {
                    validationFlags[validation].push(false)
                    break;
                }
            }
            else{
                validationFlags[validation].push(false)
                break;
            }          
          }
          break;
        case "finalMonth":
          let regexpf = /\d{2}$/
          filename = file.name.split('.')[0]           
          for (file of inputFiles) {
            if ( filename.match(regexpf) != null){                  
                if ( filename.match(regexpf)[0] === period2S ) { 
                    validationFlags[validation].push(true)
                }
                else {
                    validationFlags[validation].push(false)
                    break;
                }
            }
            else{
                validationFlags[validation].push(false)
                break;
            }          
          }
          break;
        case "actualMonth":
          let regexpf2 = /\d{2}$/
          filename = file.name.split('.')[0]                            
          for (file of inputFiles) {
            if ( filename.match(regexpf2) != null){                  
                if ( filename.match(regexpf2)[0] === actualMonth ) { 
                    validationFlags[validation].push(true)
                }
                else {
                    validationFlags[validation].push(false)
                    break;
                }
            }
            else{
                validationFlags[validation].push(false)
                break;
            }          
          }
          break;
        case "abbMonth":
          for (file of inputFiles) {
            if ( file.name.includes(period3S) ) { 
              validationFlags[validation].push(true)
            }
            else{
              validationFlags[validation].push(false)
              break;
            }
          }          
          break;
        case "Quantity":
          const monthDays = new Date( selectedPeriod.getFullYear(), selectedPeriod.getMonth() + 1, 0 ).getDate()
          if ( Number(quantity) === inputFiles.length ) { 
            validationFlags[validation].push(true)
          }
          else if ( quantity === "Month" &&  inputFiles.length === monthDays){
            validationFlags[validation].push(true)
          }
          else{
            validationFlags[validation].push(false)
          }        
          break;
        case "Levels":
          let level1, level2, level3 = false
          for (file of inputFiles){
            if ( file.name.toLowerCase().includes('nivel1') ) {
              level1 = true               
            }
            else if ( file.name.toLowerCase().includes('nivel2') ) {
              level2 = true             
            }
            else if ( file.name.toLowerCase().includes('nivel3') ) {
              level3 = true             
            }
          }
          validationFlags[validation].push(level1 && level2 && level3)
          break;
        case "ADDMonth":
          let prevMonth = new Date(selectedPeriod.getFullYear(), selectedPeriod.getMonth(), 1)
          prevMonth.setMonth(selectedPeriod.getMonth()-1)
          const validPeriod = months[prevMonth.getMonth()]
          let regexp2 = /(?:\d+)/g 
          let regexpArray = file.name.match(regexp2)          
          for (file of inputFiles) {            
            if ( regexpArray[regexpArray.length - 1].slice(-2).includes(validPeriod) ) {               
              validationFlags[validation].push(true)
            }
            else{
              validationFlags[validation].push(false)
              break;
            }
          }          
          break;
      }
    }
    return validationFlags
  }