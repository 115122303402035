import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Accordion, AccordionSummary, AccordionDetails, 
    FormGroup, FormControlLabel, Checkbox,  List, ListItem,} from '@material-ui/core/'
import {authFetch, logout} from '../../auth';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import {DatePicker} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    accordionList: {
        width: '100%',                
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    componentPrincipalTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        padding: theme.spacing(1),
        border: `solid 1px ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    }
}));



export default function OpTariff(props){
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const classes = useStyles();
    const [newOpTariffDialog, setNewOpTariffDialog] = useState(false)
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [publicationDialog, setPublicationDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [opTariffBeginDate, setOpTariffBeginDate] = useState(new Date())
    const [opTariffFinishDate, setOpTariffFinishDate] = useState(null)
    const [levelsFlags, setLevelsFlags] = useState(new Array(5).fill(true))
    const [opTariffState, setOpTariffState] = useState([])
    

    const handleNewOpTariffDialogClose = () => {
        setNewOpTariffDialog(false);
    };

    const handleNewOpTariffDialogOpen = () => {
        setNewOpTariffDialog(true);
    };
    
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handlePublicationDialogOpen = () => {
        setPublicationDialog(true);
    };
    
    const handlePublicationDialogClose = () => {
        setPublicationDialog(false);
    };

    const handleLevelFlagsChange = (idx) => (e) => {
        let auxArray = levelsFlags
        auxArray[idx] = e.target.checked
        setLevelsFlags([...auxArray])        
    }

    const handleApvButtonClick = (idx) => (e) => {        
        let auxState = opTariffState
        let auxObject = opTariffState[idx]
        let newObject = {...auxObject, Approved: true}
        auxState[idx] = newObject
        setOpTariffState([...auxState])
    }

    const handleOpTariffDataUpload = () => {
        let beginYear = Number(opTariffBeginDate.getFullYear())
        let beginMonth = Number(opTariffBeginDate.getMonth() + 1)        
        let opts = {
            'active': levelsFlags.some(el => el === true) ? true : false,
            'begin_year': beginYear,
            'begin_month': beginMonth,
            'levels': levelsFlags,
            'username': props.username,
            'market': props.market,
            'finish_month': opTariffFinishDate ? Number(opTariffFinishDate.getMonth() + 1) : null,
            'finish_year': opTariffFinishDate ? Number(opTariffFinishDate.getFullYear()) : null          
        }
        authFetch(
            "/api/update-op-tariff",
            {
              method: 'post',
              body: JSON.stringify(opts),
            }
          ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
              if (response.Upload === 'OK'){
                setFeedbackMsg('Opción tarifaria actualizada.')
                handleFeedbackDialogOpen()
                handleNewOpTariffDialogClose()
                authFetch("/api/check-markets?username=" + String(props.username) ).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.markets){                             
                            let marketOpTariffs =  response.markets.flatMap((market) => market.state ? market.tariff_option_params : []) 
                            let idx = props.markets.indexOf(props.market)                                            
                            props.setMarketsOpTariff ( marketOpTariffs )
                            props.setMarketOpTariff ( JSON.parse(marketOpTariffs[idx]) )                                                                 
                        }
                    })
              }
              else {
                setFeedbackMsg('Error al guardar los datos.')
                handleFeedbackDialogOpen()
              }
            })
    }

    const showNewOpTariffDialog = () => (
        <Dialog
            open={newOpTariffDialog}
            onClose={handleNewOpTariffDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'sm'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos para iniciar la opción tarifaria."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={8}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo de inicio</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"                                            
                                views={["year","month"]}                                           
                                value={opTariffBeginDate}                                           
                                onChange={(date) => { setOpTariffBeginDate(date);}}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk    
                                fullWidth                                                                              
                            /> 
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Niveles de tensión por aplicar:</Typography>
                        </Grid>                                      
                        <Grid item xs={9}>
                            <FormGroup>
                                {
                                    ['Nivel 1 OR', 'Nivel 1 COMP', 'Nivel 1 US', 'Nivel 2', 'Nivel 3'].map((title, idx) =>(
                                        <FormControlLabel classes={{label:classes.formText}} 
                                         control={<Checkbox checked={levelsFlags[idx]} color="primary" onChange={handleLevelFlagsChange(idx)}/>} label={title} />
                                    ))
                                }                               
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodo de finalización</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"                                            
                                views={["year","month"]}                                           
                                value={opTariffFinishDate}                                           
                                onChange={(date) => { setOpTariffFinishDate(date);}}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk    
                                fullWidth                                                                              
                            /> 
                        </Grid>
                    </Grid>                                       
                </Grid>   
                <Grid item xs={12}>                    
                </Grid>  
                <Grid item>
                    <Fab
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"                        
                    classes={{root: classes.uploadButton}}
                    onClick={handleOpTariffDataUpload}
                    style={{marginTop: 20}}
                > 
                    <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleNewOpTariffDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>                            
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const handleTariffOpUpload = (idx) => {   
        let auxState = opTariffState
        let auxObject = opTariffState[idx]
        let newObject = {...auxObject, Published: true}
        auxState[idx] = newObject        
        setOpTariffState([...auxState])        
        
        let year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1
        let period = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1
        let version = props.tariffCalcVersion
        let usernameCode = props.username.slice(0,3)
        let tariffOpStatus = {}
        tariffOpStatus[props.market] = {}       
        tariffOpStatus[props.market]['CompValues'] = newObject['CompValues']
        tariffOpStatus[props.market]['Levels'] = props.marketOpTariff['levels']

        let stripes = ['max','med','min','mon']
        let levels  = ['n1or', 'n1comp', 'n1us', 'n2', 'n3']
        let cu_optariff_form = {}
        let sa_optariff_form = {}
        cu_optariff_form[props.market] = {}
        sa_optariff_form[props.market] = {}
        for (let [pidx, stripe] of stripes.entries()){
            cu_optariff_form[props.market][stripe] = {}
            sa_optariff_form[props.market][stripe] = {}
            for (let [sdix, level] of levels.entries()){
                let auxLenght = newObject['CompValues'][pidx][sdix].length
                cu_optariff_form[props.market][stripe][level] = newObject['CompValues'][pidx][sdix][auxLenght-2]
                sa_optariff_form[props.market][stripe][level] = newObject['CompValues'][pidx][sdix][auxLenght-1]
            }
        }

        let opts = {
            'electric_tariff_id': String( usernameCode + String( String(year) + String(period) + version ) ), 
            'optariff_status': JSON.stringify(tariffOpStatus),
            'cu_optariff_form': JSON.stringify(cu_optariff_form),
            'sa_optariff_form': JSON.stringify(sa_optariff_form)
        }       
                                                  
        authFetch(
            "/api/calc-cu-op-tariff?username="+props.username+
            "&period="+String( props.periodDate.getMonth() + 1 )+    
            "&year="+ props.periodDate.getFullYear(),
            {
                method: 'post',
                body: JSON.stringify(opts),
            }
        ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response.Upload === 'OK'){
                setFeedbackMsg('Valor del Cu para opción tarifaria publicado.')
                handleFeedbackDialogOpen()   
                props.setOpTariffPub(true)                            
            }
            else {
                setFeedbackMsg('Error al guardar los datos.')
                handleFeedbackDialogOpen()
            }
        })
            
    }

    const showPublicationDialog = () => (
        <Dialog
            open={publicationDialog}
            onClose={handlePublicationDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
            ¿Desea publicar los valores para la opción tarifaria?. Esta acción no se puede deshacer.
            </DialogContentText>
        </DialogContent>
        <DialogActions>   
          <Button onClick={handlePublicationDialogClose} color="primary" autoFocus>
            Cancelar
          </Button>       
          <Button onClick={(e) => {handlePublicationDialogClose(); handleTariffOpUpload(0);}} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const updateOpTariffParams = () => {
        setLevelsFlags([...props.marketOpTariff.levels])
        setOpTariffBeginDate(new Date(props.marketOpTariff.begin_year, props.marketOpTariff.begin_month - 1, 1))
        setOpTariffFinishDate( props.marketOpTariff.finish_year ? new Date(props.marketOpTariff.finish_year, props.marketOpTariff.finish_month - 1, 1) : null)
    }

    const inputsStateList = (files, filesState, filesDate, params, paramsState, paramsDate) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Archivos</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                    <Grid container justify='space-evenly'>           
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                           
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                    {filename}
                                    </Grid>                                    
                                    <Grid item xs={6}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item xs={10}>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="flex-start">
                                                <Grid item xs={2}>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }
                                                                                                    
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Parámetros</Typography>
                </Grid>
                {
                    params.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify='space-evenly'>
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>     
                                              
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                params.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={6}>
                                    { paramsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item xs={10}>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={2}>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }                    
            </Grid>             
        </Fragment>            
    )

    const filterActiveLevels = (paramsOpTariff) => {        
        let levels =  ['Nivel 1 OR', 'Nivel 1 CO', 'Nivel 1 US', 'Nivel 2', 'Nivel 3']
        levels = levels.filter( (level, idx) => paramsOpTariff.levels[idx])
        return levels
    }

    const filterTariffOptionValues = (tariffOptionValues, paramsOpTariff, dbLabels) => {
        let values = tariffOptionValues
        values = values.filter( (val, idx) => paramsOpTariff.levels[idx] || dbLabels[idx])
        return values
    }

    const tariffOptionTable = (tariffOptionLabels, tariffOptionValues, version, dbLabels) => (
        <Grid container spacing={4} justify="center">
            {
                version === "FRANJAS HORARIAS" ? 
                ['Franja máxima', 'Franja media', 'Franja mínima', 'Franja monomia'].map((title, pidx) => (
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>{title}</Typography>
                        <TableContainer component={Paper} key={pidx}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "11%"}}></StyledTableCell>
                                    {
                                        tariffOptionLabels.map( (label, idx) => (
                                            <StyledTableCell align="center" key={idx}>{label}</StyledTableCell>
                                        ))
                                    }                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    filterActiveLevels(props.marketOpTariff).map((subtitle, sidx) => (
                                        <StyledTableRow key={sidx}>
                                            <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell> 
                                            {
                                                filterTariffOptionValues(tariffOptionValues[pidx],props.marketOpTariff, dbLabels)[sidx].map((value, idx) => (
                                                    <StyledTableCell align="center">{value.toLocaleString('en-US')}</StyledTableCell>
                                                ))
                                            }                                          
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))
                :
                ['Franja monomia'].map((title, pidx) => (
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>{title}</Typography>
                        <TableContainer component={Paper} key={pidx}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "11%"}}></StyledTableCell>
                                    {
                                        tariffOptionLabels.map( (label, idx) => (
                                            <StyledTableCell align="center" key={idx}>{label}</StyledTableCell>
                                        ))
                                    }                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.marketOpTariff.active ?
                                    filterActiveLevels(props.marketOpTariff).map((subtitle, sidx) => (
                                        <StyledTableRow key={sidx}>
                                            <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell> 
                                            {
                                                filterTariffOptionValues(tariffOptionValues[tariffOptionValues.length-1],props.marketOpTariff, dbLabels)[sidx].map((value, idx) => (
                                                    <StyledTableCell align="center">{value.toLocaleString('en-US')}</StyledTableCell>
                                                ))
                                            }                                          
                                        </StyledTableRow>
                                    ))
                                    // Si esta inactiva la info se ve como consulta
                                    :
                                    ['Nivel 1 OR', 'Nivel 1 CO', 'Nivel 1 US', 'Nivel 2', 'Nivel 3'].map((subtitle, sidx) => (
                                        <StyledTableRow key={sidx}>
                                            <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell> 
                                            {
                                                filterTariffOptionValues(tariffOptionValues[tariffOptionValues.length-1],props.marketOpTariff, dbLabels)[sidx].map((value, idx) => (
                                                    <StyledTableCell align="center">{value.toLocaleString('en-US')}</StyledTableCell>
                                                ))
                                            }                                          
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    ))
            }
        </Grid>       
    )

    const compareDates = (opTariffDate, periodDate) => {        
        let op_ini_date = new Date( opTariffDate.begin_year, opTariffDate.begin_month - 1, 1, 0, 0)
        let cu_date = new Date ( periodDate.getFullYear(), periodDate.getMonth() + 1, 1, 0, 0)
        if (op_ini_date.getTime() <= cu_date.getTime()){
            return true
        }
        else {
            return false
        }
    }

    let principalComponent = (dbLabels) => (
        <Grid container spacing={2} style={{marginBottom: 20}}>
            {
                opTariffState.map((opTariff, idx) => (
                    <Grid item xs={12} key={idx}>
                    <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} >
                        <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                            <Typography>{opTariff.Name}</Typography>
                            {
                                opTariff.Published ? 
                                <DoneIcon fontSize="small" color="success" style={{marginLeft:10}}/>
                                :
                                <HourglassFullIcon fontSize="small" color="disabled" style={{marginLeft:10}}/>                                    
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container justify="space-evenly">
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Mercado : {props.market}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Periodo insumos: {props.periodDate.toISOString('MM-YYYY').slice(0, 7)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <List className={classes.accordionList}>
                                        <ListItem>   
                                            <Grid container justify="space-evenly">
                                                <Grid item xs={8}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Lista de insumos</Typography>                                                                                            
                                                    {inputsStateList(opTariff.Files, opTariff.FilesState, opTariff.FilesDate, opTariff.Parameters, opTariff.ParametersState, opTariff.ParametersDate)}
                                                </Grid>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                            </Grid>
                                        </ListItem>                                        
                                        <ListItem>
                                            <Grid container spacing={3} justify="space-evenly">
                                                <Grid item xs={11}>
                                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Control de publicación</Typography>                                                                                            
                                                </Grid>
                                                <Grid item xs={12}>  
                                                    {tariffOptionTable(opTariff.CompLabels, opTariff.CompValues, props.calcType, dbLabels)}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {                       
                                                        opTariff.CompValues[3][0][6] > 0 ?                                                                                         
                                                        <Grid container justify="space-evenly">                                                    
                                                        <Grid item>
                                                            <Button onClick={handleApvButtonClick(idx)} disabled={opTariff.Approved} color='primary' variant='contained'>Aprobar</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={(e) => {handlePublicationDialogOpen();}} color='primary' disabled={!opTariff.Approved || opTariff.Published} variant='contained'>Publicar</Button>
                                                        </Grid>
                                                        </Grid>
                                                        :
                                                        null
                                                    }                                                    
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>                                    
                                </Grid>
                            </Grid> 
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                ))
            }
        </Grid>
    )

    useEffect (() => {
        document.title ="Opción tarifaria";
        if (props.tariffCalcVersion !== "" &&  Object.keys(props.marketOpTariff).length > 0 && compareDates(props.marketOpTariff, props.periodDate)){
            authFetch("/api/calc-op-tariff?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear() + "&market=" + String( props.market ) + "&version=" + String( props.tariffCalcVersion )
            ).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.OpTariff){
                    ////console.log('Componentes', response.Components)
                    setOpTariffState(response.OpTariff)                   
                    props.setOpTariffPub(response.OpTariff[0].Published)
                }
            })
        }        
    }, [props.market, props.periodDate, props.tariffCalcVersion, props.marketOpTariff])

    return (
        <div className={classes.root}>
            <Grid container justify="center">
                <Zoom in timeout={700}>
                    <Grid item xs={12}>
                    {showNewOpTariffDialog()}
                    {showFeedbackDialog()}
                    {showPublicationDialog()}
                    {
                        Object.keys(props.marketOpTariff).length > 0 ? 
                            props.marketOpTariff.active || (props.marketOpTariff.finish_year <= props.periodDate.getFullYear() &&
                            opTariffState.length > 0 &&
                            'Levels' in opTariffState[0] &&
                            opTariffState[0].Levels.some(Boolean))?
                                props.tariffCalcVersion === '' ?                                                        
                                <Typography variant="subtitle1" className={classes.sectionTitle}>
                                No se ha generado una versión de cálculo para el periodo actual.</Typography>
                                :
                                compareDates(props.marketOpTariff, props.periodDate) ?                             
                                    <Fragment>
                                        {  opTariffState.length > 0  ? principalComponent(opTariffState[0].Levels) : null}
                                        {
                                            opTariffState.length > 0 && !opTariffState[0].Published ?
                                            <Fab
                                            color="primary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"                        
                                            classes={{root: classes.uploadButton}}
                                            onClick={() => {handleNewOpTariffDialogOpen(); updateOpTariffParams()}}
                                            style={{marginTop: 20}}
                                            > 
                                                <Fragment><EditIcon /> {'Editar opción tarifaria'}</Fragment>                                                          
                                            </Fab>  
                                            :
                                            null
                                        }
                                                                    
                                    </Fragment>                            
                                :
                                <Typography variant="subtitle1" className={classes.sectionTitle}>
                                Periodo de cálculo por fuera del inicio de la opción tarifaria actual.</Typography>     
                            :                                           
                            <Grid container style={{'marginTop': '20%'}} justify="space-evenly">
                                <Grid item xs={10}>
                                    <Typography variant="subtitle1" className={classes.sectionTitle}>
                                    Opción tarifaria inactiva en este mercado.</Typography>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <Fab
                                        color="primary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"                        
                                        classes={{root: classes.uploadButton}}
                                        onClick={() => {handleNewOpTariffDialogOpen(); updateOpTariffParams()}}
                                        style={{marginTop: 20}}
                                        > 
                                            <Fragment><EditIcon /> {'Editar opción tarifaria'}</Fragment>                                                          
                                    </Fab>   
                                </Grid>
                            </Grid>             
                        :
                        <Grid container style={{'marginTop': '20%'}} justify="space-evenly">
                            <Grid item xs={4}>
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={handleNewOpTariffDialogOpen}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {'Iniciar opción tarifaria'}</Fragment>                                                          
                            </Fab>  
                            </Grid>
                        </Grid> 
                    }                    
                    </Grid>
                </Zoom>
            </Grid>
            
        </div>
    )
}