import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox, IconButton} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';
import DragHandleIcon from '@material-ui/icons/DragHandle';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 13,
      color: theme.palette.primary.dark,
      padding: 5,
      backgroundColor: 'white'
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },    
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: 'white',        
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date() 
    const [clientsWithAgreedPrice, setClientsWithAgreedPrice] = useState([])
    const [clientPrices, setClientPrices] = useState([])
    const [clientName, setClientName] = useState("")    

    const [priceDialog, setPriceDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [clientPrice, setClientPrice] = useState({})
    const [clientCode, setClientCode] = useState("")        

    const handlepriceDialogOpen = () => {
        setPriceDialog(true);
      };
    
    const handlePriceDialogClose = () => {
        setPriceDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
 
    const handlePriceUpload = () => {        
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        let validationFlag = true
        if ( !clientPrice.price_calc_type){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Seleccione tipo de cálculo. Ingreso inválido.") 
            validationFlag = false           
        }   
        if (clientPrice.price_calc_type && clientPrice.price_calc_type[0] === '1' && !clientPrice.price_base_value){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Ingrese precio base. Ingreso inválido.") 
            validationFlag = false
        }
        if (clientPrice.price_calc_type && clientPrice.price_calc_type[0] === '2' && (!clientPrice.price_base_value || !clientPrice.indexation_base_value)){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Ingrese precio base y/o valor base de indexación. Ingreso inválido.") 
            validationFlag = false
        }        
        if (validationFlag) {            
            authFetch(
                "/api/update-user-agreed-prices",
                {
                  method: 'post',
                  body: JSON.stringify(clientPrice),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Precio pactado guardado.')
                    handleFeedbackDialogOpen()        
                    handlePriceDialogClose()    
                    checkUserPrice(clientCode)        
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
      }

    const checkUserPrice = (clientCode) => {
        authFetch("/api/check-user-agreed-prices?username=" + String(props.username) + '&client_code=' + clientCode).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.prices){                                        
                    if (!response.client_name){
                        setFeedbackMsg('Datos proporcionados incorrectos.')
                        setClientName("")
                        handleFeedbackDialogOpen()
                    }
                    else if ( response.prices.length === 0){
                        setFeedbackMsg('Cliente sin parámetros. Puede ingresar los datos correspondientes.')
                        setClientName(response.client_name)                        
                        handleFeedbackDialogOpen()
                    }
                    else if ( response.prices.length > 0 ){
                        setClientPrices(response.prices) 
                        setClientName(response.client_name)                                               
                    }
                }
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showPriceDialog = () => (
        <Dialog
            open={priceDialog}
            onClose={handlePriceDialogClose}                   
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Cliente:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientName}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código:</Typography>
                        </Grid>
                        <Grid item>
                            <DragHandleIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={clientPrice.client_code}                                
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}    
                                disabled                                                      
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Observación:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={clientPrice.observation}                             
                                placeholder={"Detalle del arreglo."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                multiline                                       
                                onChange={(e) => {setClientPrice({...clientPrice, observation: e.target.value});}}                       
                            />
                                
                        </Grid>                                                
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de cálculo precio pactado:</Typography>
                        </Grid>
                        <Grid item>
                            <AssessmentIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientPrice.price_calc_type} 
                                placeholder={"Seleccione el tipo de cálculo."}                               
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => setClientPrice({...clientPrice, price_calc_type: e.target.value})}
                            >
                                <MenuItem value={"1-Valor fijo"} className={classes.formItem}>1-Valor fijo</MenuItem>
                                <MenuItem value={"2-Valor indexado"} className={classes.formItem}>2-Valor indexado</MenuItem>                                
                            </TextField>
                                                       
                        </Grid>
                        {
                            clientPrice.price_calc_type ?
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Valor base del precio pactado:</Typography>
                            </Grid>
                            <Grid item>
                                <MonetizationOnIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={clientPrice.price_base_value}
                                    placeholder={"Ingrese el precio pactado."}
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}   
                                    onChange={(e) => {setClientPrice({...clientPrice, price_base_value: e.target.value});}}                            
                                />                           
                            </Grid> 
                            </Fragment>
                            :
                            null
                        }
                        {
                            clientPrice.price_calc_type && clientPrice.price_calc_type[0] === '2' ?
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Variable de indexación:</Typography>
                            </Grid>
                            <Grid item>
                                <MoreVertIcon/>
                            </Grid>
                            <Grid item xs={9}>                   
                                <TextField
                                    value={clientPrice.indexation_var} 
                                    placeholder={"Seleccione la variable de indexación."}                               
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText,                                       
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}                                                                                         
                                    select
                                    onChange = {(e) => {setClientPrice({...clientPrice, indexation_var: e.target.value});
                                                        }}
                                >
                                    <MenuItem value={"IPC"} className={classes.formItem}>IPC</MenuItem>
                                    <MenuItem value={"IPP"} className={classes.formItem}>IPP</MenuItem>
                                </TextField>
                                                        
                            </Grid>  
                            </Fragment>
                            :
                            null
                        }     
                        {
                            clientPrice.price_calc_type && clientPrice.indexation_var && clientPrice.price_calc_type[0] === '2' ? 
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">{'Valor base ' + clientPrice.indexation_var + ':'}</Typography>
                            </Grid>
                            <Grid item>
                                <ShowChartIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={clientPrice.indexation_base_value}
                                    placeholder={"Ingrese el valor base del " + clientPrice.indexation_var}
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}   
                                    onChange={(e) => {setClientPrice({...clientPrice, indexation_base_value: e.target.value});}}                            
                                />                           
                            </Grid>
                            </Fragment> 
                            :
                            null
                        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                    </Grid>                                       
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={2}>                                                                                                                     
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de inicio:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            fullWidth
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                    
                            value={clientPrice.start_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientPrice({...clientPrice, start_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de terminación:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            fullWidth
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                        
                            value={clientPrice.finish_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientPrice({...clientPrice, finish_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid> 
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Estado:</Typography>                    
                    </Grid>
                    <Grid item>
                        <Grid container justify="flex-start" style={{marginTop: -10}}>
                            <Grid item>
                            <Typography variant="body2" color="primary" style={{marginLeft: 10}}>{clientPrice.active? 'Activo':'Inactivo'}</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item>
                            <Switch
                                checked={clientPrice.active}
                                onChange={(e) => {setClientPrice({...clientPrice, active: e.target.checked});}}
                                color="primary"                                                 
                            />
                            </Grid>                                                        
                        </Grid>
                    </Grid>                                       
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handlePriceUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handlePriceDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Precios Pactados | Autogeneradores Cedenar"; 
        authFetch("/api/check-ag-agreed-prices?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.clients){                    
                    setClientsWithAgreedPrice(response.clients)                                          
                }
            })               
    }, [])

    return (
        <div className={classes.root}>
            {showPriceDialog()}
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                
                <Grid item xs={6}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de usuarios con precio pactado</Typography>
                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">                    
                        <Table aria-label="markets table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: '35%'}}>Código interno</StyledTableCell>
                                    <StyledTableCell align="center">Nombre</StyledTableCell>                                                                                     
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    clientsWithAgreedPrice.map((client) => (
                                        <TableRow>      
                                            <StyledTableCell align="center">{client.client_code}</StyledTableCell>
                                            <StyledTableCell align="center">{client.client_name}</StyledTableCell>                                                                                                                                                                                                                         
                                        </TableRow>
                                    ))
                                }                                
                            </TableBody>
                        </Table>                     
                    </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={6} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Parametrizar valor del precio pactado</Typography>
                </Grid>
                <Grid item xs={12}>                    
                    <Grid container justify="center" spacing={1}>
                        <Grid item xs={3}>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código)</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField 
                                            variant="outlined" 
                                            size="small"
                                            InputProps={{
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            className={classes.formRow} 
                                            value={clientCode}
                                            onChange={(e) => {setClientCode(e.target.value); setClientName(''); setClientPrices([]);}}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <IconButton color="primary" onClick={(e) => {checkUserPrice(clientCode)}}>
                                            <SearchOutlinedIcon className={classes.searchButton} />
                                        </IconButton>                                        
                                    </Grid> 
                                </Grid>                           
                            </Paper>
                        </Grid>   
                        <Grid item xs={12}>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "4%"}}>Acuerdo</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Código Cliente</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "18%"}}>Nombre</StyledTableCell>                                                                                                     
                                        <StyledTableCell align="center" style={{width: "15%"}}>Tipo de precio</StyledTableCell>                                 
                                        <StyledTableCell align="center" style={{width: "18%"}}>Observación</StyledTableCell> 
                                        <StyledTableCell align="center" style={{width: '13%'}}>Fecha incial</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: '13%'}}>Fecha final</StyledTableCell>   
                                        <StyledTableCell align="center" style={{width: '9%'}}>Estado</StyledTableCell>                                                                     
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>    
                                        {
                                            clientPrices.map( (price, idx) => (
                                            <TableRow onClick={(e) => {handlepriceDialogOpen(); setClientPrice(price);}}>
                                                <StyledTableCell align="center">{idx+1}</StyledTableCell>
                                                <StyledTableCell align="center">{price.client_code ?  price.client_code : clientCode}</StyledTableCell>
                                                <StyledTableCell align="center">{clientName}</StyledTableCell>
                                                <StyledTableCell align="center">{price.price_calc_type ? price.price_calc_type : '-'}</StyledTableCell>
                                                <StyledTableCell align="center">{price.observation ? price.observation : '-'}</StyledTableCell>   
                                                <StyledTableCell align="center">{price.start_date ? new Date(price.start_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>
                                                <StyledTableCell align="center">{price.finish_date ? new Date(price.finish_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>
                                                <StyledTableCell align="center">{price.active ? 'Activo' : 'Inactivo'}</StyledTableCell>                                                                    
                                            </TableRow>   
                                            ))                                                                                      
                                        }                                                                                                                                                         
                                    </TableBody>
                                </Table>                                                                                                                                         
                            </Paper>                            
                        </Grid>                     
                    </Grid> 
                </Grid>    
                <Grid item xs={3}>                    
                    {
                        clientName !== '' ?
                        <Fab
                            color="primary"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="extended"                        
                            classes={{root: classes.uploadButton}}
                            onClick={() => {
                                let clientPricesArray = clientPrices;
                                clientPricesArray.push({active: true, client_code: clientCode});
                                setClientPrices([...clientPricesArray]);                                                                                        
                            }}     
                            style={{marginTop: 15}}                           
                        > 
                            <Fragment><AddIcon /> {' Agregar  '}</Fragment>                         
                        </Fab> 
                        :
                        null
                    }                     
                </Grid>                                                                                               
            </Grid>   
            </Zoom>    
        </div>
    );
}