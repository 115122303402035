export const spectrumValidURLs = [    
    "/webspectrum/principal/consola-componentes",
    "/webspectrum/principal/arbol-tarifario",
    "/webspectrum/principal/opcion-tarifaria",
    "/webspectrum/principal/reliquidaciones",
    "/webspectrum/principal/publicar-tarifas",
    "/webspectrum/principal/validacion-archivos",
    "/webspectrum/principal/validacion-parametros",
    "/webspectrum/principal/no-regulados-precio-bolsa",
    "/webspectrum/principal/mercados",    
    "/webspectrum/principal/contratos",
    "/webspectrum/principal/actores-financieros",
    "/webspectrum/principal/ofertas-no-regulados",
    "/webspectrum/principal/balance-energetico", 
    "/webspectrum/principal/reportes-sui",
    "/webspectrum/tecnico-operativo/rev-tecnica-acta-oficio",
    "/webspectrum/tecnico-operativo/generar-orden",
    "/webspectrum/facturador/parametrizacion-ciclos",
    "/webspectrum/facturador/parametrizacion-mensual",
    "/webspectrum/facturador/parametrizacion-anual",
    "/webspectrum/facturador/parametrizacion-cuentas-bancarias",
    "/webspectrum/facturador/parametrizacion-operadores-de-red",
    "/webspectrum/facturador/parametrizacion-conceptos",
    "/webspectrum/facturador/parametrizacion-alumbrado-publico",
    "/webspectrum/facturador/parametrizacion-impuesto-seguridad",
    "/webspectrum/facturador/control-principal-clientes",
    "/webspectrum/facturador/cargar-consumos-ciclo",    
    "/webspectrum/facturador/informacion-consumos-cliente",
    "/webspectrum/facturador/liquidar-consumos",   
    "/webspectrum/facturador/liquidar-alumbrado",
    "/webspectrum/facturador/liquidar-impuesto-seguridad",
    "/webspectrum/facturador/cargar-otros-conceptos",
    "/webspectrum/facturador/conceptos-por-cliente",
    "/webspectrum/facturador/generar-facturas",
    "/webspectrum/facturador/reportes-internos",
    "/webspectrum/facturador/reportes-sui",
    "/webspectrum/facturador/cargar-info-agpe-gd",
    "/webspectrum/facturador/liquidar-agpe-dg",    
    "/login",    
]