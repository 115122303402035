const CEDENAR_COLOR_1 = "#2F58C2"
const CEDENAR_COLOR_2 = "#F2B80A"
const CEDENAR_COLOR_3 = "#FFFFFF"
const EMSA_COLOR_1 = "#286B1E"
const EMSA_COLOR_2 = "#4E6BC2"
const EMSA_COLOR_3 = "#EFEEED"
const LEGACY_COLOR_1 = "#5A7570"
//const SPECTRUM_COLOR_1 = "#318F6C"
//const SPECTRUM_COLOR_1 = "#5DDBC6"
const SPECTRUM_COLOR_2 = "#D6A51C"
const SPECTRUM_COLOR_3 = "#032e51"
const STELLAR_PRIMARY_COLOR = "#329E21"
//AUTOGENERADORES
const AG_BACKGROUND = "#22202E"
const AG_COLOR_1 = "#FFFFFF"
const AG_COLOR_2 = "#2E2B3E"
const AG_COLOR_3 = "#E06021"
const AG_BORDER = "#1C1B26"


export const getColors = (username, role) => {
  let primary = ""
  let client = ""
  let client_contrast = ""
  switch (username) {
    case 'Cedenar':
      switch (role) {
        case 'ag_client':
          primary = AG_COLOR_1
          client  = AG_COLOR_2
          client_contrast = AG_COLOR_1
          return [primary, client, client_contrast, AG_BACKGROUND, AG_COLOR_3, AG_BORDER]
        case 'ag_cons':
          primary = AG_COLOR_1
          client  = AG_COLOR_2
          client_contrast = AG_COLOR_1
          return [primary, client, client_contrast, AG_BACKGROUND, AG_COLOR_3, AG_BORDER]
        default:
          primary = LEGACY_COLOR_1
          client  = CEDENAR_COLOR_1
          client_contrast = CEDENAR_COLOR_3
          return [primary,client,client_contrast]
      }      
    case 'EMSA':
      primary = EMSA_COLOR_1
      client  = EMSA_COLOR_2
      client_contrast = EMSA_COLOR_3
      return [primary,client,client_contrast]
    case 'SPECTRUM':
      primary = LEGACY_COLOR_1
      client  = SPECTRUM_COLOR_2
      client_contrast = SPECTRUM_COLOR_3
      return [primary,client,client_contrast]
    default:
      primary = STELLAR_PRIMARY_COLOR
      client  = STELLAR_PRIMARY_COLOR
      client_contrast = STELLAR_PRIMARY_COLOR
      return [primary,client,client_contrast]
  }
}



