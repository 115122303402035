import {React, useEffect, useState, Fragment, useMemo, createContext, useContext, createElement} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DataGrid from 'react-data-grid';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    filterCell:{
        lineHeight: 35,
        padding: 0,
        '> div':{
            paddingBlock: 0,
            paddingInline: 8,
            '&:first-child': {
                borderBlockEnd: '1px solid black'
            }
        }
    },   
    tableText:{
        fontSize: 11,
        textAlign: 'left'
    },    
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        marginLeft: theme.spacing(1)
    },
    filterText: {
        fontSize: 11,
        color: 'white',
        textAlign: "left",        
        background: 'black'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

const FilterContext = createContext(undefined)

function HeaderFilter (props) {
    const filters = useContext(FilterContext)
    return(                        
        <input  className={props.filterInput} value={filters[props.field]} onChange={(e) => {            
            props.setFilters({...filters, [props.field]: e.target.value})
        }}></input> 
    )
}  

let CLIENT_FORM_SECTIONS = [
    {
        name: 'Datos básicos',
        formFields: [
            {
                label: 'Código SIC',                
                key: 'sic_code'
            },
            {
                label: 'Código Cliente',               
                key: 'client_code'
            },
            {
                label: 'Tipo de identificación',
                select: true,
                options: ['NIT','CC','NA'],
                key: 'id_type'
            },
            {
                label: 'Identificación',               
                key: 'id_number'
            },
            {
                label: 'Nombre/Razón social',               
                key: 'client_name'
            },
            {
                label: 'Contacto',               
                key: 'client_contact'
            },            
            {
                label: 'Teléfono',               
                key: 'phone'
            },
            {
                label: 'Correo electrónico',               
                key: 'email'
            },
            {
                label: 'Estrato',
                select: true,
                options: ['0','1','2','3','4','5','6'],
                key: 'stratum'
            },
            {
                label: 'Fecha de ingreso',
                type: 'date',                
                key: 'client_entry_date'
            },
            {
                label: 'Estado',
                type: 'switch',
                key: 'active'
            }
        ],
        icon: <AssignmentIndIcon/>
    },
    {
        name: 'Datos comerciales',
        formFields:[            
            {
                label: 'Tipo de mercado',
                select: true,
                options: ['REGULADO','NO REGULADO'],
                key: 'client_market_type'
            },
            {
                label: 'Tipo de uso',
                select: true,
                options: ['RESIDENCIAL','COMERCIAL','INDUSTRIAL','OFICIAL','ESPECIAL','PROVISIONAL','SALUD','ACUEDUCTO','DISTRITO DE RIEGO'],
                key: 'use_type'
            },
            {
                label: 'Tipo de tarifa',
                select: true,
                options: ['MONOMIA','HORARIA'],
                key: 'tariff_type'
            },
            {
                label: 'Código NIU',               
                key: 'niu_code'
            },            
            {
                label: '% de contribución',
                select: true,
                options: ['0','10','20','NA'],
                key: 'contrib_perc'
            },
            {
                label: '% subsidio de riego',                
                key: 'irr_sub_perc'
            },
            {
                label: 'Asesor',               
                key: 'adviser'
            },
            {
                label: 'Comercializador anterior',               
                key: 'prev_com'
            },
            {
                label: 'Ciclo',   
                select: true,
                options: [],            
                key: 'client_cycle'
            },
            {
                label: 'Cuenta bancaria', 
                select: true,
                options: [],              
                key: 'bank_account_id'
            },
            {
                label: 'CU Res. 119/2007 (NO=Opción Tarifaria)',               
                select: true,
                options: ['SI','NO','NA'],
                key: 'cu_form'
            },
            {
                label: 'Sobretasa ART. 313 L 1955/19',               
                select: true,
                options: ['SI','NO'],
                key: 'surcharge'
            },
        ],
        icon: <ReceiptIcon/>
    },
    {
        name: 'Datos de ubicación',
        formFields:[
            {
                label: 'Municipio Cliente',               
                key: 'client_municip'
            },
            {
                label: 'Departamento Cliente',               
                key: 'client_departm'
            },
            {
                label: 'Dirección Correspondencia',               
                key: 'corresp_address'
            },
            {
                label: 'Municipio Correspondencia',               
                key: 'corresp_municip'
            },
            {
                label: 'Departamento Correspondencia',               
                key: 'corresp_departm'
            },
            {
                label: 'Dirección Instalación',               
                key: 'inst_address'
            },
            {
                label: 'Municipio Instalación',               
                key: 'inst_municip'
            },
            {
                label: 'Departamento Instalación',               
                key: 'inst_departm'
            },
            {
                label: 'Código DANE',               
                key: 'dane_code'
            },
            {
                label: 'Área',
                select: true,
                options: ['U','R'],
                key: 'client_ubication'
            },
            {
                label: 'Mercado',
                select: true,
                options: [],
                key: 'client_market'
            },            
        ],
        icon: <PinDropIcon/>
    },
    {
        name: 'Datos técnicos/otros',
        formFields:[
            {
                label: 'Nivel de tensión',               
                select: true,
                options: ['1','Compartido','Usuario','2','3'],
                key: 'voltage_level'
            },
            {
                label: 'Tipo de red',               
                select: true,
                options: ['AEREA','SUBTERRANEA'],
                key: 'net_type'
            },
            {
                label: 'Propiedad de activos',               
                select: true,
                options: ['CLIENTE','COMPARTIDO','OPERADOR DE RED','OTROS'],
                key: 'asset_owner'
            },
            {
                label: 'Nivel de conexión',               
                select: true,
                options: ['1','2','3','4'],
                key: 'conn_level'
            },
            {
                label: 'Operador de red',               
                select: true,
                options: [],
                key: 'net_operator'
            },            
            {
                label: 'Subestación',               
                key: 'tech_substation'
            },
            {
                label: 'Grupo',               
                key: 'tech_group'
            },
            {
                label: 'Circuito',               
                key: 'tech_circuit'
            },
            {
                label: 'Medidor',               
                key: 'client_meter'
            },
            {
                label: 'Factor',               
                key: 'client_meter_factor'
            },
            {
                label: 'Contrato XM',               
                key: 'xm_contract'
            },
            {
                label: 'Información consumos',               
                select: true,
                options: ['1-Matriz','2-Manual'],
                key: 'consumption_source'
            },
            {
                label: 'Valor plan de datos',               
                key: 'data_plan_value'
            },
            {
                label: 'Valor telemedida',               
                key: 'telemeasure_value'
            },
            {
                label: 'Factor M',               
                key: 'm_factor'
            },
            {
                label: 'No. meses en exceso',               
                key: 'months_with_excess'
            },
            {
                label: 'No. meses consecutivos sin exceso',               
                key: 'consecutive_months_out_of_excess'
            },    
            {
                label: 'No. meses consecutivos con M=6',               
                key: 'consecutive_months_m6'
            },                    
        ],
        icon: <SettingsInputAntennaIcon/>
    }
]

export default function ClientControl (props) {
    const classes = useStyles();    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [clientInfoDialog, setClientInfoDialog] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [userType, setUserType] = useState("NO REGULADO")
    const [showInactive, setShowInactive] = useState("NO")
    const [netOperatorsCode, setNetOperatorsCode] = useState([])
    const [cycles, setCycles] = useState([])
    const [bankAccounts, setBankAccounts] = useState([])
    const [clientInfo, setClientInfo] = useState({active: true})
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
        
    let billingDate = props.billingDate   
    const [filters, setFilters] = useState({
        client_name: '',
        client_code: '',
        sic_code: '',       
        use_type: '',
        client_market: '',
        client_cycle: '',
        surcharge: '',
        cu_form: '',       
    })
    const [columns, setColumns] = useState(
        [
            {key: 'client_name', width: 250, frozen: true},
            {key: 'client_code', width: 250,},
            {key: 'sic_code', width: 250},            
            {key: 'use_type', width: 250},
            {key: 'client_market', width: 250},
            {key: 'client_cycle', width: 250},
            {key: 'surcharge', width: 250},
            {key: 'cu_form', width: 250}
        ]
    )    

    const comerSec = CLIENT_FORM_SECTIONS.find(obj => obj.name === 'Datos comerciales')
    const cycleObj = comerSec.formFields.find(obj => obj.label === 'Ciclo')
    cycleObj.options = cycles
    const bankAccountObj = comerSec.formFields.find(obj => obj.label === 'Cuenta bancaria')
    bankAccountObj.options = bankAccounts

    const ubicationSec = CLIENT_FORM_SECTIONS.find(obj => obj.name === 'Datos de ubicación')
    const marketObj = ubicationSec.formFields.find(obj => obj.label === 'Mercado')
    marketObj.options = props.markets

    const comerSection = CLIENT_FORM_SECTIONS.find(obj => obj.name === 'Datos técnicos/otros')
    const netOpObj = comerSection.formFields.find(obj => obj.label === 'Operador de red')
    netOpObj.options = netOperatorsCode

    columns[0].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_name} field={'client_name'}>        
        </HeaderFilter>
        )
    columns[1].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_code} field={'client_code'}>        
        </HeaderFilter>)
    columns[2].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.sic_code} field={'sic_code'}>        
        </HeaderFilter>)
    columns[3].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.use_type} field={'use_type'}>        
        </HeaderFilter>)
    columns[4].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_market} field={'client_market'}>        
        </HeaderFilter>)
    columns[5].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_cycle} field={'client_cycle'}>        
        </HeaderFilter>)
    columns[6].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.surcharge} field={'surcharge'}>        
        </HeaderFilter>)
    columns[7].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.cu_form} field={'cu_form'}>        
        </HeaderFilter>)
    const [titles, setTitles] = useState([
        {
            client_name: <b>Nombre/Razón Social</b>,
            client_code: <b>Código del cliente</b>,
            sic_code: <b>Código SIC</b>,            
            use_type: <b>Tipo de uso</b>,
            client_market: <b>Mercado</b>,
            client_cycle: <b>Ciclo</b>,
            surcharge: <b>Aplica Sobretasa</b>,
            cu_form: <b>CU Res 119/OT</b>,
            frozen: true
        }
    ])
    const [rows, setRows] = useState([])

    
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handleClientInfoDialogOpen = () => {
        setClientInfoDialog(true);
      };
    
    const handleClientInfoDialogClose = () => {
        setClientInfoDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const downloadClients = () => {
        setIsLoading(true)
        authFetch("/api/clients-list-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&username=" + String(props.username),
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Clientes_Spectrum'+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    const updateValuesWithSelectedClient = (value) => {
        if (value === -1){
            setClientInfo({active: true})
        }
        else{
            setClientInfo(value)
        }
    }

    const uploadClientInfo = () => {
        // PENDIENTE VALIDACION DE CAMPOS PARA INGRESO VALIDO DE CLIENTES
        // Y QUE CAMPOS NO SE PUEDEN MODIFICAR DESPUES DEL INGRESO
        // //console.log(anualParams)
        // if (Object.keys(anualParams).length < 5){
        //     handleFeedbackDialogOpen()
        //     setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        // }       
        authFetch(
            "/api/update-clients?username=" + String(props.username),
            {
                method: 'post',
                body: JSON.stringify(clientInfo),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información en la lista.')
                    handleFeedbackDialogOpen()
                    authFetch("/api/check-clients?username=" + String(props.username)+                                                  
                                                    "&year="+ billingDate.getFullYear()).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.clients){                                       
                                setRows(response.clients)                    
                            }
                        }) 
                }
                else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                }
            })
        
    }

    const showClientInfoDialog = () => (
        <Dialog
            open={clientInfoDialog}
            onClose={handleClientInfoDialogClose}       
            className={classes.formText}  
            fullWidth
            scroll={'paper'}
            maxWidth={'md'}          
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Ingresar/Revisar la información del cliente</DialogTitle>
        <DialogContent className={classes.dialogContent}> 
            <Grid container spacing={2} justify="space-evenly" style={{padding: 10}}>
                {
                    CLIENT_FORM_SECTIONS.map((section, pidx) => (
                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === pidx} onChange={handlePanelChange(pidx)} >
                                <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                                    {section.icon}
                                    <Typography style={{marginLeft: 10}}>{section.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            section.formFields.map((formField) => (
                                            <Grid item xs={3}>
                                                <Typography color="primary" variant="subtitle2">{formField.label}</Typography>
                                                {
                                                    formField.type !== 'date' ? 
                                                        formField.type !== 'switch' ?
                                                        <TextField       
                                                            variant="standard"                                                        
                                                            value={clientInfo[formField.key]}                                
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.formText
                                                                }
                                                            }}                                                    
                                                            className={classes.formRow}   
                                                            onChange={(e) => {setClientInfo({...clientInfo, [formField.key]: e.target.value});}}     
                                                            select={formField.select}                      
                                                        >
                                                            {
                                                                formField.select ? 
                                                                formField.label !== 'Cuenta bancaria' ?
                                                                    formField.options.map((option, oidx) => (
                                                                        <MenuItem value={option} key={oidx}>{option}</MenuItem>
                                                                    ))
                                                                    :
                                                                    formField.options.map((option, oidx) => (
                                                                        <MenuItem value={option.id} key={oidx}>{option.bank + ': ' + option.ac_number}</MenuItem>
                                                                    ))
                                                                :
                                                                null
                                                            }                                                                                  
                                                        </TextField>
                                                        :
                                                        <Fragment>
                                                            <Switch
                                                                checked={clientInfo[formField.key]}
                                                                onChange={(e) => {setClientInfo({...clientInfo, [formField.key]: e.target.checked});}}
                                                                color="primary"                                                 
                                                            />
                                                            {clientInfo[formField.key]? 'Activo':'Inactivo'}
                                                        </Fragment>
                                                    :
                                                    <DatePicker    
                                                        variant="dialog"    
                                                        inputVariant="standard"                                                                                 
                                                        okLabel={""}
                                                        cancelLabel={""}         
                                                        format={"dd-MMMM-yyyy"}    
                                                        fullWidth                                                                                                                                                                             
                                                        value={clientInfo[formField.key]}
                                                        InputLabelProps={{                                            
                                                                    classes: {
                                                                        root: classes.labelText,                                    
                                                                    }
                                                                }}
                                                        onChange={(date) => {setClientInfo({...clientInfo, [formField.key]: date});}}
                                                        error={false}
                                                        InputProps={{                                            
                                                                    classes: {
                                                                        input: classes.formText,                                    
                                                                    }
                                                                }}
                                                    
                                                        autoOk                                                                                  
                                                    />
                                                }
                                                
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))
                }
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={(e) => {handleClientInfoDialogClose(); uploadClientInfo();}}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleClientInfoDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>           
        </DialogContent>        
        </Dialog>
    )
 

    const filteredRows = useMemo(() => {
        return titles.concat(rows.filter((r) => 
            (filters.client_code ? r.client_code.toLowerCase().includes(filters.client_code.toLowerCase()) : true) &&
            (filters.sic_code ? r.sic_code.toLowerCase().includes(filters.sic_code.toLowerCase()) : true) &&
            (filters.client_name ? r.client_name.toLowerCase().includes(filters.client_name.toLowerCase()) : true) &&
            (filters.use_type ? r.use_type.toLowerCase().includes(filters.use_type.toLowerCase()) : true) &&
            (filters.client_market ? r.client_market.toLowerCase().includes(filters.client_market.toLowerCase()) : true) &&
            (filters.client_cycle ? r.client_cycle.toLowerCase().includes(filters.client_cycle.toLowerCase()) : true) &&
            (filters.surcharge ? r.surcharge.toLowerCase().includes(filters.surcharge.toLowerCase()) : true) &&
            (filters.cu_form ? r.cu_form.toLowerCase().includes(filters.cu_form.toLowerCase()) : true) &&
            (r.client_market_type === userType) &&
            (showInactive === 'SI' ? true : r.active)
        ))
    }, [rows, filters, userType, showInactive])
  
    
    useEffect (() => {
        document.title ="Control principal clientes";                    
        authFetch("/api/check-clients?username=" + String(props.username)+                                                  
                                                  "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.clients){                                       
                    setRows(response.clients)                    
                }
            }) 
        authFetch("/api/check-net-operators").then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.net_operators){     
                    let codes = response.net_operators.flatMap(op => op.active ? op.cmc_inc_code : [])  
                    setNetOperatorsCode(codes)                                               
                }
            }) 
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){
                    let cycleNames = response.cycles.map((cycle) => cycle.code)
                    setCycles(cycleNames)                                        
                }
            }) 
        authFetch("/api/check-financial-actors?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.financial_actors){
                    let bankAccounts = response.financial_actors.flatMap((actor) => actor.type === 'EMISOR' ? {id: actor.financial_actor_id,...JSON.parse(actor.financial_actor_data)}: [])
                    setBankAccounts(bankAccounts)                                                       
                }
            })
    }, [])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}
            {showClientInfoDialog()}
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly" spacing={1}>
                    
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Control de clientes
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1} justify="center">
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2" align="center" color="primary">Tipo de cliente</Typography>
                                </Grid>                                
                                <Grid item xs={8}>
                                    <TextField                                                               
                                        value={userType}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setUserType(e.target.value);}}     
                                        select                      
                                    >                                        
                                        <MenuItem value={"NO REGULADO"}>NO REGULADO</MenuItem>                                
                                    </TextField>
                                </Grid>                                
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1} justify="center">                               
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2" align="center" color="primary">Mostrar inactivos</Typography>
                                </Grid>                                
                                <Grid item xs={8}>
                                    <TextField                                                               
                                        value={showInactive}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setShowInactive(e.target.value);}}     
                                        select                      
                                    >
                                        <MenuItem value={"NO"}>NO</MenuItem>
                                        <MenuItem value={"SI"}>SI</MenuItem>                                
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={3}>
                            <Fab
                                color="secondary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                style={{marginBottom:10}}
                                onClick={(e) => {updateValuesWithSelectedClient(-1); handleClientInfoDialogOpen();}}                                
                            > 
                                <Fragment><AddIcon /> {'Agregar'}</Fragment>                         
                            </Fab>
                        </Grid>                               
                        <Grid item xs={11}>
                            {
                                rows.length === 0 ?
                                <CircularProgress/>
                                :
                                <FilterContext.Provider value={filters}>
                                <Typography variant="subtitle2" align="center" color="primary">Total clientes: {filteredRows.length - 1}</Typography>
                                    <DataGrid columns={columns} 
                                        rows={filteredRows}
                                        headerRowHeight={35}    
                                        className={'rdg-light'} 
                                        style={{color: '#606B6A', height: 400, fontSize: 13}}                              
                                        onRowClick={(row) => {
                                            if (typeof(row.client_code) === 'string'){updateValuesWithSelectedClient(row); handleClientInfoDialogOpen();}}}
                                    />
                                </FilterContext.Provider>
                            }
                            
                        </Grid>      
                        <Grid item xs={3}>
                            {
                                isLoading ? 
                                <CircularProgress/>
                                :
                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"                        
                                    classes={{root: classes.uploadButton}}
                                    onClick={downloadClients}
                                    style={{marginTop: 20}}
                                > 
                                    <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                                </Fab>
                            }
                            
                        </Grid>                            
                </Grid>
            </Zoom>
        </div>
    )
}