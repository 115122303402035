import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

export default function AnualParam (props) {
    const classes = useStyles();
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [anualParams, setAnualParams] = useState({})    
    let billingDate = props.billingDate

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const uploadAnualParamInfo = () => {
        //console.log(anualParams)
        if (Object.keys(anualParams).length < 5){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }
        else {
            anualParams['username'] = props.username
            anualParams['billing_year'] = billingDate.getFullYear()
            authFetch(
                "/api/update-billing-anual-params",
                {
                  method: 'post',
                  body: JSON.stringify(anualParams),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información de los campos.')
                    handleFeedbackDialogOpen()
                    authFetch("/api/check-billing-anual-parameters?username=" + String(props.username)+                                                  
                                                  "&year="+ billingDate.getFullYear()).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.anual_params){
                                if (Object.keys(response.anual_params).length > 0){
                                    setAnualParams(response.anual_params)
                                }                                      
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
    }

    useEffect (() => {
        document.title ="Parametrización anual";                    
        authFetch("/api/check-billing-anual-parameters?username=" + String(props.username)+                                                  
                                                  "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.anual_params){
                    //console.log(response.anual_params)
                    if (Object.keys(response.anual_params).length > 0){
                        setAnualParams(response.anual_params)
                    }                                      
                }
            })        
    }, [props.billingDate])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly">
                    
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Parametrización anual
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>                                
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" color="primary">% Contribución estratos 5 y 6:</Typography>
                                </Grid>
                                <Grid item>
                                    <GavelIcon color="primary"/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField    
                                        type={'number'}                            
                                        value={anualParams['est_56_contr']}                                    
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setAnualParams({...anualParams, est_56_contr: e.target.value});}}                             
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" color="primary">% Contribución Industrial, Comercial y Provisional:</Typography>
                                </Grid>
                                <Grid item>
                                    <GavelIcon color="primary"/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField    
                                        type={'number'}                            
                                        value={anualParams['com_oth_contr']}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setAnualParams({...anualParams, com_oth_contr: e.target.value});}}                          
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" color="primary">Valor UVT:</Typography>
                                </Grid>
                                <Grid item>
                                    <AttachMoneyIcon color="primary"/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField    
                                        type={'number'}                            
                                        value={anualParams['uvt']}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setAnualParams({...anualParams, uvt: e.target.value});}}                            
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" color="primary">Valor Salario Mínimo:</Typography>
                                </Grid>
                                <Grid item>
                                    <AttachMoneyIcon color="primary"/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField    
                                        type={'number'}                            
                                        value={anualParams['min_sal']}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setAnualParams({...anualParams, min_sal: e.target.value});}}                            
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left" color="primary">% IVA:</Typography>
                                </Grid>
                                <Grid item>
                                    <CodeIcon color="primary"/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField    
                                        type={'number'}                            
                                        value={anualParams['iva']}                                
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setAnualParams({...anualParams, iva: e.target.value});}}                           
                                    />
                                </Grid>                                
                            </Grid>
                        </Grid>   
                        <Grid item xs={12}></Grid>
                        <Grid item>
                            <Fab
                                color="secondary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={uploadAnualParamInfo}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                            </Fab>
                        </Grid>                                          
                </Grid>
            </Zoom>
        </div>
    )
}