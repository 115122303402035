import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox, IconButton, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';
import DragHandleIcon from '@material-ui/icons/DragHandle';


const StyledTableCell = withStyles((theme) => ({
    head: {
      fontSize: 12,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 1
    },
    body: {
      fontSize: 13,
      color: theme.palette.primary.dark,
      padding: 5,
      backgroundColor: 'white'
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },    
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: 'white',        
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date() 
    const [clientsWithAgreedPrice, setClientsWithAgreedPrice] = useState([])
    const [clientPrices, setClientPrices] = useState([])
    const [clientName, setClientName] = useState("")    

    const [priceDialog, setPriceDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [clientPrice, setClientPrice] = useState({})
    const [clientCode, setClientCode] = useState("")    
    const [downloading, setDownloading] = useState(false)
    const [downloadingLect, setDownloadingLect] = useState(false)
    const [downloadingRep, setDownloadingRep] = useState(false)
    const [downloadingPb, setDownloadingPb] = useState(false)
    const [consumptionApproved, setConsumptionApproved] = useState(false)
    const [pbReady, setPbReady] = useState(false)
    let billingDate = props.billingDate    

    const handlepriceDialogOpen = () => {
        setPriceDialog(true);
      };
    
    const handlePriceDialogClose = () => {
        setPriceDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
 

    const downloadConsumption = () => {
        setDownloading(true)
        authFetch(
            "/api/consumption-users-osf-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear(),
            { responseType: 'blob' }         
          ).then(response => {
            setDownloading(false);
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status === 200){
                return response.blob()
            } 
            }).then(blob => {                              
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'ARCHIVO_CONSUMOS_'+String( billingDate.getMonth() + 1 )+'_'+billingDate.getFullYear()+'.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);   
                setDownloading(false);                                            
            })
    }
    
    const downloadReport = () => {
        setDownloadingRep(true)
        authFetch(
            "/api/consumption-report-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear(),
            { responseType: 'blob' }         
          ).then(response => {
            setDownloadingRep(false);
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status === 200){
                return response.blob()
            } 
            }).then(blob => {                              
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'REPORTE_MENSUAL_'+String( billingDate.getMonth() + 1 )+'_'+billingDate.getFullYear()+'.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);   
                setDownloadingRep(false);                                            
            })
    }

    const downloadLectures = () => {
        setDownloadingLect(true)
        authFetch(
            "/api/lectures-users-osf-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear(),
            { responseType: 'blob' }         
          ).then(response => {
            setDownloadingLect(false);
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status === 200){
                return response.blob()
            } 
            }).then(blob => {                              
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'ARCHIVO_LECTURAS_'+String( billingDate.getMonth() + 1 )+'_'+billingDate.getFullYear()+'.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);   
                setDownloadingLect(false);                                            
            })
    }

    const downloadPb = () => {
        setDownloadingPb(true)
        authFetch("/api/download-input-file?username=" + props.username +
                                           '&period=' + String( billingDate.getMonth() + 1 )
                                           +"&year="+ billingDate.getFullYear() + 
                                           "&version="+ 'def' +
                                           "&xmVersion="+ '.txf' +
                                           "&filename="+ 'TRSD' + "&timestamp=" + new Date().toISOString().slice(0,19),
                                           { responseType: 'blob' } 
        ).then(response => {
          setDownloadingPb(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {     
            let period = billingDate.getMonth() + 1
            let filePeriod = String(period).length <= 1 ? '0'+period:period                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Precio de bolsa'+'_'+filePeriod+'_'+billingDate.getFullYear()+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 

    useEffect (() => {
        document.title ="Consumos | Autogeneradores Cedenar"; 
        authFetch("/api/check-consumption-in-period?username=" + String(props.username)+
            "&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.clients){                    
                    setClientsWithAgreedPrice(response.clients)                                          
                }
            })     
        authFetch(
            "/api/check-ag-liquidation-status?username="+props.username+
                            "&month="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear()
        )
        .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response && response.liquidation_approved){                                     
                setConsumptionApproved(response.liquidation_approved[2])               
            }            
        })          
        authFetch(
            "/api/check-pb-status?username="+props.username+
                            "&month="+String( props.billingDate.getMonth() + 1 )+                              
                            "&year="+ props.billingDate.getFullYear() + 
                            "&process=only_trsd"
        )
        .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response && 'pb_flag' in response){                                 
                setPbReady(response.pb_flag)
            }            
        }
        )
    }, [props.billingDate])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly" spacing={2}>                
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Usuarios con consumo cargado</Typography>
                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">   
                        <TableContainer>        
                        <Table aria-label="markets table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Código interno</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "25%"}}>Nombre</StyledTableCell>                                                                                     
                                    <StyledTableCell align="center">Energía activa</StyledTableCell>
                                    <StyledTableCell align="center">Energía inductiva pen.</StyledTableCell>
                                    <StyledTableCell align="center">Energía capactiva</StyledTableCell>
                                    <StyledTableCell align="center">Gen. Exc1</StyledTableCell>
                                    <StyledTableCell align="center">Gen. Exc2</StyledTableCell>
                                    <StyledTableCell align="center">Gen. ExcT</StyledTableCell>
                                    <StyledTableCell align="center">Días penalizando inductiva</StyledTableCell>
                                    <StyledTableCell align="center">Dias penalizando capactiva</StyledTableCell>
                                    <StyledTableCell align="center">Factor M</StyledTableCell>
                                    {/* <StyledTableCell align="center">Meses con exceso de reactiva</StyledTableCell>
                                    <StyledTableCell align="center">Meses consecutivos sin penalizar</StyledTableCell>                                    
                                    <StyledTableCell align="center">Meses consecutivos en M=6</StyledTableCell>
                                    <StyledTableCell align="center">Crítica</StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    clientsWithAgreedPrice.map((client) => (
                                        <TableRow>      
                                            <StyledTableCell align="center">{client.client_code}</StyledTableCell>
                                            <StyledTableCell align="center">{client.client_name}</StyledTableCell>
                                            <StyledTableCell align="center">{client.active_energy.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>
                                            <StyledTableCell align="center">{client.reactive_ind_pen_energy.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>                                                                                                                                                                                                                     
                                            <StyledTableCell align="center">{client.reactive_cap_energy.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>
                                            <StyledTableCell align="center">{client.gen_exc1.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>
                                            <StyledTableCell align="center">{client.gen_exc2.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>
                                            <StyledTableCell align="center">{client.gen_exct.toLocaleString('en-US',{minimumFractionDigits: 2})}</StyledTableCell>
                                            <StyledTableCell align="center">{client.month_days_with_ind_excess}</StyledTableCell>
                                            <StyledTableCell align="center">{client.month_days_with_cap_excess}</StyledTableCell>
                                            <StyledTableCell align="center">{client.m_factor}</StyledTableCell>
                                            {/* <StyledTableCell align="center">{client.months_with_reactive_excess}</StyledTableCell>
                                            <StyledTableCell align="center">{client.consecutive_months_without_excess}</StyledTableCell>
                                            <StyledTableCell align="center">{client.months_with_m_6}</StyledTableCell>
                                            <StyledTableCell align="center">{<IconButton><AddIcon/></IconButton>}</StyledTableCell> */}
                                        </TableRow>
                                    ))
                                }                                
                            </TableBody>
                        </Table>        
                        </TableContainer>             
                    </Paper>
                </Grid>                                      
                <Grid item xs={4}>
                    {
                        downloadingRep ? 
                        <CircularProgress/>
                        :
                        <Button
                            variant="contained"
                            onClick={() => {downloadReport();}}
                            disabled={!consumptionApproved}
                            color="primary"
                        >
                            Descargar reporte
                        </Button>
                    }                    
                </Grid>  
                <Grid item xs={4}>
                    {
                        downloading ? 
                        <CircularProgress/>
                        :
                        <Button
                            variant="contained"
                            onClick={() => {downloadConsumption();}}
                            disabled={!consumptionApproved}
                            color="primary"
                        >
                            Exportar consumos
                        </Button>
                    }
                    
                </Grid>
                <Grid item xs={4}>
                    {         
                        downloadingLect ? 
                        <CircularProgress/>
                        :               
                        <Button
                            variant="contained"
                            disabled={!consumptionApproved}
                            onClick={() => {downloadLectures();}}
                            color="primary"
                        >
                            Exportar lecturas
                        </Button>
                    }
                    
                </Grid>  
                <Grid item xs={4}>
                    {         
                        downloadingPb ? 
                        <CircularProgress/>
                        :               
                        <Button
                            variant="contained"
                            disabled={!pbReady}
                            onClick={() => {downloadPb();}}
                            color="primary"
                        >
                            Descargar PB
                        </Button>
                    }
                    
                </Grid>                                                                                   
            </Grid>   
            </Zoom>    
        </div>
    );
}