import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, 
        FormGroup, FormControlLabel, Checkbox, FormControl, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Agent from '@material-ui/icons/SortByAlpha';
import WavesIcon from '@material-ui/icons/Waves';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import closestIndexTo from 'date-fns/closestIndexTo/index.js';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    accordionStyle:{
        backgroundColor: 'white',
        color:  theme.palette.primary.main,
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    dialogStyle:{
        background: theme.palette.secondary.light,
    },
    formGroupLabel: {
        fontSize: 14,
    },    
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: 0,
    },
    formText2: {
        fontSize: 13,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: 0,
    },
    labelText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",        
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    uploadButton2 : {
        fontSize: 11,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [actorDialog, setActorDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    
    // Estructuras alumbrado    
    const [selectedDepartmentName, setSelectedDepartmentName] = useState("")
    const [departmentList, setDepartmentList] = useState([])
    const [munipForms, setMunipForms] = useState([])

    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleActorDialogOpen = () => {
        setActorDialog(true);
      };
    
    const handleActorDialogClose = () => {
        setActorDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedActor = (agreements) => {
        setMunipForms([...agreements])
    }

    const handleUseTypesChange = (idx) => (e) => {
        if (e.target.checked){
            let auxMunipForms = munipForms
            let auxObjForm = munipForms[idx]
            auxObjForm['use_types'].includes(e.target.name) ? auxMunipForms[idx] = auxObjForm : auxObjForm['use_types'].push(e.target.name)
            auxMunipForms[idx] = auxObjForm
            setMunipForms([...auxMunipForms])
        }
        else{
            let auxMunipForms = munipForms
            let auxObjForm = munipForms[idx]
            auxObjForm['use_types'].includes(e.target.name) ? auxObjForm['use_types'].pop(e.target.name) : auxMunipForms[idx] = auxObjForm            
            auxMunipForms[idx] = auxObjForm
            setMunipForms([...auxMunipForms])
        }
    }
 
    const handleActorUpload = (idx) => (e) => {    
        let uploadObject = munipForms[idx]    
        if (uploadObject.use_types.length === 0 || !uploadObject.collect_var || !uploadObject.collect_type  || 
            uploadObject.collect_var.includes('RANGO') && uploadObject.collect_params.length === 0 || 
            uploadObject.collect_var.includes('FIJA') && !uploadObject.collect_params[0].value){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else{
            authFetch(
                "/api/update-department-agreements",
                {
                  method: 'post',
                  body: JSON.stringify(uploadObject),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la tabla.')
                    handleFeedbackDialogOpen()
                    handleActorDialogClose()
                    setIsLoading(true)
                    authFetch("/api/check-department-agreements?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.department_agreements){
                                setDepartmentList(response.department_agreements)    
                                setIsLoading(false)                                    
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
             
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showActorDialog = () => (
        <Dialog
            open={actorDialog}
            onClose={() => {handleActorDialogClose(); setExpanded(-1);}}       
            classes={{
                paper: classes.dialogStyle
            }}
            scroll={'paper'}
            maxWidth={'md'}
            fullWidth
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">            
                {
                    munipForms.map((formObj, pidx) => (
                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === pidx} onChange={handlePanelChange(pidx)} >
                                <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                                    <ListAltIcon/>
                                    <Typography style={{marginLeft: 10}}>{'Formulario de cobro ' + String(pidx + 1)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} justify="space-evenly">                                        
                                        <Grid item xs={2}>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" align="left">Estado:</Typography>
                                                </Grid>
                                                <Grid item xs={12}>                                                    
                                                    <Switch
                                                        checked={formObj.active}
                                                        onChange={(e) => {
                                                                let auxMunipForms = munipForms;                                                              
                                                                auxMunipForms[pidx]['active'] = e.target.checked;
                                                                setMunipForms([...auxMunipForms]);
                                                        }}
                                                        color="primary"                                                 
                                                    />
                                                    {formObj.active ? 'Activo':'Inactivo'}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" align="left">Departamento:</Typography>
                                                </Grid>                                                
                                                <Grid item xs={12}>
                                                    <TextField                                                    
                                                        value={formObj.department_name}                                                        
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        variant="standard"
                                                        className={classes.formRow}                                                           
                                                        disabled                           
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" align="left">Tipos de uso:</Typography>
                                                </Grid>                                                               
                                                <Grid item xs={12} style={{fontSize: 10}}> 
                                                    <FormControl>
                                                        <FormGroup classes={{root: classes.formGroup}}>
                                                            <FormControlLabel
                                                                control={<Checkbox 
                                                                          checked={formObj.use_types.includes('INDUSTRIAL')} 
                                                                          size="small" color="primary" name="INDUSTRIAL" onChange={handleUseTypesChange(pidx)}/>}
                                                                label="Industrial" 
                                                                classes={{label: classes.formGroupLabel}}                                                          
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox 
                                                                          checked={formObj.use_types.includes('COMERCIAL')} 
                                                                          size="small" color="primary" name="COMERCIAL" onChange={handleUseTypesChange(pidx)}/>}
                                                                label="Comercial"   
                                                                classes={{label: classes.formGroupLabel}}                                                         
                                                            />                                                            
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>       
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" align="left">Inicio vigencia:</Typography>
                                                </Grid>                                                             
                                                <Grid item xs={12}>    
                                                    <DatePicker    
                                                        variant="dialog"    
                                                        inputVariant="standard"                                                                                 
                                                        okLabel={""}
                                                        cancelLabel={""}                            
                                                        format="dd-MMMM-yyyy"
                                                        //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                                        //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                        
                                                        value={formObj.start_date}
                                                        InputLabelProps={{                                            
                                                                    classes: {
                                                                        root: classes.labelText,                                    
                                                                    }
                                                                }}
                                                        onChange={(date) => {
                                                            let auxMunipForms = munipForms;                                                              
                                                            auxMunipForms[pidx]['start_date'] = date;
                                                            delete auxMunipForms[pidx].collect_id
                                                            setMunipForms([...auxMunipForms]);
                                                        }}
                                                        error={false}
                                                        InputProps={{                                            
                                                                    classes: {
                                                                        input: classes.formText,                                    
                                                                    }
                                                                }}
                                                    
                                                        autoOk                                                                                  
                                                    />                                                                  
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" align="left">Finalización vigencia:</Typography>
                                                </Grid>                                                             
                                                <Grid item xs={12}>    
                                                    <DatePicker    
                                                        variant="dialog"    
                                                        inputVariant="standard"                                                                                 
                                                        okLabel={""}
                                                        cancelLabel={""}                            
                                                        format="dd-MMMM-yyyy"
                                                        //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                                        //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                        
                                                        value={formObj.finish_date}
                                                        InputLabelProps={{                                            
                                                                    classes: {
                                                                        root: classes.labelText,                                    
                                                                    }
                                                                }}
                                                        onChange={(date) => {
                                                            let auxMunipForms = munipForms;                                                              
                                                            auxMunipForms[pidx]['finish_date'] = date;
                                                            delete auxMunipForms[pidx].collect_id
                                                            setMunipForms([...auxMunipForms]);
                                                        }}
                                                        error={false}
                                                        InputProps={{                                            
                                                                    classes: {
                                                                        input: classes.formText,                                    
                                                                    }
                                                                }}
                                                    
                                                        autoOk                                                                                  
                                                    />                                                                  
                                                </Grid>                                             
                                                   
                                                                    
                                            </Grid>                                       
                                        </Grid>                                          
                                        <Grid item xs={10}>                                          
                                            <Grid container spacing={2} justify="space-evenly"> 
                                                <Grid item xs={1}></Grid>                                                
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle2" align="center">Variable de cobro:</Typography>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle2" align="center">Tipo de cobro:</Typography>
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={1}></Grid>
                                                <Grid item>
                                                    <EqualizerIcon/>
                                                </Grid>                                                                
                                                <Grid item xs={3}>                   
                                                    <TextField
                                                        value={formObj.collect_var}                                                        
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        variant="standard"
                                                        className={classes.formRow}      
                                                        onChange={(e) => {
                                                                let auxMunipForms = munipForms;                                                              
                                                                auxMunipForms[pidx]['collect_var'] = e.target.value;
                                                                e.target.value.includes('RANGO') ? auxMunipForms[pidx]['collect_params'] = [] : auxMunipForms[pidx]['collect_params'] = [{value: null}];                                                                    
                                                                setMunipForms([...auxMunipForms]);
                                                        }}     
                                                        select                      
                                                    >
                                                        <MenuItem value={"RANGO_PESOS"}>Rango $</MenuItem>
                                                        <MenuItem value={"RANGO_CONSUMO"}>Rango kWh</MenuItem>    
                                                        <MenuItem value={"FIJA"}>Fija</MenuItem>                                                        
                                                    </TextField>
                                                </Grid> 
                                                <Grid item xs={2}></Grid>    
                                                <Grid item>
                                                    <MonetizationOnIcon/>
                                                </Grid>                
                                                <Grid item xs={3}>                   
                                                    <TextField
                                                        value={formObj.collect_type}                                                        
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        variant="standard"
                                                        className={classes.formRow}      
                                                        onChange={(e) => {
                                                                let auxMunipForms = munipForms;                                                              
                                                                auxMunipForms[pidx]['collect_type'] = e.target.value;
                                                                setMunipForms([...auxMunipForms]);
                                                        }}    
                                                        select                      
                                                    >
                                                        <MenuItem value={"PORC_CONSUMO"}>% $ de energía</MenuItem>
                                                        <MenuItem value={"PESOS"}>$ pesos</MenuItem>    
                                                        <MenuItem value={"UVT"}>UVTs</MenuItem>                                                        
                                                    </TextField>
                                                </Grid> 
                                                <Grid item xs={1}></Grid>
                                                {
                                                    formObj.collect_var ?
                                                        formObj.collect_var.includes('RANGO') ? 
                                                            formObj.collect_type ?
                                                            <Fragment>
                                                                <Grid item xs={12}>
                                                                    <Typography align="center" variant="body2">Lista de rangos</Typography>
                                                                </Grid>
                                                                {
                                                                    formObj.collect_params.map((rangeObj, ridx) => (
                                                                        <Grid item xs={12}>
                                                                            <Typography align="center" variant="body2" style={{marginBottom: 20}}>{'Rango ' + String(ridx+1)}</Typography>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={1}>
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        type="number"
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.formText2
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                root: classes.labelText
                                                                                            }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        className={classes.formRow}
                                                                                        size="small"
                                                                                        label={formObj.collect_var.includes('PESOS') ? 'Rango Min. $' : 'Rango Min. kWh'}
                                                                                        value={rangeObj['min']}
                                                                                        onChange={(e) => {
                                                                                            let auxMunipForms = munipForms;                                                              
                                                                                            auxMunipForms[pidx]['collect_params'][ridx]['min'] = e.target.value;
                                                                                            setMunipForms([...auxMunipForms]);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        type="number"
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.formText2
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                root: classes.labelText
                                                                                            }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        className={classes.formRow}                                                                                    
                                                                                        size="small"
                                                                                        label={formObj.collect_var.includes('PESOS') ? 'Rango Max. $' : 'Rango Max. kWh'}
                                                                                        value={rangeObj['max']}
                                                                                        onChange={(e) => {
                                                                                            let auxMunipForms = munipForms;                                                              
                                                                                            auxMunipForms[pidx]['collect_params'][ridx]['max'] = e.target.value;
                                                                                            setMunipForms([...auxMunipForms]);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField
                                                                                        type="number"
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.formText2
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                root: classes.labelText
                                                                                            }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        className={classes.formRow}
                                                                                        size="small"
                                                                                        label={                                                                                            
                                                                                            formObj.collect_type === 'PORC_CONSUMO' ? 
                                                                                            '% $ de energía'
                                                                                            :
                                                                                            formObj.collect_type === 'PESOS' ? 
                                                                                            'Valor en $ pesos'
                                                                                            :
                                                                                            'Valor en UVTs'
                                                                                        }
                                                                                        value={rangeObj['value']}
                                                                                        onChange={(e) => {
                                                                                            let auxMunipForms = munipForms;                                                              
                                                                                            auxMunipForms[pidx]['collect_params'][ridx]['value'] = e.target.value;
                                                                                            setMunipForms([...auxMunipForms]);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField                                                                                        
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.formText2
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                root: classes.labelText
                                                                                            }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        className={classes.formRow}
                                                                                        size="small"
                                                                                        label={                                                                                            
                                                                                            'Lim. Min. $'
                                                                                        }
                                                                                        value={rangeObj['lim_min']}
                                                                                        onChange={(e) => {
                                                                                            let auxMunipForms = munipForms;                                                              
                                                                                            if (e.target.value == 'N' || e.target.value == ''){
                                                                                                auxMunipForms[pidx]['collect_params'][ridx]['lim_min'] = 'NA';
                                                                                                setMunipForms([...auxMunipForms]);
                                                                                            }    
                                                                                            else{
                                                                                                auxMunipForms[pidx]['collect_params'][ridx]['lim_min'] = e.target.value;
                                                                                                setMunipForms([...auxMunipForms]);
                                                                                            } 
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <TextField                                                                                        
                                                                                        InputProps={{
                                                                                            classes: {
                                                                                                input: classes.formText2
                                                                                            }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            shrink: true,
                                                                                            classes: {
                                                                                                root: classes.labelText
                                                                                            }
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        className={classes.formRow}
                                                                                        size="small"
                                                                                        label={                                                                                            
                                                                                            'Lim. Max. $'
                                                                                        }
                                                                                        value={rangeObj['lim_max']}
                                                                                        onChange={(e) => {
                                                                                            let auxMunipForms = munipForms;                     
                                                                                            if (e.target.value == 'N' || e.target.value == ''){
                                                                                                auxMunipForms[pidx]['collect_params'][ridx]['lim_max'] = 'NA';
                                                                                                setMunipForms([...auxMunipForms]);
                                                                                            }    
                                                                                            else{
                                                                                                auxMunipForms[pidx]['collect_params'][ridx]['lim_max'] = e.target.value;
                                                                                                setMunipForms([...auxMunipForms]);
                                                                                            }                                                                                                                                                                                                                             
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={1}>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))
                                                                    
                                                                }
                                                                <Grid item>
                                                                    <Fab
                                                                        color="primary"
                                                                        size="small"
                                                                        component="span"                                                        
                                                                        variant="extended"                        
                                                                        classes={{root: classes.uploadButton2}}
                                                                        onClick={() => {
                                                                            let auxMunipForms = munipForms;                                                              
                                                                            auxMunipForms[pidx]['collect_params'].push({lim_min: 'NA', lim_max: 'NA'});
                                                                            setMunipForms([...auxMunipForms]);
                                                                        }}                                                                        
                                                                    > 
                                                                        <Fragment><AddIcon style={{fontSize: 15}}/> {' Agregar  '}</Fragment>                         
                                                                    </Fab>
                                                                </Grid> 
                                                                {
                                                                    formObj.collect_params.length > 1 ?
                                                                    <Grid item>
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            component="span"                                                        
                                                                            variant="extended"                        
                                                                            classes={{root: classes.uploadButton2}}
                                                                            onClick={() => {
                                                                                let auxMunipForms = munipForms;                                                              
                                                                                auxMunipForms[pidx]['collect_params'].pop();
                                                                                setMunipForms([...auxMunipForms]);
                                                                            }}                                                                        
                                                                        > 
                                                                            <Fragment><DeleteOutlineIcon style={{fontSize: 15}}/> {' Eliminar  '}</Fragment>                         
                                                                        </Fab>
                                                                    </Grid> 
                                                                    :
                                                                    null
                                                                } 
                                                            </Fragment>
                                                            :
                                                            <Grid item xs={12}>
                                                                <Typography align="center" variant="body2">Seleccione el tipo de cobro.</Typography>
                                                            </Grid>
                                                        :
                                                            !formObj.collect_type ?
                                                            <Grid item xs={12}>
                                                                <Typography align="center" variant="body2">Seleccione el tipo de cobro.</Typography>
                                                            </Grid>
                                                            :                                                        
                                                            <Fragment>
                                                                <Grid item xs={12}>                                                                
                                                                    <Grid container justify="space-evenly" style={{marginTop: 20}}>
                                                                        <Grid item xs={2}>
                                                                            <TextField
                                                                                type="number"
                                                                                InputProps={{
                                                                                    classes: {
                                                                                        input: classes.formText2
                                                                                    }
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                    classes: {
                                                                                        root: classes.labelText
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                className={classes.formRow}
                                                                                size="small"
                                                                                label={
                                                                                    formObj.collect_type ? 
                                                                                    formObj.collect_type.includes('PESOS') ? 
                                                                                    'Valor en $' 
                                                                                    : 
                                                                                    formObj.collect_type.includes('PORC') ? 
                                                                                    '% $ de energía' 
                                                                                    : 
                                                                                    'Valor en UVTs'
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                value={formObj.collect_params[0]['value']}
                                                                                onChange={(e) => {
                                                                                    let auxMunipForms = munipForms;                                                              
                                                                                    auxMunipForms[pidx]['collect_params'][0]['value'] = e.target.value;
                                                                                    setMunipForms([...auxMunipForms]);
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <TextField                                                                                        
                                                                                InputProps={{
                                                                                    classes: {
                                                                                        input: classes.formText2
                                                                                    }
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                    classes: {
                                                                                        root: classes.labelText
                                                                                    }
                                                                                }}
                                                                                defaultValue={'NA'}
                                                                                variant="outlined"
                                                                                className={classes.formRow}
                                                                                size="small"
                                                                                label={                                                                                            
                                                                                    'Lim. Min. $'
                                                                                }
                                                                                value={formObj.collect_params[0]['lim_min']}
                                                                                onChange={(e) => {
                                                                                    let auxMunipForms = munipForms;                                                              
                                                                                    if (e.target.value == 'N' || e.target.value == ''){
                                                                                        auxMunipForms[pidx]['collect_params'][0]['lim_min'] = 'NA';
                                                                                        setMunipForms([...auxMunipForms]);
                                                                                    }    
                                                                                    else{
                                                                                        auxMunipForms[pidx]['collect_params'][0]['lim_min'] = e.target.value;
                                                                                        setMunipForms([...auxMunipForms]);
                                                                                    } 
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <TextField                                                                                        
                                                                                InputProps={{
                                                                                    classes: {
                                                                                        input: classes.formText2
                                                                                    }
                                                                                }}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                    classes: {
                                                                                        root: classes.labelText
                                                                                    }
                                                                                }}
                                                                                defaultValue={'NA'}
                                                                                variant="outlined"
                                                                                className={classes.formRow}
                                                                                size="small"
                                                                                label={                                                                                            
                                                                                    'Lim. Max. $'
                                                                                }
                                                                                value={formObj.collect_params[0]['lim_max']}
                                                                                onChange={(e) => {
                                                                                    let auxMunipForms = munipForms;                     
                                                                                    if (e.target.value == 'N' || e.target.value == ''){
                                                                                        auxMunipForms[pidx]['collect_params'][0]['lim_max'] = 'NA';
                                                                                        setMunipForms([...auxMunipForms]);
                                                                                    }    
                                                                                    else{
                                                                                        auxMunipForms[pidx]['collect_params'][0]['lim_max'] = e.target.value;
                                                                                        setMunipForms([...auxMunipForms]);
                                                                                    }                                                                                                                                                                                                                             
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>                                                              
                                                            </Fragment>
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography align="center" variant="body2">Seleccione la variable de cobro.</Typography>
                                                    </Grid>
                                                }
                                                          
                                            </Grid>                                                                       
                                        </Grid>                                                                                                           
                                        <Grid item>
                                            <Fab
                                                color="primary"
                                                size="small"
                                                component="span"
                                                aria-label="add"
                                                variant="extended"                        
                                                classes={{root: classes.uploadButton}}
                                                onClick={handleActorUpload(pidx)}
                                                style={{marginTop: 40}}
                                            > 
                                                <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                                            </Fab>
                                        </Grid>                                                           
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))
                }   
                <Grid item>
                    <Fab
                        color="primary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={() => {let aux = munipForms; aux.push({department_name: selectedDepartmentName, 
                                                                        use_types: [], active: true,
                                                                        }); setMunipForms([...aux]);}}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><AddIcon /> {' Agregar  '}</Fragment>                         
                    </Fab>
                </Grid>                                
                <Grid item>
                    <Fab
                        color="primary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={() => {handleActorDialogClose(); setExpanded(-1);}}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>                                                 
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Impuesto de seguridad";
        authFetch("/api/check-department-agreements?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.department_agreements){
                    setDepartmentList(response.department_agreements)   
                    setIsLoading(false)                                     
                }
            })
    }, [])

    return (
        <div className={classes.root}>
            {showActorDialog()}
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">
                
                <Grid item xs={11}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de departamentos:</Typography>
                </Grid>
                <Grid item xs={8}>
                    {
                        isLoading || departmentList.length === 0 ?
                        <CircularProgress/>
                        :
                        <TableContainer component={Paper}>
                            <Table aria-label="markets table">
                                <TableHead>
                                <TableRow>                                        
                                    <StyledTableCell align="center" style={{width: "55%"}}>Departamento</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "45%"}}>Formularios de cobro</StyledTableCell>                                                                           
                                </TableRow>
                                </TableHead>
                                <TableBody>                                           
                                    {departmentList.map((row,idx) => (
                                        <Fragment>
                                            {            
                                                // Cambiar MunipForms por el filtro respectivo                                       
                                                <StyledTableRow key={idx} onClick={() => {updateValuesWithSelectedActor(row.agreements); handleActorDialogOpen(); setSelectedDepartmentName(row.name);}}>                                                       
                                                    <StyledTableCell align="center" >{row.name}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.forms}</StyledTableCell>                                                                                                       
                                                </StyledTableRow>                                                
                                            }                                                
                                        </Fragment>
                                        
                                    ))}                                            
                                </TableBody>
                            </Table>
                        </TableContainer>  
                    }                                       
                </Grid>                                                        
                    
            </Grid> 
            </Zoom>                  
        </div>
    );
}