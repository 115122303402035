import DescriptionIcon from '@material-ui/icons/Description';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TimelineIcon from '@material-ui/icons/Timeline';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import TuneIcon from '@material-ui/icons/Tune';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import FlareIcon from '@material-ui/icons/Flare';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const mainNav = [
    {
        label: "tarifario",
        href: "/webspectrum/principal/",
        idx: 0,
        disabled: false,
        sideNav: [            
            {
                title: 'Cálculo de tarifas',
                icon: <ViewListIcon/>,
                nav: [
                    {
                        label: "Consola de componentes",
                        href: "/webspectrum/principal/consola-componentes",
                    },                      
                    {
                        label: "Opción tarifaria",
                        href: "/webspectrum/principal/opcion-tarifaria",
                    },  
                    {
                        label: "Reliquidaciones",
                        href: "/webspectrum/principal/reliquidaciones",
                    }, 
                    {
                        label: "Arbol tarifario",
                        href: "/webspectrum/principal/arbol-tarifario",
                    }, 
                    {
                        label: "Publicación",
                        href: "/webspectrum/principal/publicar-tarifas",
                    },          
                    {
                        label: "Ofertas no regulados",
                        href: "/webspectrum/principal/ofertas-no-regulados"
                    } 
                      
                ]
            },
            {
                title: 'Ajustes internos',
                icon: <SettingsIcon/>,
                nav: [
                    //  {
                    //     label: "Configuración principal",
                    //     href: "/webspectrum/principal/administracion",                        
                    //  },
                    {
                        label: "Mercados",
                        href: "/webspectrum/principal/mercados",
                    },
                    {
                        label: "Contratos",
                        href: "/webspectrum/principal/contratos",
                    },
                    {
                        label: "Actores financieros",
                        href: "/webspectrum/principal/actores-financieros",
                    },                     
                ]
            },
            {
                title: 'Insumos',
                icon: <PublishIcon/>,
                nav: [
                    {
                        label: "Archivos",
                        href: "/webspectrum/principal/validacion-archivos",
                    },
                    {
                        label: "Parámetros",
                        href: "/webspectrum/principal/validacion-parametros",
                    },
                    {
                        label: "Precio de bolsa no regulados",
                        href: "/webspectrum/principal/no-regulados-precio-bolsa"
                    } 
                ]
            },
            {
                title: 'Reportes',
                icon: <TimelineIcon/>,
                nav: [
                    {
                        label: "SUI",
                        href: "/webspectrum/principal/reportes-sui",
                    }, 
                    {
                        label: "Balance energético",
                        href: "/webspectrum/principal/balance-energetico",
                    },       
                             
                ]
            },
            // {
            //     title: 'Componentes',
            //     icon: <ViewListIcon/>,
            //     nav: [
            //         {
            //             label: "Consola de estado",
            //             href: "/webspectrum/principal/consola-componentes",
            //         },                    
            //     ]
            // }
        ]
    },
    {
        label: "facturador",
        href: "/webspectrum/facturador/",
        idx: 1,
        disabled: false,
        sideNav: [   
            {
                title: 'Parametrización',
                icon:  <TuneIcon/>,
                nav: [
                    {
                        label: "Ciclos",
                        href: "/webspectrum/facturador/parametrizacion-ciclos",
                    },              
                    {
                        label: "Mensual",
                        href: "/webspectrum/facturador/parametrizacion-mensual",
                    },  
                    {
                        label: "Anual",
                        href: "/webspectrum/facturador/parametrizacion-anual",
                    },  
                    {
                        label: "Cuentas bancarias",
                        href: "/webspectrum/facturador/parametrizacion-cuentas-bancarias",
                    },      
                    {
                        label: "Operadores de red",
                        href: "/webspectrum/facturador/parametrizacion-operadores-de-red",
                    },          
                    {
                        label: "Conceptos",
                        href: "/webspectrum/facturador/parametrizacion-conceptos",
                    },  
                    {
                        label: "Alumbrado público",
                        href: "/webspectrum/facturador/parametrizacion-alumbrado-publico",
                    },   
                    {
                        label: "Impuesto de seguridad",
                        href: "/webspectrum/facturador/parametrizacion-impuesto-seguridad",
                    },        
                ]
            }, 
            {
                title: 'Clientes',
                icon:  <GroupIcon/>,
                nav: [
                    {
                        label: "Control principal",
                        href: "/webspectrum/facturador/control-principal-clientes",
                    },                                                          
                ]
            }, 
            {
                title: 'Consumos',
                icon:  <AssessmentIcon/>,
                nav: [
                    {
                        label: "Cargar información por ciclos",
                        href: "/webspectrum/facturador/cargar-consumos-ciclo",
                    },       
                    {
                        label: "Información por cliente",
                        href: "/webspectrum/facturador/informacion-consumos-cliente",
                    },                               
                ]
            }, 
            {
                title: 'Liquidación de consumos',
                icon:  <LocalAtmIcon/>,
                nav: [
                    {
                        label: "Liquidar",
                        href: "/webspectrum/facturador/liquidar-consumos",
                    },                                                     
                ]
            }, 
            {
                title: 'Liquidación de impuestos',
                icon:  <AccountBalanceIcon/>,
                nav: [                   
                    {
                        label: "Alumbrado público",
                        href: "/webspectrum/facturador/liquidar-alumbrado",
                    },    
                    {
                        label: "Impuesto de seguridad",
                        href: "/webspectrum/facturador/liquidar-impuesto-seguridad",
                    },                                                  
                ]
            }, 
            {
                title: 'Liquidación otros conceptos/créditos',
                icon:  <FeaturedPlayListIcon/>,
                nav: [                          
                    {
                        label: "Cargar archivos",
                        href: "/webspectrum/facturador/cargar-otros-conceptos",
                    },                                                  
                ]
            }, 
            {
                title: 'Facturación',
                icon:  <ReceiptIcon/>,
                nav: [
                    {
                        label: "Conceptos por cliente",
                        href: "/webspectrum/facturador/conceptos-por-cliente",
                    }, 
                    {
                        label: "Generar facturas",
                        href: "/webspectrum/facturador/generar-facturas",
                    },                                        
                ]
            },
            {
                title: 'Reportes',
                icon:  <DescriptionIcon/>,
                nav: [                   
                    {
                        label: "Reportes internos",
                        href: "/webspectrum/facturador/reportes-internos",
                    },    
                    {
                        label: "Reportes SUI",
                        href: "/webspectrum/facturador/reportes-sui",
                    },                                                  
                ]
            },
            // {
            //     title: 'Autogeneradores',
            //     icon:  <AutorenewIcon/>,
            //     nav: [
            //         {
            //             label: "Cargar información",
            //             href: "/webspectrum/facturador/cargar-info-agpe-gd",
            //         }, 
            //         {
            //             label: "Liquidar",
            //             href: "/webspectrum/facturador/liquidar-agpe-dg",
            //         },                   
            //     ]
            // },         
        ]
    },
    // {
    //     label: "operativo",
    //     href: "/webspectrum/tecnico-operativo/",
    //     idx: 2,
    //     disabled: false,
    //     sideNav: [
    //         {
    //             title: 'Control de ordenes',
    //             icon:  <AssignmentIndIcon/>,
    //             nav: [
    //                 {
    //                     label: "Generar orden",
    //                     href: "/webspectrum/tecnico-operativo/generar-orden",
    //                 },                    
    //             ]
    //         },
    //         {
    //             title: 'Documentos - revisiones técnicas',
    //             icon:  <RateReviewIcon/>,
    //             nav: [
    //                 {
    //                     label: "Acta de oficio",
    //                     href: "/webspectrum/tecnico-operativo/rev-tecnica-acta-oficio",
    //                 },
    //                 // {
    //                 //     label: "Hoja de vida",
    //                 //     href: "/webspectrum/tecnico-operativo/opt1nav2",
    //                 // }
    //             ]
    //         },
    //     ]
    // },   
]

export default mainNav