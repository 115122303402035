import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox, IconButton, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
  }));
  
export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date() 
    const [offerDialog, setOfferDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [overcomeOffers, setOvercomeOffers] = useState([])
    const [clientOffers, setClientOffers] = useState([])
    const [clientName, setClientName] = useState("")
    const [clientOffer, setClientOffer] = useState({})
    const [clientCode, setClientCode] = useState("")
    
    const [componentState, setComponentState] = useState([false, false, false])
    const [filesState, setFilesState] = useState([false, false, false, false])
    const [parametersState, setParametersState] = useState([false, false, false, false])
    const [consumptionState, setConsumptionState] = useState([false])
    const [userTariffs, setUserTariffs] = useState({})
    const [isPublishing, setIsPublishing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTariffs, setIsLoadingTariffs] = useState(false)
    const [tariffsCalculated, setTariffsCalculated] = useState(false)


    const handleOfferDialogOpen = () => {
        setOfferDialog(true);
      };
    
    const handleOfferDialogClose = () => {
        setOfferDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const inputsStateList = (components, componentsState, files, filesState, params, paramsState, consumption, consumptionState) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Componentes</Typography>
                </Grid>
                {
                    components.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify="space-evenly">
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                components.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={5}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={5}>
                                    { componentsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }  
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Archivos</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                        <Grid container justify="space-evenly">           
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={5}>
                                    {filename}
                                    </Grid>                                    
                                    <Grid item xs={5}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }                                                                                                    
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Parámetros</Typography>
                </Grid>
                {
                    params.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify="space-evenly">
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                params.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={5}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={5}>
                                    { paramsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }  
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Consumos</Typography>
                </Grid>
                {
                    consumption.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify="space-evenly">
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                consumption.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={5}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={5}>
                                    { consumptionState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }                    
            </Grid>             
        </Fragment>            
    ) 
 
    const handleOfferUpload = () => {        
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        let validationFlag = true
        if ( !clientOffer.indexation_var || !clientOffer.indexation_base_value ||  !clientOffer.c_base_value || !clientOffer.g_calc_type){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false           
        }   
        if (clientOffer.g_calc_type && clientOffer.g_calc_type[0] === '1' && clientOffer.g_calc_details.g_base_value === 0){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false
        }
        if (clientOffer.g_calc_type && clientOffer.g_calc_type[0] === '2' && clientOffer.g_calc_details.k_base_value === 0){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false
        }
        if (clientOffer.g_calc_type && clientOffer.g_calc_type[0] === '3' && 
            (clientOffer.g_calc_details.g_base_value === 0 || clientOffer.g_calc_details.k_base_value === 0 || 
            clientOffer.g_calc_details.op1_perc === 0 || clientOffer.g_calc_details.op2_perc === 0)){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false
        }
        if (validationFlag) {                                                       
            authFetch(
                "/api/update-no-reg-offers",
                {
                  method: 'post',
                  body: JSON.stringify(clientOffer),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Oferta guardada.')
                    handleFeedbackDialogOpen()        
                    handleOfferDialogClose() 
                    checkUserOffer(clientCode) 
                    authFetch("/api/check-expiration-no-reg-offers?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.offers){
                                setOvercomeOffers(response.offers)                
                            }
                        })                        
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
    }      

    const checkUserOffer = (clientCode) => {
        authFetch("/api/check-no-reg-offers?username=" + String(props.username) + '&client_code=' + clientCode).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.offers){
                    if (!response.client_name){
                        setFeedbackMsg('No se encontró cliente para el código proporcionado.')
                        handleFeedbackDialogOpen()
                        setClientName("")
                    }
                    else if (response.offers.length === 0){
                        setClientName(response.client_name)
                        setFeedbackMsg('Cliente sin ofertas. Puede ingresar los datos correspondientes.')                        
                        handleFeedbackDialogOpen()
                    }
                    else if (response.offers.length > 0){
                        setClientName(response.client_name)
                        setClientOffers(response.offers)                        
                        if (componentState.concat(filesState).concat(parametersState).concat(consumptionState).every(Boolean)){
                            authFetch("/api/check-no-reg-user-tariffs?username=" + props.username + 
                                '&period=' +  String( props.periodDate.getMonth() + 1 )
                                + "&year=" + props.periodDate.getFullYear()                
                                + "&version=" + props.tariffCalcVersion
                                + '&client_code=' + clientCode).then(response => {
                                if (response.status === 422){
                                    logout()
                                    return null
                                }
                                return response.json()
                            }).then(response => {
                                if (response && response.user_tariff){                
                                    setUserTariffs(response.user_tariff)                                         
                                }
                            }) 
                        }                                              
                    }
                }
            })
    }

    const publishTariffs = () => {
        setIsPublishing(true)
        authFetch("/api/publish-no-reg-tariffs?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion
            + '&cycle=' + '1NR').then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.Upload === 'OK'){                
                setFeedbackMsg("Tarifas de usuarios no regulados publicadas.") 
                handleFeedbackDialogOpen() 
                setIsPublishing(false)   
                authFetch("/api/check-no-reg-components?username=" + props.username + 
                    '&period=' +  String( props.periodDate.getMonth() + 1 )
                    + "&year=" + props.periodDate.getFullYear()                
                    + "&version=" + props.tariffCalcVersion).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                    return response.json()
                }).then(response => {
                    if (response && response.components_flags){                                            
                        setTariffsCalculated(response.tariffs_flag)                   
                    }
                })                                    
            }
        })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showOfferDialog = () => (
        <Dialog
            open={offerDialog}
            onClose={handleOfferDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes de la oferta."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Cliente:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientName}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Mercado:</Typography>
                        </Grid>
                        <Grid item>
                            <LocationCityIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={'No regulado'}
                                
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}    
                                disabled                                                      
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Detalle:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={clientOffer.detail}                             
                                placeholder={"Detalle de la oferta."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                multiline                                       
                                onChange={(e) => {setClientOffer({...clientOffer, detail: e.target.value});}}                       
                            />
                                
                        </Grid>                                                
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>    
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Variable de indexación:</Typography>
                        </Grid>
                        <Grid item>
                            <MoreVertIcon/>
                        </Grid>
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientOffer.indexation_var} 
                                placeholder={"Seleccione la variable de indexación."}                               
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => {setClientOffer({...clientOffer, indexation_var: e.target.value});
                                                    }}
                            >
                                <MenuItem value={"IPC"} className={classes.formItem}>IPC</MenuItem>
                                <MenuItem value={"IPP"} className={classes.formItem}>IPP</MenuItem>
                            </TextField>
                                                    
                        </Grid>       
                        {
                            clientOffer.indexation_var ? 
                            <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">{'Valor base ' + clientOffer.indexation_var + ':'}</Typography>
                            </Grid>
                            <Grid item>
                                <ShowChartIcon/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    type="number"
                                    value={clientOffer.indexation_base_value}
                                    placeholder={"Ingrese el valor base del " + clientOffer.indexation_var}
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}   
                                    onChange={(e) => {setClientOffer({...clientOffer, indexation_base_value: e.target.value});}}                            
                                />                           
                            </Grid>
                            </Fragment> 
                            :
                            null
                        }               
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Valor base componente C:</Typography>
                        </Grid>
                        <Grid item>
                            <MonetizationOnIcon/>
                        </Grid>
                        <Grid item xs={9}>
                             <TextField
                                type="number"
                                value={clientOffer.c_base_value}
                                placeholder={"Ingrese el valor de C."}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setClientOffer({...clientOffer, c_base_value: e.target.value});}}                            
                            />                           
                        </Grid>                         
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de cálculo G:</Typography>
                        </Grid>
                        <Grid item>
                            <AssessmentIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientOffer.g_calc_type} 
                                placeholder={"Seleccione el tipo de cálculo."}                               
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => setClientOffer({...clientOffer, g_calc_type: e.target.value, g_calc_details: {}})}
                            >
                                <MenuItem value={"1-100% Precio fijo"} className={classes.formItem}>1-100% Precio fijo</MenuItem>
                                <MenuItem value={"2-100% Precio de bolsa mas k"} className={classes.formItem}>2-100% Precio de bolsa mas k</MenuItem>
                                <MenuItem value={"3-% Precio fijo y % Precio de bolsa mas k"} className={classes.formItem}>3-% Precio fijo y % Precio de bolsa mas k</MenuItem>
                            </TextField>
                                                       
                        </Grid>    
                        {
                            clientOffer.g_calc_type ? 
                                clientOffer.g_calc_type[0] === '1' ? 
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">Valor base componente G:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'g_base_value' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.g_base_value : 0}
                                            placeholder={"Ingrese el valor base de G."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, g_base_value: e.target.value}})
                                            }}                            
                                        />                           
                                    </Grid> 
                                </Fragment>
                                :
                                clientOffer.g_calc_type[0] === '2' ? 
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">Valor base K:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'k_base_value' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.k_base_value : 0}
                                            placeholder={"Ingrese el valor K."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, k_base_value: e.target.value}})
                                            }}                             
                                        />                           
                                    </Grid> 
                                </Fragment>
                                :
                                clientOffer.g_calc_type[0] === '3' ? 
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">Valor base componente G:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'g_base_value' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.g_base_value : null}
                                            placeholder={"Ingrese el valor base de G."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, g_base_value: e.target.value}})
                                            }}                            
                                        />                           
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">Valor base K:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <MonetizationOnIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'k_base_value' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.k_base_value : 0}
                                            placeholder={"Ingrese el valor K."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, k_base_value: e.target.value}})
                                            }}                             
                                        />                           
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">% Opción 1:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FiberManualRecordIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'op1_perc' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.op1_perc : 0}
                                            placeholder={"Ingrese el % de la opción 1."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, op1_perc: e.target.value}})
                                            }}                             
                                        />                           
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" align="left">% Opción 2:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FiberManualRecordIcon/>
                                    </Grid>
                                    <Grid item xs={9}>
                                    <TextField
                                            type="number"
                                            value={clientOffer.g_calc_details && 'op2_perc' in clientOffer.g_calc_details ? 
                                                clientOffer.g_calc_details.op2_perc : 0}
                                            placeholder={"Ingrese el % de la opción 2."}
                                            InputProps={{                                    
                                                classes: {
                                                    input: classes.formText
                                                }
                                            }}
                                            variant="standard"
                                            className={classes.formRow}   
                                            onChange={(e) => {
                                                let gCalcDetails = clientOffer.g_calc_details
                                                setClientOffer({...clientOffer, g_calc_details: {...gCalcDetails, op2_perc: e.target.value}})
                                            }}                             
                                        />                          
                                    </Grid>
                                </Fragment>
                                :
                                null
                            :
                            null

                        }                                                                                                                                                                                                                                                                                                                                                                                    
                    </Grid>                                       
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={2}> 
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de suscripción:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                                                    
                            value={clientOffer.suscription_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, suscription_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>                                                                                                                    
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de inicio:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                    
                            value={clientOffer.start_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, start_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de terminación:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                        
                            value={clientOffer.finish_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, finish_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item>
                        <Grid container justify="flex-start" style={{marginTop: 10}}>
                            <Grid item>
                            <Typography variant="body2" color="primary" style={{marginLeft: 10}}>{clientOffer.active? 'Activa':'Inactiva'}</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item>
                            <Switch
                                checked={clientOffer.active}
                                onChange={(e) => {setClientOffer({...clientOffer, active: e.target.checked});}}
                                color="primary"                                                 
                            />
                            </Grid>                                                        
                        </Grid>
                    </Grid>                     
                    </Grid>                    
                </Grid>                           
                <Grid item style={{marginTop: 20}}>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleOfferUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item style={{marginTop: 20}}>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleOfferDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const downloadOffers = () => {
        setIsLoading(true)
        authFetch("/api/download-no-reg-offers"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&username=" + String(props.username),
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Ofertas_NR_'+props.username+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    const downloadTariffs = () => {
        setIsLoadingTariffs(true)
        authFetch("/api/download-no-reg-tariffs"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            + "&username=" + String(props.username)             
            + '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion,
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoadingTariffs(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            let month = props.periodDate.getMonth() + 2 > 12 ? 1 : props.periodDate.getMonth() + 2                
            month = String(month).length === 1 ? '0'+String( month ) : String( month ) 
            let year = props.periodDate.getFullYear()
            year = props.periodDate.getMonth() + 2 > 12 ? year + 1 : year

            a.download = 'Tarifas_NR_'+props.username+'_'+year+'_'+month+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    useEffect (() => {
        document.title ="Ofertas usuarios no regulados";
        authFetch("/api/check-expiration-no-reg-offers?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.offers){
                    setOvercomeOffers(response.offers)                
                }
            })

        authFetch("/api/check-no-reg-components?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.components_flags){                
                setComponentState(response.components_flags)    
                setTariffsCalculated(response.tariffs_flag)                   
            }
        })

        authFetch("/api/check-no-reg-files?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.files_flags){                
                setFilesState(response.files_flags)                                         
            }
        })
        
        authFetch("/api/check-no-reg-parameters?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.parameters_flags){                
                setParametersState(response.parameters_flags)                                         
            }
        })

        authFetch("/api/check-no-reg-consumption?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&cycle=" + '1NR').then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.consumption_flags){                
                setConsumptionState(response.consumption_flags)                                         
            }
        })
    }, [props.periodDate])

    return (
        <div className={classes.root}>
            {showOfferDialog()}
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                
                <Grid item xs={9}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de ofertas por vencer</Typography>
                    <TableContainer component={Paper} style={{marginTop: 10}}>
                        <Table aria-label="markets table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Código de cliente</StyledTableCell>
                                <StyledTableCell align="center">Nombre</StyledTableCell>
                                <StyledTableCell align="center">Días restantes</StyledTableCell>
                                <StyledTableCell align="center">Fecha Final</StyledTableCell>                                
                            </TableRow>
                            </TableHead>
                            <TableBody>    
                                {
                                    overcomeOffers.map((offer) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="center">{offer.client_code}</StyledTableCell>
                                            <StyledTableCell align="center">{offer.client_name}</StyledTableCell>
                                            <StyledTableCell align="center" style={ offer.remaining_days < 0 ? {background: '#FB9187'} : {}}>{offer.remaining_days < 0 ? 'VENCIDA' : offer.remaining_days}</StyledTableCell>
                                            <StyledTableCell align="center">{new Date(offer.finish_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"})}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }                                                                                                                                   
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>                    
                    <Grid container justify="center" spacing={1} alignItems="center">
                        <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>Oferta por cliente</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={clientCode}
                                onChange={(e) => {setClientCode(e.target.value); setClientName(''); setClientOffers([]);}}
                            >
                            </TextField>
                        </Grid>
                        
                        <Grid item>
                            <IconButton color="primary" onClick={(e) => {checkUserOffer(clientCode)}}>
                                <SearchOutlinedIcon className={classes.searchButton} />
                            </IconButton>                             
                        </Grid>
                        
                        <Grid item xs={12}></Grid>
                    
                        <Grid item xs={11}>
                        <TableContainer component={Paper} style={{marginTop: 10}}>
                            <Table aria-label="markets table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "5%"}}>Oferta</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Código Cliente</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "15%"}}>Nombre</StyledTableCell>                                
                                    <StyledTableCell align="center" style={{width: "5%"}}>Var. indexación</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Var. indexación base</StyledTableCell>                               
                                    <StyledTableCell align="center" style={{width: "15%"}}>Tipo de Cálculo G</StyledTableCell>                                                                   
                                    <StyledTableCell align="center" style={{width: "10%"}}>Valor C</StyledTableCell>                           
                                    <StyledTableCell align="center" style={{width: "15%"}}>Fecha inicial</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "15%"}}>Fecha final</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>    
                                    {
                                        clientOffers.map( (offer, idx) => (
                                                <TableRow onClick={(e) => {handleOfferDialogOpen(); setClientOffer(offer);}}>
                                                    <StyledTableCell align="center">{idx+1}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.client_code ?  offer.client_code : clientCode}</StyledTableCell>
                                                    <StyledTableCell align="center">{clientName}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.indexation_var ? offer.indexation_var : '-'}</StyledTableCell> 
                                                    <StyledTableCell align="center">{offer.indexation_base_value ? offer.indexation_base_value : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.g_calc_type ? offer.g_calc_type : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.c_base_value ? offer.c_base_value : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.start_date ? new Date(offer.start_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.finish_date ? new Date(offer.finish_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>                                                                                                                    
                                                </TableRow>   
                                        )) 
                                    }                                                                                                                  
                                </TableBody>
                            </Table>
                        </TableContainer> 
                        </Grid>
                        <Grid item xs={3}>                    
                            {
                            clientName !== '' ?
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() => {
                                    let clientOffersArray = clientOffers;
                                    clientOffersArray.push({active: true, client_code: clientCode, username: props.username});
                                    setClientOffers([...clientOffersArray]);                                                                                        
                                }}     
                                style={{marginTop: 15}}                           
                            > 
                                <Fragment><AddIcon /> {' Agregar  '}</Fragment>                         
                            </Fab> 
                            :
                            null
                            }                     
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Descargar ofertas</Typography>
                    <Grid container justify="center">
                    <Grid item xs={6}>                        
                        {
                            isLoading ? 
                            <CircularProgress/>
                            :
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={downloadOffers}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                            </Fab>
                        }                           
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Publicación de tarifas</Typography>
                    <Grid container spacing={2} justify="space-evenly" alignItems="flex-start">
                        <Grid item xs={5} className={classes.darkText}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Insumos necesarios</Typography>
                            {inputsStateList(['Transmisión','Distribución','Restricciones'], !tariffsCalculated ? componentState : [true, true, true], 
                                ['ADEM', 'CPROG', 'TRSD m+1', 'Precio Escasez m+1'],!tariffsCalculated ? filesState : [true, true, true, true],
                                ['VkWUF', 'IPP m+1'],!tariffsCalculated ? parametersState : [true, true, true],['Consumos ciclo 1NR'],consumptionState)}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Tarifas del cliente</Typography>
                            <TableContainer component={Paper} style={{marginTop: 10}}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Tm max</StyledTableCell>
                                        <StyledTableCell align="center">Rm</StyledTableCell>
                                        <StyledTableCell align="center">Dm</StyledTableCell>                                
                                        <StyledTableCell align="center">Cv</StyledTableCell>
                                        <StyledTableCell align="center">Gm</StyledTableCell>
                                        <StyledTableCell align="center">Pr max</StyledTableCell>
                                        <StyledTableCell align="center">Cu max</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>      
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].t_max_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].r_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].d_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>             
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].c_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].g_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].pr_max_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> 
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].max.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>                                                                                                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} style={{marginTop: 10}}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Tm med</StyledTableCell>
                                        <StyledTableCell align="center">Rm</StyledTableCell>
                                        <StyledTableCell align="center">Dm</StyledTableCell>                                
                                        <StyledTableCell align="center">Cv</StyledTableCell>
                                        <StyledTableCell align="center">Gm</StyledTableCell>
                                        <StyledTableCell align="center">Pr med</StyledTableCell>
                                        <StyledTableCell align="center">Cu med</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>      
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].t_med_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].r_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].d_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>             
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].c_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].g_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].pr_med_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> 
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].med.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>                                                                                                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} style={{marginTop: 10}}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Tm min</StyledTableCell>
                                        <StyledTableCell align="center">Rm</StyledTableCell>
                                        <StyledTableCell align="center">Dm</StyledTableCell>                                
                                        <StyledTableCell align="center">Cv</StyledTableCell>
                                        <StyledTableCell align="center">Gm</StyledTableCell>
                                        <StyledTableCell align="center">Pr min</StyledTableCell>
                                        <StyledTableCell align="center">Cu min</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>      
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].t_min_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].r_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].d_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>             
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].c_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].g_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].pr_min_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> 
                                        <StyledTableCell align="center">{userTariffs[clientCode] ? userTariffs[clientCode].min.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>                                                                                                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Control de publicación (todos los clientes)</Typography>
                                {
                                    isPublishing ? 
                                    <CircularProgress/>
                                    :
                                    <Button 
                                        onClick={(e) => {publishTariffs();}} 
                                        color='primary' 
                                        disabled={!componentState.concat(filesState).concat(parametersState).concat(consumptionState).every(Boolean) || tariffsCalculated} 
                                        variant='contained'
                                    >
                                        Publicar
                                    </Button>
                                }                                
                        </Grid>
                    </Grid>
                     
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Descargar tarifas</Typography>
                    <Grid container justify="center">
                    <Grid item xs={6}>                        
                        {
                            isLoadingTariffs ? 
                            <CircularProgress/>
                            :
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                disabled={!tariffsCalculated}
                                onClick={downloadTariffs}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                            </Fab>
                        }                           
                    </Grid>
                    </Grid>
                </Grid>                                                                                       
            </Grid>   
            </Zoom>    
        </div>
    );
}