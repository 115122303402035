import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Incum from '@material-ui/icons/Layers';
import Agent from '@material-ui/icons/SortByAlpha';
import ORCode from '@material-ui/icons/SettingsEthernet';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [actorDialog, setActorDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [financialActorList, setFinancialActorList] = useState([])
     
    const [financialActorID, setFinancialActorID] = useState(-1)
    const [financialActorType, setFinancialActorType] = useState("")
    const [financialActorNIT, setFinancialActorNIT] = useState(0)
    const [financialActorDV, setFinancialActorDV] = useState(0)
    const [financialActorActive, setFinancialActorActive] = useState(true)       
    // Datos para beneficiario
    const [financialActorBenName, setFinancialActorBenName] = useState("")
    const [financialActorBenAddress, setFinancialActorBenAddress] = useState("")
    const [financialActorBenCity, setFinancialActorBenCity] = useState("")
    const [financialActorBenDepartment, setFinancialActorBenDepartment] = useState("")
    const [financialActorBenTelephone, setFinancialActorBenTelephone] = useState(0)
    // Datos para emisor
    const [financialActorEmiBank, setFinancialActorEmiBank] = useState("")
    const [financialActorEmiAccountType, setFinancialActorEmiAccountType] = useState("")
    const [financialActorEmiAccountNumber, setFinancialActorEmiAccountNumber] = useState(0)
    const [financialActorEmiOwner, setFinancialActorEmiOwner] = useState("")

    const handleActorDialogOpen = () => {
        setActorDialog(true);
      };
    
    const handleActorDialogClose = () => {
        setActorDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedActor = (idx) => {
        let actorData = JSON.parse(financialActorList[idx].financial_actor_data)
        setFinancialActorID(financialActorList[idx].financial_actor_id);
        setFinancialActorType(financialActorList[idx].type);
        setFinancialActorNIT(financialActorList[idx].nit);
        setFinancialActorDV(financialActorList[idx].dv);
        setFinancialActorActive(financialActorList[idx].active);
        if (financialActorList[idx].type === 'BENEFICIARIO')         
        {setFinancialActorBenName(actorData['name']);
        setFinancialActorBenAddress(actorData['address']);
        setFinancialActorBenCity(actorData['city']);
        setFinancialActorBenDepartment(actorData['department']);
        setFinancialActorBenTelephone(actorData['telephone']);}
        else{
        setFinancialActorEmiBank(actorData['bank']);
        setFinancialActorEmiAccountType(actorData['ac_type']);
        setFinancialActorEmiAccountNumber(actorData['ac_number']);
        setFinancialActorEmiOwner(actorData['owner']);}               
        
    }
 
    const handleActorUpload = () => {        
        if (financialActorType.length === 0 || financialActorNIT === 0){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {
            let financialActorData = {}
            if (financialActorType === 'BENEFICIARIO'){
                financialActorData['name'] = financialActorBenName 
                financialActorData['address'] = financialActorBenAddress
                financialActorData['city'] = financialActorBenCity
                financialActorData['department'] = financialActorBenDepartment
                financialActorData['telephone'] = financialActorBenTelephone
            }
            else{
                financialActorData['bank'] = financialActorEmiBank
                financialActorData['ac_type'] = financialActorEmiAccountType
                financialActorData['ac_number'] = financialActorEmiAccountNumber
                financialActorData['owner'] = financialActorEmiOwner
            }

            let opts = {                
                'type': financialActorType,
                'nit': financialActorNIT,
                'dv': financialActorDV,
                'active': financialActorActive,
                'owner': props.username,
                'financial_actor_data': JSON.stringify(financialActorData),                
              }
              financialActorID === -1 ? setFinancialActorID(-1) : opts['financial_actor_id'] = financialActorID
              authFetch(
                "/api/update-financial-actors",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                    handleFeedbackDialogOpen()
                    handleActorDialogClose()
                    authFetch("/api/check-financial-actors?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.financial_actors){                                                         
                                setFinancialActorList(response.financial_actors)                                
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showActorDialog = () => (
        <Dialog
            open={actorDialog}
            onClose={handleActorDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo:</Typography>
                        </Grid>
                        <Grid item>
                            <MarketName/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={financialActorType}
                                placeholder={"Seleccione el tipo de actor financiero."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setFinancialActorType(e.target.value)}   
                                select                      
                            >
                                <MenuItem value={"BENEFICIARIO"}>Beneficiario</MenuItem>
                                <MenuItem value={"EMISOR"}>Emisor</MenuItem>                                
                            </TextField>
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">NIT:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={financialActorNIT}
                                placeholder={"Ingrese el NIT sin puntos."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setFinancialActorNIT(e.target.value)}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">DV</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={financialActorDV}
                                placeholder={"Ingrese el dígito de verificación."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setFinancialActorDV(e.target.value)}                            
                            />
                        </Grid>                       
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    {
                        financialActorType === 'BENEFICIARIO' ? 
                        <Grid container spacing={2}>                                                 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Nombre/Razón Social:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorBenName}  
                                    placeholder={"Ingrese el nombre del beneficiario."}                              
                                    InputProps={{                                        
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorBenName(e.target.value)}                                                                                     
                                />
                            </Grid>   
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Dirección:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorBenAddress}  
                                    placeholder={"Ingrese la dirección del beneficiario."}                              
                                    InputProps={{                                        
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorBenAddress(e.target.value)}                                                                                     
                                />
                            </Grid> 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Teléfono:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    type={'number'}
                                    value={financialActorBenTelephone}  
                                    placeholder={"Ingrese el teléfono del beneficiario."}                              
                                    InputProps={{                                        
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorBenTelephone(e.target.value)}                                                                                     
                                />
                            </Grid> 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Ciudad:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorBenCity}  
                                    placeholder={"Ingrese la ciudad del beneficiario."}                              
                                    InputProps={{                                        
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorBenCity(e.target.value)}                                                                                     
                                />
                            </Grid> 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Departamento:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorBenDepartment}  
                                    placeholder={"Ingrese el departamento del beneficiario."}                              
                                    InputProps={{                                        
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorBenDepartment(e.target.value)}                                                                                     
                                />
                            </Grid>                       
                        </Grid>
                        :
                        financialActorType === 'EMISOR' ? 
                        <Grid container spacing={2}>                                                 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Entidad bancaria:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorEmiBank}  
                                    placeholder={"Ingrese el nombre de la entidad bancaria emisora."}                              
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorEmiBank(e.target.value)}                                                                                     
                                />
                            </Grid>   
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Tipo de cuenta:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorEmiAccountType}  
                                    placeholder={"Seleccione el tipo de cuenta bancaria."}                              
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorEmiAccountType(e.target.value)}    
                                    select                                                                                 
                                >
                                    <MenuItem value={"Ahorros"}>Ahorros</MenuItem>
                                    <MenuItem value={"Corriente"}>Corriente</MenuItem>   
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">No. de cuenta:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorEmiAccountNumber}  
                                    placeholder={"Ingrese el número de la cuenta."}                              
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorEmiAccountNumber(e.target.value)}                                                                                                                        
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="left">Titular:</Typography>
                            </Grid>
                            <Grid item>
                                <Agent/>
                            </Grid>                
                            <Grid item xs={9}>                   
                                <TextField
                                    value={financialActorEmiOwner}  
                                    placeholder={"Ingrese el titular de la cuenta bancaria."}                              
                                    InputProps={{                                    
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="standard"
                                    className={classes.formRow}    
                                    onChange={(e) => setFinancialActorEmiOwner(e.target.value)}                                                                                     
                                />
                            </Grid>                      
                        </Grid>
                        :
                        null
                    }
                                                           
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Estado:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch
                                checked={financialActorActive}
                                onChange={(e) => setFinancialActorActive(e.target.checked)}
                                color="primary"                                                 
                            />
                            {financialActorActive? 'Activo':'Inactivo'}
                        </Grid> 
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Actores financieros";
        authFetch("/api/check-financial-actors?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.financial_actors){
                    setFinancialActorList(response.financial_actors)                                        
                }
            })
    }, [])

    return (
        <div className={classes.root}>
            {showActorDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de actores financieros:</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "6%"}}>No.</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "18%"}}>Tipo</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "25%"}}>NIT</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "6%"}}>DV</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "45%"}}>Entidad</StyledTableCell>                                        
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {financialActorList.map((row,idx) => (
                                            <StyledTableRow key={idx} onClick={() => {updateValuesWithSelectedActor(idx); handleActorDialogOpen();}}>
                                                <StyledTableCell align="center">{idx + 1}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.type}</StyledTableCell>
                                                <StyledTableCell align="center">{row.nit}</StyledTableCell>
                                                <StyledTableCell align="center">{row.dv}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.type === 'BENEFICIARIO' ? JSON.parse(row.financial_actor_data)['name']:JSON.parse(row.financial_actor_data)['bank']}</StyledTableCell>                                                
                                            </StyledTableRow>
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{ handleActorDialogOpen(); setFinancialActorID(-1);}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>      
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}