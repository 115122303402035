import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    let billingDate = props.billingDate

    const [consumptionDialog, setConsumptionDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [clientCode, setClientCode] = useState("")        
    const [manualClients, setManualClients] = useState([])
    const [clientConsumption, setClientConsumption] = useState({})
    const [cycleConsumption, setCycleConsumption] = useState({})

    const handleConsumptionDialogOpen = () => {
        setConsumptionDialog(true);
      };
    
    const handleConsumptionDialogClose = () => {
        setConsumptionDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
   
    const handleConsumptionUpload = () => {        
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        let validationFlag = true
        if ( !clientConsumption.active_energy || !clientConsumption.reactive_ind_energy ||  !clientConsumption.reactive_ind_pen_energy || !clientConsumption.reactive_cap_energy){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false           
        }         
        if ( cycleConsumption['ap_'+clientConsumption.client_cycle] ){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Liquidación de consumos aprobada, no se pueden modificar los consumos.") 
            validationFlag = false
        }  
        if (validationFlag) {            
            authFetch(
                "/api/update-client-consumption?billing_period="+String( billingDate.getMonth() + 1 )+
                "&billing_year="+ billingDate.getFullYear()
                ,
                {
                  method: 'post',
                  body: JSON.stringify(clientConsumption),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Consumos guardados.')
                    handleFeedbackDialogOpen()        
                    handleConsumptionDialogClose()            
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
      }

    const checkUserConsumption = (clientCode) => {
        authFetch("/api/check-client-consumption?username=" + String(props.username) + '&client_code=' + clientCode+
        "&billing_period="+String( billingDate.getMonth() + 1 )+                                                    
        "&billing_year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.client_consumption){
                    //console.log(response.client_consumption)
                    if (Object.keys(response.client_consumption).length === 0){
                        setFeedbackMsg('No se encontró cliente para el código proporcionado.')
                        handleFeedbackDialogOpen()
                    }
                    else if (Object.keys(response.client_consumption).length === 1){
                        setFeedbackMsg('Cliente sin consumos. Puede ingresar los datos correspondientes.')
                        setClientConsumption({...response.client_consumption, client_code: clientCode})
                        handleFeedbackDialogOpen()
                    }
                    else if (Object.keys(response.client_consumption).length > 1){
                        setClientConsumption(response.client_consumption)                        
                    }
                }
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showConsumptionDialog = () => (
        <Dialog
            open={consumptionDialog}
            onClose={handleConsumptionDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes del consumo."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Cliente:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientConsumption.client_name}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientConsumption.client_code}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid>                                                                        
                    </Grid>                                       
                </Grid>                     
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">{'Energía activa [kWh]'}</Typography>
                        </Grid>
                        <Grid item>
                            <WbIridescentIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={clientConsumption.active_energy}
                                placeholder={"Consumo de energía activa"}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setClientConsumption({...clientConsumption, active_energy: e.target.value});}}                            
                            />                           
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">{'Energía inductiva [kWh]'}</Typography>
                        </Grid>
                        <Grid item>
                            <WbIridescentIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={clientConsumption.reactive_ind_energy}
                                placeholder={"Consumo de energía inductiva"}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setClientConsumption({...clientConsumption, reactive_ind_energy: e.target.value});}}                            
                            />                           
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">{'Energía inductiva facturable[kWh]'}</Typography>
                        </Grid>
                        <Grid item>
                            <WbIridescentIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={clientConsumption.reactive_ind_pen_energy}
                                placeholder={"Consumo de energía inductiva facturable"}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setClientConsumption({...clientConsumption, reactive_ind_pen_energy: e.target.value});}}                            
                            />                           
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">{'Energía capacitiva[kWh]'}</Typography>
                        </Grid>
                        <Grid item>
                            <WbIridescentIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                type="number"
                                value={clientConsumption.reactive_cap_energy}
                                placeholder={"Consumo de energía capacitiva"}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {setClientConsumption({...clientConsumption, reactive_cap_energy: e.target.value});}}                            
                            />                           
                        </Grid>
                    </Grid>                                       
                </Grid>
                <Grid item xs={12}></Grid>                         
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleConsumptionUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleConsumptionDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Consumo por usuario";
        authFetch("/api/check-manual-consumption-clients?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.manual_clients){
                    setManualClients(response.manual_clients)                                   
                }
            })
        authFetch("/api/get-cycle-users?username=" + String(props.username)+
        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
        if (response.status === 422){
            logout()
            return null
        }
            return response.json()
        }).then(response => {
            if (response && response.cycle_users){   
                let cycleUsers = response.cycle_users                               
                let opts = {
                    users_by_cycle: cycleUsers,                    
                }  
                authFetch("/api/check-cycle-consumptions?username=" + String(props.username) +
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                {
                    method: 'post',
                    body: JSON.stringify(opts)
                }
                ).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.cycle_consumption_flags){                  
                        setCycleConsumption(response.cycle_consumption_flags)                                                                                            
                        }
                    })                                                        
            }
        }) 
    }, [])

    return (
        <div className={classes.root}>
            {showConsumptionDialog()}
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                
                <Grid item xs={12}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de clientes con ingreso manual</Typography>
                    <TableContainer component={Paper} style={{marginTop: 10}}>
                        <Table aria-label="clients table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Código de cliente</StyledTableCell>
                                <StyledTableCell align="center">Nombre</StyledTableCell>
                                <StyledTableCell align="center">Tipo de mercado</StyledTableCell>
                                <StyledTableCell align="center">Ciclo</StyledTableCell>                                
                            </TableRow>
                            </TableHead>
                            <TableBody>     
                                {
                                    manualClients.map((client) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="center">{client.client_code}</StyledTableCell>
                                            <StyledTableCell align="center">{client.client_name}</StyledTableCell>
                                            <StyledTableCell align="center">{client.client_market_type}</StyledTableCell>
                                            <StyledTableCell align="center">{client.client_cycle}</StyledTableCell> 
                                        </StyledTableRow>
                                    ))
                                } 
                                                                                                                                
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Consultar/Modificar consumo por cliente</Typography>
                    <Grid container justify="center" spacing={1} alignItems="flex-start">
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Buscar cliente (por código)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={clientCode}
                                onChange={(e) => {setClientCode(e.target.value)}}
                            >

                            </TextField>
                        </Grid>
                        <Grid item>
                            <SearchOutlinedIcon color="primary" fontSize={'large'} onClick={(e) => {checkUserConsumption(clientCode)}}/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{marginTop: 10}}>
                        <Table aria-label="markets table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" style={{width: "10%"}}>Código Cliente</StyledTableCell>
                                <StyledTableCell align="center" style={{width: "25%"}}>Nombre</StyledTableCell>                                
                                <StyledTableCell align="center" style={{width: "15%"}}>Energía activa</StyledTableCell>
                                <StyledTableCell align="center" style={{width: "15%"}}>Energía inductiva</StyledTableCell>                               
                                <StyledTableCell align="center" style={{width: "15%"}}>Energía inductiva penalizada</StyledTableCell>                                 
                                <StyledTableCell align="center" style={{width: "15%"}}>Energía capacitiva</StyledTableCell>                                                                
                            </TableRow>
                            </TableHead>
                            <TableBody>    
                                {
                                    Object.keys(clientConsumption).length > 0 ?
                                    <StyledTableRow onClick={(e) => {handleConsumptionDialogOpen()}}>
                                        <StyledTableCell align="center">{clientConsumption.client_code}</StyledTableCell>
                                        <StyledTableCell align="center">{clientConsumption.client_name}</StyledTableCell>
                                        <StyledTableCell align="center">{clientConsumption.active_energy ? clientConsumption.active_energy : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{clientConsumption.reactive_ind_energy ? clientConsumption.reactive_ind_energy : '-'}</StyledTableCell>                       
                                        <StyledTableCell align="center">{clientConsumption.reactive_ind_pen_energy ? clientConsumption.reactive_ind_pen_energy : '-'}</StyledTableCell>
                                        <StyledTableCell align="center">{clientConsumption.reactive_cap_energy ? clientConsumption.reactive_cap_energy : '-'}</StyledTableCell>                                                                                                                                                 
                                    </StyledTableRow>
                                    :
                                    null
                                }                                                                                                                  
                            </TableBody>
                        </Table>
                    </TableContainer>                     
                </Grid> 
                                                                                                    
            </Grid>   
            </Zoom>    
        </div>
    );
}