import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5,
      background: 'white'
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {      
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [cycleDialog, setCycleDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [cyclesList, setCyclesList] = useState([])
    
    const [cycleID, setCycleID] = useState(-1)
    const [cycleCode, setCycleCode] = useState("")
    const [cyclePeriodicity, setCyclePeriodicity] = useState("")
    const [cycleMarketType, setCycleMarketType] = useState("")
    const [cycleState, setCycleState] = useState(true)
    const [cycleInitialDate, setCycleInitialDate] = useState(new Date())    

    const [usersInCycle, setUsersInCycle] = useState({})

    const handleCycleDialogOpen = () => {
        setCycleDialog(true);
      };
    
    const handleCycleDialogClose = () => {
        setCycleDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedCycle = (idx) => {
        
        setCycleID(cyclesList[idx].billing_cycle_id);
        setCycleCode(cyclesList[idx].code);
        setCyclePeriodicity(cyclesList[idx].periodicity);
        setCycleMarketType(cyclesList[idx].market_type);
        setCycleState(cyclesList[idx].active);
        setCycleInitialDate(cyclesList[idx].entry_date);
    }

    const handleCycleUpload = () => {        
        if (cycleCode.length === 0 || cyclePeriodicity.length === 0 || cycleMarketType.length === 0){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {
            
            let opts = {                
                'code': cycleCode,
                'periodicity': cyclePeriodicity,
                'active': cycleState,
                'market_type': cycleMarketType,
                'entry_date': cycleInitialDate,
                'username': props.username,                                
              }
              cycleID === -1 ? setCycleID(-1) : opts['billing_cycle_id'] = cycleID
              authFetch(
                "/api/update-billing-cycles",
                {
                  method: 'post',
                  body: JSON.stringify(opts),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                    handleFeedbackDialogOpen()
                    handleCycleDialogClose()
                    authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.cycles){
                                setCyclesList(response.cycles)                                        
                            }
                        })
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showCycleDialog = () => (
        <Dialog
            open={cycleDialog}
            onClose={handleCycleDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código del ciclo:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={cycleCode}
                                placeholder={"Código del ciclo."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setCycleCode(e.target.value)}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Periodicidad:</Typography>
                        </Grid>
                        <Grid item>
                            <DateRangeIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={cyclePeriodicity}
                                placeholder={"Seleccione el tipo de periodicidad."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setCyclePeriodicity(e.target.value)}   
                                select                      
                            >
                                <MenuItem value={"1: 1-Fin mes"}>1: 1-Fin mes</MenuItem>
                                <MenuItem value={"2: 16-15"}>2: 16-15</MenuItem>                                
                                <MenuItem value={"3: 16-15"}>3: 11-10</MenuItem>
                                <MenuItem value={"4: 16-15"}>4: 21-20</MenuItem>
                            </TextField>
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de mercado:</Typography>
                        </Grid>
                        <Grid item>
                            <ViewAgendaIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={cycleMarketType}
                                placeholder={"Seleccione el tipo de mercado."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setCycleMarketType(e.target.value)}   
                                select                      
                            >
                                <MenuItem value={"REGULADO"}>REGULADO</MenuItem>
                                <MenuItem value={"NO REGULADO"}>NO REGULADO</MenuItem>                                
                            </TextField>
                        </Grid>
                                              
                    </Grid>                                       
                </Grid>                    
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Estado:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch
                                checked={cycleState}
                                onChange={(e) => setCycleState(e.target.checked)}
                                color="primary"                                                 
                            />
                            {cycleState? 'Activo':'Inactivo'}
                        </Grid> 
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Fecha de ingreso:</Typography>
                        </Grid>
                        <Grid item>
                            <EventIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <DatePicker    
                                variant="dialog"    
                                inputVariant="standard"                                                                                 
                                okLabel={""}
                                cancelLabel={""}
                                openTo="month"
                                format="MMMM-yyyy"
                                //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                views={["year","month"]}                                
                                value={cycleInitialDate}
                                InputLabelProps={{                                            
                                            classes: {
                                                root: classes.labelText,                                    
                                            }
                                        }}
                                onChange={setCycleInitialDate}
                                error={false}
                                InputProps={{                                            
                                            classes: {
                                                input: classes.formText,                                    
                                            }
                                        }}
                            
                                autoOk                                                                                  
                            /> 
                        </Grid> */}
                    </Grid>                    
                </Grid>     
                <Grid item xs={12}></Grid>                      
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleCycleUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleCycleDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Ciclos de facturación | Autogeneradores Cedenar";
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){
                    setCyclesList(response.cycles)                                        
                }
            })
        authFetch("/api/get-cycle-users?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycle_users){
                    setUsersInCycle(response.cycle_users)                                        
                }
            })
    }, [])

    return (
        <div className={classes.root}>
            {showCycleDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Ciclos usuarios autogeneradores</Typography>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                            <TableContainer>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Código ciclo</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Periodicidad</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Estado</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "18%"}}>Tipo mercado</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "18%"}}>Fecha de ingreso</StyledTableCell>      
                                        <StyledTableCell align="center" style={{width: "15%"}}>No. usuarios</StyledTableCell>                                     
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {cyclesList.map((row,idx) => (
                                            <StyledTableRow key={idx} onClick={() => {updateValuesWithSelectedCycle(idx); handleCycleDialogOpen();}}>
                                                <StyledTableCell align="center">{row.code}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.periodicity}</StyledTableCell>
                                                <StyledTableCell align="center">{row.active ? 'Activo':'Inactivo'}</StyledTableCell>
                                                <StyledTableCell align="center">{row.market_type}</StyledTableCell>
                                                <StyledTableCell align="center" >{row.entry_date ? new Date(row.entry_date).toISOString().slice(0,7) : null}</StyledTableCell> 
                                                <StyledTableCell align="center" >{usersInCycle[row.code]}</StyledTableCell>                                                                                                
                                            </StyledTableRow>
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            </Paper>
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{ handleCycleDialogOpen(); setCycleID(-1);}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>      
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}