import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Incum from '@material-ui/icons/Layers';
import Agent from '@material-ui/icons/SortByAlpha';
import ORCode from '@material-ui/icons/SettingsEthernet';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [actorDialog, setActorDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [conceptsList, setConceptsList] = useState([])
    const [conceptData, setConceptData] = useState({})

    const [netOperatorsList, setNetOperatorsList] = useState([])
    const [netOperatorData, setNetOperatorData] = useState({active: true})

    const handleActorDialogOpen = () => {
        setActorDialog(true);
      };
    
    const handleActorDialogClose = () => {
        setActorDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedActor = (idx) => {
        if (idx === -1){
            setConceptData({active: true})
        }
        else{
            setConceptData(conceptsList[idx])
        }
    }
 
    const handleActorUpload = () => {     
        //console.log(conceptData)   
        if (!conceptData.code || !conceptData.description || !conceptData.app_iva || !conceptData.app_credit){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {            
            if (!conceptData['concept_id']){
                conceptData['username'] = props.username
            }            
            authFetch(
            "/api/update-concepts",
            {
                method: 'post',
                body: JSON.stringify(conceptData),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                handleFeedbackDialogOpen()
                handleActorDialogClose()
                authFetch("/api/check-concepts-list?username="+props.username).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.concepts_list){                    
                            setConceptsList(response.concepts_list)                                        
                        }
                    })
                }
                else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                }
            })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showActorDialog = () => (
        <Dialog
            open={actorDialog}
            onClose={handleActorDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingresar/Revisar los datos del concepto."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField          
                                disabled={conceptData.concept_id ? true : false}                    
                                value={conceptData.code}
                                placeholder={"Código del concepto."}
                                InputProps={{
                                    readOnly: conceptData.concept_id ? true : false,
                                    className:conceptData.concept_id ? classes.formDefined : {},
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setConceptData({...conceptData, code: e.target.value})}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Descripción:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField          
                                disabled={conceptData.concept_id ? true : false}                         
                                value={conceptData.description}
                                placeholder={"Descripción del concepto."}
                                InputProps={{
                                    readOnly: conceptData.concept_id ? true : false,
                                    className: conceptData.concept_id ? classes.formDefined : {},
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setConceptData({...conceptData, description: e.target.value})}                            
                            />
                        </Grid>  
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Observación:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                                                
                                value={conceptData.observation}
                                placeholder={"Observación del concepto."}
                                InputProps={{                                   
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setConceptData({...conceptData, observation: e.target.value})}   
                                multiline                         
                            />
                        </Grid>                
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>                   
                    <Grid container spacing={2}>                                                                       
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Aplica IVA:</Typography>
                        </Grid>
                        <Grid item>
                            <Area/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={conceptData.app_iva}
                                placeholder={"Zona."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setConceptData({...conceptData, app_iva: e.target.value})}   
                                select                      
                            >
                                <MenuItem value={"S"}>SI</MenuItem>
                                <MenuItem value={"N"}>NO</MenuItem>                                
                            </TextField>
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Aplica crédito:</Typography>
                        </Grid>
                        <Grid item>
                            <Area/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={conceptData.app_credit}
                                placeholder={"Zona."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setConceptData({...conceptData, app_credit: e.target.value})}   
                                select                      
                            >
                                <MenuItem value={"S"}>SI</MenuItem>
                                <MenuItem value={"N"}>NO</MenuItem>                                
                            </TextField>
                        </Grid>                      
                    </Grid>                                                                                                          
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Estado:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch
                                checked={conceptData.active}
                                onChange={(e) => setConceptData({...conceptData, active: e.target.checked})}
                                color="primary"                                                 
                            />
                            {conceptData.active? 'Activo':'Inactivo'}
                        </Grid> 
                        
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Conceptos de liquidación";
        
        authFetch("/api/check-concepts-list?username="+props.username).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.concepts_list){                    
                    setConceptsList(response.concepts_list)                                        
                }
            })
        
    }, [])

    return (
        <div className={classes.root}>
            {showActorDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de conceptos usados:</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>                                        
                                        <StyledTableCell align="center" style={{width: "15%"}}>Código</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "35%"}}>Descripción</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "15%"}}>Estado</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "35%"}}>Observación</StyledTableCell>                                                                                 
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {conceptsList.map((row,idx) => (
                                        <StyledTableRow 
                                            key={idx} 
                                            onClick={() => {updateValuesWithSelectedActor(idx); handleActorDialogOpen();}}
                                        >                                                       
                                            <StyledTableCell align="center" >{row.code}</StyledTableCell>
                                            <StyledTableCell align="center">{row.description}</StyledTableCell>
                                            <StyledTableCell align="center">{row.active ? 'Activo':'Inactivo'}</StyledTableCell>
                                            <StyledTableCell align="center" >{row.observation ? row.observation : '-'}</StyledTableCell>                                                                                            
                                        </StyledTableRow>
                                            
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{updateValuesWithSelectedActor(-1); handleActorDialogOpen();}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>      
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}