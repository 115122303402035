import {React, useEffect, useState, Fragment} from 'react'
import ReactDOMServer from 'react-dom/server'
import { makeStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, 
        Button,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tab, AppBar, Accordion, AccordionSummary,
        AccordionDetails, FormControlLabel, Checkbox, ListItem, ListItemSecondaryAction, ListItemText, List, Box, Select} from '@material-ui/core/'
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ReplayIcon from '@material-ui/icons/Replay';
import SignaturePad from 'react-signature-canvas';
import {DatePicker} from '@material-ui/pickers';
import SvgComponents from './SvgComponents'

// CONSTANTES //
// Secciones acta //
const ACT_SECTIONS = ['Encabezado', 'Medidores', 'Telemedida', 'Transformadores', 'Observaciones', 'Fotos', 'Diagrama Unifilar', 'Firmas']
// Encabezado //
const USER_DATA_INPUTS = [
    {Name: 'Nombre', Type: 'text'},
    {Name: 'Dirección', Type: 'text'},
    {Name: 'Telefono', Type: 'number'},
    {Name: 'Tipo de usuario', Type: 'text', Select: true, Values: ['RESIDENCIAL','COMERCIAL','INDUSTRIAL','OFICIAL','OTRO']},
    {Name: 'Ciudad', Type: 'text'},
    {Name: 'Departamento', Type: 'text'},
    {Name: 'Razón Social', Type: 'text'},
    {Name: 'Código contrato', Type: 'text'},
    {Name: 'Código SIC', Type: 'text'},
    {Name: 'NIU', Type: 'text'},
]
const NET_DATA_INPUTS = [
    {Name: 'Nivel de tensión', Type: 'text', Select: true, Values: ['I','II','III']},
    {Name: 'Tensión de la medida', Type: 'number'},
    {Name: 'Conexión', Type: 'text', Select: true, Values: ['Monofásica','Trifásica']},
    {Name: 'Encargado', Type: 'text'},
    {Name: 'Operador de red', Type: 'text', Select: true, Values: ['AFINA','AIR-E','CEDENAR','CELSIA COLOMBIA','CELSIA TOLIMA',
    'CENS','CEO','CETSA','CHEC','CODENSA','DICEL','DICELER','DISPAC',
    'EBSA','EDEQ','EEBP','ELECTROCAQUETA','ELECTROHUILA','EMCALI','EMCARTAGO',
    'EMSA','ENELAR','ENERCA','ENERCO','EPM','ESSA']},
    {Name: 'No. transformador', Type: 'number'},
    {Name: 'Capacidad [kVA]', Type: 'number'},
    {Name: 'Nodo transformador no.', Type: 'number'},
    {Name: 'Nodo acometida', Type: 'text'},
    {Name: 'Subestación', Type: 'text'},
    {Name: 'Circuito', Type: 'text'}
]
// Medidores //
let meterTypes = {
    ITRON: ['ACE6000',
    'SL7000',
    'SENTINEL',
    'ACE3000'],
    ISKRA: [
        'MT174',
        'MT880',
        'D37C2',
        'T37C2',
    ],
    ELGAMA: [
        'LZQM',
        'EPQM',
        'GAMA300',
        'EPQS',
        'EMS142',
        'EMS144',
    ],
    ELSTER: [
        'A1300', 
        'A1800',
        'A1052',
        'AS1440',
        'A1100',
        'A1R',
    ],
    LANDIS: [
        'ZMG405',
        'ZMD405',
        'ZMD100AS',
        'M12E',
        'MAXSYS 2510',
    ],
    HIKING:[
        'DTS238'
    ],
    NANSEN:[
        'SPECTRUM'
    ],
    RYMEL: [
        'AMTB1A',
        'AMTB1E',
    ],
    ACTARIS: [
        'SL7000'
    ],
    MICROSTAR:[
        'P2000'
    ],
    ABB: [
        'ALPHA',
        'ALPHA II'   
    ],
    EMH: [
        'LZQJ'
    ],
    HOLLEY: [
        'DDS-I',
        'DDS-II',
        'EMS142',
        'EMS144',
        'HLD03',
        'DT58',
    ],
    NEXUS:[        
        '1262',
        '1500'
]}
const METER_DATA_INPUTS = [
    {Name: 'Serie', Type: 'number', Shared: true},
    {Name: 'Marca', Type: 'text', Select: true, Values:['ITRON','ISKRA','ELGAMA','ELSTER','LANDIS','HIKING','NANSEN',
                                                        'RYMEL','ACTARIS','MICROSTAR','ABB','EMH','HOLLEY','NEXUS'], Shared: true},
    {Name: 'Modelo', Type: 'text', Select: true, Multi: true, Values: meterTypes, Shared: true},    
    {Name: 'Lectura', Type: 'number', Idxs: [3,4]},
    {Name: 'kh o kd', Type: 'number', Idxs: [5,6]},
    {Name: 'Clase', Type: 'number', Select: true, Values: ['0.2', '0.25', '0.5', '0.55', '1', '2'], Idxs: [7,8]},
    {Name: 'Ubicación', Type: 'text', Select: true, Values:['Patio','Poste','Interior','Exterior'], Shared: true},
    {Name: 'Conexión medidor', Type: 'text', Select: true, Values:['Directa','Semidirecta','Indirecta'], Shared: true},
    {Name: 'No. de elementos', Type: 'number', Select: true, Values: ['1', '2', '2 1/2', '2 Aarón', '3', 'NA'], Shared: true},
    {Name: 'Sentido de medición', Type: 'text', Select: true, Values:['Unidireccional','Bidireccional'], Shared: true},
    {Name: 'No. hilos', Type: 'number', Shared: true},
    {Name: 'No. fases', Type: 'number', Shared: true},
    {Name: 'Fecha fabricación', Type: 'date', Shared: true},
    {Name: 'Factor FX', Type: 'number', Shared: true},
    {Name: 'Corriente máxima', Type: 'number', Shared: true},
    {Name: 'Tensión máxima', Type: 'number', Shared: true},    
]
const SELLOS_DATA_INPUTS = [
    {Name: 'Cubierta medidor activa', Type: 'text'},
    {Name: 'Cubierta medidor reactiva', Type: 'text'},
    {Name: 'Bornera medidor activa', Type: 'text'},
    {Name: 'Bornera medidor reactiva', Type: 'text'},
    {Name: 'Bloque de pruebas', Type: 'text'},        
    {Name: 'Sellos de celda de medida', Type: 'text'},
]
// Telemedida //
const TELEMEASURE_DATA_INPUTS = [
    {Name: 'Marca', Type: 'text'},
    {Name: 'Serie', Type: 'number'},
    {Name: 'Teléfono o IP', Type: 'text'},
    {Name: 'IMEI', Type: 'number'},
    {Name: 'Desfase Hora Col(s)', Type: 'number'},
    {Name: 'Latitud', Type: 'number'},
    {Name: 'Longitud', Type: 'number'},
    {Name: 'Funcionamiento', Type: 'text', Values: ['Exitosa', 'No exitosa', 'N/A']},
]
// Transformadores //
const MEASURE_DATA_INPUTS = [
    {Name: 'Punto de medición', Type: 'text'},
    {Name: 'Múltiplo de medida', Type: 'text'},    
]
const INSTALLED_TRF_DATA_INPUTS = [
    {Name: 'No. certificado calibración', Type: 'number'},
    {Name: 'Laboratorio que lo emite', Type: 'text'},
    {Name: 'Año de fabricación', Type: 'number'},
    {Name: 'Marca', Type: 'text'},
    {Name: 'Tipo', Type: 'text'},
    {Name: 'Modelo', Type: 'text'},
    {Name: 'Relaciones (todas)', Type: 'text'},
    {Name: 'Cargabilidad', Type: 'text'},
    {Name: 'Clase', Type: 'text'},
    {Name: 'Relación actual', Type: 'text'},
    {Name: 'Serie', Type: 'text'},
    {Name: 'Sellos instalados OR', Type: 'text'},
    {Name: 'Sellos instalados RF', Type: 'text'},
    {Name: 'Sellos retirados OR', Type: 'text'},
    {Name: 'Sellos retirados RF', Type: 'text'},
]
const CURRENT_TESTS = [
    {Name: 'Ip', Type: 'number'},
    {Name: 'Is', Type: 'number'},
    {Name: 'RTC', Type: 'number'},
]
const VOLTAGE_TESTS = [
    {Name: 'Vp', Type: 'number'},
    {Name: 'Vs', Type: 'number'},
    {Name: 'RTT', Type: 'number'},
]
const RETIRED_TRF_DATA_INPUTS = [    
    {Name: 'Año de fabricación', Type: 'number'},
    {Name: 'Marca', Type: 'text'},
    {Name: 'Tipo', Type: 'text'},
    {Name: 'Modelo', Type: 'text'},
    {Name: 'Relaciones (todas)', Type: 'text'},
    {Name: 'Cargabilidad', Type: 'text'},
    {Name: 'Clase', Type: 'text'},
    {Name: 'Relación actual', Type: 'text'},
    {Name: 'Serie', Type: 'text'},
    {Name: 'Sellos retirados OR', Type: 'text'},
    {Name: 'Sellos retirados RF', Type: 'text'},
]
const ERROR_PERCENTAGE = [
    {Name: '% error medidor serie', Type: 'number'},
    {Name: 'R', Type: 'number'},
    {Name: 'S', Type: 'number'},
    {Name: 'T', Type: 'number'},
    {Name: 'Total', Type: 'number'},
]
// Observaciones //
const MEASURE_CODE_INPUTS = [
    {Name: '¿INSTALACIÓN TIENE MEDIDOR DE RESPALDO?', Type: 'text', Values: ['SI', 'NO', 'N/A']},
    {Name: '¿SE EFECTUA SINCRONIZACIÓN DE TIEMPO?', Type: 'text', Values: ['SI', 'NO', 'N/A']},
    {Name: '¿SE EFECTUA REPROGRAMACIÓN DEL MEDIDOR?', Type: 'text', Values: ['SI', 'NO', 'N/A']},
    {Name: '¿SE EFECTUO ENTREGA DE HOJA DE VIDA?', Type: 'text', Values: ['SI', 'NO', 'N/A']},
    {Name: '¿EL SISTEMA TIENE ELEMENTOS EN FALLA?', Type: 'text', Values: ['SI', 'NO', 'N/A']},      
]
const MARKET_RULES_INPUTS = [
    {Name: '¿SE ENCONTRÓ IRREGULARIDAD EN LA MEDIDA?', Type: 'text', Values: ['SI', 'NO']},
    {Name: '¿ASISTIÓ EL COMERCIALIZADOR A LA VISITA?', Type: 'text', Values: ['SI', 'NO']},
    {Name: '¿ASISTIÓ EL OPERADOR DE RED A LA VISITA?', Type: 'text', Values: ['SI', 'NO']},
    {Name: '¿SE EFECTUO SUSPENSIÓN O CORTE DEL SERVICIO?', Type: 'text', Values: ['SI', 'NO']},
    {Name: '¿SE EFECTUO RECONEXIÓN O REINSTALACIÓN?', Type: 'text', Values: ['SI', 'NO']},      
    {Name: '¿REGISTRA EL CONSUMO DE UN SOLO USUARIO?', Type: 'text', Values: ['SI', 'NO']},      
]
// Fotos //
// Diagramas //
// Desde el servidor //
// Ordenes de trabajo //
const ORDER_LIST = [{numero:'',cliente:'',fecha:'',direccion:'', tipo: ''},
{numero:'',cliente:'',fecha:'',direccion:'', tipo: ''}]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    photoItem: {
        padding: theme.spacing(3),
        border: '1px dashed grey',     
        background: theme.palette.secondary.light  
    },
    uploadButton: {
        borderRadius: 0
    },
    responsiveImg: {
        width: '100%',
        height: 'auto'
    },
    title: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.dark,
        padding: 0,
        borderRadius: 3,
        margin: theme.spacing(1),
        fontWeight: 'normal'
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "75%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.primary.dark,
        padding: 0
    },
    dialogTitle: {
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.secondary.main,
        background: theme.palette.primary.dark,
        fontWeight: 'normal',
        padding: 5
    },
    formText: {
        fontSize: 15,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    button : {
        fontSize: theme.typography.subtitle2.fontSize,      
        borderRadius: 8,
        fontWeight: 'normal',
        textTransform: 'none'
        //backgroundColor: theme.palette.primary.dark  
    },
    photoButton: {
       padding: 30,     
       color: theme.palette.primary.dark,
       fontWeight: 'bold'     
    },
    confirmButton : {
        fontSize: theme.typography.caption.fontSize,      
        borderRadius: 8,
        fontWeight: 'bold',
        padding: 5
        //backgroundColor: theme.palette.primary.dark  
    },
    navBar: {
        fontSize: theme.typography.subtitle2.fontSize,      
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.light,
        width: 'auto' 
    },
    tabRoot: {
        fontSize: theme.typography.subtitle1.fontSize,
        color: theme.palette.primary.dark,
        textTransform: 'none'
    },
    tabOn: {
        borderBottom: `4px solid ${theme.palette.primary.dark}`,  
    },
    panelRoot:{
        padding: 0,
        paddingTop: 5,
    },
    formPanel: {
        //width: '100%'
        padding: 10,
        background: theme.palette.secondary.main
    },
    formLabel: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        color: theme.palette.primary.dark
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formParagraphInput30: {
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.dark,
        maxWidth: '30px',
        textAlign: 'center',
        paddingBottom: 3,
        paddingTop: 0
    },
    formParagraphInput50: {
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.dark,
        maxWidth: '50px',
        textAlign: 'center',
        paddingBottom: 3,
        paddingTop: 0
    },
    formParagraphInput90: {
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.dark,
        maxWidth: '90px',
        textAlign: 'center',
        paddingBottom: 3,
        paddingTop: 0
    },
    formParagraphInput130: {
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.primary.dark,
        maxWidth: '130px',
        textAlign: 'center',
        paddingBottom: 3,
        paddingTop: 0
    },
    accordionTitle: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark,        
    },    
    noDisplay: {
        display: 'none'
    },
    signatureFormText:{
        maxWidth: "235px",       
        height: "75px",
    },
    sigContainer: {
        width: "250px",
        height: "100px",
        margin: "0 auto",
        backgroundColor: "#fff",
        borderRadius: 2,
        border: "1px solid black"
    },
    sigPad: {
        width: "100%",
        height: "100%"
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        fontSize: theme.typography.subtitle2.fontSize,        
    }
      
  }));


  
export default function Summary(props) {
    const classes = useStyles();
    // Navegacion
    // Valor de la seccion para la navegacion del acta
    const [tabValue, setTabValue] = useState(1);    
    // Estado de habilitacion de las tabs
    const [activeTabs, setActiveTabs] = useState([true, false, false, false ,false, false, false, false])
    // Checklist para las ordenes de trabajo de un tecnico
    const [selectedOrder, setSelectedOrder] = useState(ORDER_LIST.map((order)=>(false)))
    const [orderList, setOrderList] = useState(ORDER_LIST)
    // Flags para activar los dialogos
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [signatureDialog, setSignatureDialog] = useState(false) 
    const [sendInformartionDialog, setSendInformartionDialog] = useState(false) 
    const [emailListDialog, setEmailListDialog] = useState(false) 
   
    // Variables del formulario
    // Seccion encabezado //
    const [userData, setUserData] = useState(new Array(10).fill(null))
    const [netData, setNetData] = useState(new Array(11).fill(null))
    const [subsData, setSubsData] = useState(new Array(11).fill(null))    
    // Seccion medidores //
    const [meterType, setMeterType] = useState(0)
    const [foundMeterData, setFoundMeterData] = useState([new Array(18).fill(null), new Array(18).fill(null)])  
    const [installedMeterData, setInstalledMeterData] = useState([new Array(18).fill(null), new Array(18).fill(null)])
    const [retiredMeterData, setRetiredMeterData] = useState([new Array(18).fill(null), new Array(18).fill(null)])
    const [installedSellosData, setInstalledSellosData] = useState([new Array(16).fill(null), new Array(16).fill(null)])
    const [retiredSellosData, setRetiredSellosData] = useState([new Array(16).fill(null), new Array(16).fill(null)])
    // Seccion telemedida //
    const [telemeasureData, setTelemeasureData] = useState(new Array(12).fill(null))
    const [telemeasureFlags, setTelemeasureFlags] = useState(new Array(3).fill(false))
    // Seccion transformadores //
    const [transformerMeasureData, setTransformerMeasureData] = useState(new Array(2).fill(null))
    const [currentTrfType, setCurrentTrfType] = useState(0)
    const [voltageTrfType, setVoltageTrfType] = useState(0)
    const [currentInstalledTrfData, setCurrentInstalledTrfData] = useState([new Array(18).fill(null), new Array(18).fill(null), new Array(18).fill(null)])
    const [voltageInstalledTrfData, setVoltageInstalledTrfData] = useState([new Array(18).fill(null), new Array(18).fill(null), new Array(18).fill(null)])
    const [currentRetiredTrfData, setCurrentRetiredTrfData] = useState([new Array(11).fill(null), new Array(11).fill(null), new Array(11).fill(null)])
    const [voltageRetiredTrfData, setVoltageRetiredTrfData] = useState([new Array(11).fill(null), new Array(11).fill(null), new Array(11).fill(null)])
    const [errorPercentageData, setErrorPercentageData] = useState(new Array(21).fill(0))
    // Seccion observaciones //
    const [measureCodeQuestions, setMeasureCodeQuestions] = useState(new Array(6).fill(null))
    const [measureCodeQuestionsFlags, setMeasureCodeQuestionsFlags] = useState(new Array(5).fill(new Array(3).fill(false)))
    const [marketRulesQuestions, setMarketRulesQuestions] = useState(new Array(6).fill(null))
    const [marketRulesQuestionsFlags, setMarketRulesQuestionsFlags] = useState(new Array(6).fill(new Array(2).fill(false)))
    const [generalObservation, setGeneralObservation] = useState('')
    // Seccion fotos //
    const [photoURLs, setPhotoURLs] = useState(new Array (4).fill(null))
    const [photoFiles, setPhotoFiles] = useState(new Array (4).fill(null))
    // Seccion diagramas //
    const [diagramType, setDiagramType] = useState(-1)  
    const [diagramComponent, setDiagramComponent] = useState(null)  
    const [diagramFile, setDiagramFile] = useState(null)
    const [diagramLabels, setDiagramLabels] = useState( new Array(5).fill('') )
    // Seccion firmas //
    let sigPad = [new Set(),new Set(), new Set()]
    const[trimmedDataURL, setTrimmedDataURL] = useState([null, null, null])           
    const[signatureIdx, setSignatureIdx] = useState(0)                        
    const[signatureData, setSignatureData] = useState(new Array(6).fill(null)) 
    const[emailList, setEmailList] = useState([{label: 'Email 1', value: ''}])
    

    // Funciones necesarias //
    let navFunction = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        
        function success(pos) {
            var crd = pos.coords;
            let auxArray = telemeasureData
            auxArray[9] = crd.latitude
            auxArray[10] = crd.longitude
            setTelemeasureData([...auxArray])
            // //console.log('Your current position is:');
            // //console.log('Latitude : ' + crd.latitude);
            // //console.log('Longitude: ' + crd.longitude);
            // //console.log('More or less ' + crd.accuracy + ' meters.');
        };
        
        function error(err) {
            let auxArray = telemeasureData
            auxArray[9] = -1
            auxArray[10] = -1
            setTelemeasureData([...auxArray])
            console.warn('ERROR(' + err.code + '): ' + err.message);
                    
        };
        
        navigator.geolocation.getCurrentPosition(success, error, options);
    }
    const clearSigPad = (idx) => {    
        sigPad[idx].clear()           
    }
    const trimSignature = (idx) => {       
        setTrimmedDataURL((trimmedDataURL) => Object.assign(trimmedDataURL.slice(), {[idx]: !(sigPad[idx] === undefined)? sigPad[idx].getTrimmedCanvas().toDataURL('image/png'):null}))        
    }
    const handleSignatureChange = (idx) => (e) => {
        setSignatureIdx(idx)
        handleSignatureDialogOpen()
    }
    const updateDiagramByIdx = (idx, rpts, rcts, fx, cap, client) => {
        let DiagramComponent = SvgComponents[idx]
        let auxDiagram = <DiagramComponent rpts={rpts} rcts={rcts} fx={fx} cap={cap} client={client} className={classes.responsiveImg}/>
        let auxDiagramString = ReactDOMServer.renderToString(auxDiagram)
        let auxDiagramBlob = new Blob([auxDiagramString], {type: 'image/svg+xml'})
        let auxDiagramFile = new File([auxDiagramBlob], 'diagram.svg')
        setDiagramFile(auxDiagramFile)                
        setDiagramComponent(auxDiagram)  
    }
    function onClose() {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    // FUNCIONES DE NAVEGACION //
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
    const handleSignatureDialogOpen = () => {
        setSignatureDialog(true);
      };
    const handleSignatureDialogClose = () => {
        setSignatureDialog(false);
    };
    const handleSendInformationDialogOpen = () => {
        setSendInformartionDialog(true);
      }
    const handleSendInformationDialogClose = () => {
        setSendInformartionDialog(false);
    }
    const handleEmailListDialogOpen = () => {
        setEmailListDialog(true);
      };
    const handleEmailListDialogClose = () => {
        setEmailListDialog(false);
    };
    const handleEmailListChange = (idx) => (e) => {
        let auxEmailList = emailList
        auxEmailList[idx].value = e.target.value
        setEmailList([...auxEmailList])
    }
    const addObjToEmailList = (e) => {
        let auxEmailList = emailList
        auxEmailList.push({label: 'Email ' + String(Number(emailList.length + 1)), value: ''}) 
        setEmailList([...auxEmailList])
    }
    const deleteObjFromEmailList = (e) => {
        let auxEmailList = emailList
        auxEmailList.pop()
        setEmailList([...auxEmailList])
    }
    const stepConfirmation = () => (
        <Grid container justify="space-evenly">       
            {
                tabValue > 1 ? 
                <Grid item xs={4} md={2}>
                    <Button
                        //startIcon={<CheckBoxIcon size="small"/>}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{marginTop:10}}
                        onClick={() => {                                                                                                                                           
                                setTabValue((tabValue) => (tabValue-1));
                                }}
                    >
                        Atras
                    </Button>
                </Grid>
                :
                null
            }     
            <Grid item xs={4} md={2}>
                <Button
                    //startIcon={<CheckBoxIcon size="small"/>}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{marginTop:10}}
                    onClick={() => {
                            handleFeedbackDialogOpen();                                                         
                            if (tabValue <= ACT_SECTIONS.length){ setActiveTabs( (activeTabs) => ( Object.assign( activeTabs.slice(), {[tabValue]: true}) ) );}                            
                            setTabValue((tabValue) => (tabValue+1));
                            }}
                >
                    Confirmar
                </Button>
            </Grid> 
        </Grid>
        
    )
    const showSectionValidationDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.dialogContent}>
                Confirmación de la validación de la información.
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button className={classes.button} onClick={() => {handleFeedbackDialogClose(); }} color="primary" variant="contained">
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
    const saveConfirmation = () => (
        <Grid container spacing={3} justify="space-evenly">
            <Grid item xs={4} md={2}>
                <Button
                    //startIcon={<CheckBoxIcon size="small"/>}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{marginTop:10}}
                    onClick={() => {                                                                                                                                           
                            setTabValue((tabValue) => (tabValue-1));
                            }}
                >
                    Atras
                </Button>
            </Grid>           
            <Grid item xs={4} md={2}>
                <Button
                    //startIcon={<CheckBoxIcon size="small"/>}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{marginTop:10}}
                    onClick={handleEmailListDialogOpen}
                >
                    Enviar
                </Button>
            </Grid> 
        </Grid>        
    )
    const validSendInformartionDialog = () => (
        <Dialog
            open={sendInformartionDialog}
            onClose={handleSendInformationDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.dialogContent}>
               {window.navigator.onLine ? 'Ingresando valores' : 'Dispositivo sin conexión. Valores guardados en memoria'}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button className={classes.button} onClick={() => {handleSendInformationDialogClose(); handleEmailListDialogClose();}} color="primary" variant="contained">
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
    const showSignatureDialog = (idx) => (
        <Dialog
            open={signatureDialog}
            onClose={handleSignatureDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Ingrese su firma</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.dialogContent}>
                <div className={classes.sigContainer}>
                    <SignaturePad canvasProps={{className: classes.sigPad}}
                    ref={(ref) => { sigPad[idx] = ref }} />
                </div> 
            </DialogContentText>
        </DialogContent>
        <DialogActions>     
          <Grid container justify="space-evenly">
              <Grid item>
                <Button className={classes.button} onClick={() => {clearSigPad(idx);}} color="primary" variant="contained">
                    Limpiar
                </Button> 
              </Grid>
              <Grid item xs={2}>
                  
              </Grid>
              <Grid item>
                <Button className={classes.button} onClick={() => {handleSignatureDialogClose(); trimSignature(idx);}} color="primary" variant="contained">
                     Guardar
                </Button>
              </Grid>
          </Grid>                       
        </DialogActions>
        </Dialog>
    )
    const showEmailListDialog = () => (
        <Dialog
            open={emailListDialog}
            onClose={handleEmailListDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Ingrese los emails destino</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.dialogContent}>
                <Grid container spacing={3} justify="space-evenly">                    
                    {
                        emailList.map((emailObj, idx) => (
                            <Grid item xs={12}>
                                <TextField                   
                                    label={emailObj.label}                                                                               
                                    type={'text'}
                                    value={emailObj.value}    
                                    onChange={handleEmailListChange(idx)}
                                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="filled"
                                    className={classes.formRow}                                               
                                />
                            </Grid>
                        ))
                    }
                    <Grid item>
                        <Button startIcon={<AddIcon/>} className={classes.button} onClick={addObjToEmailList} color="secondary" variant="contained">
                            Agregar
                        </Button>
                    </Grid>                    
                    {
                        emailList.length > 1 ?
                        <Grid item>
                        <Button startIcon={<RemoveIcon/>} className={classes.button} onClick={deleteObjFromEmailList} color="secondary" variant="contained">
                            Eliminar
                        </Button>
                        </Grid>
                        : 
                        null
                    }                                          
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>     
          <Grid container justify="space-evenly">
              <Grid item>
                <Button className={classes.button} onClick={handleEmailListDialogClose} color="primary" variant="contained">
                    Cancelar
                </Button>
                
              </Grid>
              <Grid item xs={2}>
                  
              </Grid>
              <Grid item>
                <Button className={classes.button} onClick={() => {handleSendInformationDialogOpen();}} color="primary" variant="contained">
                    Confirmar
                </Button> 
              </Grid>
          </Grid>                       
        </DialogActions>
        </Dialog>
    )
    // FUNCIONES DE CONTROL //
    // Ordenes de trabajo //
    const handleSelectedOrderChange = (e) => {
        let auxSelectedOrderArray = ORDER_LIST.map((order)=>(false))
        auxSelectedOrderArray[Number(e.target.name)] = true
        setSelectedOrder([...auxSelectedOrderArray])
    }
    // Formulario //
    // Encabezado //
    const handleUserDataFormChange = (idx) => (e) => {
        let auxArray = userData
        auxArray[idx] = e.target.value
        setUserData([...auxArray])          
        if (idx === 0){
            let auxLabels = diagramLabels
            auxLabels[4] = String(e.target.value)
            setDiagramLabels([...auxLabels])           
            if (diagramType !== -1){
                let finalLabels = ['Relación PTs: ' + diagramLabels[0], 
                                'Relación CTs: '  + diagramLabels[1],
                                'Factor FX: ' +  diagramLabels[2]
                                ,'Capacidad: ' + diagramLabels[3] + ' kVA'
                                ,'Cliente: ' +  String(e.target.value)]
                updateDiagramByIdx(diagramType, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])
            } 
        }     
    }    
    const handleNetDataFormChange = (idx) => (e) => {
        let auxArray = netData
        auxArray[idx] = e.target.value
        setNetData([...auxArray])   
        if (idx === 6){
            let auxLabels = diagramLabels
            auxLabels[3] = String(e.target.value)
            setDiagramLabels([...auxLabels])           
            if (diagramType !== -1){
                let finalLabels = ['Relación PTs: ' + diagramLabels[0], 
                                'Relación CTs: '  + diagramLabels[1],
                                'Factor FX: ' +  diagramLabels[2]
                                ,'Capacidad: ' + String(e.target.value) + ' kVA'
                                ,'Cliente: ' +  diagramLabels[4]]
                updateDiagramByIdx(diagramType, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])
            } 
        }     
    }
    const handleSubsDataFormChange = (idx) => (e) => {
        let auxArray = subsData
        auxArray[idx] = e.target.value
        setSubsData([...auxArray])                
    }
    // Medidores //
    const handleMeterTypeChange = (e) => {
        setMeterType(e.target.value)
    }
    const handleFoundMeterDataChange = (idx) => (e) => {              
        let auxArray = foundMeterData
        auxArray[meterType][idx] = idx === 15 ? e : e.target.value
        setFoundMeterData([...auxArray])        
        if (idx === 16 && meterType === 0){
            let auxLabels = diagramLabels
            auxLabels[2] = String(e.target.value)
            setDiagramLabels([...auxLabels])  
            if (diagramType !== -1){
                let finalLabels = ['Relación PTs: ' + diagramLabels[0], 
                                'Relación CTs: '  + diagramLabels[1],
                                'Factor FX: ' +  String(e.target.value)
                                ,'Capacidad: ' + diagramLabels[3] + ' kVA'
                                ,'Cliente: ' + diagramLabels[4]]
                updateDiagramByIdx(diagramType, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])
            }          
            //
        }                    
    }
    const handleInstalledMeterDataChange = (idx) => (e) => {
        let auxArray = installedMeterData
        auxArray[meterType][idx] = idx === 15 ? e : e.target.value
        setInstalledMeterData([...auxArray])                        
    }
    const handleRetiredMeterDataChange = (idx) => (e) => {
        let auxArray = retiredMeterData
        auxArray[meterType][idx] = idx === 15 ? e : e.target.value
        setRetiredMeterData([...auxArray])                
    }
    const handleInstalledSellosDataChange = (idx) => (e) => {        
        let auxArray = installedSellosData
        auxArray[meterType][idx] = e.target.value
        setInstalledSellosData([...auxArray])                     
    }
    const handleRetiredSellosDataChange = (idx) => (e) => {
        let auxArray = retiredSellosData
        auxArray[meterType][idx] = e.target.value
        setRetiredSellosData([...auxArray])              
    }
    // Telemedida //
    const handleTelemeasureDataChange = (idx) => (e) => {
        let auxArray = telemeasureData
        auxArray[idx] = e.target.value
        setTelemeasureData([...auxArray])               
    }
    const handleTelemeasureFlagsChange = (idx) => (e) => {
        let auxArray = new Array(3).fill(false)
        auxArray[idx] = true
        setTelemeasureFlags([...auxArray])
        let auxDataArray = telemeasureData
        auxDataArray[auxDataArray.length - 1] = e.target.name
        setTelemeasureData([...auxDataArray])              
    }
    // Transformadores //
    const handleTrfTypeChange = (title) => (e) => {
        onClose();
        if (title === 'TC'){
            setCurrentTrfType(e.target.value)
        }
        else {
            setVoltageTrfType(e.target.value)
        }
    }
    const handleTransformerMeasureDataChange = (idx) => (e) => {
        let auxArray = transformerMeasureData
        auxArray[idx] = e.target.value
        setTransformerMeasureData([...auxArray])        
    }
    const handleCurrentInstalledTrfDataChange = (idx) => (e) => {
        let auxArray = currentInstalledTrfData
        auxArray[currentTrfType][idx] = e.target.value
        setCurrentInstalledTrfData([...auxArray])    
        if (idx === 9){
            auxArray[0][idx] = e.target.value
            auxArray[1][idx] = e.target.value
            auxArray[2][idx] = e.target.value
            setCurrentInstalledTrfData([...auxArray])
            let auxLabels = diagramLabels
            auxLabels[1] = String(e.target.value)
            setDiagramLabels([...auxLabels])  
            if (diagramType !== -1){
                let finalLabels = ['Relación PTs: ' + diagramLabels[0], 
                                'Relación CTs: '  + String(e.target.value),
                                'Factor FX: ' +  diagramLabels[2]
                                ,'Capacidad: ' + diagramLabels[3] + ' kVA'
                                ,'Cliente: ' + diagramLabels[4]]
                updateDiagramByIdx(diagramType, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])
            }          
            //
        }     
    }
    const handleVoltageInstalledTrfDataChange = (idx) => (e) => {
        let auxArray = voltageInstalledTrfData
        auxArray[voltageTrfType][idx] = e.target.value
        setVoltageInstalledTrfData([...auxArray]) 
        if (idx === 9){
            auxArray[0][idx] = e.target.value
            auxArray[1][idx] = e.target.value
            auxArray[2][idx] = e.target.value
            setVoltageInstalledTrfData([...auxArray]) 
            let auxLabels = diagramLabels
            auxLabels[0] = String(e.target.value)
            setDiagramLabels([...auxLabels])  
            if (diagramType !== -1){
                let finalLabels = ['Relación PTs: ' + String(e.target.value), 
                                'Relación CTs: '  + diagramLabels[1],
                                'Factor FX: ' +  diagramLabels[2]
                                ,'Capacidad: ' + diagramLabels[3] + ' kVA'
                                ,'Cliente: ' + diagramLabels[4]]
                updateDiagramByIdx(diagramType, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])
            }          
            //
        }            
    }
    const handleCurrentRetiredTrfDataChange = (idx) => (e) => {
        let auxArray = currentRetiredTrfData
        auxArray[currentTrfType][idx] = e.target.value
        setCurrentRetiredTrfData([...auxArray])   
        if (idx === 7){
            auxArray[0][idx] = e.target.value
            auxArray[1][idx] = e.target.value
            auxArray[2][idx] = e.target.value
            setCurrentRetiredTrfData([...auxArray]) 
        }             
    }
    const handleVoltageRetiredTrfDataChange = (idx) => (e) => {
        let auxArray = voltageRetiredTrfData
        auxArray[voltageTrfType][idx] = e.target.value
        setVoltageRetiredTrfData([...auxArray])
        if (idx === 7){
            auxArray[0][idx] = e.target.value
            auxArray[1][idx] = e.target.value
            auxArray[2][idx] = e.target.value
            setVoltageRetiredTrfData([...auxArray]) 
        }               
    }
    const handleErrorPercentageDataChange = (idx) => (e) => {
        let auxArray = errorPercentageData
        auxArray[idx] = Number(e.target.value)
        auxArray[4] = ((auxArray[1]+auxArray[2]+auxArray[3])/3).toFixed(2)
        auxArray[8] = ((auxArray[5]+auxArray[6]+auxArray[7])/3).toFixed(2)
        auxArray[12] = ((auxArray[9]+auxArray[10]+auxArray[11])/3).toFixed(2)
        auxArray[16] = ((auxArray[13]+auxArray[14]+auxArray[15])/3).toFixed(2)
        auxArray[20] = ((auxArray[17]+auxArray[18]+auxArray[19])/3).toFixed(2)
        setErrorPercentageData([...auxArray])        
    }
    // Observaciones //
    const handleMeasureCodeQuestionsChange = (pidx, idx) => (e) => {
        let flags = new Array(3).fill(false)
        flags[idx] = true
        let auxArray = measureCodeQuestionsFlags
        auxArray[pidx] = flags
        setMeasureCodeQuestionsFlags([...auxArray])
        let auxDataArray = measureCodeQuestions
        auxDataArray[pidx] = idx !== -1 ? e.target.name : e.target.value
        setMeasureCodeQuestions([...auxDataArray])             
    }
    const handleMarketRulesQuestionsChange = (pidx, idx) => (e) => {
        let flags = new Array(2).fill(false)
        flags[idx] = true
        let auxArray = marketRulesQuestionsFlags
        auxArray[pidx] = flags
        setMarketRulesQuestionsFlags([...auxArray])
        let auxDataArray = marketRulesQuestions
        auxDataArray[pidx] = e.target.name
        setMarketRulesQuestions([...auxDataArray])                     
    }
    const handleGeneralObservationChange = (e) => {
        setGeneralObservation(e.target.value)        
    }
    // Fotos //
    const handlePhotoInputChange = (idx) => (e) => {
        let auxArray = photoURLs
        auxArray[idx] = URL.createObjectURL(e.target.files[0])        
        setPhotoURLs([...auxArray])
        auxArray = photoFiles
        auxArray[idx] = e.target.files[0]
        setPhotoFiles([...auxArray])
        //console.log('files', auxArray)
    }
    const deletePhotoURL = (idx) => (e) => {        
        let auxArray = photoURLs
        URL.revokeObjectURL(auxArray[idx])
        auxArray[idx] = null        
        setPhotoURLs([...auxArray])
        auxArray = photoFiles
        auxArray[idx] = null
        setPhotoFiles([...auxArray])
    }
    // Diagramas //
    const handleDiagramTypeChange = (e) => {        
        setDiagramType(e.target.value)   
        let finalLabels = ['Relación PTs: ' + diagramLabels[0], 
                                'Relación CTs: '  + diagramLabels[1],
                                'Factor FX: ' +  diagramLabels[2]
                                ,'Capacidad: ' + diagramLabels[3] + ' kVA'
                                ,'Cliente: ' + diagramLabels[4]]
        updateDiagramByIdx(e.target.value, finalLabels[0], finalLabels[1], finalLabels[2], finalLabels[3], finalLabels[4])        
    }    
    // Firmas //
    const handleSignatureDataChange = (idx) => (e) => {
        let auxArray = signatureData
        auxArray[idx] = e.target.value
        setSignatureData([...auxArray])       
    }
    // COMPONENTES COMO FUNCIONES //
    // Seccion encabezado //
    const userDataForm = () => (
        <Grid container spacing={2}>           
            {
                USER_DATA_INPUTS.map((obj, idx) => (
                    <Grid item xs={12} md={4}>                   
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={userData[idx]}    
                            onChange={handleUserDataFormChange(idx)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >
                            {
                                obj.Select ?
                                obj.Values.map((value) => (
                                    <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                ))                                
                                :
                                null
                            }
                        </TextField>
                    </Grid> 
                ))
            }                                                                
        </Grid>        
    )      
    const netDataForm = () => (
        <Grid container spacing={2}>        
            {                
                NET_DATA_INPUTS.map((obj, idx) => (
                    <Grid item xs={12} md={4}>                   
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={netData[idx]}    
                            onChange={handleNetDataFormChange(idx)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >
                            {
                                obj.Select ?
                                obj.Values.map((value) => (
                                    <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                ))                                
                                :
                                null
                            }
                        </TextField>
                    </Grid> 
                ))
            }                                        
        </Grid>        
    )
    const susbDataForm = (classes) => (
        <Grid container spacing={0} alignItems="flex-end" style={{padding: 0}}>
            <Grid item>
                <p style={{maxHeight: '5px'}}>En</p>
            </Grid>
            <Grid item>
                 <TextField value={subsData[0]} onChange={handleSubsDataFormChange(0)} InputProps={{classes: {input: classes.formParagraphInput90}}} size="small"></TextField>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>a los</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[1]} onChange={handleSubsDataFormChange(1)} InputProps={{classes: {input: classes.formParagraphInput50}}} size="small"></TextField>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>dias del mes&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>de</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[2]} onChange={handleSubsDataFormChange(2)} InputProps={{classes: {input: classes.formParagraphInput90}}} size="small"></TextField>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>a las horas
                se hicieron&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>presentes los sres:</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[3]} onChange={handleSubsDataFormChange(3)}  InputProps={{classes: {input: classes.formParagraphInput130}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}> y</p>
            </Grid>   
            <Grid item>
                <TextField value={subsData[4]} onChange={handleSubsDataFormChange(4)} InputProps={{classes: {input: classes.formParagraphInput130}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>en representación&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>de las empresas</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[5]} onChange={handleSubsDataFormChange(5)} InputProps={{classes: {input: classes.formParagraphInput130}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}> y</p>
            </Grid>   
            <Grid item>
                <TextField value={subsData[6]} onChange={handleSubsDataFormChange(6)} InputProps={{classes: {input: classes.formParagraphInput130}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>respectivamente,&nbsp;</p>
            </Grid>            
            <Grid item>
                <p style={{maxHeight: '5px'}}>con el fin de realizar una revisión a los equipos&nbsp;</p>
            </Grid>     
            <Grid item>
                <p style={{maxHeight: '5px'}}>que componen el sistema de medida del&nbsp;</p>
            </Grid>     
            <Grid item>
                <p style={{maxHeight: '5px'}}>inmueble. Se informa al suscriptor y/o usuario&nbsp;</p>
            </Grid>  
            <Grid item>
                <p style={{maxHeight: '5px'}}>su derecho a solicitar asesoría o participación&nbsp;</p>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>de un técnico particular al cual se le concede&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>un término máximo de</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[7]} onChange={handleSubsDataFormChange(7)} InputProps={{classes: {input: classes.formParagraphInput50}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>minutos, para&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>hacerse presente en el sitio de la revisión.&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>Cumplido este tiempo se procede a efectuar la&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>revisión. El suscriptor hace uso de sus&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>derechos SI</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[8]} onChange={handleSubsDataFormChange(8)} InputProps={{classes: {input: classes.formParagraphInput30}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>NO</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[9]} onChange={handleSubsDataFormChange(9)} InputProps={{classes: {input: classes.formParagraphInput30}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>En las mediciones&nbsp;</p>
            </Grid>           
            <Grid item>
                <p style={{maxHeight: '5px'}}> correspondientes a fronteras comerciales&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>representadas por&nbsp;</p>
            </Grid>
            <Grid item>
                <TextField value={subsData[10]} onChange={handleSubsDataFormChange(10)} InputProps={{classes: {input: classes.formParagraphInput130}}} size="small"></TextField>
            </Grid> 
            <Grid item>
                <p style={{maxHeight: '5px'}}>se procede de conformidad con las&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>resoluciones emitidas por la CREG, en&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>particular lo establecido en el reglamento de&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>Comercialización (Res. CREG 156 de 2011) y&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>Código de Medida (Res. CREG 038 de 2014) o&nbsp;</p>
            </Grid>
            <Grid item>
                <p style={{maxHeight: '5px'}}>aquellas que la modifiquen o sustituyan.&nbsp;</p>
            </Grid>
        </Grid>       
    )
    // Seccion medidores //
    const meterDataForm = (classes, dataControl, changeControl) => (

        <Grid container spacing={2} justify="center"> 
            <Grid item xs={12} md={12}>                   
                <TextField                    
                    label={'Medidor'}
                    select
                    value={meterType}
                    onChange={handleMeterTypeChange}                    
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                          
                >                 
                    {/* {
                        idx === 2 ? <MenuItem value="NGN" className={classes.formItem}>NINGUNO</MenuItem> : null
                    }                        */}
                    <MenuItem value={0} className={classes.formItem}>PRINCIPAL</MenuItem>
                    <MenuItem value={1} className={classes.formItem}>RESPALDO</MenuItem>                                                                       
                </TextField>
            </Grid>
            {
                METER_DATA_INPUTS.map((obj, idx) => (
                    obj.Shared ? 
                    <Fragment>                                                       
                        <Grid item xs={12} md={3}>                                               
                            {
                                obj.Type !== 'date' ?                                 
                                <TextField                                                       
                                    label={obj.Name}                                            
                                    select={obj.Select}
                                    type={obj.Type}
                                    value={dataControl[meterType][idx >= 6 ? idx + 3 : idx] ? dataControl[meterType][idx >= 6 ? idx + 3 : idx] : ''}  
                                    defaultValue={''}                                                  
                                    onChange={changeControl(idx >= 6 ? idx + 3 : idx)}
                                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    variant="filled"
                                    className={classes.formRow}                                               
                                >
                                    {
                                        obj.Select ?
                                        obj.Multi ?                                         
                                            dataControl[meterType][1] ?
                                            obj.Values[dataControl[meterType][1]].map((value) => (
                                                <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                            ))          
                                            :
                                            []                                            
                                        :
                                        obj.Values.map((value) => (
                                            <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                        ))                                
                                        :
                                        null
                                    }
                                </TextField>                                 
                                :
                                <DatePicker    
                                    label={obj.Name} 
                                    variant="dialog"    
                                    inputVariant="filled"                                                                                 
                                    okLabel={""}
                                    cancelLabel={""}
                                    openTo="month"
                                    format="MMMM-yyyy"                                            
                                    views={["year","month"]}                                           
                                    value={dataControl[meterType][idx+3] ? dataControl[meterType][idx+3] : new Date()}                                           
                                    onChange={changeControl(idx+3)}
                                    error={false}
                                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                    InputProps={{                                            
                                                classes: {
                                                    input: classes.formText,                                    
                                                }
                                            }}                                
                                    autoOk       
                                    fullWidth                                                                                                               
                                /> 
                            }
                        </Grid>                     
                    </Fragment>
                    :
                    <Fragment>                                                       
                        <Grid item xs={12} md={4}>     
                            <Typography variant="subtitle2" color="primary">{obj.Name}</Typography>              
                            <TextField                                                       
                                label={'Medidor activa'}                                            
                                select={obj.Select}
                                type={obj.Type}
                                value={dataControl[meterType][obj.Idxs[0]] ? dataControl[meterType][obj.Idxs[0]] : ''}  
                                defaultValue={''}                                                  
                                onChange={changeControl(obj.Idxs[0])}
                                InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="filled"
                                className={classes.formRow}                                               
                            >
                                {
                                    obj.Select ?                                
                                    obj.Values.map((value) => (
                                        <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                    ))                                
                                    :
                                    null
                                }
                            </TextField>   
                            <TextField                                                       
                                label={'Medidor reactiva'}                                            
                                select={obj.Select}
                                type={obj.Type}
                                value={dataControl[meterType][obj.Idxs[1]] ? dataControl[meterType][obj.Idxs[1]] : ''}  
                                defaultValue={''}                                                  
                                onChange={changeControl(obj.Idxs[1])}
                                InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="filled"
                                className={classes.formRow}                                               
                            >
                                {
                                    obj.Select ?                                    
                                    obj.Values.map((value) => (
                                        <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                    ))                                
                                    :
                                    null
                                }
                            </TextField>
                        </Grid>                       
                    </Fragment>
                ))
            }                     
        </Grid>        
    )
    const sellosDataForm = (classes, dataControl, changeControl) => (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={12}>
                <TextField                    
                    label={'Medidor'}
                    select
                    value={meterType}
                    onChange={handleMeterTypeChange}                    
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                          
                >                                    
                    <MenuItem value={0} className={classes.formItem}>PRINCIPAL</MenuItem>
                    <MenuItem value={1} className={classes.formItem}>RESPALDO</MenuItem>                                                                       
                </TextField>
            </Grid>
            
        {['INSTALADO', 'RETIRADO', 'OR-RF-LAB'].map((title, pidx) => (
            <Fragment>   
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{title}</Typography>
                </Grid>                                                        
                {
                    SELLOS_DATA_INPUTS.slice(0,5).map((obj, idx) => (
                        <Grid item xs={12} md={3}>                                               
                            <TextField                   
                                label={obj.Name}                                            
                                select={obj.Select}
                                type={obj.Type}
                                value={dataControl[meterType][5*pidx + idx] ? dataControl[meterType][5*pidx + idx] : ''}    
                                defaultValue={''}
                                onChange={changeControl(5*pidx + idx)}
                                InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="filled"
                                className={classes.formRow}                                               
                            >                                
                            </TextField>
                        </Grid> 
                    ))
                }                                                   
            </Fragment>
        ))}
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary">CELDA DE MEDIDA</Typography> 
            </Grid>
            <Grid item xs={12} md={3}>                                  
                <TextField                   
                    label={SELLOS_DATA_INPUTS[SELLOS_DATA_INPUTS.length-1].Name}                                            
                    select={SELLOS_DATA_INPUTS[SELLOS_DATA_INPUTS.length-1].Select}
                    type={SELLOS_DATA_INPUTS[SELLOS_DATA_INPUTS.length-1].Type}
                    value={dataControl[meterType][dataControl[meterType].length - 1] 
                        ? dataControl[meterType][dataControl[meterType].length - 1]: ''} 
                    defaultValue={''}   
                    onChange={changeControl(dataControl[meterType].length - 1)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                                               
                >                                
                </TextField>
            </Grid> 
        </Grid>        
    )
    // Seccion telemedida //
    const telemeasureDataForm = (classes) => {        
        return(
            <Grid container spacing={2} justify="center">
                {['INSTALADO', 'RETIRADO'].map((title, pidx) => (
                    <Fragment>                
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="primary">{title}</Typography>
                        </Grid>                                 
                        {
                            TELEMEASURE_DATA_INPUTS.slice(0,4).map((obj, idx) => (
                                <Grid item xs={12} md={3}>                                               
                                    <TextField                   
                                        label={obj.Name}                                            
                                        select={obj.Select}
                                        type={obj.Type}
                                        value={telemeasureData[4*pidx + idx] ? telemeasureData[4*pidx + idx] : ''}    
                                        defaultValue={''}
                                        onChange={handleTelemeasureDataChange(4*pidx + idx)}
                                        InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                        InputProps={{
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="filled"
                                        className={classes.formRow}                                               
                                    >                                
                                    </TextField>
                                </Grid> 
                            ))
                        }    
                    </Fragment>
                ))}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">RELOJ MEDIDOR</Typography>
                    </Grid>  
                    <Grid item xs={12} md={3}>                   
                        <TextField                   
                            label={TELEMEASURE_DATA_INPUTS[4].Name}                                            
                            select={TELEMEASURE_DATA_INPUTS[4].Select}
                            type={TELEMEASURE_DATA_INPUTS[4].Type}
                            value={telemeasureData[8] ? telemeasureData[8] : ''}    
                            defaultValue={''}
                            onChange={handleTelemeasureDataChange(8)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">GEORREFERENCIA</Typography>
                    </Grid>  
                    <Grid item xs={12} md={3}>                   
                        <TextField                   
                            label={TELEMEASURE_DATA_INPUTS[5].Name}                                            
                            select={TELEMEASURE_DATA_INPUTS[5].Select}
                            type={TELEMEASURE_DATA_INPUTS[5].Type}
                            value={telemeasureData[9] ? telemeasureData[9] : ''}    
                            defaultValue={''}
                            onChange={handleTelemeasureDataChange(9)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        />  
                    </Grid>
                    <Grid item xs={12} md={3}>                   
                        <TextField                   
                            label={TELEMEASURE_DATA_INPUTS[6].Name}                                            
                            select={TELEMEASURE_DATA_INPUTS[6].Select}
                            type={TELEMEASURE_DATA_INPUTS[6].Type}
                            value={telemeasureData[10] ? telemeasureData[10] : ''}    
                            defaultValue={''}
                            onChange={handleTelemeasureDataChange(10)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        />  
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">FUNCIONAMIENTO</Typography>
                    </Grid> 
                    <Grid item xs={12}>            
                        {
                            TELEMEASURE_DATA_INPUTS[TELEMEASURE_DATA_INPUTS.length - 1].Values.map((label, idx) => (
                                <FormControlLabel className={classes.formText} control={<Checkbox checked={telemeasureFlags[idx]} name={label} onChange={handleTelemeasureFlagsChange(idx)}  color="primary" />} label={label} />
                            ))
                        }                                               
                    </Grid>
            </Grid> 
    )}
    // Seccion transformadores //
    const transformerMeasureDataForm = (classes) => (
        <Grid container spacing={2} justify="center">
            {
                MEASURE_DATA_INPUTS.map((obj, idx) => (
                    <Grid item xs={12} md={3}> 
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={transformerMeasureData[idx] ? transformerMeasureData[idx] : ''}    
                            defaultValue={''}
                            onChange={handleTransformerMeasureDataChange(idx)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                ))
            }           
        </Grid>
    )
    const installedTRFDataForm = (classes, title, trfType, dataControl, changeControl) => (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
                <TextField                    
                    label={'No. Trafo'}
                    select
                    value={trfType}
                    onChange={handleTrfTypeChange(title)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}      
                    onClose={onClose}
                >
                    {
                        title.includes('TC') ? 
                        ['TC1','TC2','TC3'].map((label, idx) =>(
                            <MenuItem value={idx} className={classes.formItem}>{label}</MenuItem>
                        ))
                    :
                        ['TT1','TT2','TT3'].map((label, idx) =>(
                        <MenuItem value={idx} className={classes.formItem}>{label}</MenuItem>
                        ))
                    }                    
                </TextField>
            </Grid>
            {
                INSTALLED_TRF_DATA_INPUTS.map((obj, idx) => (
                    <Grid item xs={12} md={3}> 
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={dataControl[trfType][idx] ? dataControl[trfType][idx] : ''}    
                            defaultValue={''}
                            onChange={changeControl(idx)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                ))
            } 
                
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary">PRUEBAS</Typography>
            </Grid>                                                           
            {                    
                title.includes('TC') ?                     
                CURRENT_TESTS.map((obj, idx) => (
                    <Grid item xs={12} md={3}> 
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={dataControl[trfType][idx+13] ? dataControl[trfType][idx+13] : ''}    
                            defaultValue={''}
                            onChange={changeControl(idx+13)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                ))                     
                :
                VOLTAGE_TESTS.map((obj, idx) => (
                    <Grid item xs={12} md={3}> 
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={dataControl[trfType][idx+13] ? dataControl[trfType][idx+13] : ''}    
                            defaultValue={''}
                            onChange={changeControl(idx+13)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                )) 
            }                                    
        </Grid> 
    )
    const retiredTRFDataForm = (classes, title, trfType, dataControl, changeControl) => (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
                <TextField                    
                    label={'No. Trafo'}
                    select
                    value={trfType}
                    onChange={handleTrfTypeChange(title)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}      
                                         
                >
                    {
                        title.includes('TC') ? 
                        ['TC1','TC2','TC3'].map((label, idx) =>(
                            <MenuItem value={idx} className={classes.formItem}>{label}</MenuItem>
                        ))
                    :
                        ['TT1','TT2','TT3'].map((label, idx) =>(
                        <MenuItem value={idx} className={classes.formItem}>{label}</MenuItem>
                        ))
                    }                    
                </TextField>
            </Grid>
            {
                RETIRED_TRF_DATA_INPUTS.map((obj, idx) => (
                    <Grid item xs={12} md={3}> 
                        <TextField                   
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            value={dataControl[trfType][idx] ? dataControl[trfType][idx] : ''}    
                            defaultValue={''}
                            onChange={changeControl(idx)}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}                                               
                        >                                
                        </TextField>
                    </Grid>
                ))
            }                                                           
        </Grid> 
    )
    const errorPercentageForm = (classes) => (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={3}> 
                <TextField                   
                    label={ERROR_PERCENTAGE[0].Name}                                            
                    select={ERROR_PERCENTAGE[0].Select}
                    type={ERROR_PERCENTAGE[0].Type}
                    value={errorPercentageData[0] ? errorPercentageData[0] : ''}    
                    defaultValue={''}
                    onChange={handleErrorPercentageDataChange(0)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                                               
                >                                
                </TextField>
            </Grid> 
            {['TENSIÓN (V)', 'CORRIENTE (A)', 'FACTOR DE POTENCIA', '% ERROR ACTIVA', '% ERROR REACTIVA'].map((title, pidx) => (
                <Fragment>                
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{title}</Typography>
                </Grid> 
                {
                    ERROR_PERCENTAGE.slice(1,5).map((obj, idx) =>(
                        <Grid item xs={12} md={3}> 
                            <TextField                   
                                label={obj.Name}                                            
                                select={obj.Select}
                                type={obj.Type}
                                value={obj.Name !== 'Total' ? Number(errorPercentageData[4*pidx + idx + 1]) > 0 ?
                                    Number(errorPercentageData[4*pidx + idx + 1]) : ''
                                      : ((Number(errorPercentageData[4*pidx + idx - 2]) + 
                                        Number(errorPercentageData[4*pidx + idx - 1]) + 
                                        Number(errorPercentageData[4*pidx + idx]))/3).toFixed(2)}        
                                defaultValue={''}                            
                                onChange={handleErrorPercentageDataChange(4*pidx + idx + 1)}
                                InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="filled"
                                className={classes.formRow}                                               
                            >                                
                            </TextField>
                        </Grid> 
                    ))
                }                
                </Fragment>
            ))}                
        </Grid>
    )
    // Seccion observaciones //
    const measureCodeQuestionsForm = (classes) => (
        <Grid container spacing={2} justify="center">            
            {MEASURE_CODE_INPUTS.map((Obj, pidx) => (
                <Fragment>                
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">{Obj.Name}</Typography>
                    </Grid> 
                    <Grid item xs={12}>    
                        {
                            Obj.Values.map((label, idx) => (
                                <FormControlLabel className={classes.formText} control={<Checkbox checked={measureCodeQuestionsFlags[pidx][idx]} name={label} onChange={handleMeasureCodeQuestionsChange(pidx, idx)}  color="primary" />} label={label} />
                            ))
                        }                                                                         
                    </Grid>                              
                </Fragment>
            ))} 
            {
                measureCodeQuestionsFlags[4][0] ?
                    <Grid item xs={12} md={4}>                   
                        <TextField                    
                            label={'¿Cúales?'}                    
                            multiline
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            onChange={handleMeasureCodeQuestionsChange(5,-1)}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                }
                            }}
                            variant="filled"
                            className={classes.formRow}      
                                                
                        />
                    </Grid> 
                    :
                    null
            }               
        </Grid>
    )
    const marketRulesQuestionsForm = (classes) => (
        <Grid container spacing={2} justify="center">            
           {MARKET_RULES_INPUTS.map((Obj, pidx) => (
                <Fragment>                
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">{Obj.Name}</Typography>
                    </Grid> 
                    <Grid item xs={12}>    
                        {
                            Obj.Values.map((label, idx) => (
                                <FormControlLabel className={classes.formText} control={<Checkbox checked={marketRulesQuestionsFlags[pidx][idx]} name={label} onChange={handleMarketRulesQuestionsChange(pidx, idx)}  color="primary" />} label={label} />
                            ))
                        }                                                                         
                    </Grid>                              
                </Fragment>
            ))}                           
        </Grid>
    )
    const generalObservationForm = (classes) => (
        <Grid container spacing={2}>
            <Grid item xs={12}>                   
                <TextField                    
                    label={'Comentario'}
                    value={generalObservation}
                    onChange={handleGeneralObservationChange}                    
                    multiline
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                                               
                />
            </Grid> 
        </Grid>
    )
    // Seccion fotos //
    const photoListForm = (classes) => (
        <Grid container spacing={2}>
            {
                ['Foto 1', 'Foto 2', 'Foto 3', 'Foto 4'].map((label, idx) => (
                    <Grid item xs={12} md={6}>
                        <Box className={classes.photoItem}>
                            {
                                photoURLs[idx] ===  null ?
                                <form action={"#"}>                            
                                    <label htmlFor={label}>
                                    <input hidden id={label} name={label} type="file" accept="image/*" onInput={handlePhotoInputChange(idx)}/>
                                    <Button fullWidth className={classes.photoButton} component="span">
                                        {label}                                
                                    </Button>
                                    </label>                                
                                </form>
                                :
                                <Fragment>
                                    <img src={photoURLs[idx]} className={classes.responsiveImg} style={{marginBottom: 20}}/>  
                                    <Button                                                                                                                                                                            
                                        className={classes.button}
                                        onClick={deletePhotoURL(idx)}
                                        component="span"
                                        color="primary" variant="contained"
                                        startIcon={ <ReplayIcon />}
                                    >
                                        Cambiar
                                    </Button>                                                                                                          
                                </Fragment>
                            }                            
                        </Box>                                                        
                    </Grid>
                ))
            }
        </Grid>
    )
    // Seccion diagramas //
    const diagramSelectionForm = (classes) => (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={12}>                   
                <TextField                    
                    label={'Tipo de diagrama'}
                    select
                    value={diagramType}
                    onChange={handleDiagramTypeChange}                    
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                          
                >                                     
                    <MenuItem value={0} className={classes.formItem}>Medida indirecta</MenuItem>
                    <MenuItem value={1} className={classes.formItem}>Medida indirecta con respaldo</MenuItem>        
                    <MenuItem value={2} className={classes.formItem}>Medida semidirecta trafo compartido</MenuItem>  
                    <MenuItem value={3} className={classes.formItem}>Medida semidirecta trafo dedicado</MenuItem>  
                    <MenuItem value={4} className={classes.formItem}>Medida directa</MenuItem>                                                                 
                </TextField>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box style={{border: '1px dashed grey',}}>                             
                {
                    diagramComponent
                }    
                </Box>                                     
            </Grid>
        </Grid>
    )
    // Seccion firmas //
    const signatureDataForm = (classes, idx) => (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>                   
                <TextField                    
                    label={'Nombre'}
                    value={signatureData[2*idx]}
                    onChange={handleSignatureDataChange(2*idx)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}      
                                         
                />
            </Grid>
            <Grid item xs={12} md={4}>                   
                <TextField                    
                    label={'C.C. No.'}
                    value={signatureData[2*idx + 1]}
                    onChange={handleSignatureDataChange(2*idx + 1)}
                    InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                    InputProps={{
                        classes: {
                            input: classes.formText
                        }
                    }}
                    variant="filled"
                    className={classes.formRow}                                               
                />
            </Grid>
            <Grid item xs={12} md={4}>                   
                <TextField                    
                    label={'Firma'}                                       
                    InputLabelProps={{ 
                        shrink: true, 
                        classes: {root:classes.formLabel},                                                
                    }}
                    InputProps={{
                        readOnly: true,
                        classes: {
                            input: classes.signatureFormText
                        },
                        endAdornment:
                            <Grid container justify="flex-start" alignItems="center">
                                <Grid item>
                                
                                    <img
                                        src={trimmedDataURL[idx]}                                
                                        style={{
                                            height: 65,
                                            width: 200,
                                            borderRadius: 2,
                                            border: "1px solid black",                                    
                                        }}
                                    />
                          
                                </Grid>
                            </Grid>
                            
                    }}                                          
                    variant="filled"                        
                    onClick={handleSignatureChange(idx)}
                >                    
                </TextField>
            </Grid>      
        </Grid>
    )    
    useEffect (() => { 
        document.title ="Acta revisión técnica";
        navFunction()
        // authFetch("/api/check-markets?username=" + String(props.username)).then(response => {
        //     if (response.status === 401){
        //         //console.log("No autorizado.")
        //         return null
        //     }
        //         return response.json()
        //     }).then(response => {
        //         if (response && response.markets && response.cmc){
        //             setMarketList(response.markets)
        //             ////console.log(response)
        //             setCmcList(response.cmc)
        //         }
        //     })
    }, [])

    return (
        <Zoom in timeout={800}> 
        <div className={classes.root}>           
            {showSectionValidationDialog()}
            {showSignatureDialog(signatureIdx)}
            {validSendInformartionDialog()}  
            {showEmailListDialog()}         
            <Grid container justify="center">
                <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" className={classes.title}>Ordenes de trabajo pendientes</Typography>
                </Grid>                                   
                <Grid item xs={12} md={10}>                                                                              
                    <List dense className={classes.list}>
                        {orderList.map((order, idx) => (
                            <ListItem key={idx}>                                    
                                <ListItemText primary={`Orden # ${order.numero}`} style={{margin:0}}
                                    secondary={
                                        <Grid container>
                                            <Grid item xs={12}>
                                            Dirección:  {order.direccion}
                                            </Grid>
                                            <Grid item xs={12}>
                                            Fecha:{order.fecha}
                                            </Grid>    
                                        </Grid>                                                                                    
                                    }/>
                                <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    color="primary"  
                                    name={idx}
                                    checked={selectedOrder[idx]}    
                                    onChange={handleSelectedOrderChange}                           
                                />
                                </ListItemSecondaryAction>
                            </ListItem>
                            )
                        )}
                    </List>                                                                                                                                  
                </Grid>                              
                <Grid item xs={12}></Grid>
                <Grid item xs={4} md={1}>
                    <Button
                        //startIcon={<FindInPageIcon size="small"/>}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        style={{marginTop:10}}
                        fullWidth
                    >
                        Cargar
                    </Button>
                </Grid> 
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" className={classes.title}>Acta de revisión/instalación medición de energía</Typography>
                </Grid>                    
                <Grid item xs={12} md={10}>
                    <TabContext value={tabValue}>
                        <AppBar position="static" className={classes.navBar} color="secondary">
                            <TabList onChange={handleTabChange} centered variant="scrollable">
                                {
                                    ACT_SECTIONS.map((label, idx)=>(
                                        <Tab label={label} value={idx+1} disabled={!activeTabs[idx]} classes={{root: classes.tabRoot, selected: classes.tabOn,}} />
                                    ))
                                }                           
                            </TabList>
                        </AppBar>
                        <TabPanel value={1} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>                                
                                    {
                                        false ?
                                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                                        <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Datos del usuario</AccordionSummary>
                                        <AccordionDetails>{userDataForm()}</AccordionDetails>
                                        </Accordion>
                                        :
                                        null
                                    }                                                                                                        
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Datos de la red</AccordionSummary>
                                    <AccordionDetails>{netDataForm()}</AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Aceptación suscriptor</AccordionSummary>
                                    <AccordionDetails>{susbDataForm(classes)}</AccordionDetails>
                                </Accordion>  
                                                            
                            </Paper>
                            {stepConfirmation()}  
                        </TabPanel>
                        <TabPanel value={2} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Medidor encontrado</AccordionSummary>
                                    <AccordionDetails>{meterDataForm(classes, foundMeterData, handleFoundMeterDataChange)}</AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Medidor instalado</AccordionSummary>
                                    <AccordionDetails>{meterDataForm(classes, installedMeterData, handleInstalledMeterDataChange)}</AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Medidor retirado</AccordionSummary>
                                    <AccordionDetails>{meterDataForm(classes, retiredMeterData, handleRetiredMeterDataChange)}</AccordionDetails>
                                </Accordion>  
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Sellos medidor instalado</AccordionSummary>
                                    <AccordionDetails>{sellosDataForm(classes, installedSellosData, handleInstalledSellosDataChange)}</AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Sellos medidor retirado</AccordionSummary>
                                    <AccordionDetails>{sellosDataForm(classes, retiredSellosData, handleRetiredSellosDataChange)}</AccordionDetails>
                                </Accordion>
                                                            
                            </Paper>
                            {stepConfirmation()} 
                        </TabPanel>
                        <TabPanel value={3} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Verificación telemedida</AccordionSummary>
                                    <AccordionDetails>{telemeasureDataForm(classes)}</AccordionDetails>
                                </Accordion>
                            
                            
                            </Paper>
                            {stepConfirmation()}
                        </TabPanel>
                        <TabPanel value={4} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Medición</AccordionSummary>
                                    <AccordionDetails>{transformerMeasureDataForm(classes)}</AccordionDetails>
                                </Accordion>   
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Trafos de corriente inst/enct</AccordionSummary>
                                    <AccordionDetails>{installedTRFDataForm(classes,'TC', currentTrfType, currentInstalledTrfData, handleCurrentInstalledTrfDataChange)}</AccordionDetails>
                                </Accordion> 
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Trafos de tensión inst/enct</AccordionSummary>
                                    <AccordionDetails>{installedTRFDataForm(classes,'TT', voltageTrfType, voltageInstalledTrfData, handleVoltageInstalledTrfDataChange)}</AccordionDetails>
                                </Accordion> 
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Trafos de corriente retirados</AccordionSummary>
                                    <AccordionDetails>{retiredTRFDataForm(classes,'TC', currentTrfType, currentRetiredTrfData, handleCurrentRetiredTrfDataChange)}</AccordionDetails>
                                </Accordion> 
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Trafos de tensión retirados</AccordionSummary>
                                    <AccordionDetails>{retiredTRFDataForm(classes,'TT', voltageTrfType, voltageRetiredTrfData, handleVoltageRetiredTrfDataChange)}</AccordionDetails>
                                </Accordion> 
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Porcentajes de error</AccordionSummary>
                                    <AccordionDetails>{errorPercentageForm(classes)}</AccordionDetails>
                                </Accordion>                      
                            
                            </Paper>
                            {stepConfirmation()}
                        </TabPanel>
                        <TabPanel value={5} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>                        
                                <Accordion>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Código de medida</AccordionSummary>
                                    <AccordionDetails>{measureCodeQuestionsForm(classes)}</AccordionDetails>
                                </Accordion> 
                                <Accordion>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Reglamento de comercialización</AccordionSummary>
                                    <AccordionDetails>{marketRulesQuestionsForm(classes)}</AccordionDetails>
                                </Accordion> 
                                <Accordion>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Observaciones/irregularidades</AccordionSummary>
                                    <AccordionDetails>{generalObservationForm(classes)}</AccordionDetails>
                                </Accordion>  
                            
                            </Paper>
                            {stepConfirmation()}
                        </TabPanel>
                        <TabPanel value={6} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>  
                                <Accordion>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Listado de fotos</AccordionSummary>
                                    <AccordionDetails>{photoListForm(classes)}</AccordionDetails>
                                </Accordion>                                              
                            </Paper>
                            {stepConfirmation()}
                        </TabPanel>
                        <TabPanel value={7} classes={{root: classes.panelRoot}}>
                        <Paper className={classes.formPanel}>  
                                <Accordion>
                                    <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon  color="primary"/>}>Diagramas</AccordionSummary>
                                    <AccordionDetails>{diagramSelectionForm(classes)}</AccordionDetails>
                                </Accordion>                                              
                            </Paper>
                            {stepConfirmation()}
                        </TabPanel>
                        <TabPanel value={8} classes={{root: classes.panelRoot}}>
                            <Paper className={classes.formPanel}>
                                {["usuario", "R.F.", "ag. OR"].map((name,idx) => (
                                    <Accordion key={idx}>
                                        <AccordionSummary className={classes.accordionTitle} expandIcon={<AddIcon color="primary"/>}>Constancia de aceptación {name}</AccordionSummary>
                                        <AccordionDetails>{signatureDataForm(classes, idx)}</AccordionDetails>                                    
                                    </Accordion>                                
                                ))}                                                        
                                
                            </Paper>
                            {saveConfirmation()}
                        </TabPanel>
                    </TabContext>
                </Grid>      
            </Grid>       
        </div>
        </Zoom> 
    );
}