import React from 'react'
import {useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Stepper, Step, StepButton, StepLabel, Collapse, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Grid, Paper, IconButton, Button, TextField,
    Box, Typography, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Zoom, CircularProgress} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {authFetch, logout} from '../../auth';
import getPeriods from '../../const/yearPeriods';
import getMonths from '../../const/monthPeriods';
import getAbbMonths from '../../const/abbMonthsPeriods';
import validateInput from '../../const/validation';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: theme.spacing(2),
      color: theme.palette.primary.main
    },
    componentTitle: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(1),
      marginTop: '20%'
    },
    componentTitleNoMargin: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(3),      
    },
    rlqMsg: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: 10,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(1),
      paddingTop: 0
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    modalPaper: {
      padding: theme.spacing(3),
      marginTop: '10%'
    },
    stepper: {
        padding: theme.spacing(3)
    },
    stepText: {
        color: theme.palette.secondary.dark,
    },
    stepTextActive: {
        color: theme.palette.primary.main,
    }, 
    uploadButton : {
      fontSize: 13,
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
    }
}));

const useRowStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    hoverBackground:{
      background: theme.palette.action.hover
    },
}));

const StyledTableCell3StepInfo = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.primary.main}`
  },
  body: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
    color: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.light}`
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,      
      padding: 5
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableCellSecondStep = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,     
      //width: "15%",
      padding: 5
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
    },
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableCellCollapse = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,      
      padding: 5
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.dark
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,     
    },
  }))(TableRow);

/* Validacion */

/*Data de las tablas*/
function getSteps() {
    return ['Verifique la información de los ciclos a procesar.', 'Suba los archivos correspondientes.', 'Compruebe la información procesada.'];
}

  
function ThirdStepRow(props) {
    const row  = props.row;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <StyledTableRow className={classes.root}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>          
          <StyledTableCell align="left">
            {row.code}
          </StyledTableCell>
          <StyledTableCell align="left">
            {props.name}
          </StyledTableCell>
          <StyledTableCell align="left">{props.cyclesUsers[row.code]}</StyledTableCell>
          <StyledTableCell align="left">{ String(props.billingDate.getMonth() + 1) + '/' + String(props.billingDate.getFullYear())}</StyledTableCell>          
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className={classes.hoverBackground}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div" color="primary">
                  Información asociada
                </Typography>
                <Grid container spacing={1} justify="center">
                  <Grid item xs={5}>
                    <Table size="small" style={{tableLayout: 'fixed', fontSize: 11}}>
                      <TableHead>
                        <StyledTableRow>
                        {['Usuarios procesados'].map((label, idx) => (
                          <StyledTableCell3StepInfo key={idx} align="center" style={{fontSize: 12}}>{label}</StyledTableCell3StepInfo>
                        ))}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>                                       
                        <StyledTableRow>
                        {[props.cycleData[ 'u_'+row.code ]].map((value, idx) => (
                          <StyledTableCell3StepInfo key={idx} align="center"  style={{fontSize: 12}}>{value}</StyledTableCell3StepInfo>
                        ))}
                        </StyledTableRow>                                                        
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={5}>
                    <Table size="small" style={{tableLayout: 'fixed', fontSize: 11}}>
                      <TableHead>
                        <StyledTableRow>
                        {['Códigos sin procesar'].map((label, idx) => (
                          <StyledTableCell3StepInfo key={idx} align="center" style={{fontSize: 12}}>{label}</StyledTableCell3StepInfo>
                        ))}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>                                       
                        
                        {props.cycleData[ 'u_np_'+row.code ].map((value, idx) => (
                          <StyledTableRow>
                            <StyledTableCell3StepInfo key={idx} align="center"  style={{fontSize: 12}}>{value}</StyledTableCell3StepInfo>
                          </StyledTableRow>
                        ))}
                                                                                
                      </TableBody>
                    </Table>
                  </Grid>
                  
                </Grid>
                
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
}

export default function InputVerification (props) {
    const classes  = useStyles();      
    let billingDate = props.billingDate        
    const [modificationDialog, setModificationDialog] = useState(false)
    const [modificationDesignation, setModificationDesignation] = useState([])
    const [uploadDialog, setUploadDialog] = useState(false)
    const [uploadMessage, setUploadMessage] = useState('')
    const [activeStep, setActiveStep] = useState(0);

    const [cycles, setCycles] = useState([])
    const [cyclesUsers, setCyclesUsers] = useState({})
    const [cycleCredits, setCycleCredits] = useState({})
    const [cycleOtherConcepts, setCycleOtherConcepts] = useState({})
    const [billingStatus, setBillingStatus] = useState({})

    const [isLoading, setIsLoading] = useState(false)
    const [clickIndex, setClickIndex] = useState(-1)
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleBack = () => {        
        if (activeStep > 0){
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleModificationDialogClose = () => {
      setModificationDialog(false)
    }

    const handleUploadDialogClose = () => {
      setUploadDialog(false)
    }

    // A modificar acorde a agpe gd
    const handleUploadFile = (e, cycle, type)  => {      
      e.preventDefault()    
      if (e.target.files.length > 0){                 
        setIsLoading(true)                     
        const formData = new FormData()
        let i = 0
        let file = {}
        for (file of e.target.files){
            formData.append('file' + i, file)
            i = i + 1
        }
        let opts = {
            'filename': e.target.files[0].name,      
            'contentType': e.target.files[0].type,              
        }
        authFetch(
            "/api/get-gcs-signed-url?username="+props.username+
                            "&period="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear(),
            {
                method: 'post',                
                //body: formData,
                body: JSON.stringify(opts)
            }
        ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response.url){   
                let gcs_signed_url = response.url
                fetch(
                    gcs_signed_url,               
                    {
                        method: 'put',            
                        body: e.target.files[0],
                        headers: {
                            'Content-Type': e.target.files[0].type
                        }
                    }
                ).then(r => {if (r.status === 200){ return true } else {return false}})
                    .then(response => {
                        if (response){
                          //alert('llamado a procesar')
                          let fetchURL = type === 'oth' ? "/api/process-other-concepts-file" : '/api/process-credits-concepts-file'
                          authFetch(fetchURL + "?username="+props.username+
                                                            "&billing_period="+String( billingDate.getMonth() + 1 )+                                                    
                                                            "&billing_year="+ billingDate.getFullYear() +                                                              
                                                            "&cycle="+ cycle
                            ,
                            {
                              method: 'post',                
                              body: JSON.stringify(opts)
                            }
                          ).then(response => {
                            if (response.status !== 200){
                              setUploadDialog(true)
                              setIsLoading(false)
                              setUploadMessage('Error al subir archivos.')
                              if (response.status === 422){
                                logout()
                              }
                              return null
                            }
                              return response.json()
                            }).then(response => {
                              ////console.log('res', response)
                              if (response && response.Upload === 'OK'){                 
                                setUploadMessage(type === 'oth' ? 'Archivo de otros conceptos procesado exitosamente.' : 'Archivo de créditos procesado exitosamente.')                    
                                setUploadDialog(true) 
                                setIsLoading(false)
                              }       
                              else {    
                                setUploadMessage('Error.')                    
                                setUploadDialog(true) 
                                setIsLoading(false)                            
                              }                                    
                          }
                          )
                        }                       
                        else {
                            setUploadDialog(true)
                            setIsLoading(false)
                            setUploadMessage('Error al subir archivo.')
                        }                              
                    })
            }
            else {
            setUploadDialog(true)
            setIsLoading(false)
            setUploadMessage('Error al subir archivos.')
            }
        }) 
                                                          
      
      } 
      else {
        setUploadDialog(true)
        setUploadMessage('No ha seleccionado ningún archivo.')
      }        
    }

    const handleSwitchChange = (event) => {
      setModificationDialog(true)
      setModificationDesignation([event.target.name, event.target.id])
    }

    // A modificar para bd agpe gd 
    const deleteFromDialog = () => {
      setIsLoading(true)          
      let fetchURL = modificationDesignation[1].slice(0,3) === 'oth' ?  "/api/delete-cycle-other-concepts" : "/api/delete-cycle-credit-concepts" 
      authFetch(
        fetchURL + "?username=" + String(props.username) +
        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )     
        +"&cycle="+modificationDesignation[0]   
       ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
          if (response && response.Delete === 'OK'){
            setIsLoading(false)
            setModificationDialog(false)
            setUploadMessage(modificationDesignation[1].slice(0,3) === 'oth' ? 'Otros conceptos' : 'Créditos' +  ' del ciclo eliminados.')                    
            setUploadDialog(true)
          }
          else {
            alert("Error en el proceso")
            setIsLoading(false)
          }
      })
    }

    useEffect (() => {
        document.title ="Consumos por ciclo"; 
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
          if (response.status === 422){
              logout()
          }
              return response.json()
          }).then(response => {
              if (response && response.cycles){                                                      
                  setCycles(response.cycles)                                                          
              }
          })
        authFetch("/api/get-cycle-users?username=" + String(props.username)+
          "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
          if (response.status === 422){
              logout()
          }
              return response.json()
          }).then(response => {
              if (response && response.cycle_users){   
                  let cycleUsers = response.cycle_users               
                  setCyclesUsers(response.cycle_users)
                  let opts = {
                    users_by_cycle: cycleUsers,                    
                  }  
                  authFetch("/api/check-cycle-other-concepts?username=" + String(props.username) +
                  "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                  {
                    method: 'post',
                    body: JSON.stringify(opts)
                  }
                  ).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.cycle_oth_concepts_flags){                                            
                          setCycleOtherConcepts(response.cycle_oth_concepts_flags)        
                          setCycleCredits(response.cycle_credit_concepts_flags)                                                                                                        
                        }
                    }) 
                  authFetch("/api/check-billing-status-by-cycle?username=" + String(props.username) +
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                    {
                        method: 'post',
                        body: JSON.stringify(opts)
                    }
                    ).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.billing_status){                                            
                                setBillingStatus(response.billing_status)                                                                                            
                            }
                        }
                    )                                     
              }
          }) 
        
    }, [isLoading, props.billingDate])

    const modificationConfirm = () => (
      <Dialog
        open={modificationDialog}
        onClose={handleModificationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea elimina la información seleccionada?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si desea eliminar la información relacionada al ciclo {modificationDesignation[0]}, haga click en ACEPTAR.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModificationDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => {deleteFromDialog(); handleModificationDialogClose();}} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    )
    
    const uploadConfirm = () => (
      <Dialog
        open={uploadDialog}
        onClose={handleUploadDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Información"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    )

    const uploadForm = (filename, extensions, idx, quantity, cycle, type, enabled, disabled) => (
      enabled && !disabled ?
      <form action={"#"}>
         <label htmlFor={type+idx}>
          <input
            hidden
            id={type+idx}
            name={type+idx}
            type="file"   
            accept={extensions} 
            onClick={(e) => {setClickIndex(type+idx); e.target.value = null;}}   
            onChange={(e) => {handleUploadFile(e, cycle, type); }}            
            multiple={quantity === "1" ? false : true}  
          />
          {
            isLoading && type+idx === clickIndex?
            <CircularProgress/>
            :
            <Fab
              color="primary"
              size="small"
              component="span"
              aria-label="add"
              variant="extended"                        
              classes={{root: classes.uploadButton}}      
              disabled={!enabled || disabled}                     
            >              
              <AddIcon /> { quantity === "1" ? 'Subir archivo' : 'Subir archivos' }
            </Fab>
          }
                  
        </label>
      </form>   
      :
      <Fab
        color="primary"
        size="small"
        component="span"
        aria-label="add"
        variant="extended"                        
        classes={{root: classes.uploadButton}}      
        disabled={!enabled || disabled}                     
      >        
        <AddIcon /> { quantity === "1" ? 'Subir archivo' : 'Subir archivos' }
      </Fab>
    )

    const firstStepContent = () => (
      <TableContainer component={Paper}>
        {modificationConfirm()}
        {uploadConfirm()}
        <Table className={classes.table} stickyHeader>
            <TableHead>
                <TableRow>
                    <StyledTableCell align="center" style={{width: "10%"}}>Ciclo</StyledTableCell>
                    <StyledTableCell align="center" style={{width: "20%"}}>Nombre</StyledTableCell>
                    <StyledTableCell align="center" style={{width: "10%"}}>No. usuarios</StyledTableCell>                    
                    <StyledTableCell align="center" style={{width: "15%"}}>Mercado</StyledTableCell>
                    <StyledTableCell align="center" style={{width: "12%"}}>Autogeneradores</StyledTableCell>                    
                    <StyledTableCell align="center" style={{width: "17%"}}>Ingreso</StyledTableCell>
                    <StyledTableCell align="center" style={{width: "16%"}}>Modificar</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { cycles.length > 0 ?
                 
                  cycles.map((cycle, idx) => (
                    <Fragment>
                      {
                        cyclesUsers[cycle.code] > 0 ?
                        <Fragment>
                        <StyledTableRow key={idx}>
                          
                          <StyledTableCell component="th" scope="row" align="center" rowspan={2}>{cycle.code}</StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">{'Otro conceptos'}</StyledTableCell>
                          <StyledTableCell align="center" rowspan={2}>{cyclesUsers[cycle.code]}</StyledTableCell>
                          <StyledTableCell align="center" rowspan={2}>{cycle.market_type}</StyledTableCell>
                          <StyledTableCell align="center" rowspan={2}>{cycle.autogenerators}</StyledTableCell>                          
                          <StyledTableCell align="center">
                            {
                                cycleOtherConcepts[cycle.code] ? 
                                <Grid container spacing={1} justify="center" alignItems="center">
                                  <Grid item>
                                    <CheckCircleOutlineIcon color="success"/>         
                                  </Grid> 
                                  <Grid item>
                                    Listo
                                  </Grid>                                                                                          
                                </Grid>                                
                                :
                                <Grid container spacing={1} justify="center" alignItems="center">
                                  <Grid item>
                                    <HourglassEmptyIcon color="error"/>         
                                  </Grid> 
                                  <Grid item>
                                    Pendiente
                                  </Grid>                                                                                          
                                </Grid>  
                            }
                          </StyledTableCell>                            
                          <StyledTableCell align="center">
                            {
                              isLoading && modificationDesignation[1] === 'oth'+idx? 
                              <CircularProgress/>
                              :                            
                              <Switch disabled={!cycleOtherConcepts[cycle.code] || billingStatus[cycle.code]} checked={modificationDialog && modificationDesignation[1] === 'oth'+idx} key={idx}  id={'oth'+idx} color="primary" name={cycle.code} onChange={handleSwitchChange}/>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow key={idx}>                                                    
                          <StyledTableCell component="th" scope="row" align="center">{'Créditos'}</StyledTableCell>                                               
                          <StyledTableCell align="center">
                            {
                                cycleCredits[cycle.code] ? 
                                <Grid container spacing={1} justify="center" alignItems="center">
                                  <Grid item>
                                    <CheckCircleOutlineIcon color="success"/>         
                                  </Grid> 
                                  <Grid item>
                                    Listo
                                  </Grid>                                                                                          
                                </Grid>                                
                                :
                                <Grid container spacing={1} justify="center" alignItems="center">
                                  <Grid item>
                                    <HourglassEmptyIcon color="error"/>         
                                  </Grid> 
                                  <Grid item>
                                    Pendiente
                                  </Grid>                                                                                          
                                </Grid>  
                            }
                          </StyledTableCell>                            
                          <StyledTableCell align="center">
                            {
                              isLoading && modificationDesignation[1] === 'crd'+idx? 
                              <CircularProgress/>
                              :                            
                              <Switch disabled={!cycleCredits[cycle.code] || billingStatus[cycle.code]} checked={modificationDialog && modificationDesignation[1] === 'crd'+idx} key={idx}  id={'crd'+idx} color="primary" name={cycle.code} onChange={handleSwitchChange}/>
                            }
                          </StyledTableCell>
                        </StyledTableRow>
                        </Fragment>
                        :
                        null
                      }
                      
                      </Fragment>
                    ))
                  :
                  <StyledTableRow>
                          <StyledTableCell component="th" scope="row" align="center">
                              -
                          </StyledTableCell>
                          <StyledTableCell align="center">-</StyledTableCell>
                          <StyledTableCell align="center">-</StyledTableCell>
                          <StyledTableCell align="center">-</StyledTableCell>
                          <StyledTableCell align="center">-</StyledTableCell>
                          <StyledTableCell align="center">-</StyledTableCell>
                  </StyledTableRow>
                }                
            </TableBody>
        </Table>
      </TableContainer>
    )

    const secondStepContent = () => (
      <Grid container justify="space-evenly" spacing={2}>
        <Grid item xs={7}>
          <Typography color="primary">FORMATO OTROS CONCEPTOS (Nombres de las columnas deben ser iguales)</Typography>
          <Table>
            <TableHead>
              <StyledTableRow>                
                <StyledTableCell align="center">CODIGO_CONCEPTO</StyledTableCell>
                <StyledTableCell align="center">CODIGO_SIC</StyledTableCell>
                <StyledTableCell align="center">VALOR_CONCEPTO</StyledTableCell>
              </StyledTableRow>
            </TableHead>
          </Table>  
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary">FORMATO CRÉDITOS (Nombres de las columnas deben ser iguales)</Typography>
          <Table>
            <TableHead>
              <StyledTableRow>                
                <StyledTableCell align="center">CODIGO CLIENTE</StyledTableCell>
                <StyledTableCell align="center">CONCEPTO</StyledTableCell>
                <StyledTableCell align="center">VALOR TOTAL</StyledTableCell>
                <StyledTableCell align="center">SALDO FINAL PERIODO</StyledTableCell>
                <StyledTableCell align="center">PAGO A CAPITAL</StyledTableCell>
                <StyledTableCell align="center">INTERES CORRIENTE</StyledTableCell>
                <StyledTableCell align="center">VR CUOTA MES</StyledTableCell>
                <StyledTableCell align="center">TASA INTERES EA</StyledTableCell>
                <StyledTableCell align="center">No. CUOTA</StyledTableCell>
              </StyledTableRow>
            </TableHead>
          </Table>  
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {uploadConfirm()}
            <Table className={classes.table} stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Ciclo</StyledTableCell>
                        <StyledTableCell align="center">Nombre</StyledTableCell>                                                                 
                        <StyledTableCellSecondStep align="center">Extensión</StyledTableCellSecondStep>
                        <StyledTableCellSecondStep align="center">Cantidad</StyledTableCellSecondStep>
                        <StyledTableCellSecondStep align="center" style={{width: "20%"}}>Consumos liquidados</StyledTableCellSecondStep>
                        <StyledTableCellSecondStep align="center">Ingreso</StyledTableCellSecondStep>
                      
                    </TableRow>
                </TableHead>
                <TableBody>
                    { cycles.length > 0 ? 
                        cycles.map((cycle, idx) => (                            
                            cyclesUsers[cycle.code] > 0 ?
                            <Fragment>
                              {
                              cycleOtherConcepts[cycle.code] ?                               
                              null
                              :
                              <StyledTableRow key={'oth'+idx}>
                                  <StyledTableCellSecondStep component="th" scope="row" align="center" rowSpan={! cycleCredits[cycle.code] ? 2 : 1}>
                                      {cycle.code}
                                  </StyledTableCellSecondStep>
                                  <StyledTableCellSecondStep align="center"> { 'Otros conceptos' }</StyledTableCellSecondStep>                                                     
                                  <StyledTableCellSecondStep align="center">{ '.xlsx' }</StyledTableCellSecondStep> 
                                  <StyledTableCellSecondStep align="center">{ 1 }</StyledTableCellSecondStep> 
                                  <StyledTableCellSecondStep align="center">
                                  {
                                      cycleOtherConcepts['liq_'+cycle.code] ? 
                                      <Grid container justify="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                          <CheckCircleOutlineIcon color="success"/>         
                                        </Grid> 
                                        <Grid item>
                                          Listo
                                        </Grid>                                                                                          
                                      </Grid>                                
                                      :
                                      <Grid container justify="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                          <HourglassEmptyIcon color="error"/>         
                                        </Grid> 
                                        <Grid item>
                                          Pendiente
                                        </Grid>                                                                                          
                                      </Grid>  
                                  }
                                  </StyledTableCellSecondStep>
                                  <StyledTableCellSecondStep align="center">
                                      {                                   
                                          uploadForm(cycle.code, '.xlsx', idx, '1', cycle.code, 'oth', cycleOtherConcepts['liq_'+cycle.code], billingStatus[cycle.code])
                                      }                                
                                  </StyledTableCellSecondStep>                                              
                              </StyledTableRow>
                              }
                              {
                              cycleCredits[cycle.code] ? 
                              null
                              :
                              <StyledTableRow key={'crd'+idx}>       
                                  {
                                    cycleOtherConcepts[cycle.code] ? 
                                    <StyledTableCellSecondStep component="th" scope="row" align="center">
                                      {cycle.code}
                                    </StyledTableCellSecondStep>
                                    :
                                    null
                                  }                        
                                  <StyledTableCellSecondStep align="center"> { 'Créditos' }</StyledTableCellSecondStep>                                                     
                                  <StyledTableCellSecondStep align="center">{ '.xlsx' }</StyledTableCellSecondStep> 
                                  <StyledTableCellSecondStep align="center">{ 1 }</StyledTableCellSecondStep> 
                                  <StyledTableCellSecondStep align="center">
                                  {
                                      cycleCredits['liq_'+cycle.code] ? 
                                      <Grid container justify="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                          <CheckCircleOutlineIcon color="success"/>         
                                        </Grid> 
                                        <Grid item>
                                          Listo
                                        </Grid>                                                                                          
                                      </Grid>                                
                                      :
                                      <Grid container justify="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                          <HourglassEmptyIcon color="error"/>         
                                        </Grid> 
                                        <Grid item>
                                          Pendiente
                                        </Grid>                                                                                          
                                      </Grid>  
                                  }
                                  </StyledTableCellSecondStep>
                                  <StyledTableCellSecondStep align="center">
                                      {                                   
                                          uploadForm(cycle.code, '.xlsx', idx, '1', cycle.code, 'crd', cycleCredits['liq_'+cycle.code], billingStatus[cycle.code])
                                      }                                
                                  </StyledTableCellSecondStep>                                              
                              </StyledTableRow>
                              }
                            </Fragment>
                            :
                            null
                        ))
                            
                        :
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row" align="left">
                              -
                          </StyledTableCell>
                          <StyledTableCell align="left">-</StyledTableCell>
                          <StyledTableCell align="left">
                            -
                          </StyledTableCell>
                          <StyledTableCell align="left">-</StyledTableCell>
                        </StyledTableRow>
                    }
                </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>      
    )

    const thirdStepContent = () => (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
        <TableHead>
              <TableRow>
                  <StyledTableCellCollapse/>
                  <StyledTableCellCollapse align="left">Ciclo</StyledTableCellCollapse>
                  <StyledTableCellCollapse align="left">Nombre</StyledTableCellCollapse>
                  <StyledTableCellCollapse align="left">No. usuarios</StyledTableCellCollapse>
                  <StyledTableCellCollapse align="left">Periodo de consumo</StyledTableCellCollapse>                 
              </TableRow>
          </TableHead>
          <TableBody>
            {cycles.length > 0 ?
                cycles.map((cycle, idx) => (   
                cyclesUsers[cycle.code] > 0 ?       
                <Fragment>      
                  <ThirdStepRow key={idx} row={cycle} billingDate={billingDate} cyclesUsers={cyclesUsers} name={'Otros conceptos'} cycleData={cycleOtherConcepts}/>
                  <ThirdStepRow key={idx} row={cycle} billingDate={billingDate} cyclesUsers={cyclesUsers} name={'Créditos'} cycleData={cycleCredits}/>
                </Fragment>
                :
                null
                ))
              :
              <Fragment>
                <StyledTableRow>
                        <StyledTableCell component="th" scope="row" align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                        <StyledTableCell component="th" scope="row" align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                        <StyledTableCell align="left">-</StyledTableCell>
                </StyledTableRow>
              </Fragment>
            }
          </TableBody>
        </Table>
      </TableContainer>
    )

    function getStepContent(step) {
        switch (step) {
          case 0:
            return (
              firstStepContent()
            )
          case 1:
            return (
              secondStepContent()
            )          
          case 2:
            return (
              thirdStepContent()
            );
          default:
            return 'Unknown step';
        }
    }

    return (
        <div className={classes.root}>
          {
             props.market !== '' ?
              //!props.tariffsPublished ?
              //<Grid container justify="center" alignItems="center">
                //<Grid item xs={10}>
                  //<Typography variant="subtitle2" className={classes.componentTitle}>Las tarifas no han sido publicadas para el periodo seleccionado.</Typography>
                //</Grid>
              //</Grid>              
              //:
              Object.keys(cycleCredits).length > 0 ?                          
              <Grid container spacing={3} justify="center">
                  <Zoom in timeout={800}>                                   
                    <Grid item xs={12}>                        
                        <Paper elevation={2}>
                            <Stepper nonLinear activeStep={activeStep} classes={{root: classes.stepper}}>
                                {steps.map((label, index) => {
                                return (
                                    <Step key={index} >
                                        <StepButton onClick={handleStep(index)}>
                                            <StepLabel classes={{label: classes.stepText, active: classes.stepTextActive}}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                                })}
                            </Stepper>                        
                        </Paper>                    
                    </Grid>
                  </Zoom>
                  <Zoom in timeout={800}>
                    <Grid item xs={3}>
                        <div>
                            <IconButton disabled={activeStep === 0} onClick={handleBack} className={classes.button} color={"primary"}>
                                <ArrowBackIosIcon/>
                            </IconButton>    
                            <IconButton disabled={activeStep === steps.length-1} onClick={handleNext} className={classes.button} color={"primary"}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                  </Zoom>
                  <Zoom in style={{transitionDelay: '600ms'}} timeout={800}>
                    <Grid item xs={12}>                               
                        
                      {getStepContent(activeStep)}            
                        
                    </Grid>
                  </Zoom>
              </Grid>             
              :
              <CircularProgress/>
            :
            null
          }            
        </div>
    );
}