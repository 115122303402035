import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Link, Redirect, Switch} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { responsiveFontSizes, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline"
import {getColors} from "./styles/theme"
import Login from "./components/LoginComponent"
import MainNav from "./components/MainNavigationComponent"
import { spectrumValidURLs } from './navigation/spectrumValid'
import { cedenarValidURLs } from './navigation/cedenarValid'

import './App.css';
import {useAuth, logout} from "./auth"
import getPeriods from './const/yearPeriods'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { RotateLeftOutlined, SignalCellularNullOutlined } from '@material-ui/icons';

// Funciona para recursos encontrados/no encontrados
// La habilitacion deshabilitacion de modulos y pestañas se hace en MainNav
const validURLsFromUser = (user, role) => {
 
    switch (user) {                        
        case 'SPECTRUM':
            switch (role){
              case 'dev':
                return spectrumValidURLs.filter(url => url.includes('/webspectrum/principal/') || url.includes('login') || url.includes('/webspectrum/facturador/'))
                break;
              case 'comer':
                return spectrumValidURLs.filter(url => url.includes('/webspectrum/principal/') || url.includes('login') )
                break;
              case 'only-fact':
                return spectrumValidURLs.filter(url => url.includes('/webspectrum/facturador/') || url.includes('login') )
                break;
              case 'fact':
                return spectrumValidURLs.filter(url => url.includes('/webspectrum/principal/') || url.includes('login') || url.includes('/webspectrum/facturador/'))
                break;
              case 'field-tech':
                let fieldTechValidURLs = spectrumValidURLs.filter(url => url.includes('/webspectrum/tecnico-operativo') || url.includes('login'))
                return fieldTechValidURLs
                break;
              default:
                return []
            }
        case 'Cedenar':
          switch (role){
            case 'dev':
              return cedenarValidURLs.filter(url => url.includes('/webcedenar/principal/') || url.includes('login') || (url.includes('/webcedenar/autogeneradores/') && !(url.includes('consultas')) ) )
              break;
            case 'comer':
              return cedenarValidURLs.filter(url => url.includes('/webcedenar/principal/') || url.includes('login') )
              break;          
            case 'ag':
              return cedenarValidURLs.filter(url => (url.includes('/webcedenar/autogeneradores/') && !(url.includes('consultas')))  || url.includes('login') )
              break;
            case 'ag_client':
              return cedenarValidURLs.filter(url => url.includes('/webcedenar/autogeneradores/consultas') || url.includes('login') )
              break;
            case 'ag_cons':
              return cedenarValidURLs.filter(url => url.includes('/webcedenar/autogeneradores/consultas') || url.includes('login') )
              break;
            default:
              return []
          }
          
        default:
            return([])
    }      
  
}

function App() {    
    
  var actualDate = new Date()
  var actualYear = actualDate.getFullYear()
  var actualMonth = actualDate.getMonth() // Month-1
  var prevMonthDate = new Date(actualYear, actualMonth-1, 1)
  const [logged] = useAuth()
  const localStorageUser = logged ? JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')) : null  
  const [username, setUsername] = useState(localStorageUser ? localStorageUser.user : '')
  const [role, setRole] = useState(localStorageUser ? localStorageUser.role : '')
  const [agent, setAgent] = useState(localStorageUser ? localStorageUser.agent : '')  
  const [user, setUser] = useState(localStorageUser ? localStorageUser.email : '')
  const [currentPath, setCurrentPath] = useState(window.location.pathname)
  const [inputPeriodDate, setInputPeriodDate] = useState(prevMonthDate)
  const [selectedMarket, setSelectedMarket] = useState('')
  const [marketDemand, setMarketDemand] = useState(1)
  const [energyBillingPeriod, setEnergyBillingPeriod] = useState(prevMonthDate)

  
  //window.screen.orientation.addEventListener('change', function(e) { window.location.reload() })

  const renewUserInfo = (user, role) => {
    if (user.length > 0){
      setUsername('')
    }
    else if (role.length > 0){
      setRole('')
    }
  }

  // logout()
  // //console.log('logged', logged)
  // //console.log('user', username)
  // //console.log('currentPath', currentPath)
  let validURLs = []

  if (!logged){
    validURLs = [];
    renewUserInfo(username, role)
  }
  else {
    validURLs = validURLsFromUser(username,role)    
  }
 

  let colors = getColors(username, role)
  let theme =  createMuiTheme({
    palette: {
      background: {
        default: colors.length > 3 ? colors[3] : "#ffffff"
      },
      primary: {
        main: !logged ?  "#329E21" : colors[0],
        contrastText: colors.length > 3 ? colors[1] : '#E8E6E3',
      },
      secondary: {
        main: colors.length > 3 ? colors[1] : '#E8E6E3',
        contrastText: !logged ?  "#329E21" : colors[0],
      }, 
      client: { 
        main: colors[1],
        contrastText: colors[2]
      },
      dashboard: colors.length > 3 ? {
        main: colors[1],
        selected: colors[4],
        background: colors[3],
        border: colors[5]
      }
      :
      {}
    },
    typography: {
      fontFamily: "Roboto Condensed"
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {         
          '*::-webkit-scrollbar': {
            width: '9px',    
                    
          },
          '::-webkit-scrollbar-track': {
            background: "#f1f1f1",     
            marginTop: 10,
            marginBottom: 10,       
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',           
          }
        }
      }
    }
  }, colors)
  theme = responsiveFontSizes(theme);


  return (
    <Router>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
          <div className="App">            
            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap" rel="stylesheet"></link>                       
            { logged && username.length > 0 && validURLs.length > 0 ?               
                currentPath != '/login' && currentPath.includes(username.toLowerCase())?      
                  validURLs.includes(currentPath)?
                  <Redirect to={currentPath}/> 
                  :
                  null                                
                :
                <React.Fragment>                  
                  {currentPath != validURLs[0] ? setCurrentPath(validURLs[0]) : null}
                  <Redirect to={validURLs[0]}/>     
                </React.Fragment>           
               : 
                <React.Fragment>
                  {currentPath != '/login' ? setCurrentPath('/login') : null}
                  <Redirect to="/login"/>                       
                </React.Fragment>
            }                               
            <Switch>
              <Route path="/login">               
                <Login setUsername={setUsername} setAgent={setAgent} setRole={setRole} setUser={setUser}/>        
              </Route>      
              <Route path={"/web"+username.toLowerCase()}>                               
                { validURLs.includes(currentPath) ?
                  <MainNav username={username} appName={"LEGACY"} clientColor={theme.palette.client.main} 
                    agent={agent} periodDate={inputPeriodDate} setPeriodDate={setInputPeriodDate}  
                    validURLs={validURLs}                             
                    market={selectedMarket} setMarket={setSelectedMarket} marketDemand={marketDemand} role={role} setMarketDemand={setMarketDemand}
                    billingDate={energyBillingPeriod} setBillingDate={setEnergyBillingPeriod} 
                    user={user}
                    />        
                  :
                  <div>Recurso no encontrado.<a href={validURLs[0]}>Volver al inicio</a></div>
                }
              </Route>    
            </Switch>  
          </div>                      
      </ThemeProvider> 
      </MuiPickersUtilsProvider>
    </Router>
  );
}

export default App;