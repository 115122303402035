import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox, IconButton, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 13,
      color: theme.palette.primary.dark,
      padding: 5,
      backgroundColor: 'white'
    },
  }))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },    
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: 'white',        
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();     
    let billingDate = props.billingDate
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")    
    const [internalReportStatus, setInternalReportStatus] = useState({})
    const [generationInProgress, setGenerationInProgress] = useState(false)
    const [downloadInProgress, setDownloadInProgress] = useState(false)

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };
 
    const callReportGeneration = (code) => {
        setGenerationInProgress(true)                     
        authFetch("/api/generate-internal-report?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&code="+code).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setGenerationInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {             
                if (response && response.Upload === 'OK'){
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Reporte " + code + " generado.")    
                    setGenerationInProgress(false)       
                    authFetch("/api/check-internal-reports-status?username=" + String(props.username)+
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.reports_status){                    
                                setInternalReportStatus(response.reports_status)                                          
                            }
                        })             
                }                                                                 
            })                 
         
        
    }

    const downloadReport = (code) => {
        setDownloadInProgress(true)
        authFetch("/api/download-report-file?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&code="+code + "&timestamp=" + new Date().toISOString().slice(0,19),{ responseType: 'blob' }).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setDownloadInProgress(false)             
                return null
            }
                return response.blob()
            }).then(blob => {                 
                setDownloadInProgress(false)            
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;    
                a.download = 'Reporte_001_'+props.username +'_'+ billingDate.getFullYear() +'_'+String( billingDate.getMonth() + 1 ) + '.csv' 
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);                                               
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                               
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 

    useEffect (() => {
        document.title ="Reportes internos"; 
        authFetch("/api/check-internal-reports-status?username=" + String(props.username)+
        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.reports_status){                    
                    setInternalReportStatus(response.reports_status)                                          
                }
            })               
    }, [props.billingDate])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                
                <Grid item xs={5}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Estado actual</Typography>
                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">                    
                        <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center" color="primary">Reporte 001</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    generationInProgress ?
                                        <Grid container spacing={2} justify="center">
                                            <CircularProgress></CircularProgress>
                                            <Grid item xs={12}></Grid>                                            
                                        </Grid>
                                        :
                                        <Fab
                                            color="primary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"                        
                                            classes={{root: classes.uploadButton}}
                                            style={{marginBottom:10}}
                                            disabled={!internalReportStatus['process_001']}
                                            onClick={(e) => {callReportGeneration('001');}}                                
                                        > 
                                            <Fragment><DonutLargeIcon /> {'Procesar'}</Fragment>                         
                                        </Fab>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    downloadInProgress ?
                                    <Grid container spacing={2} justify="center">
                                        <CircularProgress></CircularProgress>
                                        <Grid item xs={12}></Grid>                                            
                                    </Grid>
                                    :
                                    <Fab
                                        color="primary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"                        
                                        classes={{root: classes.uploadButton}}
                                        style={{marginBottom:10}}
                                        disabled={!internalReportStatus['download_001']}
                                        onClick={(e) => {downloadReport("001");}}                                
                                    > 
                                        <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                                    </Fab>      
                                }                                 
                            </Grid> 
                        </Grid>                   
                    </Paper>
                </Grid>                                                                                                 
            </Grid>   
            </Zoom>    
        </div>
    );
}