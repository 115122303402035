import {React, useEffect, useState, Fragment, useMemo, createContext, useContext, createElement} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DataGrid from 'react-data-grid';
import EditIcon from '@material-ui/icons/Edit';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    filterCell:{
        lineHeight: 35,
        padding: 0,
        '> div':{
            paddingBlock: 0,
            paddingInline: 8,
            '&:first-child': {
                borderBlockEnd: '1px solid black'
            }
        }
    },   
    tableText:{
        fontSize: 11,
        textAlign: 'left'
    },    
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    dataTable:{
        color: '#606B6A',
        ['--rdg-background-color']: '#D6EEEC',
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 90          
    },
    filterInput200: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",        
    },
    filterText: {
        fontSize: 11,
        color: 'white',
        textAlign: "left",        
        background: 'black'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

const FilterContext = createContext(undefined)

function HeaderFilter (props) {
    const filters = useContext(FilterContext)
    return(                        
        <input  className={props.filterInput} value={filters[props.field]} onChange={(e) => {            
            props.setFilters({...filters, [props.field]: e.target.value})
        }}></input> 
    )
}  

let CLIENT_FORM_SECTIONS = [
    {
        name: 'Datos básicos',
        formFields: [
            {
                label: 'Código SIC',                
                key: 'sic_code',
                disabled: true
            },
            {
                label: 'Código producto',               
                key: 'client_code',
                disabled: true
            },
            {
                label: 'Código contrato',               
                key: 'contract_code'
            },
            {
                label: 'Tipo de identificación',
                select: true,
                options: ['NIT','CC','DPI'],
                key: 'id_type'
            },
            {
                label: 'Identificación',               
                key: 'id_number'
            },
            {
                label: 'Nombre/Razón social',               
                key: 'client_name'
            },  
            {
                label: 'Dirección',               
                key: 'address'
            },
            {
                label: 'Municipio',               
                key: 'municip'
            },       
            {
                label: 'Correo electrónico',               
                key: 'email'
            },
            {
                label: 'Teléfono',               
                key: 'telephone'
            },              
            {
                label: 'Fecha de ingreso',
                type: 'date',                
                key: 'client_entry_date'
            },
            {
                label: 'Estado',
                type: 'switch',
                key: 'active'
            },
            {
                label: 'Fecha de salida',
                type: 'date',                
                key: 'client_left_date'
            },
        ],
        icon: <AssignmentIndIcon/>
    },
    {
        name: 'Datos comerciales',
        formFields:[            
            {
                label: 'Tipo de mercado',
                select: true,
                options: ['REGULADO','NO REGULADO'],
                key: 'client_market_type'
            },
            {
                label: 'Tipo de uso',
                select: true,
                options: ['RESIDENCIAL','COMERCIAL','INDUSTRIAL','OFICIAL','ESPECIAL','PROVISIONAL','SALUD','ACUEDUCTO','DISTRITO DE RIEGO'],
                key: 'use_type'
            },
            {
                label: 'Estrato',
                select: true,
                options: ['0','1','2','3','4','5','6'],
                key: 'stratum'
            },
            {
                label: 'Ciclo',
                select: true,
                options: [],
                key: 'client_cycle'
            },
            {
                label: 'Consumo subsistencia',
                select: true,
                options: ['1: 1-130 kWh', '2: 1-173 kWh'],
                key: 'subsistence_consumption'
            },
            {
                label: 'Tipo de autogenerador',
                select: true,
                options: ['AGPE','GD','AGGE'],
                key: 'ag_type'
            },
            {
                label: 'Utiliza FNCER',
                select: true,
                options: ['SI','NO'],
                key: 'fncer'
            },
            {
                label: 'Rango capacidad instalada',
                select: true,
                options: ['1: [0 - 0.1] MW', '2: [0.1 - 1] MW', , '3: [1 - 5] MW'],
                key: 'installed_capacity'
            },
            {
                label: 'Capacidad instalada kW',
                type: 'number',                
                key: 'spec_capacity'
            },
            {
                label: 'Aplica Precio Pactado',
                select: true,
                options: ['SI','NO'],
                key: 'agreed_price'
            },
            {
                label: 'Tipo de pago excedentes',
                select: true,
                options: ['1: Saldo para cubrir facturas de periodos siguientes','2: Saldo a pagarse en el siguiente mes', '3: Saldo a pagarse por semestres anuales'],
                key: 'exceed_payment'
            },
            {
                label: 'Contribución especial',
                type: 'number',                
                key: 'special_contribution'
            },
            // {
            //     label: 'Saldo a favor',
            //     type: 'number',                
            //     key: 'positive_balance'
            // },
            // {
            //     label: 'Fecha de pago saldo a favor',
            //     type: 'date',                
            //     key: 'p_balance_payment_date'
            // },
        ],
        icon: <ReceiptIcon/>
    },
    {
        name: 'Datos técnicos',
        formFields:[
            {
                label: 'Nivel de tensión',               
                select: true,
                options: ['1','Compartido','Usuario','2','3'],
                key: 'voltage_level'
            }, 
            {
                label: 'Marca medidor',               
                key: 'meter_brand'
            },            
            {
                label: 'Serial medidor',               
                key: 'client_meter'
            }, 
            {
                label: 'ID elemento medidor',               
                key: 'measurement_element_id'
            },  
            {
                label: 'Tipo de medición', 
                select: true,
                options: ['Lecturas','Consumos'],              
                key: 'measuring_type'
            },
            {
                label: 'Factor del medidor',               
                key: 'meter_factor'
            },          
            {
                label: 'Factor M',               
                key: 'm_factor'
            },            
            {
                label: 'No. Meses consecutivos sin penalizar',               
                key: 'consecutive_months_out_of_excess'
            },  
            {
                label: <pr>No. Meses con factor<br/> M = 6</pr>,               
                key: 'consecutive_months_m6'
            },              
            {
                label: 'Causa de no lectura',               
                select: true,
                options: [],
                key: 'no_lecture_cause'
            },         
        ],
        icon: <SettingsInputAntennaIcon/>
    }
]

export default function ClientControl (props) {
    const classes = useStyles();    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [clientInfoDialog, setClientInfoDialog] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState("")        
    const [loadingInformation, setLoadingInformation] = useState(true)
    const [clientInfo, setClientInfo] = useState({active: true})
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [cycles, setCycles] = useState([])
    const [causesList, setCausesList] = useState([])
    const [clientIdx, setClientIdx] = useState(null)
        
    let billingDate = props.billingDate   
    const [filters, setFilters] = useState({
        client_name: '',
        client_code: '',
        use_type: '',       
        client_market_type: '',
        stratum: '',
        ag_type: '',
        fncer: '',
        installed_capacity: '',       
    })
    const [columns, setColumns] = useState(
        [
            {key: 'edit', minWidth: 20, width: 50, frozen: true},
            {key: 'client_name', width: 250, frozen: true},
            {key: 'client_code', width: 120,},
            {key: 'use_type', width: 120},            
            {key: 'client_market_type', width: 120},
            {key: 'stratum', width: 120},
            {key: 'ag_type', width: 120},
            {key: 'fncer', width: 120},
            {key: 'installed_capacity', width: 120}
        ]
    )    

    const comerSec = CLIENT_FORM_SECTIONS.find(obj => obj.name === 'Datos comerciales')
    const techSec = CLIENT_FORM_SECTIONS.find(obj => obj.name === 'Datos técnicos')
    const cycleObj = comerSec.formFields.find(obj => obj.label === 'Ciclo')
    cycleObj.options = cycles
    const noLectureObj = techSec.formFields.find(obj => obj.label === 'Causa de no lectura')
    noLectureObj.options = causesList

    columns[1].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput200} value={filters.client_name} field={'client_name'}>        
        </HeaderFilter>
        )
    columns[2].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_code} field={'client_code'}>        
        </HeaderFilter>)
    columns[3].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.use_type} field={'use_type'}>        
        </HeaderFilter>)
    columns[4].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_market_type} field={'client_market_type'}>        
        </HeaderFilter>)
    columns[5].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.stratum} field={'stratum'}>        
        </HeaderFilter>)
    columns[6].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.ag_type} field={'ag_type'}>        
        </HeaderFilter>)
    columns[7].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.fncer} field={'fncer'}>        
        </HeaderFilter>)
    columns[8].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.installed_capacity} field={'installed_capacity'}>        
        </HeaderFilter>)
    const [titles, setTitles] = useState([
        {
            edit: <b>Editar</b>,
            client_name: <b>Nombre/Razón Social</b>,
            client_code: <b>Código interno</b>,                       
            use_type: <b>Uso</b>,
            client_market_type: <b>Mercado</b>,
            stratum: <b>Estrato</b>,
            ag_type: <b>Tipo</b>,
            fncer: <b>FNCER</b>,
            installed_capacity: <b>Capac. instalada</b>,            
        }
    ])
    const [rows, setRows] = useState([])

    
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handleClientInfoDialogOpen = () => {
        setClientInfoDialog(true);
      };
    
    const handleClientInfoDialogClose = () => {
        setClientInfoDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const downloadClients = () => {
        setIsLoading(true)
        authFetch("/api/clients-list-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&username=" + String(props.username),
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Usuarios_AG_Cedenar'+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    const updateValuesWithSelectedClient = (value, idx) => {
        if (value === -1){
            setClientInfo({active: true})
        }
        else{
            setClientInfo(value)
        }
        setClientIdx(idx)
    }

    const uploadClientInfo = () => {
        // PENDIENTE VALIDACION DE CAMPOS PARA INGRESO VALIDO DE CLIENTES
        // Y QUE CAMPOS NO SE PUEDEN MODIFICAR DESPUES DEL INGRESO        
        // if (Object.keys(anualParams).length < 5){
        //     handleFeedbackDialogOpen()
        //     setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        // }        
        delete clientInfo.edit
        authFetch(
            "/api/update-clients?username=" + String(props.username)+
            "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
            {
                method: 'post',
                body: JSON.stringify(clientInfo),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                    setFeedbackMsg('Datos guardados. Verifique la información en la lista.')
                    handleFeedbackDialogOpen()
                    authFetch("/api/check-clients?username=" + String(props.username)+                                                  
                                                    "&year="+ billingDate.getFullYear()).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.clients){                                       
                                let rows = response.clients
                                let rowsWithEditButton = []
                                rows.forEach(element => {
                                    element = {...element, edit:<EditIcon style={{fontSize:16}}/>}
                                    rowsWithEditButton.push(element)
                                });                                  
                                setRows(rowsWithEditButton)                   
                            }
                        }) 
                }
                else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                }
            })
        
    }

    const showClientInfoDialog = () => (
        <Dialog
            open={clientInfoDialog}
            onClose={handleClientInfoDialogClose}       
            className={classes.formText}  
            fullWidth
            scroll={'paper'}
            maxWidth={'md'}          
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Ingresar/Revisar la información del usuario</DialogTitle>
        <DialogContent className={classes.dialogContent}> 
            <Grid container spacing={2} justify="space-evenly" style={{padding: 10}}>
                {
                    CLIENT_FORM_SECTIONS.map((section, pidx) => (
                        <Grid item xs={12}>
                            <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === pidx} onChange={handlePanelChange(pidx)} >
                                <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                                    {section.icon}
                                    <Typography style={{marginLeft: 10}}>{section.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>                                
                                    <Grid container spacing={2}>
                                        {
                                            section.formFields.map((formField) => (
                                                ( (formField.key !== 'stratum' && formField.key !== 'subsistence_consumption' && formField.key !== 'measurement_element_id') || 
                                                (formField.key === 'stratum' && clientInfo.use_type === 'RESIDENCIAL') || 
                                                (formField.key === 'subsistence_consumption' && clientInfo.use_type === 'RESIDENCIAL') ||
                                                (formField.key === 'measurement_element_id' && clientInfo['measuring_type'] && clientInfo['measuring_type'] === 'Consumos'))
                                                ?
                                                <Grid item xs={3}>                                                                                                                                                     
                                                    <Typography color="primary" variant="subtitle2">{formField.label}</Typography>                                                    
                                                    {
                                                        formField.type !== 'date' ? 
                                                            formField.type !== 'switch' ?                                                                                                                                
                                                                    <TextField       
                                                                        disabled={formField.disabled && clientIdx !== null && rows[clientIdx][formField.key]}
                                                                        type={formField.type ? formField.type : 'text'}
                                                                        variant="standard"                                                        
                                                                        value={clientInfo[formField.key]}                                
                                                                        InputProps={{
                                                                            classes: {
                                                                                input: classes.formText
                                                                            }
                                                                        }}                                                    
                                                                        className={classes.formRow}   
                                                                        onChange={(e) => {setClientInfo({...clientInfo, [formField.key]: e.target.value});}}     
                                                                        select={formField.select}                      
                                                                    >
                                                                {
                                                                    formField.select ? 
                                                                        formField.key !== 'installed_capacity' ?
                                                                        formField.options.map((option, oidx) => (
                                                                            <MenuItem value={option} key={oidx}>{option}</MenuItem>
                                                                        ))
                                                                        :
                                                                        clientInfo.ag_type && clientInfo.ag_type === 'GD' ? 
                                                                        formField.options.map((option, oidx) => (
                                                                            <MenuItem value={option} key={oidx}>{option}</MenuItem>
                                                                        ))
                                                                        :
                                                                        clientInfo.ag_type && clientInfo.ag_type === 'AGPE' ?
                                                                        formField.options.slice(0,2).map((option, oidx) => (
                                                                            <MenuItem value={option} key={oidx}>{option}</MenuItem>
                                                                        ))
                                                                        :
                                                                        clientInfo.ag_type && clientInfo.ag_type === 'AGGE' ?
                                                                        formField.options.slice(-1).map((option, oidx) => (
                                                                            <MenuItem value={option} key={oidx}>{option}</MenuItem>
                                                                        ))
                                                                        :
                                                                        null
                                                                    :
                                                                    null
                                                                }                                                                                  
                                                                </TextField>                                                            
                                                            :
                                                            <Fragment>
                                                                <Switch
                                                                    checked={clientInfo[formField.key]}
                                                                    onChange={(e) => {setClientInfo({...clientInfo, [formField.key]: e.target.checked});}}
                                                                    color="primary"                                                 
                                                                />
                                                                <Typography variant="body2" color="primary">{clientInfo[formField.key]? 'Activo':'Inactivo'}</Typography>
                                                            </Fragment>
                                                        :
                                                        <DatePicker    
                                                            variant="dialog"    
                                                            inputVariant="standard"                                                                                 
                                                            okLabel={""}
                                                            cancelLabel={""}         
                                                            format={"dd-MMMM-yyyy"}    
                                                            fullWidth                                                                                                                                                                             
                                                            value={clientInfo[formField.key] ? clientInfo[formField.key] : null}
                                                            InputLabelProps={{                                            
                                                                        classes: {
                                                                            root: classes.labelText,                                    
                                                                        }
                                                                    }}
                                                            onChange={(date) => {setClientInfo({...clientInfo, [formField.key]: date}); }}
                                                            error={false}
                                                            InputProps={{                                            
                                                                        classes: {
                                                                            input: classes.formText,                                    
                                                                        }
                                                                    }}
                                                        
                                                            autoOk                                                                                  
                                                        />
                                                    }
                                                    
                                                </Grid>
                                                :
                                                null
                                            ))
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))
                }
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={(e) => {handleClientInfoDialogClose(); uploadClientInfo();}}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleClientInfoDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>           
        </DialogContent>        
        </Dialog>
    )
 

    const filteredRows = useMemo(() => {
        return titles.concat(rows.filter((r) => 
            (filters.client_code ? r.client_code.toLowerCase().includes(filters.client_code.toLowerCase()) : true) &&
            (filters.client_market_type ? r.client_market_type.toLowerCase().includes(filters.client_market_type.toLowerCase()) : true) &&
            (filters.client_name ? r.client_name.toLowerCase().includes(filters.client_name.toLowerCase()) : true) &&
            (filters.use_type ? r.use_type.toLowerCase().includes(filters.use_type.toLowerCase()) : true) &&
            (filters.stratum ? String(r.stratum).toLowerCase().includes(filters.stratum.toLowerCase()) : true) &&
            (filters.ag_type ? r.ag_type.toLowerCase().includes(filters.ag_type.toLowerCase()) : true) &&
            (filters.fncer ? r.fncer.toLowerCase().includes(filters.fncer.toLowerCase()) : true) &&
            (filters.installed_capacity ? r.installed_capacity.toLowerCase().includes(filters.installed_capacity.toLowerCase()) : true)            
        ))
    }, [rows, filters])
  
    
    useEffect (() => {
        document.title ="Control usuarios | Autogeneradores Cedenar";                    
        authFetch("/api/check-clients?username=" + String(props.username)+                                                  
                                                  "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.clients){     
                    let rows = response.clients
                    let rowsWithEditButton = []
                    rows.forEach(element => {
                        element = {...element, edit:<EditIcon style={{fontSize:16}}/>}
                        rowsWithEditButton.push(element)
                    });                                  
                    setRows(rowsWithEditButton)                    
                    setLoadingInformation(false)
                }
            }) 
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){
                    let cycleNames = response.cycles.map((cycle) => cycle.code)
                    setCycles(cycleNames)                                        
                }
            }) 
        authFetch("/api/check-no-lecture-causes-list?username="+props.username+"&mode=client").then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.causes_list){   
                    let causes = response.causes_list.map(cause  => cause.description)              
                    causes.splice(0,0,'NA') 
                    setCausesList([...causes])                                        
                }
            })
        
    }, [])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}
            {showClientInfoDialog()}
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly" spacing={1}>                    
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle} color="primary">Usuarios autogeneradores
                        </Typography>
                    </Grid>                                                                                               
                    <Grid item xs={12}>
                        {
                            rows.length === 0 && loadingInformation?
                            <CircularProgress/>
                            :                            
                            <Grid container alignItems="center" spacing={1}>
                                
                                <Grid item xs={10}>
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                    <FilterContext.Provider value={filters}>
                                    <Typography variant="subtitle2" align="center" color="primary">Total usuarios: {filteredRows.length - 1}</Typography>
                                        <DataGrid columns={columns} 
                                            rows={filteredRows}
                                            headerRowHeight={35}                                                
                                            className={'rdg-light'}                            
                                            style={{color: '#606B6A', height: 400}}
                                            onRowClick={(row) => {
                                                let idx  = rows.indexOf(row);
                                                updateValuesWithSelectedClient(row, idx); handleClientInfoDialogOpen();}}
                                        />
                                    </FilterContext.Provider>
                                    </Paper>
                                </Grid>
                                
                                
                                <Grid item xs={2}>
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"                        
                                        classes={{root: classes.uploadButton}}
                                        style={{marginBottom:10}}
                                        onClick={(e) => {updateValuesWithSelectedClient(-1, null); handleClientInfoDialogOpen();}}                                
                                    > 
                                        <Fragment><AddIcon /> {'Agregar'}</Fragment>                         
                                    </Fab>
                                    {
                                        isLoading ? 
                                        <CircularProgress/>
                                        :
                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"                        
                                            classes={{root: classes.uploadButton}}
                                            onClick={downloadClients}
                                            style={{marginTop: 20}}
                                        > 
                                            <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                                        </Fab>
                                    }
                                    </Paper>
                                </Grid>
                                
                            </Grid>                            
                        }
                        
                    </Grid>                                                     
                </Grid>
            </Zoom>
        </div>
    )
}