import {React, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { IconButton,  InputAdornment} from '@material-ui/core'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {login, logout} from "../auth"


function isMobile() { return ( navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) ); }

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Derechos Reservados © Legacy Software '}              
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset`,      
    },   
  }
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [togglePassword, setTogglePassword] = useState(true)

  useEffect (() => {
    document.title ="Iniciar sesión"
  }, [])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const onSubmitClick = (e)=>{
    e.preventDefault()
    let opts = {
      'email': email,
      'password': password
    }
    fetch('/login', {
      method: 'post',
      body: JSON.stringify(opts)
    }).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
      .then(token => {
        if (token.access_token){
          if (isMobile() && token.role !== 'field-tech')
          {
            alert("Versión móvil no disponible para las credenciales suministradas.")
          }
          else{
            login(token)     
            props.setUsername(token.user)
            props.setAgent(token.agent)
            props.setRole(token.role)
            props.setUser(token.email)
            setTimeout(() => {
              window.location.reload()                             
            },200)
          }                    
        }
        else if ('invalid_password' in token) {        
          alert("Credenciales incorrectas.")
          if (token.failed_attempts >= 3 ){
            let msg = "Intentos disponibles por ingresar credenciales: " + String(6-token.failed_attempts)
            alert(msg)
          }
        }
        else if ('inactive_account' in token) {        
          alert("Cuenta inactiva. Póngase en contacto con el administrador del sistema.")
          if (token.failed_attempts >= 3 ){
            let msg = "Intentos disponibles por ingresar credenciales: " + String(6-token.failed_attempts)
            alert(msg)
          }
        }

      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />     
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <form className={classes.form} action="#" autoComplete="off">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario"
            name="email"      
            autoFocus
            onChange={handleEmailChange}
            value={email}
            inputProps={{ className: classes.input}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type={togglePassword ? "password" : "text"}
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
            value={password}
            inputProps={{ className: classes.input}}
            InputProps = {
              {
                  endAdornment: ( 
                  <InputAdornment position="end"> {
                      togglePassword ?
                      ( 
                      <VisibilityOff 
                          onMouseDown={(e) => {setTogglePassword(false)}}
                          onMouseUp={(e) => {setTogglePassword(true)}}
                      />
                      ) 
                      :
                      ( 
                      <Visibility                                                          
                          onMouseUp={(e) => {setTogglePassword(true)}}
                      />
                      ) 
                  } 
                  </InputAdornment>
                  ),
              }
              }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmitClick}
          >
            Ingresar
          </Button>                
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

