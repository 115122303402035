import {React, useEffect, useState, Fragment, useRef} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Menu,
    Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Checkbox} from '@material-ui/core/'
import {authFetch, logout} from '../../auth';
//import {useReactToPrint} from "react-to-print";
import ReactToPrint from 'react-to-print';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5,
      border: `1px solid ${theme.palette.secondary.dark}`
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    opTariffBackground:
    {
        backgroundColor: theme.palette.secondary.main
    }
}));

export default function TariffTree(props){
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const classes = useStyles();
    const [tariffTree, setTariffTree] = useState({})   
    const publicationPdfRef = useRef();
    const year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1;

    const TariffTable = (props) => (
        <div ref={publicationPdfRef}>
        <Grid container spacing={4} justify="center">
            {
                props.version === "FRANJAS HORARIAS" ? 
                ['Franja máxima', 'Franja media', 'Franja mínima', 'Franja monomia'].map((title, pidx) => (
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>{title}</Typography>
                        <TableContainer component={Paper} key={pidx}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "12%"}}></StyledTableCell>
                                    <StyledTableCell align="center">Tm</StyledTableCell>
                                    <StyledTableCell align="center">Dm</StyledTableCell>
                                    <StyledTableCell align="center">Rm</StyledTableCell>
                                    <StyledTableCell align="center">Cvm</StyledTableCell>
                                    <StyledTableCell align="center">Gm</StyledTableCell>
                                    <StyledTableCell align="center">PRm</StyledTableCell>
                                    <StyledTableCell align="center">Cu</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Cu op. tarif</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    ['Nivel 1 OR', 'Nivel 1 CO', 'Nivel 1 US', 'Nivel 2', 'Nivel 3'].map((subtitle, sidx) => (
                                        <StyledTableRow key={sidx}>
                                            <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell>
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.t[pidx].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell>: null}
                                            <StyledTableCell align="center">{props.tariffsObj.d[sidx].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell>
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.r[0].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell> : null}
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.c[0].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell> : null}
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.g[0].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell> : null}
                                            {   sidx > 2 ?
                                                <StyledTableCell align="center">{props.tariffsObj.pr[3*pidx + sidx -2].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell>
                                                :
                                                sidx === 0 ?
                                                <StyledTableCell rowspan={3} align="center">{props.tariffsObj.pr[3*pidx].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell> : null
                                            }
                                            <StyledTableCell align="center">{props.tariffsObj.tariffs[stripes[pidx]][levels[sidx]].toLocaleString('en-US',{minimumFractionDigits: 6})}</StyledTableCell>
                                            <StyledTableCell align="center">{
                                                props.marketOpTariff.levels[sidx] && 'op_tariffs' in props.tariffsObj ? 
                                                props.tariffsObj.op_tariffs[stripes[pidx]][levels[sidx]] > 0 ? 
                                                    props.tariffsObj.op_tariffs[stripes[pidx]][levels[sidx]].toLocaleString('en-US',{minimumFractionDigits: 6})
                                                    :
                                                    '-'
                                                :
                                                '-'
                                            }</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))
                :
                ['Franja monomia'].map((title, pidx) => (
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>{title}</Typography>
                        <TableContainer component={Paper} key={pidx}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "12%"}}></StyledTableCell>
                                    <StyledTableCell align="center">Tm</StyledTableCell>
                                    <StyledTableCell align="center">Dm</StyledTableCell>
                                    <StyledTableCell align="center">Rm</StyledTableCell>
                                    {
                                        props.username === 'Cedenar' && year >= 2024 ?
                                        <StyledTableCell align="center">Cvm sin COT</StyledTableCell>
                                        :
                                        null
                                    }
                                    <StyledTableCell align="center">Cvm</StyledTableCell>
                                    <StyledTableCell align="center">Gm</StyledTableCell>
                                    <StyledTableCell align="center">PRm</StyledTableCell>
                                    <StyledTableCell align="center">Cu 119</StyledTableCell>
                                    {
                                    props.username === 'Cedenar' && year >= 2024 ? 
                                    <StyledTableCell align="center" style={{width: "10%"}}>Cu con COT</StyledTableCell>
                                    :
                                    <StyledTableCell align="center" style={{width: "10%"}}>Cu op. tarifaria</StyledTableCell>                                    
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    ['Nivel 1 OR', 'Nivel 1 CO', 'Nivel 1 US', 'Nivel 2', 'Nivel 3'].map((subtitle, sidx) => (
                                        <StyledTableRow key={sidx}>
                                            <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell>
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.t[3]}</StyledTableCell>: null}
                                            <StyledTableCell align="center">{props.tariffsObj.d[sidx]}</StyledTableCell>
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.r[0]}</StyledTableCell> : null}
                                            {   
                                                props.username === 'Cedenar' && year >= 2024 && Object.keys(props.tariffsObj).includes('cot_values') ?
                                                    sidx > 2 ? 
                                                    <StyledTableCell align="center">{(props.tariffsObj.c[sidx < 3 ? 0 : sidx-2] - props.tariffsObj.cot_values[sidx < 3 ? 0 : sidx-2]).toFixed(6)}</StyledTableCell>
                                                    :
                                                    sidx === 0 ?
                                                    <StyledTableCell rowspan={3} align="center">{(props.tariffsObj.c[sidx < 3 ? 0 : sidx-2] - props.tariffsObj.cot_values[sidx < 3 ? 0 : sidx-2]).toFixed(6)}</StyledTableCell>
                                                    :
                                                    null
                                                :
                                                null
                                            }
                                            {   
                                                props.username === 'Cedenar' && year >= 2024 ?
                                                    sidx > 2 ? 
                                                    <StyledTableCell align="center">{props.tariffsObj.c[sidx < 3 ? 0 : sidx-2]}</StyledTableCell>
                                                    :
                                                    sidx === 0 ?
                                                    <StyledTableCell rowspan={3} align="center">{props.tariffsObj.c[sidx < 3 ? 0 : sidx-2]}</StyledTableCell>
                                                    :
                                                    null
                                                :
                                                sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.c[0]}</StyledTableCell> : null
                                            }
                                            { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{props.tariffsObj.g[0]}</StyledTableCell> : null}
                                            {   sidx > 2 ?
                                                <StyledTableCell align="center">{props.tariffsObj.pr[3*3 + sidx -2]}</StyledTableCell>
                                                :
                                                sidx === 0 ?
                                                <StyledTableCell rowspan={3} align="center">{props.tariffsObj.pr[3*3]}</StyledTableCell> : null
                                            }
                                            <StyledTableCell align="center">{
                                                props.username === 'Cedenar' && year >= 2024 && Object.keys(props.tariffsObj).includes('cot_values')? 
                                                (props.tariffsObj.tariffs[stripes[3]][levels[sidx]]  - props.tariffsObj.cot_values[sidx < 3 ? 0 : sidx-2]).toFixed(6)
                                                :
                                                props.tariffsObj.tariffs[stripes[3]][levels[sidx]].toFixed(6)
                                            }</StyledTableCell>
                                            {
                                                props.username === 'Cedenar' && year >= 2024 ? 
                                                <StyledTableCell align="center" className={classes.opTariffBackground}>{                                                                                                    
                                                    props.tariffsObj.tariffs[stripes[3]][levels[sidx]].toFixed(6)
                                                }</StyledTableCell>
                                                :
                                                <StyledTableCell align="center" className={classes.opTariffBackground}>{                                                                                                    
                                                        (props.marketOpTariff.levels[sidx] || props.username === 'Cedenar' && year < 2024) && 'op_tariffs' in props.tariffsObj ? 
                                                            props.tariffsObj.op_tariffs[stripes[3]][levels[sidx]] > 0 ? 
                                                            props.tariffsObj.op_tariffs[stripes[3]][levels[sidx]].toLocaleString('en-US',{minimumFractionDigits: 6})
                                                            :
                                                            '-'
                                                        :
                                                        '-'
                                                }</StyledTableCell>
                                            }
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))
            }
        </Grid>    
        </div>   
    )

    useEffect (() => {
        document.title ="Arbol tarifario";
        let year = Number(props.periodDate.getMonth() + 1) < 12 ? props.periodDate.getFullYear() : props.periodDate.getFullYear() + 1
        let period = Number(props.periodDate.getMonth() + 1) < 12 ? Number(props.periodDate.getMonth() + 1) + 1 : 1
        let version = props.tariffCalcVersion
        let usernameCode = props.username.slice(0,3)
        authFetch("/api/get-tariff-tree?electric_tariff_id=" + String( usernameCode + String( String(year) + String(period) + version ) ) 
            + "&market=" + props.market
        ).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.tariffTree){
                    ////console.log(response.tariffTree)
                    setTariffTree(response.tariffTree)                    
                }
            })
    }, [props.periodDate, props.tariffCalcVersion, props.market])

    return (
        <div className={classes.root}>            
            <Grid container justify="center">
                <Zoom in timeout={700}>
                    <Grid item xs={12}>
                    {   
                        props.tariffCalcVersion === '' ?                                                        
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            No se ha generado una versión de cálculo para el periodo actual.</Typography>
                        :
                            Object.keys(tariffTree).length > 0 ?
                            tariffTree.published ?
                            <Fragment>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            Tarifas publicadas: {tariffTree.pubDate}</Typography>
                            {/* <ReactToPrint
                                trigger={() => <button>Print this out!</button>}
                                content={() => publicationPdfRef.current}
                            />                         */}
                            <TariffTable version={props.calcType} tariffsObj={tariffTree} marketOpTariff={props.marketOpTariff} username={props.username} />                                                                                        
                            {/* <button onClick={handlePublicationPrint}>Imprimir</button>  */}
                            </Fragment>
                            :
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            Las tarifas aun no han sido publicadas para los parámetros seleccionados.</Typography>
                            :
                            null
                    }          
                              
                    </Grid>
                    
                </Zoom>
            </Grid>                            
        </div>
    )
}