import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Incum from '@material-ui/icons/Layers';
import Agent from '@material-ui/icons/SortByAlpha';
import ORCode from '@material-ui/icons/SettingsEthernet';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    //const [inputPeriod, setInputPeriod] = useState(periods[actualMonth-1]);
 
    const [actorDialog, setActorDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [netOperatorsList, setNetOperatorsList] = useState([])
    const [netOperatorData, setNetOperatorData] = useState({active: true})

    const handleActorDialogOpen = () => {
        setActorDialog(true);
      };
    
    const handleActorDialogClose = () => {
        setActorDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const updateValuesWithSelectedActor = (idx) => {
        if (idx === -1){
            setNetOperatorData({active: true})
        }
        else{
            setNetOperatorData(netOperatorsList[idx])
        }
    }
 
    const handleActorUpload = () => {        
        if (!netOperatorData.cmc_name || !netOperatorData.cmc_inc_code || !netOperatorData.cmc_no_code || !netOperatorData.cmc_zone || !netOperatorData.opr_market){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.")
        }   
        else {            
            authFetch(
            "/api/update-net-operators",
            {
                method: 'post',
                body: JSON.stringify(netOperatorData),
            }
            ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response.Upload === 'OK'){
                setFeedbackMsg('Datos guardados. Verifique la información reflejada en la lista.')
                handleFeedbackDialogOpen()
                handleActorDialogClose()
                authFetch("/api/check-net-operators").then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.net_operators){                    
                            setNetOperatorsList(response.net_operators)                                        
                        }
                    })
                }
                else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                }
            })
        }     
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showActorDialog = () => (
        <Dialog
            open={actorDialog}
            onClose={handleActorDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingresar/Revisar los datos del operador de red."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Nombre:</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.cmc_name}
                                placeholder={"Nombre del operador."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_name: e.target.value})}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código SIC Comercializador</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.cmc_inc_code}
                                placeholder={"Código SIC comercializador."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => {
                                                  setNetOperatorData({...netOperatorData, cmc_inc_code: e.target.value, cmc_no_code: e.target.value.slice(0,3) + 'D'});
                                                }}                           
                            />
                        </Grid>    
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código SIC OR</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.cmc_no_code}
                                placeholder={"Código SIC OR."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_no_code: e.target.value})}                            
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">NIT</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.cmc_nit}
                                placeholder={"NIT"}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_nit: e.target.value})}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Gerente</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.rel_oper}
                                placeholder={"Gerente"}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, rel_oper: e.target.value})}                            
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Cédula</Typography>
                        </Grid>
                        <Grid item>
                            <CodeIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={netOperatorData.doc_oper}
                                placeholder={"No. cédula."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, doc_oper: e.target.value})}                            
                            />
                        </Grid>                  
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>                   
                    <Grid container spacing={2}>                                                 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Municipio:</Typography>
                        </Grid>
                        <Grid item>
                            <Agent/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField                                
                                value={netOperatorData.cmc_municip}
                                placeholder={"Municipio."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_municip: e.target.value})}                            
                            />
                        </Grid>   
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Dirección:</Typography>
                        </Grid>
                        <Grid item>
                            <Agent/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField                                
                                value={netOperatorData.cmc_address}
                                placeholder={"Dirección."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_address: e.target.value})}                            
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Teléfono:</Typography>
                        </Grid>
                        <Grid item>
                            <Agent/>
                        </Grid>                
                        <Grid item xs={9}>                   
                        <TextField                                
                                value={netOperatorData.cmc_phone}
                                placeholder={"Teléfono."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_phone: e.target.value})}                            
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Zona:</Typography>
                        </Grid>
                        <Grid item>
                            <Agent/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={netOperatorData.cmc_zone}
                                placeholder={"Zona."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setNetOperatorData({...netOperatorData, cmc_zone: e.target.value})}   
                                select                      
                            >
                                <MenuItem value={"CENTRO-SUR"}>CENTRO-SUR</MenuItem>
                                <MenuItem value={"NORTE"}>NORTE</MenuItem>                                
                            </TextField>
                        </Grid> 
                                             
                    </Grid>                                                                                                          
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Estado:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Switch
                                checked={netOperatorData.active}
                                onChange={(e) => setNetOperatorData({...netOperatorData, active: e.target.checked})}
                                color="primary"                                                 
                            />
                            {netOperatorData.active? 'Activo':'Inactivo'}
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Mercado:</Typography>
                        </Grid>
                        <Grid item>
                            <MarketName/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={netOperatorData.opr_market}
                                placeholder={"Seleccione el mercado."}
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}      
                                onChange={(e) => setNetOperatorData({...netOperatorData, opr_market: e.target.value})}   
                                select                      
                            >
                                {
                                    props.markets.map((market, idx) => (
                                        <MenuItem value={market} key={idx}>{market}</MenuItem>
                                    ))
                                }                                                                                      
                            </TextField>
                        </Grid>
                    </Grid>                    
                </Grid>                           
                <Grid item>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleActorDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    useEffect (() => {
        document.title ="Operadores de red";

        authFetch("/api/check-net-operators").then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.net_operators){                    
                    setNetOperatorsList(response.net_operators)                                        
                }
            })
        
    }, [])

    return (
        <div className={classes.root}>
            {showActorDialog()}
            {showFeedbackDialog()}
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>Lista de operadores de red:</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>                                        
                                        <StyledTableCell align="center" style={{width: "24%"}}>Nombre</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "24%"}}>NIT</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "10%"}}>Código SIC</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "24%"}}>Mercado</StyledTableCell>        
                                        <StyledTableCell align="center" style={{width: "20%"}}>Zona</StyledTableCell>                                 
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {netOperatorsList.map((row,idx) => (
                                        <StyledTableRow 
                                            key={idx} 
                                            onClick={() => {updateValuesWithSelectedActor(idx); handleActorDialogOpen();}}
                                        >                                                       
                                            <StyledTableCell align="center" >{row.cmc_name}</StyledTableCell>
                                            <StyledTableCell align="center">{row.cmc_nit ? row.cmc_nit : '-'}</StyledTableCell>
                                            <StyledTableCell align="center">{row.cmc_inc_code}</StyledTableCell>
                                            <StyledTableCell align="center" >{row.opr_market ? row.opr_market : '-'}</StyledTableCell>  
                                            <StyledTableCell align="center" >{row.cmc_zone ? row.cmc_zone : '-'}</StyledTableCell>                                              
                                        </StyledTableRow>
                                            
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer> 
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() =>{updateValuesWithSelectedActor(-1); handleActorDialogOpen();}}
                                style={{marginTop: 20}}
                            > 
                                <Fragment><AddIcon /> {' Agregar '}</Fragment>                         
                            </Fab>      
                        </Grid>                                                        
                </Zoom>                
            </Grid>       
        </div>
    );
}