import {React, useEffect, useState, Fragment, useMemo, createContext, useContext, createElement} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import MarketName from '@material-ui/icons/CalendarViewDay';
import Area from '@material-ui/icons/CropFree';
import State from '@material-ui/icons/RadioButtonChecked';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/ListAlt';
import {DatePicker} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GavelIcon from '@material-ui/icons/Gavel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DataGrid from 'react-data-grid';
import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    filterCell:{
        lineHeight: 35,
        padding: 0,
        '> div':{
            paddingBlock: 0,
            paddingInline: 8,
            '&:first-child': {
                borderBlockEnd: '1px solid black'
            }
        }
    },   
    tableText:{
        fontSize: 11,
        textAlign: 'left'
    },    
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    dataTable:{
        color: '#606B6A',
        ['--rdg-background-color']: '#D6EEEC',
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 90          
    },
    filterInput200: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",        
    },
    filterText: {
        fontSize: 11,
        color: 'white',
        textAlign: "left",        
        background: 'black'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));


export default function ClientControl (props) {
    const classes = useStyles();    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [clientInfoDialog, setClientInfoDialog] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState("")        
    const [loadingInformation, setLoadingInformation] = useState(true)
    const [clientInfo, setClientInfo] = useState({active: true})
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
        
    let billingDate = props.billingDate       
    const [columns, setColumns] = useState(
        [                       
            {key: 'client_code', frozen: true, name: 'Código cliente'},
            {key: 'year',  name: 'Año'},            
            {key: 'month', name: 'Mes'},
            {key: 'concept_code', name: 'Concepto'},
            {key: 'energy', name: 'Energía'},
            {key: 'value', name: 'Valor'},
        ]
    )     
    const [rows, setRows] = useState([])

       
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const handleClientInfoDialogOpen = () => {
        setClientInfoDialog(true);
      };
    
    const handleClientInfoDialogClose = () => {
        setClientInfoDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const downloadClients = () => {
        setIsLoading(true)
        authFetch("/api/get-csv-data-liquidation?username=" + String(props.username) +"&month="+String( billingDate.getMonth() + 1 )+                              
        "&year="+ billingDate.getFullYear()+"&download=" + 'yes'+ "&timestamp=" + new Date().toISOString().slice(0,19),
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'LiquidacionAG_'+ billingDate.getFullYear()+'_'+ String( billingDate.getMonth() + 1 ) +'_' +'.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    
    useEffect (() => {
        setLoadingInformation(true)
        document.title ="Exportar liquidación | Autogeneradores Cedenar";        

        authFetch("/api/get-csv-data-liquidation?username=" + String(props.username) +"&month="+String( billingDate.getMonth() + 1 )+                              
        "&year="+ billingDate.getFullYear()).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                    if (response && response.data){                                                            
                        setRows(response.data)
                        setLoadingInformation(false)
                }
            })
        
    }, [props.billingDate])

    return(
        <div className={classes.root}>
            {showFeedbackDialog()}            
            <Zoom in timeout={800}>
                <Grid container justify="space-evenly" spacing={1}>                    
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle} color="primary">Exportar Liquidación
                        </Typography>
                    </Grid>                                                                                               
                    <Grid item xs={12}>
                        {
                            rows.length === 0 && loadingInformation?
                            <CircularProgress/>
                            :                            
                            <Grid container alignItems="center" spacing={1}>
                                
                                <Grid item xs={10}>
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                    
                                    <Typography variant="subtitle2" align="center" color="primary">Total conceptos: {rows.length}</Typography>
                                        <DataGrid 
                                            columns={columns} 
                                            rows={rows}
                                            headerRowHeight={35}                                                
                                            className={'rdg-light'}                            
                                            style={{color: '#606B6A', height: 400}}                                            
                                        />                                    
                                    </Paper>
                                </Grid>
                                
                                
                                <Grid item xs={2}>
                                    <Paper className={classes.contentPaper} elevation={0} variant="outlined">                                    
                                    {
                                        isLoading ? 
                                        <CircularProgress/>
                                        :
                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"                        
                                            classes={{root: classes.uploadButton}}
                                            onClick={downloadClients}
                                            style={{marginTop: 0}}
                                            disabled={rows.length === 0}
                                        > 
                                            <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                                        </Fab>
                                    }
                                    </Paper>
                                </Grid>
                                
                            </Grid>                            
                        }
                        
                    </Grid>                                                     
                </Grid>
            </Zoom>
        </div>
    )
}