import {React, useEffect, useState, Fragment, useContext, createContext, useMemo} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';
import DataGrid from 'react-data-grid';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    filterInput100: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        maxWidth: 100          
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

const FilterContext = createContext(undefined)

function HeaderFilter (props) {
    const filters = useContext(FilterContext)
    return(                        
        <input  className={props.filterInput} value={filters[props.field]} onChange={(e) => {            
            props.setFilters({...filters, [props.field]: e.target.value})
        }}></input> 
    )
} 

export default function Summary(props) {
    const classes = useStyles();   
    //let billingDate = new Date(auxDate.setMonth(auxDate.getMonth() + 1)) 
    let billingDate = props.billingDate    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    
    const [cycles, setCycles] = useState([])
    const [cycleUsers, setCycleUsers] = useState([])
    const [cycleLiqConsumption, setCycleLiqConsumption] = useState({})
    const [cycleLiqAgreement, setCycleLiqAgreement] = useState({})
    const [selectedCycle, setSelectedCycle] = useState("")
    const [liquidationInProgress, setLiquidationInProgress] = useState(false)
    const [approvationInProgress, setApprovationInProgress] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    // Deberian cambiar si se cambia el period Date    
    const [cycleLiqConsumptionFlag, setCycleLiqConsumptionFlag] = useState(false)
    const [cycleLiqAgreementFlag, setCycleLiqAgreementFlag] = useState(false)
    const [departmentAgreementsFlag, setDepartmentAgreementsFlag] = useState(false)
   

    const [filters, setFilters] = useState({
        client_name: '',
        client_code: '',
        inst_departm: '',       
        use_type: '',             
    })
    const [columns, setColumns] = useState(
        [
            {key: 'client_name', width: 250, frozen: true},
            {key: 'client_code', width: 130,},
            {key: 'inst_departm', width: 130},            
            {key: 'use_type', width: 130},                        
            {key: 'agreement_active', width: 130},           
            {key: 'collect_var', width: 130},
            {key: 'var_value', width: 130},
            {key: 'min', width: 130},
            {key: 'max', width: 130},
            {key: 'collect_type', width: 130},
            {key: 'collect_value', width: 130},
            {key: 'collect_liquidation', width: 130},
        ]           
    ) 
    columns[0].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_name} field={'client_name'}>        
        </HeaderFilter>
        )
    columns[1].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput100} value={filters.client_code} field={'client_code'}>        
        </HeaderFilter>)
    columns[2].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput100} value={filters.inst_departm} field={'inst_departm'}>        
        </HeaderFilter>)
    columns[3].headerRenderer = ({column}) => (
        <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput100} value={filters.use_type} field={'use_type'}>        
        </HeaderFilter>)
    const [titles, setTitles] = useState([
        {
            client_name: <b>Nombre/Razón Social</b>,
            client_code: <b>Código del cliente</b>,
            inst_departm: <b>Departamento inst.</b>,            
            use_type: <b>Tipo de uso</b>,                        
            agreement_active: <b>Acuerdo existente</b>,            
            collect_var: <b>Variable de cobro</b>,
            var_value: <b>Valor variable cliente</b>,
            min: <b>Límite mínimo</b>,
            max: <b>Límite máximo</b>,
            collect_type: <b>Tipo de cobro</b>,
            collect_value: <b>Valor del cobro</b>,
            collect_liquidation: <b>Valor del impuesto</b>,
        }
    ])
    const [rows, setRows] = useState([])
    
   

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const inputsStateList = (files, filesState) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Liquidación y parámetros</Typography>
                </Grid>  
                {
                    files.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>   
                    <Grid container justify="flex-start">           
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>     
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>       
                            {
                                files.map((filename, idx) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                    {filename}
                                    </Grid>      
                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={5}>
                                        { filesState[idx] ? 
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <DoneIcon fontSize="small" color="success"/>
                                                </Grid>
                                                <Grid item>                                            
                                                    Listo
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                                <Grid item>
                                                    <HighlightOffIcon fontSize="small" color="error"/>
                                                </Grid>
                                                <Grid item>
                                                    Pendiente
                                                </Grid>                                            
                                            </Grid> 
                                        }                                   
                                    </Grid>
                                </Fragment>
                                ))                            
                            }      
                        </Grid>   
                    </Grid>         
                    </Grid> 
                    :
                    null
                }
                           
            </Grid>             
        </Fragment>            
    ) 

    const callLiquidation = () => {
        setLiquidationInProgress(true)                           
        authFetch("/api/liquidate-cycle-department-agreement?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+selectedCycle).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.") 
                setLiquidationInProgress(false)
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.Upload === 'OK'){                                                      
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Impuesto de seguridad para el ciclo liquidado.") 
                    setLiquidationInProgress(false)                                                
                }
                else{
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.") 
                    setLiquidationInProgress(false)
                }
            }) 
        
    }
 
    const approveLiquidation = () => {
        setApprovationInProgress(true)
        authFetch("/api/approve-cycle-department-agreement-liquidation?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&cycle="+selectedCycle).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setApprovationInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.Upload === 'OK'){                                                      
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Liquidación de impuesto de seguridad aprobada.")    
                    setApprovationInProgress(false)     
                    setCycleLiqAgreementFlag(true)                                                
                }
                else{
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.")         
                    setApprovationInProgress(false)              
                }
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const updateFlags = (value) => {
        setCycleLiqAgreementFlag(cycleLiqAgreement[value])        
        setCycleLiqConsumptionFlag(cycleLiqConsumption[value])
        // Update para la grilla 
        // let autogenerators = cycles.filter(cycle => cycle.code === value)[0].autogenerators 
        // if (autogenerators === 'SI'){
        //     columns.push({key: 'gen_energy', width: 130})
        //     columns.push({key: 'val_gen_energy', width: 130})
        //     columns.push({key: 'agpe_exc', width: 130})
        //     titles[0]['val_gen_energy'] = <b>Valor E. permutada</b>
        //     titles[0]['gen_energy'] = <b>Energía permutada</b>
        //     titles[0]['agpe_exc'] = <b>Valor excedente AGPE</b>
        // }
        // else if (columns.length > 26){
        //     columns.pop()
        //     columns.pop()
        //     columns.pop()
        // }
    }

    const filteredRows = useMemo(() => {
        return titles.concat(rows.filter((r) => 
            (filters.client_code ? r.client_code.includes(filters.client_code.toLowerCase()) : true) &&             
            (filters.client_name ? r.client_name.includes(filters.client_name.toLowerCase()) : true) &&           
            (filters.inst_departm ? r.inst_departm.includes(filters.inst_departm.toLowerCase()) : true) &&
            (filters.use_type ? r.use_type.includes(filters.use_type.toLowerCase()) : true)
        ))
    }, [rows, filters])


    useEffect (() => {
        document.title ="Liquidación de impuesto de seguridad";        
        
        authFetch("/api/check-billing-cycles?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycles){                                                      
                    setCycles(response.cycles)                                                          
                }
            })      
        authFetch("/api/get-cycle-users?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.cycle_users){   
                    let cycleUsers = response.cycle_users               
                    setCycleUsers(response.cycle_users)
                    let opts = {
                        users_by_cycle: cycleUsers,
                        year: billingDate.getFullYear(),
                        month: String( billingDate.getMonth() + 1 )
                    }  
                    authFetch("/api/check-department-agreement-liq-flags?username=" + String(props.username) +
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                    {
                        method: 'post',
                        body: JSON.stringify(opts)
                    }
                    ).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {                                          
                            if (response && response.consumption_liq_flags){                  
                                setDepartmentAgreementsFlag(response.param_department_agreements)
                                setCycleLiqConsumption(response.consumption_liq_flags)
                                setCycleLiqAgreement(response.agreement_liq_flags)    
                                if (selectedCycle != ""){
                                    setCycleLiqAgreementFlag(response.agreement_liq_flags[selectedCycle])        
                                    setCycleLiqConsumptionFlag(response.consumption_liq_flags[selectedCycle])
                                }                            
                            }
                        })                                     
                }
            })  
           
        if (selectedCycle !== ""){
            ////console.log("consultando clientes")
            setLoadingData(true)           
            
            authFetch("/api/check-cycle-department-agreement-liquidation-?username=" + String(props.username)+
            "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
            "&cycle="+selectedCycle).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    setLoadingData(false)
                    if (response && response.department_agreement_liquidation){                            
                        let liquidation = response.department_agreement_liquidation
                        liquidation.forEach((obj, idx) => {liquidation[idx] = {...obj,                                                     
                                                    collect_liquidation: obj['IS'] ? parseFloat(obj['IS']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) : '-', 
                                                }; })                                                 
                        setRows(liquidation)                                                                                           
                    }
                })
        }
    }, [props.billingDate, selectedCycle, liquidationInProgress])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                                
                <Grid item xs={12} style={{marginTop: 10}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Liquidación de impuesto de seguridad</Typography>
                    <Grid container justify="space-evenly" spacing={1} alignItems="flex-start">
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Ciclo:</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                select
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={selectedCycle}
                                onChange={(e) => {setSelectedCycle(e.target.value); updateFlags(e.target.value);}}
                            >
                                {
                                    cycles.map((cycle,idx) => (
                                        cycleUsers[cycle.code] > 0 ?
                                        <MenuItem key={idx} value={cycle.code}>{cycle.code}</MenuItem>
                                        :
                                        null
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6} className={classes.darkText}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Insumos necesarios</Typography>
                            {
                                cycles.length > 0 && selectedCycle !== ""?
                                inputsStateList(['Liquidación consumos ciclo '+selectedCycle,'Parametrización acuerdos departamentales'],[cycleLiqConsumptionFlag, departmentAgreementsFlag])
                                :
                                null
                            }                            
                        </Grid>                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Control de aprobación</Typography>
                            <Grid container justify="space-evenly" spacing={1} style={{marginTop:40}}>
                                <Grid item>
                                    {
                                        liquidationInProgress ?
                                        <CircularProgress></CircularProgress>
                                        :
                                        <Button 
                                            onClick={(e) => {callLiquidation();}} 
                                            color='primary' 
                                            disabled={!cycleLiqConsumptionFlag || !departmentAgreementsFlag || cycleLiqAgreementFlag || rows.length > 1 || loadingData}  
                                            variant='contained'
                                        >
                                            Liquidar
                                        </Button>
                                    }                                    
                                </Grid>
                                <Grid item>
                                    {
                                        approvationInProgress ? 
                                        <CircularProgress></CircularProgress>
                                        :
                                        <Button 
                                            onClick={(e) => {approveLiquidation();}} 
                                            color='primary' 
                                            disabled={filteredRows.length <= 1 || cycleLiqAgreementFlag || loadingData} 
                                            variant='contained'
                                        >
                                            Aprobar
                                        </Button>
                                    }
                                    
                                </Grid>                                
                            </Grid>
                        </Grid>                        
                    </Grid>                                                           
                </Grid> 
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Información procesada</Typography>   
                    <FilterContext.Provider value={filters}>                        
                        {
                            loadingData ? 
                            <CircularProgress></CircularProgress>
                            :
                            <Fragment>
                                <Typography variant="subtitle2" align="center" color="primary">Total clientes liquidados: {filteredRows.length - 1}</Typography>
                                <DataGrid columns={columns} 
                                    rows={filteredRows}
                                    headerRowHeight={35}    
                                    className={'rdg-light'} 
                                    style={{color: '#606B6A', height: 400, fontSize: 12}}                                                                                                 
                                />
                            </Fragment>
                            
                        }
                        
                    </FilterContext.Provider>                                      
                </Grid>                                                                                       
            </Grid>   
            </Zoom>    
        </div>
    );
}