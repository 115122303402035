import React from 'react'
import {useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Accordion, AccordionDetails, AccordionSummary, Typography, Grid, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, Zoom} from '@material-ui/core'
import {authFetch, logout} from '../auth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AnualTable from './ParameterInputComponents/AnualTable'
import { animateScroll } from 'react-scroll' 
import useViewport from '../hooks/useViewport';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(2)
    },
    heading: {     
      color: theme.palette.primary.dark,
    },
    rlqMsg: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1),
      },
    secondaryHeading: {
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.primary.dark,
      marginLeft: theme.spacing(2)
    },
    accordion: {
        background: '#F2F5F5',            
        //backgroundColor: theme.palette.secondary.light,
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1),
        marginTop: '20%'
    },
    componentTitleNoMargin: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(3),      
      },
  }));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:nth-of-type(1)': {
        width: "25%"
      },
      '&:nth-of-type(2)': {
        width: "50%"
      },
      '&:nth-of-type(3)': {
        width: "25%"
      },
      fontSize: theme.typography.subtitle2.fontSize
    },
    body: {
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      color: theme.palette.primary.dark,
      //padding: theme.spacing(1)
    },
  }))(TableCell);

export default function ParameterVerification (props) {    
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [inputParametersStatus, setInputParametersStatus] = useState([])
    var height = useViewport()[0]
    
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect (() => {
        document.title ="Validación de parámetros";
        authFetch("/api/check-parameters?username=" + props.username + 
                '&period=' +  String( props.periodDate.getMonth() + 1 )
                + "&year=" + props.periodDate.getFullYear()                
                + "&market=" + props.market + "&version=" + props.tariffCalcVersion + "&calcType=" + props.calcType +
                "&reliq=" + props.reliqFinished +
                "&reliquidations=" + props.usedXmVersions.length+
                "&userType=" + props.userType,
          ).then(response => {
          if (response.status === 422){
              logout()
          }
              return response.json()
          }).then(response => {
              if (response && response.parameters_state){      
                let inputParametersStatusAux = response.parameters_state.filter(param => props.userType === 'Regulados' ? true : param.Filename === 'IPP' || param.Filename === 'IPC'
                ||  param.Filename === 'VkWUF' || param.Filename === 'CT_PR_NR'
                )            
                setInputParametersStatus(inputParametersStatusAux)                               
              }
        })
    }, [props.market, props.periodDate, props.tariffCalcVersion, props.reliqFinished,  props.usedXmVersions])

    const handleModificationChange = (idx) => (e) => {
        e.preventDefault()     
        setInputParametersStatus([...inputParametersStatus], inputParametersStatus[idx]["Entry"] = false) 
    }

    const scrollTo = (id, height) => { 
        let div = document.getElementById(id)   
        let offsetTop = 0    
        if (div != null){
            offsetTop = div.offsetTop
        }            
        animateScroll.scrollTo(offsetTop)
    }

    return(
        <div className={classes.root}>
        {
            props.market !== '' ? 
                !props.tariffsPublished && props.tariffCalcVersion === '' ?
                <Grid container justify="center" alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Debe crear una versión de cálculo para ingresar parámetros.</Typography>
                </Grid>
                </Grid>                
                :
                (!props.tariffsPublished || !props.opTariffPub || !props.reliqFinished) && props.tariffCalcVersion !== '' ?
                <Zoom in timeout={800}>
                <Grid container justify="space-evenly">
                    <Grid item xs={11}>
                        {
                            !props.reliqFinished ? 
                            <Typography variant="subtitle2" className={classes.rlqMsg}>Reliquidación activa.</Typography>
                            :
                            null
                        } 
                        <TableContainer component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Designación</StyledTableCell>
                                        <StyledTableCell align="left">Descripción</StyledTableCell>
                                        <StyledTableCell align="left">Ingreso</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={1}>
                        {
                            inputParametersStatus.length > 0 ? 
                            inputParametersStatus.map(
                                (parameter, idx) => (                                   
                                    <Grid item xs={12}>      
                                    <div id={idx} key={idx}>                                  
                                    <Accordion classes={{root: classes.accordion}}                                      
                                        TransitionProps={{ unmountOnExit: true }} expanded={expanded === idx} onChange={handlePanelChange(idx)}>
                                        
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon color="primary"/>}                                                                                
                                        >                                           
                                            <Grid container>
                                                <Grid container item xs={3} justify="flex-start">
                                                    <Typography className={classes.heading}>{parameter.Initials}<sub>{parameter.Sub}</sub></Typography>
                                                </Grid>
                                                <Grid container item xs={6} justify="flex-start">
                                                    <Typography className={classes.secondaryHeading}>{parameter.Description}</Typography>
                                                </Grid>
                                                <Grid container item xs={3} alignItems="flex-end">
                                                    <Grid item container justify="flex-end" xs={4}>
                                                        {parameter.Entry ? <CheckCircleOutlineIcon color="primary"/> : <HourglassEmptyIcon color="error"/>}
                                                    </Grid>
                                                    <Grid item container justify="flex-start" xs={8}>
                                                        { parameter.Entry ? 
                                                            <Typography className={classes.secondaryHeading}>Estado: Listo</Typography> : 
                                                            <Typography className={classes.secondaryHeading}>Estado: Pendiente</Typography>}
                                                    </Grid>                                
                                                </Grid>
                                            </Grid>                                                                    
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AnualTable id={idx} username={props.username} filename={parameter.Filename} 
                                                        fileEnabled={parameter.FileEnabled}
                                                        validations={parameter.Validations} period={props.period} periodDate={props.periodDate} quantity={"1"} 
                                                        entry={parameter.Entry} processHeaders={parameter.Process} data={parameter.Calc_Values}
                                                        extension={parameter.Extension}
                                                        market = {props.market}
                                                        marketDemand = {props.marketDemand}
                                                        orientation = {parameter.Orientation} 
                                                        unit = {parameter.Unit}                                
                                                        replicable = {parameter.Replicable}                  
                                                        requiredLimits = {parameter.RequiredLimits} 
                                                        limitsMsg = {parameter.RequiredLimits ? parameter.LimitsMsg : ''}
                                                        limits = {parameter.RequiredLimits ? parameter.Limits : [0,0]}
                                                        setInputParametersStatus={setInputParametersStatus}    
                                                        handleModification={handleModificationChange}              
                                                        tariffCalcVersion={props.tariffCalcVersion}   
                                                        calcType={props.calcType}   
                                                        strDist={props.strDist} 
                                                        reliqFinished={props.reliqFinished}
                                                        usedXmVersions={props.usedXmVersions}    
                                                        userType={props.userType}      
                                                        user={props.user}                    
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                    </div> 
                                    </Grid>    
                                )
                            )
                            :
                            <div></div>
                        }  
                        </Grid>                  
                    </Grid>            
                </Grid>
                </Zoom>
                :
                props.tariffsPublished && props.opTariffPub && props.reliqFinished && props.tariffCalcVersion !== '' ?
                <Fragment>
                <Grid container justify="center" alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="subtitle2" className={classes.componentTitleNoMargin}>Tarifas publicadas. No se pueden modificar los insumos de esta versión.</Typography>
                </Grid>
                </Grid>
                <Zoom in timeout={800}>
                <Grid container justify="space-evenly">
                    <Grid item xs={11}>
                        {
                            !props.reliqFinished ? 
                            <Typography variant="subtitle2" className={classes.rlqMsg}>Reliquidación activa.</Typography>
                            :
                            null
                        } 
                        <TableContainer component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Designación</StyledTableCell>
                                        <StyledTableCell align="left">Descripción</StyledTableCell>
                                        <StyledTableCell align="left">Ingreso</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={1}>                        
                        {
                            inputParametersStatus.length > 0 ? 
                            inputParametersStatus.filter(param => props.userType === 'Regulados' ? true : param.Filename === 'IPP' || param.Filename === 'IPC'
                                ||  param.Filename === 'VkWUF' || param.Filename === 'CT_PR_NR'
                                ).map(
                                (parameter, idx) => (                                   
                                    <Grid item xs={12}>      
                                    <div id={idx} key={idx}>                                  
                                    <Accordion classes={{root: classes.accordion}}                                      
                                        TransitionProps={{ unmountOnExit: true }} expanded={expanded === idx} onChange={handlePanelChange(idx)}>
                                        
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon color="primary"/>}                                                                                
                                        >                                           
                                            <Grid container>
                                                <Grid container item xs={3} justify="flex-start">
                                                    <Typography className={classes.heading}>{parameter.Initials}<sub>{parameter.Sub}</sub></Typography>
                                                </Grid>
                                                <Grid container item xs={6} justify="flex-start">
                                                    <Typography className={classes.secondaryHeading}>{parameter.Description}</Typography>
                                                </Grid>
                                                <Grid container item xs={3} alignItems="flex-end">
                                                    <Grid item container justify="flex-end" xs={4}>
                                                        {parameter.Entry ? <CheckCircleOutlineIcon color="primary"/> : <HourglassEmptyIcon color="error"/>}
                                                    </Grid>
                                                    <Grid item container justify="flex-start" xs={8}>
                                                        { parameter.Entry ? 
                                                            <Typography className={classes.secondaryHeading}>Estado: Listo</Typography> : 
                                                            <Typography className={classes.secondaryHeading}>Estado: Pendiente</Typography>}
                                                    </Grid>                                
                                                </Grid>
                                            </Grid>                                                                    
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AnualTable id={idx} username={props.username} filename={parameter.Filename} 
                                                        fileEnabled={parameter.FileEnabled}
                                                        validations={parameter.Validations} period={props.period} periodDate={props.periodDate} quantity={"1"} 
                                                        entry={parameter.Entry} processHeaders={parameter.Process} data={parameter.Calc_Values}
                                                        extension={parameter.Extension}
                                                        market = {props.market}
                                                        marketDemand = {props.marketDemand}
                                                        orientation = {parameter.Orientation} 
                                                        unit = {parameter.Unit}                                
                                                        replicable = {parameter.Replicable}                  
                                                        requiredLimits = {parameter.RequiredLimits} 
                                                        limitsMsg = {parameter.RequiredLimits ? parameter.LimitsMsg : ''}
                                                        limits = {parameter.RequiredLimits ? parameter.Limits : [0,0]}
                                                        setInputParametersStatus={setInputParametersStatus}    
                                                        handleModification={handleModificationChange}              
                                                        tariffCalcVersion={props.tariffCalcVersion}   
                                                        calcType={props.calcType}   
                                                        strDist={props.strDist} 
                                                        reliqFinished={props.reliqFinished}
                                                        usedXmVersions={props.usedXmVersions}    
                                                        noEdit={(props.tariffsPublished && props.opTariffPub && props.reliqFinished)} 
                                                        userType={props.userType}       
                                                        role={props.role}                  
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                    </div> 
                                    </Grid>    
                                )
                            )
                            :
                            <div></div>
                        }  
                        </Grid>                  
                    </Grid>            
                </Grid>
                </Zoom>
                </Fragment>
                :
                null
            :
            null
        }
        
        </div>
    )

}