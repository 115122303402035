import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Checkbox, IconButton, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
  }));
  
export default function Summary(props) {
    const classes = useStyles();    
    let stripes = ['max','med','min','mon']
    let levels  = ['n1or','n1comp','n1us','n2','n3']
    const [offerDialog, setOfferDialog] = useState(false) 
    const [regDialog, setRegDialog] = useState(false)
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [overcomeOffers, setOvercomeOffers] = useState([])
    const [clientOffers, setClientOffers] = useState([])
    const [clientRegs, setClientRegs] = useState([])
    const [clientName, setClientName] = useState("")
    const [clientOffer, setClientOffer] = useState({})
    const [clientReg, setClientReg] = useState({})
    const [clientCode, setClientCode] = useState("")
    
    const [userTariffs, setUserTariffs] = useState({})
    const [isPublishing, setIsPublishing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [tariffsCalculated, setTariffsCalculated] = useState(false)


    const handleRegDialogOpen = () => {
        setRegDialog(true);
      };
    
    const handleRegDialogClose = () => {
        setRegDialog(false);
    };

    const handleOfferDialogOpen = () => {
        setOfferDialog(true);
      };
    
    const handleOfferDialogClose = () => {
        setOfferDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const inputsStateList = (components, componentsState) => (
        <Fragment>                            
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.componentTitle}>Componentes</Typography>
                </Grid>
                {
                    components.length > 0 ? 
                    <Grid container justify="center">
                    <Grid item xs={11}>
                        <Grid container justify="space-evenly">
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Nombre
                        </Grid>                             
                        <Grid item xs={5} style={{fontWeight: 'bold'}}>
                            Estado
                        </Grid>  
                            {
                                components.map((param, idx) => (
                                <Fragment>
                                    <Grid item xs={5}>
                                        {param}
                                    </Grid>                                    
                                    <Grid item xs={5}>
                                    { componentsState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <DoneIcon fontSize="small"/>
                                            </Grid>
                                            <Grid item>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="center" spacing={2}>
                                            <Grid item>
                                                <HighlightOffIcon fontSize="small" color="error"/>
                                            </Grid>
                                            <Grid item>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid>
                                    }
                                    </Grid>
                                </Fragment>
                                ))                            
                            }
                        </Grid>
                    </Grid>
                    </Grid>
                    :
                    null
                }  
                           
            </Grid>             
        </Fragment>            
    ) 
 
    const handleOfferUpload = () => {               
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        let validationFlag = true
        if ( clientOffer.calc_type === 1 && (!clientOffer.indexation_var || !clientOffer.indexation_var_base_value ||  !clientOffer.c_base_value || !clientOffer.g_base_value)){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false           
        }   
        if ( clientOffer.calc_type === 1 && (!clientOffer.suscription_date || !clientOffer.start_date ||  !clientOffer.finish_date)){
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false
        }
        if ( clientOffer.calc_type === null ){                        
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false
        }
        
        if (validationFlag) {                                                       
            authFetch(
                "/api/update-alu-offers",
                {
                  method: 'post',
                  body: JSON.stringify(clientOffer),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Oferta guardada.')
                    handleFeedbackDialogOpen()        
                    handleOfferDialogClose() 
                    checkUserOffer(clientCode) 
                    authFetch("/api/check-expiration-alu-offers?username=" + String(props.username)).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.offers){
                                setOvercomeOffers(response.offers)                
                            }
                        })                       
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
    }    

    const handleRegUpload = () => {               
        ////console.log(contractNumber, seller, detail, market, calcType, baseValue, indexedValue, actualIpp, baseIpp, initialDate, endDate, active)
        let validationFlag = true
        if ( (!clientReg.reg_code || !clientReg.reg_name ||  !clientReg.voltage_level)){            
            handleFeedbackDialogOpen()
            setFeedbackMsg("Algunos campos estan vacíos. Ingreso inválido.") 
            validationFlag = false           
        }   
        
        if (validationFlag) {                                                       
            authFetch(
                "/api/update-alu-regs",
                {
                  method: 'post',
                  body: JSON.stringify(clientReg),
                }
              ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                  if (response.Upload === 'OK'){
                    setFeedbackMsg('Matrícula guardada.')
                    handleFeedbackDialogOpen()        
                    handleRegDialogClose() 
                    checkUserOffer(clientCode)                                           
                  }
                  else {
                    setFeedbackMsg('Error al guardar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
    }      

    const checkUserOffer = (clientCode) => {
        authFetch("/api/check-alu-offers?username=" + String(props.username) + '&municip_code=' + clientCode).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.offers){                    
                    if (response.offers.length === 0){                        
                        setFeedbackMsg('Municipio sin ofertas')                        
                        handleFeedbackDialogOpen()
                    }
                    else if (response.offers.length > 0){                        
                        setClientOffers(response.offers)                        
                        if (props.tariffsPublished){
                            authFetch("/api/check-alu-user-tariffs?username=" + props.username + 
                                '&period=' +  String( props.periodDate.getMonth() + 1 )
                                + "&year=" + props.periodDate.getFullYear()                
                                + "&version=" + props.tariffCalcVersion
                                + '&municip_code=' + clientCode).then(response => {
                                if (response.status === 422){
                                    logout()
                                    return null
                                }
                                return response.json()
                            }).then(response => {
                                if (response && response.user_tariff){                
                                    setUserTariffs(response.user_tariff)                                         
                                }
                            }) 
                        }                                              
                    }
                }
            })
        authFetch("/api/check-alu-regs?username=" + String(props.username) + '&municip_code=' + clientCode).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.regs){                    
                        if (response.regs.length === 0){                        
                            setFeedbackMsg('Municipio sin matrículas')                        
                            handleFeedbackDialogOpen()
                        }
                        else if (response.regs.length > 0){                        
                            setClientRegs(response.regs)                  
                        }
                    }
                })
    }

    const publishTariffs = () => {
        setIsPublishing(true)
        authFetch("/api/publish-alu-user-tariffs?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion
            + '&cycle=' + 'AP').then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.Upload === 'OK'){                
                setFeedbackMsg("Tarifas publicadas para municipios AP.") 
                handleFeedbackDialogOpen() 
                setIsPublishing(false)   
                authFetch("/api/check-alu-components?username=" + props.username + 
                    '&period=' +  String( props.periodDate.getMonth() + 1 )
                    + "&year=" + props.periodDate.getFullYear()                
                    + "&version=" + props.tariffCalcVersion).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                    return response.json()
                }).then(response => {
                    if (response && response.tariffs_flag){                                  
                        setTariffsCalculated(response.tariffs_flag)                   
                    }
                })                                    
            }
        })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showOfferDialog = () => (
        <Dialog
            open={offerDialog}
            onClose={handleOfferDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes de la oferta."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Municipio:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientOffer.municip_name}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código:</Typography>
                        </Grid>
                        <Grid item>
                            <LocationCityIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={clientOffer.municip_code}
                                
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}    
                                disabled                                                      
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Contrato:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={clientOffer.contract_code}                             
                                placeholder={"Código del contrato."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                multiline                                       
                                onChange={(e) => {setClientOffer({...clientOffer, contract_code: e.target.value});}}                       
                            />
                                
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Detalle:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={clientOffer.detail}                             
                                placeholder={"Detalle del contrato."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow} 
                                multiline                                       
                                onChange={(e) => {setClientOffer({...clientOffer, detail: e.target.value});}}                       
                            />
                                
                        </Grid>                                                
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                    <Grid container spacing={2}>    
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Tipo de cálculo:</Typography>
                        </Grid>
                        <Grid item>
                            <MoreVertIcon/>
                        </Grid>
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientOffer.calc_type}                                                            
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText,                                       
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                                         
                                select
                                onChange = {(e) => {
                                        
                                        if (e.target.value === 0){
                                            setClientOffer({...clientOffer, start_date: null, finish_date: null, c_base_value: null, g_base_value: null,
                                                indexation_var: null, indexation_var_base_value: null, suscription_date: null, calc_type: 0})
                                        }
                                        else{
                                            setClientOffer({...clientOffer, calc_type: e.target.value});
                                        }
                                                    }}
                            >
                                <MenuItem value={0} className={classes.formItem}>Sin contrato</MenuItem>
                                <MenuItem value={1} className={classes.formItem}>Contrato: indexación</MenuItem>
                            </TextField>
                                                    
                        </Grid> 
                        {
                            clientOffer.calc_type === 1 ?
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left">Variable de indexación:</Typography>
                                </Grid>
                                <Grid item>
                                    <MoreVertIcon/>
                                </Grid>
                                <Grid item xs={9}>                   
                                    <TextField
                                        value={clientOffer.indexation_var} 
                                        placeholder={"Seleccione la variable de indexación."}                               
                                        InputProps={{                                    
                                            classes: {
                                                input: classes.formText,                                       
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}                                                                                         
                                        select
                                        onChange = {(e) => {setClientOffer({...clientOffer, indexation_var: e.target.value});
                                                            }}
                                    >
                                        <MenuItem value={"IPC"} className={classes.formItem}>IPC</MenuItem>
                                        <MenuItem value={"IPP"} className={classes.formItem}>IPP</MenuItem>
                                    </TextField>
                                                            
                                </Grid>
                            </Fragment>
                            :
                            null
                        }
                               
                        {
                            clientOffer.calc_type === 1 && clientOffer.indexation_var ? 
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left">{'Valor base ' + clientOffer.indexation_var + ':'}</Typography>
                                </Grid>
                                <Grid item>
                                    <ShowChartIcon/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="number"
                                        value={clientOffer.indexation_var_base_value}
                                        placeholder={"Ingrese el valor base del " + clientOffer.indexation_var}
                                        InputProps={{                                    
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setClientOffer({...clientOffer, indexation_var_base_value: e.target.value});}}                            
                                    />                           
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left">Valor base componente C:</Typography>
                                </Grid>
                                <Grid item>
                                    <MonetizationOnIcon/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="number"
                                        value={clientOffer.c_base_value}
                                        placeholder={"Ingrese el valor de C."}
                                        InputProps={{                                    
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {setClientOffer({...clientOffer, c_base_value: e.target.value});}}                            
                                    />                           
                                </Grid>                                                   
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" align="left">Valor base componente G:</Typography>
                                </Grid>
                                <Grid item>
                                    <MonetizationOnIcon/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        type="number"
                                        value={ 
                                            clientOffer.g_base_value}
                                        placeholder={"Ingrese el valor base de G."}
                                        InputProps={{                                    
                                            classes: {
                                                input: classes.formText
                                            }
                                        }}
                                        variant="standard"
                                        className={classes.formRow}   
                                        onChange={(e) => {                                            
                                            setClientOffer({...clientOffer, g_base_value: e.target.value})
                                        }}                            
                                    />                           
                                </Grid>  
                            </Fragment> 
                            :
                            null
                        }               
                                                                                                                                                                                                                                                                                                                                                                                                         
                    </Grid>                                       
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={2}> 
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de suscripción:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                                                    
                            value={clientOffer.suscription_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, suscription_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>                                                                                                                    
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de inicio:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                    
                            value={clientOffer.start_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, start_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Fecha de terminación:</Typography>                    
                    </Grid>                        
                    <Grid item >
                        <DateRangeIcon/>
                    </Grid>
                    <Grid item xs={9}>
                        <DatePicker    
                            variant="dialog"    
                            inputVariant="standard"                                                                                 
                            okLabel={""}
                            cancelLabel={""}                            
                            format="dd-MMMM-yyyy"
                            //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                            //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}                                                        
                            value={clientOffer.finish_date}
                            InputLabelProps={{                                            
                                        classes: {
                                            root: classes.labelText,                                    
                                        }
                                    }}
                            onChange={(date) => {setClientOffer({...clientOffer, finish_date: date});}}
                            error={false}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        }
                                    }}
                        
                            autoOk                                                                                  
                        /> 
                    </Grid>
                    <Grid item>
                        <Grid container justify="flex-start" style={{marginTop: 10}}>
                            <Grid item>
                            <Typography variant="body2" color="primary" style={{marginLeft: 10}}>{clientOffer.active? 'Activa':'Inactiva'}</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item>
                            <Switch
                                checked={clientOffer.active}
                                onChange={(e) => {setClientOffer({...clientOffer, active: e.target.checked});}}
                                color="primary"                                                 
                            />
                            </Grid>                                                        
                        </Grid>
                    </Grid>                     
                    </Grid>                    
                </Grid>                           
                <Grid item style={{marginTop: 20}}>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleOfferUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item style={{marginTop: 20}}>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleOfferDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const showRegDialog = () => (
        <Dialog
            open={regDialog}
            onClose={handleRegDialogClose}       
            className={classes.formText}
            scroll={'paper'}
            maxWidth={'md'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>{"Ingrese los datos correspondientes de la matrícula."}</DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="space-evenly">                
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Municipio:</Typography>
                        </Grid>
                        <Grid item>
                            <LocationCityIcon/>
                        </Grid>                
                        <Grid item xs={9}>                   
                            <TextField
                                value={clientReg.municip_code}                               
                                InputProps={{
                                    readOnly: true,
                                    className: classes.formDefined,
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}   
                                disabled                                                           
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Código:</Typography>
                        </Grid>
                        <Grid item>
                            <BusinessCenterIcon/>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField                                
                                value={clientReg.reg_code}
                                placeholder={"Código."}
                                InputProps={{                                    
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}
                                onChange={(e) => {setClientReg({...clientReg, reg_code: e.target.value});}}                                                                                          
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="left">Nombre:</Typography>
                        </Grid>
                        <Grid item>
                            <DehazeIcon/>
                        </Grid>               
                        <Grid item xs={9}>
                            <TextField
                                value={clientReg.reg_name}                             
                                placeholder={"Nombre."}
                                InputProps={{                                   
                                    classes: {                                        
                                        input: classes.formText
                                    }
                                }}
                                variant="standard"
                                className={classes.formRow}                                                                        
                                onChange={(e) => {setClientReg({...clientReg, reg_name: e.target.value});}}                       
                            />
                                
                        </Grid>                                                                        
                    </Grid>                                       
                </Grid>     
                <Grid item xs={4}>
                                                          
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={2}> 
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" align="left">Nivel de tensión:</Typography>
                    </Grid>
                    <Grid item>
                        <DehazeIcon/>
                    </Grid>               
                    <Grid item xs={9}>
                        <TextField
                            value={clientReg.voltage_level}                             
                            placeholder={"Nombre."}
                            InputProps={{                                   
                                classes: {                                        
                                    input: classes.formText
                                }
                            }}
                            variant="standard"
                            className={classes.formRow}    
                            select                                                                    
                            onChange={(e) => {setClientReg({...clientReg, voltage_level: e.target.value});}}                       
                        >
                            <MenuItem value={"1"} key={1}>{"1"}</MenuItem> 
                            <MenuItem value={"Compartido"} key={2}>{"Compartido"}</MenuItem> 
                            <MenuItem value={"Usuario"} key={3}>{"Usuario"}</MenuItem> 
                            <MenuItem value={"2"} key={4}>{"2"}</MenuItem> 
                            <MenuItem value={"3"} key={5}>{"3"}</MenuItem> 
                        </TextField>
                    </Grid> 
                    <Grid item>
                        <Grid container justify="flex-start" style={{marginTop: 10}}>
                            <Grid item>
                            <Typography variant="body2" color="primary" style={{marginLeft: 10}}>{clientReg.active? 'Activa':'Inactiva'}</Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item>
                            <Switch
                                checked={clientReg.active}
                                onChange={(e) => {setClientReg({...clientReg, active: e.target.checked});}}
                                color="primary"                                                 
                            />
                            </Grid>                                                        
                        </Grid>
                    </Grid>                     
                    </Grid>                    
                </Grid>                           
                <Grid item style={{marginTop: 20}}>
                     <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleRegUpload}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><SaveIcon /> {' Guardar  '}</Fragment>                         
                    </Fab>
                </Grid>                   
                <Grid item style={{marginTop: 20}}>
                    <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleRegDialogClose}
                        style={{marginTop: 20}}
                    > 
                        <Fragment><CancelIcon /> {'Cancelar'}</Fragment>                         
                    </Fab>
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const downloadRegsTariff = () => {
        setIsLoading(true)
        authFetch("/api/download-alu-regs-tariff"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&username=" + String(props.username) + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion,
            { responseType: 'blob' } 
        ).then(response => {
          setIsLoading(false) 
          if (response.status === 422){
              logout()
          }
          else if (response.status === 200){
            return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');            
            let month = props.periodDate.getMonth() + 2 > 12 ? 1 : props.periodDate.getMonth() + 2                
            month = String(month).length === 1 ? '0'+String( month ) : String( month ) 
            let year = props.periodDate.getFullYear()
            year = props.periodDate.getMonth() + 2 > 12 ? year + 1 : year
            a.style.display = 'none';
            a.href = url;
            a.download = 'Tarifas_AP_'+props.username+'_'+year+'_'+month+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);                                               
        })
    }

    useEffect (() => {
        document.title ="Tarifas alumbrado público";
        authFetch("/api/check-expiration-alu-offers?username=" + String(props.username)).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.offers){
                    setOvercomeOffers(response.offers)                
                }
            })

        authFetch("/api/check-alu-components?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.tariffs_flag){                                  
                setTariffsCalculated(response.tariffs_flag)                   
            }
        })

    }, [props.periodDate])

    return (
        <div className={classes.root}>
            {showOfferDialog()}
            {showRegDialog()}
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                
                <Grid item xs={9}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Estado de ofertas por municipio</Typography>
                    <TableContainer component={Paper} style={{marginTop: 10}}>
                        <Table aria-label="markets table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Código del municipio</StyledTableCell>
                                <StyledTableCell align="center">Nombre</StyledTableCell>
                                <StyledTableCell align="center">Estado</StyledTableCell>
                                <StyledTableCell align="center">Fecha Final</StyledTableCell>                                
                            </TableRow>
                            </TableHead>
                            <TableBody>    
                                {
                                    overcomeOffers.map((offer) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="center">{offer.municip_code}</StyledTableCell>
                                            <StyledTableCell align="center">{offer.municip_name}</StyledTableCell>
                                            <StyledTableCell align="center" style={ offer.expired ? {background: '#FB9187'} : {}}>{offer.expired ? 'VENCIDA' : offer.finish_date ? 'VIGENTE' : 'SIN DATOS'}</StyledTableCell>
                                            <StyledTableCell align="center">{offer.finish_date ? new Date(offer.finish_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }                                                                                                                                   
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Grid>
                <Grid item xs={12} style={{marginTop: 30}}>                    
                    <Grid container justify="center" spacing={1} alignItems="center">
                        <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>Parametrizar ofertas/matrículas por municipio</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="subtitle2" align="center" color="primary">Buscar municipio (por código)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                InputProps={{
                                    classes: {
                                        input: classes.formText
                                    }
                                }}
                                className={classes.formRow} 
                                value={clientCode}
                                onChange={(e) => {setClientCode(e.target.value); setClientName(''); setClientOffers([]); setClientRegs([]);}}
                            >
                            </TextField>
                        </Grid>
                        
                        <Grid item>
                            <IconButton color="primary" onClick={(e) => {checkUserOffer(clientCode)}}>
                                <SearchOutlinedIcon className={classes.searchButton} />
                            </IconButton>                             
                        </Grid>
                        
                        <Grid item xs={12}></Grid>
                    
                        <Grid item xs={11}>
                        <TableContainer component={Paper} style={{marginTop: 10}}>
                            <Table aria-label="markets table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "5%"}}>Oferta</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Código municipio</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "15%"}}>Nombre</StyledTableCell>                                
                                    <StyledTableCell align="center" style={{width: "5%"}}>Var. indexación</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Base indexación</StyledTableCell>                               
                                    <StyledTableCell align="center" style={{width: "15%"}}>Valor base G</StyledTableCell>                                                                   
                                    <StyledTableCell align="center" style={{width: "10%"}}>Valor base C</StyledTableCell>                           
                                    <StyledTableCell align="center" style={{width: "15%"}}>Fecha inicial</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "15%"}}>Fecha final</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>    
                                    {
                                        clientOffers.map( (offer, idx) => (
                                                <TableRow onClick={(e) => {handleOfferDialogOpen(); setClientOffer(offer);}}>
                                                    <StyledTableCell align="center">{idx+1}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.municip_code ?  offer.municip_code : clientCode}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.municip_name}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.indexation_var ? offer.indexation_var : '-'}</StyledTableCell> 
                                                    <StyledTableCell align="center">{offer.indexation_var_base_value ? offer.indexation_var_base_value : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.g_base_value ? offer.g_base_value : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.c_base_value ? offer.c_base_value : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.start_date ? new Date(offer.start_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>
                                                    <StyledTableCell align="center">{offer.finish_date ? new Date(offer.finish_date).toLocaleString('en-GB', {day: "2-digit", month: "2-digit", year: "numeric"}) : '-'}</StyledTableCell>                                                                                                                    
                                                </TableRow>   
                                        )) 
                                    }                                                                                                                  
                                </TableBody>
                            </Table>
                        </TableContainer> 
                        </Grid>
                        <Grid item xs={3}>                    
                            {
                            clientOffers.length > 0 ?
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() => {
                                    let clientOffersArray = clientOffers;
                                    clientOffersArray.push({active: true, municip_code: clientCode, username: props.username, municip_name: clientOffers[0].municip_name});
                                    setClientOffers([...clientOffersArray]);                                                                                        
                                }}     
                                style={{marginTop: 15}}                           
                            > 
                                <Fragment><AddIcon /> {' Agregar  '}</Fragment>                         
                            </Fab> 
                            :
                            null
                            }                     
                        </Grid>
                        <Grid item xs={11}>
                        <TableContainer component={Paper} style={{marginTop: 10}}>
                            <Table aria-label="markets table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width: "10%"}}>Matrícula</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "15%"}}>Código</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "25%"}}>Nombre</StyledTableCell>                                
                                    <StyledTableCell align="center" style={{width: "15%"}}>Nivel tensión</StyledTableCell>
                                    <StyledTableCell align="center" style={{width: "20%"}}>Municipio</StyledTableCell>                               
                                    <StyledTableCell align="center" style={{width: "15%"}}>Activa</StyledTableCell>                                                                                                      
                                </TableRow>
                                </TableHead>
                                <TableBody>    
                                    {
                                        clientRegs.map( (reg, idx) => (
                                                <TableRow onClick={(e) => {
                                                        handleRegDialogOpen(); 
                                                        setClientReg(reg);
                                                        }
                                                    }>
                                                    <StyledTableCell align="center">{idx+1}</StyledTableCell>
                                                    <StyledTableCell align="center">{reg.reg_code}</StyledTableCell>
                                                    <StyledTableCell align="center">{reg.reg_name}</StyledTableCell>
                                                    <StyledTableCell align="center">{reg.voltage_level}</StyledTableCell> 
                                                    <StyledTableCell align="center">{reg.municip_code}</StyledTableCell>
                                                    <StyledTableCell align="center">{reg.active ? 'Activa' : 'Inactiva'}</StyledTableCell>
                                                                                                                                                                       
                                                </TableRow>   
                                        )) 
                                    }                                                                                                                  
                                </TableBody>
                            </Table>
                        </TableContainer> 
                        </Grid>
                        <Grid item xs={3}>                    
                            {
                                // Debe existir una oferta para añadir matriculas
                            clientOffers.length > 0 ?
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={() => {
                                    let clientRegsArray = clientRegs;
                                    clientRegsArray.push({active: true, municip_code: clientCode, username: props.username});
                                    setClientRegs([...clientRegsArray]);                                                                                        
                                }}     
                                style={{marginTop: 15}}                           
                            > 
                                <Fragment><AddIcon /> {' Agregar  '}</Fragment>                         
                            </Fab> 
                            :
                            null
                            }                     
                        </Grid>
                    </Grid>
                </Grid>                 
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Cálculo de tarifas</Typography>
                    <Grid container spacing={2} justify="space-evenly" alignItems="flex-start">
                        <Grid item xs={4} className={classes.darkText}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Insumos necesarios</Typography>
                            {inputsStateList(['Publicación tarifas reguladas'], [props.tariffsPublished])}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Tarifas del municipio</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{width: "17%"}}></StyledTableCell>
                                            <StyledTableCell align="center">Tm</StyledTableCell>
                                            <StyledTableCell align="center">Dm</StyledTableCell>
                                            <StyledTableCell align="center">Rm</StyledTableCell>
                                            <StyledTableCell align="center">Cvm</StyledTableCell>
                                            <StyledTableCell align="center">Gm</StyledTableCell>
                                            <StyledTableCell align="center">PRm</StyledTableCell>
                                            <StyledTableCell align="center">Cu</StyledTableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            ['Nivel 1 OR', 'Nivel 1 CO', 'Nivel 1 US', 'Nivel 2', 'Nivel 3'].map((subtitle, sidx) => (
                                                <StyledTableRow key={sidx}>
                                                    <StyledTableCell variant="head" align="center">{subtitle}</StyledTableCell>
                                                    { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{clientCode in userTariffs ? userTariffs[clientCode].t_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>: null}
                                                    <StyledTableCell align="center">{clientCode in userTariffs ? userTariffs[clientCode]['d_val_'+levels[sidx]].toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                                    { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{clientCode in userTariffs ? userTariffs[clientCode].r_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> : null}
                                                    { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{clientCode in userTariffs ? userTariffs[clientCode].c_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> : null}
                                                    { sidx === 0 ? <StyledTableCell rowspan={5} align="center">{clientCode in userTariffs ? userTariffs[clientCode].g_val.toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> : null}
                                                    {   sidx > 2 ?
                                                        <StyledTableCell align="center">{clientCode in userTariffs ?  userTariffs[clientCode]['pr_val_n' + String(sidx-1)].toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>
                                                        :
                                                        sidx === 0 ?
                                                        <StyledTableCell rowspan={3} align="center">{clientCode in userTariffs ?  userTariffs[clientCode]['pr_val_n1'].toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell> : null
                                                    }
                                                    <StyledTableCell align="center">{clientCode in userTariffs ?  userTariffs[clientCode][levels[sidx]].toLocaleString('en-US',{maximumFractionDigits: 2}) : '-'}</StyledTableCell>                                                    
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Publicar (todos)</Typography>
                                {
                                    isPublishing ? 
                                    <CircularProgress/>
                                    :
                                    <Button 
                                        onClick={(e) => {publishTariffs();}} 
                                        color='primary' 
                                        disabled={!props.tariffsPublished || tariffsCalculated} 
                                        variant='contained'
                                    >
                                        Publicar
                                    </Button>
                                }                                
                        </Grid>
                    </Grid>                     
                </Grid> 
                <Grid item xs={12} style={{marginTop: 30}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Exportar tarifas de todas las matrículas</Typography>
                    <Grid container justify="center">
                    <Grid item xs={6}>                        
                        {
                            isLoading ? 
                            <CircularProgress/>
                            :
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"                        
                                classes={{root: classes.uploadButton}}
                                onClick={downloadRegsTariff}
                                style={{marginTop: 20}}
                                disabled={!tariffsCalculated}
                            > 
                                <Fragment><ArrowDropDownCircleIcon /> {'Descargar'}</Fragment>                         
                            </Fab>
                        }                           
                    </Grid>
                    </Grid>
                </Grid>                                                                                      
            </Grid>   
            </Zoom>    
        </div>
    );
}