import {React, useEffect, useState, Fragment, useContext, createContext, useMemo} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';
import DataGrid from 'react-data-grid';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TrainRounded } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({  
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    contentPaper:{
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.secondary.light,
        padding: 10,
        color: theme.palette.primary.main,       
        height: 150
    },
    contentPaperState:{
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.secondary.light,
        padding: 10,
        color: theme.palette.primary.main,       
        fontSize: 13,       
        height: 150
    },
    contentTable:{
        background: theme.palette.secondary.light,
        padding: 10,
        color: theme.palette.primary.main,       
    },
    darkText: {
        color: theme.palette.primary.main
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    filterInput: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        width: 200          
    },
    filterInput100: {
        fontSize: 12,
        fontFamily: 'Roboto Condensed',
        padding: 1,  
        maxWidth: 100          
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    },
    infoNumber:{
        background: theme.palette.primary.main, 
        padding:8, 
        borderRadius: 5,
        fontWeight: 'bold',
        color: theme.palette.secondary.light
      },
    infoText:{      
        fontWeight: 'bold',
        color: theme.palette.primary.dark
    }, 
    formRow: {
        width: "100%",                    
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: 'white'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));

const FilterContext = createContext(undefined)

function HeaderFilter (props) {
    const filters = useContext(FilterContext)
    return(                        
        <input  className={props.filterInput} value={filters[props.field]} onChange={(e) => {            
            props.setFilters({...filters, [props.field]: e.target.value})
        }}></input> 
    )
} 

export default function Summary(props) {
    const classes = useStyles();    
    //let billingDate = new Date(auxDate.setMonth(auxDate.getMonth() + 1)) 
    let billingDate = props.billingDate    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    
    const [cycles, setCycles] = useState([])    
    const [cycleConsumption, setCycleConsumption] = useState({})
    const [cycleTariffs, setCycleTariffs] = useState({})
    const [cycleLiqConsumption, setCycleLiqConsumption] = useState({})
    const [selectedCycle, setSelectedCycle] = useState("")
    const [liquidationInProgress, setLiquidationInProgress] = useState(false)
    const [approvationInProgress, setApprovationInProgress] = useState(false)
    const [uploadingInProgress, setUploadingInProgress] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    // Deberian cambiar si se cambia el period Date
    const [cycleConsumptionFlag, setCycleConsumptionFlag] = useState(false)
    const [cycleTariffsFlag, setCycleTariffsFlag] = useState(false)  
    const [pbFlag, setPbFlag] = useState(false)  
    const [totalUsers, setTotalUsers] = useState(0)
    const [openLiqUsers, setOpenLiqUsers] = useState(0)


    const [inputData, setInputData] = useState([])
    const [selectedAgType, setSelectedAgType] = useState("")
    const [consumptionUsers, setConsumptionUsers] = useState([])
    const [liquidationApprovedFlag, setLiquidationApprovedFlag] = useState(false)
    const [osfLiquidationFlag, setOsfLiquidationFlag] = useState(false)
    

    const [filters, setFilters] = useState({
        client_name: '',
        client_code: '',     
        voltage_level: '',             
    })
    const [columns, setColumns] = useState(
        [
            {key: 'client_name', width: 250, frozen: true, name: <b>Nombre/Razón Social</b>},
            {key: 'client_code', width: 130, name: <b>Código del cliente</b>},                                               
            {key: 'period', width: 130, name: <b>Periodo de tarifa</b>},
            {key: 'active_tariff', width: 130, name: <b>Tarifa</b>},            
            {key: 'd_val', width: 130, name: <b>Tarifa reactivas</b>},            
            {key: 'total_active_energy', width: 130, name: <b>Energía activa</b>},
            {key: 'reactive_ind_energy', width: 130, name: <b>Energía inductiva</b>},
            {key: 'reactive_cap_energy', width: 130, name: <b>Energía capacitiva</b>},
            {key: 'generated_energy', width: 130, name: <b>Energía generada</b>},
            {key: 'excedentary_energy', width: 130, name: <b>Energía excedentaria</b>},
            {key: 'm_factor', width: 130, name: <b>Factor M</b>},
            {key: 'val_active_energy', width: 130, name: <b>Valor activa</b>},
            {key: 'val_reactive_ind_energy', width: 130, name: <b>Valor inductiva</b>},
            {key: 'val_reactive_cap_energy', width: 130, name: <b>Valor capacitiva</b>},
            {key: 'val_generated_energy', width: 130, name: <b>Valor generada</b>},
            {key: 'val_exchange_energy', width: 130, name: <b>Costo intercambio</b>},
            {key: 'val_excedentary_energy', width: 130, name: <b>Valor excedentaria</b>},                                   
            {key: 'val_subsidy', width: 130, name: <b>Valor subsidio</b>},  
            {key: 'val_contribution', width: 130, name: <b>Valor contribución</b>},  
            {key: 'val_total', width: 130, name: <b>Fuente</b>}, 
        ]
    ) 
    // columns[0].headerRenderer = ({column}) => (
    //     <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput} value={filters.client_name} field={'client_name'}>        
    //     </HeaderFilter>
    //     )
    // columns[1].headerRenderer = ({column}) => (
    //     <HeaderFilter column={column} setFilters={setFilters} filterInput={classes.filterInput100} value={filters.client_code} field={'client_code'}>        
    //     </HeaderFilter>)
    
    
    const [rows, setRows] = useState([])
   
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const inputsStateList = (files, filesState) => (
        <Fragment>                                               
            {
                files.length > 0 ? 
                <Grid container justify="center">
                <Grid item xs={12}>   
                    <Grid container justify="space-evenly" alignItems="center" spacing={1}>           
                    <Grid item xs={7} style={{fontWeight: 'bold'}}>
                        Nombre
                    </Grid>     
                    
                    <Grid item xs={5} style={{fontWeight: 'bold'}}>
                        Estado
                    </Grid>       
                        {
                            files.map((filename, idx) => (
                            <Fragment>
                                <Grid item xs={7}>
                                {filename}
                                </Grid>                                          
                                <Grid item xs={5}>
                                    { filesState[idx] ? 
                                        <Grid container alignItems="flex-start" justify="center" spacing={1}>
                                            <Grid item>
                                                <DoneIcon style={{fontSize: 12}} color="success"/>
                                            </Grid>
                                            <Grid item>                                            
                                                Listo
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="flex-start" justify="center" spacing={1}>
                                            <Grid item>
                                                <HighlightOffIcon style={{fontSize: 12}} color="error"/>
                                            </Grid>
                                            <Grid item>
                                                Pendiente
                                            </Grid>                                            
                                        </Grid> 
                                    }                                   
                                </Grid>
                            </Fragment>
                            ))                            
                        }      
                    </Grid>   
                </Grid>         
                </Grid> 
                :
                null
            }                                                    
        </Fragment>            
    ) 
    // PENDIENTE AGREGAR TYPE PARA TIPOS DE LIQUIDACION
    const callLiquidation = () => {
        setLiquidationInProgress(true)                        
        authFetch("/api/liquidate-ag-users-consumption?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                {
                    method: 'post',
                    body: JSON.stringify({codes: consumptionUsers}),
                }).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.") 
                setLiquidationInProgress(false)
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.Upload === 'OK'){                                                      
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Consumos liquidados.") 
                    setLiquidationInProgress(false)                                                
                }
                else{
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.") 
                    setLiquidationInProgress(false)
                }
            }) 
        
    }
 
    const approveLiquidation = () => {
        setApprovationInProgress(true)
        authFetch("/api/-approve-ag-liquidation?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setApprovationInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.Upload === 'OK'){                                                      
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Liquidación de consumos aprobada.")    
                    setApprovationInProgress(false)     
                    setLiquidationApprovedFlag(true)                                                
                }
                else{
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.")         
                    setApprovationInProgress(false)              
                }
            })
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                   
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const updateFlags = (value) => {
        if ('3' === value[0]){        
            setInputData(['Tarifas regulados', <pr>Consumos usuarios<br/>{value.slice(8)}</pr>, 'Precio pactado'])
        }
        else{
            setInputData(['Tarifas regulados', <pr>Consumos usuarios<br/>{value.slice(8)}</pr>, 'Precio de bolsa y Precio escasez'])
        }
        setCycleTariffsFlag(cycleTariffs ? cycleTariffs['REGULADO']: false)
    }

    const uploadOSFFile = (file) => {
        setUploadingInProgress(true)
        let opts = {
            'filename': file.name,      
            'contentType': file.type,              
        }
        authFetch(
            "/api/get-gcs-signed-url?username="+props.username+
                            "&period="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear()+                        
                            "&files_from="+ 'ag',
            {
                method: 'post',                
                //body: formData,
                body: JSON.stringify(opts)
            }
        ).then(r => {
            if (r.status === 422){
                logout()
                return null
            }
            if (r.status === 200){
                return r.json()
            }
            else {
                setFeedbackMsg("Error subiendo archivo.")
                handleFeedbackDialogOpen()               
                setUploadingInProgress(false)
            }
        })
        .then(response => {
            if (response.url){
                let signedUrl = response.url       
                fetch(
                    signedUrl,
                    {
                        method: 'put',
                        body: file,
                        headers: {
                            'Content-Type': file.type
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200){
                        authFetch("/api/upload-osf-liquidation?username=" + String(props.username)+
                            "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                            {
                                method: 'post',
                                body: JSON.stringify({codes: consumptionUsers, filename: file.name}),
                            }).then(response => {
                        if (response.status === 422){
                            logout()
                            return null
                        }
                        else if (response.status !== 200){
                            handleFeedbackDialogOpen()
                            setFeedbackMsg("Error en el proceso.") 
                            setUploadingInProgress(false)
                            return null
                        }
                            return response.json()
                        }).then(response => {
                            if (response && response.Upload === 'OK'){                                                      
                                handleFeedbackDialogOpen()
                                setFeedbackMsg("Liquidación cargada exitosamente.") 
                                setUploadingInProgress(false)                                                
                            }
                            else{
                                handleFeedbackDialogOpen()
                                setFeedbackMsg("Error en el proceso.") 
                                setUploadingInProgress(false)
                            }
                        }) 
                    }
                    else{
                        setFeedbackMsg("Error subiendo archivo.")
                        handleFeedbackDialogOpen()                        
                        setUploadingInProgress(false)
                    }
                })             
            }  
            else {                
                setFeedbackMsg("Error subiendo archivo.")
                handleFeedbackDialogOpen()
                setUploadingInProgress(false)
            }              
        })
    }

    const uploadOSFXml = (files) => {
        setUploadingInProgress(true)
        const formData1 = new FormData()        
        let file = {} 
        setLiquidationInProgress(true)            
        for (var i = 0; i < 10; i++){
            formData1.append('file' + i, files[i])              
        }
        let opts = {
            'filename': files[0].name,   
            'codes': consumptionUsers,               
        }
        authFetch(
            "/api/upload-files?username="+props.username+
                            "&period="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear() +                                
                            "&part="+"1",
            {
            method: 'post',
            body: formData1,
            }
        ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response.Upload === 'OK'){
                
                authFetch("/api/upload-osf-xml-liquidation?username=" + String(props.username)+
                    "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
                    {
                        method: 'post',
                        body: JSON.stringify(opts),
                    }).then(response => {
                if (response.status === 422){
                    logout()
                    setLiquidationInProgress(false)
                    return null
                }
                else if (response.status !== 200){
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.") 
                    setUploadingInProgress(false)
                    setLiquidationInProgress(false)
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.Upload === 'OK'){                                                      
                        handleFeedbackDialogOpen()
                        setFeedbackMsg("Liquidación cargada exitosamente.") 
                        setUploadingInProgress(false)
                        setLiquidationInProgress(false)                                                
                    }
                    else{
                        handleFeedbackDialogOpen()
                        setFeedbackMsg("Error en el proceso.") 
                        setUploadingInProgress(false)
                        setLiquidationInProgress(false)
                    }
                }) 
                    
            }             
              
            else {                
                setFeedbackMsg("Error subiendo archivo.")
                handleFeedbackDialogOpen()
                setUploadingInProgress(false)
                setLiquidationInProgress(false)
            }              
        })
    }

    useEffect (() => {
        document.title ="Liquidación de consumos | Autogeneradores Cedenar";      
        
        if (selectedAgType !== ''){

            authFetch(
                "/api/check-ag-liquidation-status?username="+props.username+
                                "&month="+String( billingDate.getMonth() + 1 )+                              
                                "&year="+ billingDate.getFullYear()
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.liquidation_approved){                                     
                    setLiquidationApprovedFlag(response.liquidation_approved[0])
                    setOsfLiquidationFlag(response.liquidation_approved[1])
                }            
            })

            authFetch(
                "/api/check-users-consumption-status?username="+props.username+
                                "&month="+String( billingDate.getMonth() + 1 )+                              
                                "&year="+ billingDate.getFullYear()+
                                "&method=only_clients_number"
            )
            .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
                if (response && response.client_codes){                                     
                    setTotalUsers(response.client_codes.length)
                }            
            }) 

            // Tarifas de CU con m-1 al mes de consumos
            let billingPeriod = billingDate.getMonth() + 1
            let billingYear = billingDate.getFullYear()
            let prevPeriod = billingPeriod - 1 >= 1? billingPeriod - 1 : 12
            let prevYear = billingPeriod - 1 >= 1? billingYear : billingYear - 1
            authFetch("/api/check-tariffs-by-cycle?electric_tariff_id=" + String(props.username).slice(0,3) + prevYear + 
                                                                    String(prevPeriod) + 'def' +  "&origin=ag").then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.tariffs_flags){                                                                                              
                        setCycleTariffs(response.tariffs_flags)
                        if (selectedAgType != ""){                                                           
                            setCycleTariffsFlag(response.tariffs_flags['REGULADO'])                          
                        }                                                           
                    }
                }) 

            authFetch(
                    "/api/check-pb-status?username="+props.username+
                                    "&month="+String( billingDate.getMonth() + 1 )+                              
                                    "&year="+ billingDate.getFullYear()
                )
                .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                .then(response => {
                    if (response && 'pb_flag' in response){                                     
                        setPbFlag(response.pb_flag)
                    }            
                }
                )
            
            authFetch("/api/check-consumption-in-period?username=" + String(props.username)+
                "&month="+String( billingDate.getMonth() + 1 )+                              
                "&year="+ billingDate.getFullYear()+
                "&method=only_codes").then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.clients){ 
                        let clientCodes = response.clients.map((client) => client.client_code)                   
                        setConsumptionUsers(clientCodes)                                          
                    }
                }) 
                
            if (selectedAgType !== ""){
                ////console.log("consultando clientes")
                setLoadingData(true)            
                authFetch("/api/check-ag-consumption-liquidation?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )+
                "&ag_type="+selectedAgType[0]).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        setLoadingData(false)
                        if (response && response.clients_liquidation){    
                            ////console.log(response.clients_liquidation) 
                            let liquidation = response.clients_liquidation
                            let openLiqTotal = 0
                            liquidation.forEach((obj, idx) => {
                                liquidation[idx] = {...obj, 
                                active_tariff: 'active_tariff' in obj ? obj['active_tariff'].toFixed(2) : '',
                                d_val: 'd_val' in obj ? obj['d_val'].toFixed(2) : '',
                                total_active_energy: '100' in obj ? obj['100']['energy'] + ' kWh' : '', 
                                reactive_ind_energy: '11' in obj ? obj['11']['energy']+ ' kVArh' : '',
                                reactive_cap_energy: '12' in obj ? obj['12']['energy']+ ' kVArh' : '',
                                generated_energy: '85' in obj ? obj['85']['energy']+ ' kWh' : '',
                                excedentary_energy: '482' in obj ? obj['482']['energy']+ ' kWh' : '',
                                val_active_energy: '100' in obj ? parseFloat(obj['100']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $' : '',
                                val_reactive_ind_energy: '11' in obj ? parseFloat(obj['11']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $': '',
                                val_reactive_cap_energy: '12' in obj ? parseFloat(obj['12']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $': '',
                                val_exchange_energy: '132' in obj ? parseFloat(obj['132']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $': '',                                                   
                                val_generated_energy: '85' in obj ? parseFloat(obj['85']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $': '',
                                val_excedentary_energy: '482' in obj ? parseFloat(obj['482']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $': '',
                                val_subsidy: '730' in obj ? parseFloat(obj['730']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $' : '',
                                val_contribution: '800' in obj ? parseFloat(obj['800']['value']).toLocaleString('en-US',{minimumFractionDigits: 2}) + ' $' : '',                            
                                val_total:  obj.origin
                                };

                                if( obj.origin === 'Open'){                                    
                                    openLiqTotal = openLiqTotal + 1
                                }

                                                })            
                            setOpenLiqUsers(openLiqTotal)                                    
                            setRows(liquidation)                                                                                           
                        }
                    })
            }
        }
    }, [props.billingDate, selectedAgType, liquidationInProgress])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            <Zoom in timeout={800}>
            <Grid container justify="space-evenly">                                
                <Grid item xs={12} style={{marginTop: 10}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Liquidación de consumos</Typography>
                    <Grid container justify="space-evenly" spacing={1} alignItems="stretch">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Tipo de usuario:</Typography>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                <TextField 
                                    select
                                    variant="outlined" 
                                    size="small"
                                    InputProps={{
                                        classes: {
                                            input: classes.formText
                                        }
                                    }}
                                    className={classes.formRow} 
                                    value={selectedAgType}
                                    onChange={(e) => {setSelectedAgType(e.target.value); updateFlags(e.target.value);}}
                                    //onChange={(e) => {setSelectedCycle(e.target.value); }}
                                >
                                    {
                                        [{code: '1: AGPE [0 a 0.1] MW'},{code: '2: AGPE [0.1 a 1] MW'},{code: '3: AGPE PP'},{code: '4: AGPE NO FNCER'}].map((cycle,idx) => (                                                                                
                                            <MenuItem key={idx} value={cycle.code}>{cycle.code}</MenuItem>                                        
                                        ))
                                    }
                                </TextField>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Insumos necesarios:</Typography>
                            <Paper className={classes.contentPaperState} elevation={0} variant="outlined">
                                {
                                    inputsStateList(inputData,[cycleTariffsFlag, selectedAgType[0] === '1' && consumptionUsers.length > 0 ? true : false, pbFlag])
                                }
                            </Paper>                            
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Controles:</Typography>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                            <Grid container justify="space-evenly" spacing={1}>
                                <Grid item>
                                    {
                                        liquidationInProgress ?
                                        <CircularProgress></CircularProgress>
                                        :
                                        <Button 
                                            onClick={(e) => {callLiquidation();}} 
                                            color='primary' 
                                            disabled={!(selectedAgType[0] === '1' && consumptionUsers.length > 0) || liquidationApprovedFlag || !([cycleTariffsFlag, selectedAgType[0] === '1' && consumptionUsers.length > 0 ? true : false, pbFlag].every(Boolean))}  
                                            variant='contained'
                                        >
                                            Liquidar
                                        </Button>
                                    }                                    
                                </Grid>
                                <Grid item>
                                    {
                                        approvationInProgress ? 
                                        <CircularProgress></CircularProgress>
                                        :
                                        <Button 
                                            onClick={(e) => {approveLiquidation();}} 
                                            color='primary' 
                                            disabled={rows.length === 0 || liquidationApprovedFlag || loadingData} 
                                            variant='contained'
                                        >
                                            Aprobar
                                        </Button>
                                    }
                                    
                                </Grid>                                
                            </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.sectionTitle}>Avance Liquidación:</Typography>
                            <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                            <Grid container alignItems="center" justify="center" spacing={1}>                                                                
                                <Grid item xs={5}>
                                <Typography variant="h5" align="center" className={classes.infoNumber}>{totalUsers > 0 ? (100*(osfLiquidationFlag ?  openLiqUsers : rows.length)/totalUsers).toFixed(0) + '%' : '0 %'}</Typography>                          
                                </Grid>
                                <Grid item xs={5}>
                                <Typography variant="subtitle1" align="center" className={classes.infoText}>{(osfLiquidationFlag ?  openLiqUsers : rows.length) + ' de ' + totalUsers + ' Usuarios'}</Typography>                          
                                </Grid>                        
                            </Grid>
                            </Paper>                            
                        </Grid>                                                                                                                      
                    </Grid>                                                           
                </Grid> 
                <Grid item xs={9} style={{marginTop: 10}}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Cargar liquidación Open</Typography>                    
                    {   
                        <form action={"#"}>
                            <label htmlFor={'osf_file'}>
                            <input
                                hidden
                                id={'osf_file'}
                                name={'osf_file'}
                                type="file"                                   
                                onClick={(e) => {e.target.value = null;}}   
                                //onChange={(e) => {uploadOSFFile(e.target.files[0]); }}            
                                onChange={(e) => {uploadOSFXml(e.target.files); }}
                                multiple={true}  
                            />
                            {uploadingInProgress ?
                                <CircularProgress></CircularProgress>
                                :
                                <Button 
                                    startIcon={<AddIcon/>}
                                    //onClick={(e) => {callLiquidation();}} 
                                    component="span"
                                    color='primary' 
                                    disabled={!(selectedAgType[0] === '1' && rows.length > 0) || liquidationApprovedFlag}  
                                    variant='contained'
                                >
                                    Subir
                                </Button>
                            }
                        </label>
                        </form> 
                    }
                </Grid>
                <Grid item xs={12} style={{marginTop: 10}}>                                    
                    <Typography variant="subtitle1" className={classes.sectionTitle}>Información procesada</Typography>   
                    <FilterContext.Provider value={filters}>                        
                        {
                            loadingData ? 
                            <CircularProgress></CircularProgress>
                            :
                            <Fragment>
                                <Paper className={classes.contentTable} elevation={0} variant="outlined">
                                <Typography variant="subtitle2" align="center" color="primary">Total clientes liquidados: { osfLiquidationFlag ?  openLiqUsers : rows.length}</Typography>
                                <DataGrid columns={columns} 
                                    rows={rows}
                                    headerRowHeight={35}                                        
                                    className={'rdg-light'}                            
                                    style={{color: '#606B6A', height: 400}}                                                                                                    
                                />
                                </Paper>
                            </Fragment>
                            
                        }
                        
                    </FilterContext.Provider>                                      
                </Grid>                                                                                       
            </Grid>   
            </Zoom>    
        </div>
    );
}