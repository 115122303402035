import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, InputAdornment,
        Button,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tab, AppBar, Accordion, AccordionSummary,
        AccordionDetails, FormControlLabel, Checkbox, ListItem, ListItemSecondaryAction, ListItemText, List, Box, Fab} from '@material-ui/core/'
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AddIcon from '@material-ui/icons/Add';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SubjectIcon from '@material-ui/icons/Subject';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TodayIcon from '@material-ui/icons/Today';
import {DateTimePicker} from '@material-ui/pickers';
import { TrainRounded } from '@material-ui/icons';

// CONSTANTES //
// Formulario de generar orden //
const ORDER_GEN_INPUTS = [
    {Name: 'No. identificación cliente', Type: 'number', Icon: <PersonPinIcon color="primary"/>},
    {Name: 'Tipo de actividad', Type: 'text', Select: true, 
        Values:['Visita previa-indirecta','Visita previa-semidirecta','Visita previa-directa',
                'Cambio de comercializador-indirecta','Cambio de comercializador-semidirecta','Cambio de comercializador-directa',
                'Telemedida', 'Cambio de medidor', 'Cambio de CTS/PTS'],  Icon: <AssignmentIcon color="primary"/>},
    {Name: 'Fecha/Hora estimada visita', Type: 'date', Icon: <TodayIcon color="primary"/>},
    {Name: 'Operador asignado', Type: 'text', Select: true, Values: ['Nombre op. 1','Nombre op. 2','Nombre op. 3'], Icon: <GroupWorkIcon color="primary"/>},
    {Name: 'Prioridad', Type: 'text', Select: true, Values: ['1-Inmediata','2-Normal','3-Baja'], Icon: <PriorityHighIcon color="primary"/>},
    {Name: 'Descripción', Type: 'text', Multi: true, Icon: <SubjectIcon color="primary"/>},
    {Name: 'Costo previsto', Type: 'number', Icon: <MonetizationOnIcon color="primary"/>},   
    {Name: 'Encargado', Type: 'text', Icon: <SupervisorAccountIcon color="primary"/>},  
]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    button : {
        fontSize: theme.typography.subtitle2.fontSize,      
        borderRadius: 8,
        fontWeight: 'normal',
        textTransform: 'none'
        //backgroundColor: theme.palette.primary.dark  
    },
    formRow: {
        width: "90%",       
        maxHeight: "75%",        
    },
    formText: {
        fontSize: 15,        
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1),
        marginTop: 5
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formLabel: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },    
    formPanel: {
        //width: '100%'
        padding: 10,
        background: 'white',
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        borderRadius: 3,
    },
    title: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.dark,
        padding: 0,
        borderRadius: 3,
        margin: theme.spacing(1),
        fontWeight: 'normal'
    },
}))

export default function OrderAssignation (props) {
    const classes = useStyles();
    const [orderDate, setOrderDate] = useState(new Date())

    const onBlur = () => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const orderAssignForm = () => (
        <Grid container spacing={2} justify="center">             
            {
                ORDER_GEN_INPUTS.map((obj, idx) => (                    
                    obj.Type !== 'date' ? 
                    <Grid item xs={12} md={6}>                                
                        <TextField                                                       
                            label={obj.Name}                                            
                            select={obj.Select}
                            type={obj.Type}
                            multiline={obj.Multi}     
                            margin="none"                       
                            //value={dataControl[meterType][idx >= 7 ? idx + 3 : idx] ? dataControl[meterType][idx >= 7 ? idx + 3 : idx] : ''}  
                            //defaultValue={''}                                                  
                            //onChange={changeControl(idx >= 7 ? idx + 3 : idx)}
                          
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{
                                classes: {
                                    input: classes.formText
                                },
                                startAdornment:(
                                    <InputAdornment position="start" color="primary">
                                    {obj.Icon}
                                    </InputAdornment>
                                )
                            }}
                            variant="filled"
                            onClose={()=>{
                                setTimeout(() => {
                                    document.activeElement.blur();
                                }, 0);
                            }}
                            className={classes.formRow}                                               
                        >
                            {
                                obj.Select ?                            
                                obj.Values.map((value) => (
                                    <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>
                                ))                                
                                :
                                null
                            }
                        </TextField>   
                    </Grid>                              
                    :
                    <Grid item xs={12} md={6}>
                        <DateTimePicker  
                            clearable  
                            variant="dialog"    
                            inputVariant="filled"   
                            label={obj.Name}                                                                              
                            okLabel={"asignar"}
                            cancelLabel={""}
                            clearLabel={"limpiar"}
                            className={classes.formRow}   
                            // openTo="month"
                            // format="MMMM-yyyy"                                            
                            // views={["year","month"]}                                           
                            value={orderDate}                                           
                            onChange={setOrderDate}
                            error={false}
                            onClose={onBlur}
                            InputLabelProps={{ shrink: true, classes: {root:classes.formLabel} }}
                            InputProps={{                                            
                                        classes: {
                                            input: classes.formText,                                    
                                        },
                                        startAdornment:(
                                            <InputAdornment position="start" color="primary">
                                            {obj.Icon}
                                            </InputAdornment>
                                        )
                                    }}                                                                   
                            fullWidth                                                                                                               
                        />  
                    </Grid>                   
                ))
            }                     
        </Grid>        
    )

    return (
        <Zoom in timeout={600}>
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Typography variant="subtitle1" className={classes.title}>Asignación de una orden de trabajo/revisión</Typography>
                    </Grid>                         
                    <Grid item xs={12} md={10}>
                        <div className={classes.formPanel}>
                            {orderAssignForm()}
                            <Button className={classes.button} variant="contained" color="primary" style={{marginTop: 30}}>
                                Confirmar
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Zoom>
    )
}