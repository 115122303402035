import * as React from "react"

const IndMeasure = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={752}
    height={699}
    viewBox="-0.5 -0.5 752 699"
    style={{
      backgroundColor: "#fff",
    }}
    {...props}
  >
    <text
        x={355}
        y={60}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rpts}
    </text>
    <text
        x={150}
        y={165}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rcts}
    </text>
    <text
        x={528}
        y={50}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.fx}
    </text>
    <text
        x={370}
        y={520}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.cap}
    </text>
    <text
        x={400}
        y={655}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.client}
    </text>
    <defs>
      <style>
        {"@import url(https://fonts.googleapis.com/css?family=Noto+Sans);"}
      </style>
    </defs>
    <circle
      cx={276}
      cy={156}
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="all"
      r={24}
    />
    <path fill="none" pointerEvents="all" d="M410.4 132H300V84h110.4z" />
    <circle
      cx={338.4}
      cy={108}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(180 355.2 108)"
      pointerEvents="all"
      r={24}
    />
    <circle
      cx={372}
      cy={108}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(180 355.2 108)"
      pointerEvents="all"
      r={24}
    />
    <path
      d="M410.4 108H396m-81.6 0H300"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="all"
    />
    <path
      d="M108 36h168"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path fill="none" pointerEvents="all" d="M0 0h204v36H0z" />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 15,
            marginLeft: 1,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"RED EN MEDIA TENSI\xD3N"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={85}
        y={19}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"RED EN MEDIA TENSI\xD3N"}
      </text>
    </switch>
    <path
      d="M276 108V36M300 108h-24M410.4 108H480"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M480 84h96v96h-96z"
    />
    <path
      d="m480 180 96-96"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M480 60h96v24h-96z"
    />
    <path
      d="M300 156h180M276 132v-24M276 228v-48"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 175,
            marginLeft: 356,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR ELECTR\xD3NICO"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={440}
        y={179}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR ELECTR\xD3NICO"}
      </text>
    </switch>
    <path
      d="m314.4 242.4 6-18 6 18Z"
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      d="M277.2 324v-48"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M283.2 324v60h-12v-60z"
    />
    <path
      d="m277.2 276 48-36M283.2 339l42 .6M325.2 240v99.6M277.2 384v72"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <circle
      cx={260.4}
      cy={511.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 511.2)"
      pointerEvents="none"
      r={24}
    />
    <circle
      cx={294}
      cy={511.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 511.2)"
      pointerEvents="none"
      r={24}
    />
    <path
      d="M277.2 566.4V552m0-81.6V456M277.2 566.4v72M270.7 639h12v36h12.6l-18.6 22.8-18.6-22.8h12.6Z"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />    
  </svg>
)

const IndBackMeasure = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={752}
    height={699}
    viewBox="-0.5 -0.5 752 699"
    style={{
      backgroundColor: "#fff",
    }}
    {...props}
  >
    <text
        x={380}
        y={70}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rpts}
    </text>
    <text
        x={150}
        y={165}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rcts}
    </text>
    <text
        x={585}
        y={50}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.fx}
    </text>
    <text
        x={370}
        y={520}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.cap}
    </text>
    <text
        x={400}
        y={655}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.client}
    </text>
    <defs>
      <style>
        {"@import url(https://fonts.googleapis.com/css?family=Noto+Sans);"}
      </style>
    </defs>
    <circle
      cx={276}
      cy={156}
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="all"
      r={24}
    />
    <path fill="none" pointerEvents="all" d="M434.4 132H324V84h110.4z" />
    <circle
      cx={362.4}
      cy={108}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(180 379.2 108)"
      pointerEvents="all"
      r={24}
    />
    <circle
      cx={396}
      cy={108}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(180 379.2 108)"
      pointerEvents="all"
      r={24}
    />
    <path
      d="M434.4 108H420m-81.6 0H324"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="all"
    />
    <path
      d="M108 36h168"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path fill="none" pointerEvents="all" d="M0 0h204v36H0z" />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 15,
            marginLeft: 1,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"RED EN MEDIA TENSI\xD3N"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={85}
        y={19}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"RED EN MEDIA TENSI\xD3N"}
      </text>
    </switch>
    <path
      d="M276 108V36M324 108h-48M434.4 108H480"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M480 84h96v96h-96z"
    />
    <path
      d="m480 180 96-96"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M480 60h96v24h-96z"
    />
    <path
      d="M300 156h180M276 132v-24M276 228v-48"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 172,
            marginLeft: 356,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR"}
              <br />
              {"PRINCIPAL"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={440}
        y={176}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR..."}
      </text>
    </switch>
    <path
      d="m314.4 242.4 6-18 6 18Z"
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      d="M277.2 324v-48"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M283.2 324v60h-12v-60z"
    />
    <path
      d="m277.2 276 48-36M283.2 339l42 .6M325.2 240v99.6M277.2 384v72"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <circle
      cx={260.4}
      cy={511.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 511.2)"
      pointerEvents="none"
      r={24}
    />
    <circle
      cx={294}
      cy={511.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 511.2)"
      pointerEvents="none"
      r={24}
    />
    <path
      d="M277.2 566.4V552m0-81.6V456M277.2 566.4v72M270.7 639h12v36h12.6l-18.6 22.8-18.6-22.8h12.6Z"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M600 84h96v96h-96z"
    />
    <path
      d="m600 180 96-96"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M600 60h96v24h-96z"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 172,
            marginLeft: 456,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR DE"}
              <br />
              {"RESPALDO"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={540}
        y={176}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR DE..."}
      </text>
    </switch>
    <path
      d="M576 156h24M576 108l24 .2"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />    
  </svg>
)

const SemiCompMeasure = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={764}
    height={805}
    viewBox="-0.5 -0.5 764 805"
    style={{
      backgroundColor: "#fff",
    }}
    {...props}
  >
    <text
        x={460}
        y={730}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.client}
    </text>
    <text
        x={330}
        y={505}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rcts}
    </text>
    <text
        x={655}
        y={400}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.fx}
    </text>
    <defs>
      <style>
        {"@import url(https://fonts.googleapis.com/css?family=Noto+Sans);"}
      </style>
    </defs>
    <path
      d="M108 36h168"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path fill="none" pointerEvents="all" d="M0 0h204v36H0z" />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 15,
            marginLeft: 1,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"RED EN MEDIA TENSI\xD3N"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={85}
        y={19}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"RED EN MEDIA TENSI\xD3N"}
      </text>
    </switch>
    <path
      d="M276 108V36"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path
      d="M277.2 168v72M277.2 350.4V372"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <circle
      cx={260.4}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <circle
      cx={294}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <path
      d="M277.2 350.4V336m0-81.6V240M456 672v72M449.5 744.6h12v36h12.6l-18.6 22.8-18.6-22.8h12.6ZM278.282 172.406c-8.69-25.511-4.487-31.94 22.39-33.195 26.885-1.25 31.204-7.42 22.39-33.195"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      d="m276.29 165.6 22.8-25.2 25.2-26.4M472.97 487.03l89.83-36.43M562.8 450.6l43.1-.74"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M606 432h96v96h-96z"
    />
    <path
      d="m606 528 96-96M480 504h126M454.8 498.6 456 528"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 467,
            marginLeft: 466,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR"}
              <br />
              {"ELECTR\xD3NICO"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={550}
        y={470}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR..."}
      </text>
    </switch>
    <circle
      cx={456}
      cy={504}
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      r={24}
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M606 408h96v24h-96z"
    />
    <path
      d="M132 444v-72M132 372h288M456 480V372M456 372h-36M456 624v-96M456 672q36-24 0-36"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 38,
            height: 1,
            paddingTop: 385,
            marginLeft: 91,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"O. C."}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={110}
        y={389}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"O. C."}
      </text>
    </switch>    
  </svg>
)

const SemiDedMeasure = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={752}
    height={805}
    viewBox="-0.5 -0.5 752 805"
    style={{
      backgroundColor: "#fff",
    }}
    {...props}
  >
    <text
        x={380}
        y={730}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.client}
    </text>
    <text
        x={150}
        y={505}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.rcts}
    </text>
    <text
        x={475}
        y={400}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.fx}
    </text>
    <defs>
      <style>
        {"@import url(https://fonts.googleapis.com/css?family=Noto+Sans);"}
      </style>
    </defs>
    <path
      d="M108 36h168"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path fill="none" pointerEvents="all" d="M0 0h204v36H0z" />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 15,
            marginLeft: 1,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"RED EN MEDIA TENSI\xD3N"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={85}
        y={19}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"RED EN MEDIA TENSI\xD3N"}
      </text>
    </switch>
    <path
      d="M276 108V36"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path
      d="M277.2 168v72M277.2 350.4V372"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <circle
      cx={260.4}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <circle
      cx={294}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <path
      d="M277.2 350.4V336m0-81.6V240M277.2 672v72M270.7 744.6h12v36h12.6l-18.6 22.8-18.6-22.8h12.6ZM278.282 172.406c-8.69-25.511-4.487-31.94 22.39-33.195 26.885-1.25 31.204-7.42 22.39-33.195"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      d="m276.29 165.6 22.8-25.2 25.2-26.4M294.17 487.03 384 450.6M384 450.6l43.1-.74"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M427.2 432h96v96h-96z"
    />
    <path
      d="m427.2 528 96-96M301.2 504h126M276 498.6l1.2 29.4"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 467,
            marginLeft: 317,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR"}
              <br />
              {"ELECTR\xD3NICO"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={401}
        y={470}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR..."}
      </text>
    </switch>
    <circle
      cx={277.2}
      cy={504}
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      r={24}
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M427.2 408h96v24h-96z"
    />
    <path
      d="M277.2 480V372M277.2 624v-96M277.2 672q36-24 0-36"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />    
  </svg>
)

const DirectMeasure = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={752}
    height={805}
    viewBox="-0.5 -0.5 752 805"
    style={{
      backgroundColor: "#fff",
    }}
    {...props}
  >
    <text
        x={450}
        y={760}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.client}
    </text>
    <text
        x={370}
        y={425}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={14}
        textAnchor="middle"
      >
        {props.fx}
    </text>
    <defs>
      <style>
        {"@import url(https://fonts.googleapis.com/css?family=Noto+Sans);"}
      </style>
    </defs>
    <path
      d="M108 36h168"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path fill="none" pointerEvents="all" d="M0 0h204v36H0z" />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 15,
            marginLeft: 1,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"RED EN MEDIA TENSI\xD3N"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={85}
        y={19}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"RED EN MEDIA TENSI\xD3N"}
      </text>
    </switch>
    <path
      d="M276 108V36"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="stroke"
    />
    <path
      d="M277.2 168v72M277.2 350.4V372"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <circle
      cx={260.4}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <circle
      cx={294}
      cy={295.2}
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      transform="rotate(270 277.2 295.2)"
      pointerEvents="none"
      r={24}
    />
    <path
      d="M277.2 350.4V336m0-81.6V240M456 672v72M449.5 744.6h12v36h12.6l-18.6 22.8-18.6-22.8h12.6ZM278.282 172.406c-8.69-25.511-4.487-31.94 22.39-33.195 26.885-1.25 31.204-7.42 22.39-33.195"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      d="m276.29 165.6 22.8-25.2 25.2-26.4"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M408 456h96v96h-96z"
    />
    <path
      d="m408 552 96-96"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 420,
            marginLeft: 411,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"MEDIDOR"}
              <br />
              {"ELECTR\xD3NICO"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={495}
        y={424}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"MEDIDOR..."}
      </text>
    </switch>
    <path
      fill="rgba(255, 255, 255, 1)"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      pointerEvents="none"
      d="M408 432h96v24h-96z"
    />
    <path
      d="M132 444v-72M132 372h288M456 432v-60M456 372h-36M456 672V552"
      fill="none"
      stroke="rgba(0, 0, 0, 1)"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      pointerEvents="none"
    />
    <switch transform="matrix(1.2 0 0 1.2 -.5 -.5)">
      <foreignObject
        pointerEvents="none"
        width="100%"
        height="100%"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 38,
            height: 1,
            paddingTop: 385,
            marginLeft: 91,
          }}
        >
          <div
            data-drawio-colors="color: rgba(0, 0, 0, 1);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "none",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"O. C."}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={110}
        y={389}
        fill="rgba(0, 0, 0, 1)"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"O. C."}
      </text>
    </switch>    
  </svg>
)

export default [IndMeasure, IndBackMeasure, SemiCompMeasure, SemiDedMeasure, DirectMeasure]