import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
    Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Menu,
    Accordion, AccordionSummary, AccordionDetails, CircularProgress, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Checkbox} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';
import {authFetch, logout} from '../../auth';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DataGrid from 'react-data-grid';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3,
      fontSize: 11,
      minWidth: 60,
    },
    body: {
      fontSize: 10,
      color: 'black',
      height: 30,
      backgroundColor: 'white',
      padding: 2
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    tableHeader:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 1,
        fontSize: 12,
    },
    container: {
        maxHeight: 440,
        overflowX: "auto"
    },
    cell:{
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.primary.dark,
        padding: 1,
        fontSize: 11,
        textAlign: 'center'
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formRow: {
        width: "80%",       
        minHeight: 19,          
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    tableTitle: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 13 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 13,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1),
        minHeight: 19
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
        padding: theme.spacing(1),
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    componentPrincipalTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        padding: 2,
        border: `solid 1px ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
    },
    avatarStyle: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: theme.palette.primary.dark,
    },
    accordionList: {
        width: '100%',                
    },
    dividerStyle: {
        backgroundColor: theme.palette.client.contrastText
    },    
    listInputsContentStyle: {
        color:  theme.palette.primary.dark,  
        marginLeft: theme.spacing(2)      
    }
}));

export default function SUIReport (props){
    const classes = useStyles();  
    let billingDate = props.billingDate
    const [feedbackDialog, setFeedbackDialog] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [expanded, setExpanded] = useState(false);
    const [suiReportState, setSuiReportState] = useState([])
    const [reportInputs, setReportInputs] = useState([])  
    const [reportColumns, setReportColumns] = useState([])  
    const [processing, setProcessing] = useState(false)
    const [downloading, setDownloading] = useState(false)    
    const [marketsSubsidy, setMarketsSubsidy] = useState({})
    const [internalReportStatus, setInternalReportStatus] = useState({})
   
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
  
    const DataTable = (props) => (
      <DataGrid columns={props.columns} rows={props.rows} className={'rdg-light'} 
        style={{color: '#606B6A', height: 400, fontSize: 12}}/> 
    )

    const processReportInformation = (code, reportIdx) => (e) => {
        let uploadInfo = true
        // if ( suiReportState[reportIdx]['Code'] !== 'T9' && reportInputs[reportIdx].some(input => input === null)){
        //     handleFeedbackDialogOpen()
        //     setFeedbackMsg('No se ha ingresado la información necesaria.')
        //     uploadInfo = false
        // }
        // else if ( suiReportState[reportIdx]['Code'] === 'T9' ){
        //     let cmcFlag = reportInputs[reportIdx].slice(5,8).some(input => input === null)
        //     let marketsSubValues = []
        //     Object.keys(marketsSubsidy).length > 0 ? Object.values(marketsSubsidy).forEach( array => array.forEach( val => marketsSubValues.push(val))) : marketsSubValues = []
        //     let marketsFlag = marketsSubValues.some(val => val === null)
        //     uploadInfo = !(cmcFlag || marketsFlag)
        //     if (!uploadInfo){
        //         handleFeedbackDialogOpen()
        //         setFeedbackMsg('No se ha ingresado la información necesaria.')
        //     }
        // }
        
        if (uploadInfo) {            
            setProcessing(true)                        
            let reportState = {...suiReportState[reportIdx]}
            reportState.Processed = true            
            reportInputs[reportIdx].forEach( (value,vidx) => reportState.Inputs[vidx].Value = value)
            delete reportState.Data
            let opts = {                                         
                'username': props.username,   
                'period': String( billingDate.getMonth() + 1 ),
                'year': billingDate.getFullYear(),                
                'report_status': reportState      
            }
            authFetch(
                "/api/process-sui-report-"+code,
                    {
                    method: 'post',
                    body: JSON.stringify(opts),
                    }
                ).then(r => {
                    if (r.status === 422){
                        logout()
                        return null
                    }
                    else if (r.status === 200){
                        return r.json()
                    }
                    else {
                        setFeedbackMsg('Error en el procesamiento.')
                        handleFeedbackDialogOpen()
                        setProcessing(false) 
                        return null
                    }
                })
                .then(response => {
                  if ( response && response.Upload === 'OK'){
                    setFeedbackMsg('Reporte Procesado: '+code)
                    handleFeedbackDialogOpen()
                    setProcessing(false)       
                    authFetch("/api/check-billing-sui-reports?username=" + props.username +
                        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )
                        ).then(response => {
                            if (response.status === 422){
                                logout()
                                return null
                            }
                                return response.json()
                            }).then(response => {
                                if (response && response.sui_reports){                        
                                    let suiReportsInputs = new Array()                                                                 
                                    response.sui_reports.forEach(report => { let auxArray = new Array(); report.Inputs.forEach(input => auxArray.push(input.Value ? input.Value : null)); suiReportsInputs.push( auxArray );} );   
                                    let suiReportColumns =  new Array()
                                    response.sui_reports.forEach(report => {let reportColNames = new Array(); report.DataLabels.forEach(
                                    (label,lidx) => {reportColNames.push({name: label, key: report.DataDbLabels[lidx], resizable: true})}
                                    ); suiReportColumns.push(reportColNames);} );        
                                    setReportColumns([...suiReportColumns])                                                     
                                    setReportInputs([...suiReportsInputs])
                                    setSuiReportState([...response.sui_reports])                                               
                                }
                            })
                  }
                  else {
                    setFeedbackMsg('Error al procesar los datos.')
                    handleFeedbackDialogOpen()
                  }
                })
        }
    }

    const downloadReport = (code) => (e) => {
        let year = billingDate.getFullYear()
        let period = String( billingDate.getMonth() + 1 )            
        setDownloading(true)   
        authFetch("/api/sui-report-download?report_type=" + "billing" 
            + "&username=" + props.username + "&code=" + code + "&timestamp=" + new Date().toISOString().slice(0,19) +
            "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 ),
            { responseType: 'blob' }
            ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                else if (response.status === 200){
                    return response.blob()
                }                    
                }).then(blob => {     
                    let filePeriod = String(period).length <= 1 ? '0'+period:period 
                    setDownloading(false)                   
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'FSUI'+code+'_'+props.username+'_'+filePeriod+'_'+year+'.csv';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);                                    
                })
        
    }

    const addT2Garanty = () => {                
        let newGarantyInputVal = reportInputs[0].slice(0,10).map(val => (null))
        let newGarantyInputObj = suiReportState[0].Inputs.slice(0,10).map(obj => ({...obj, Value: null}))
        let newT2InputVal = [...reportInputs[0], ...newGarantyInputVal]        
        let newT2InputsObj = [...suiReportState[0].Inputs, ...newGarantyInputObj]        
        let reportInputAux = reportInputs
        reportInputAux[0] = newT2InputVal
        setReportInputs([...reportInputAux]);
        setSuiReportState([...suiReportState], suiReportState[0].Inputs = newT2InputsObj);
    }

    const deleteT2Garanty = () => {
        let actualLength = reportInputs[0].length                
        let reportInputsAux = reportInputs
        reportInputsAux[0].splice(actualLength-10,10)
        setReportInputs([...reportInputsAux]);
        let suiReportInputAux = suiReportState[0].Inputs
        suiReportInputAux.splice(actualLength-10,10)
        setSuiReportState([...suiReportState], suiReportState[0].Inputs = suiReportInputAux)              
    }

    const addMarketSubsidy = (market) => {        
        if (!market){
            setFeedbackMsg('Seleccione un mercado.')
            handleFeedbackDialogOpen()
        }
        else {
            market = market.split("-")[1]
            let auxMarketsSubsidy = marketsSubsidy
            if (!(market in auxMarketsSubsidy)){
                auxMarketsSubsidy[market] = [null,null,null,null]
                ////console.log(auxMarketsSubsidy)
                setMarketsSubsidy(auxMarketsSubsidy)
            }
            else{
                setFeedbackMsg('El mercado ya fue agregado.')
                handleFeedbackDialogOpen()
            }
        }                
        ////console.log(marketsSubsidy)
    }

    let principalComponent = () => (
        <Grid container spacing={1} justify="space-evenly">                            
            {
                suiReportState.map((suiState, pidx) =>(
                    <Grid key={pidx} item xs={12}>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === pidx} onChange={handlePanelChange(pidx)} >
                            <AccordionSummary expandIcon={<ArrowDropDownIcon color="primary"/>}>
                                <Typography>{suiState.Name}</Typography>
                                {
                                    suiState.Processed  ? 
                                    <DoneIcon fontSize="small" color="success" style={{marginLeft:10}}/>
                                    :
                                    <HourglassFullIcon fontSize="small" color="disabled" style={{marginLeft:10}}/>                                    
                                }
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid container spacing={2} justify="space-evenly">
                                <Grid item xs={6}>                                    
                                    <Typography variant="subtitle1" color="primary">{suiState.Processed ? 'Estado: procesado': 'Estado: sin procesar'}</Typography>
                                    {
                                        suiState.Processed ?
                                        <Typography variant="subtitle1" color="primary">{'Fecha de procesamiento: ' + suiState.ProcessingDate}</Typography>
                                        : 
                                        null
                                    }
                                </Grid>                                                                
                                <Grid item xs={9}>
                                    {
                                        suiState.Inputs.length > 0 ? 
                                        <Fragment>
                                        <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Información a ingresar</Typography>
                                        <Grid container spacing={2} justify="space-evenly">
                                        {
                                            suiState.Inputs.map((input, sidx) => (
                                                <Fragment>                                                
                                                    {
                                                        suiState.Code === 'T2' && sidx % 10 === 0?
                                                        <Grid item xs={12}> 
                                                        <Typography variant="subtitle1" color="primary">
                                                            {'Garantía ' + String(Number(sidx/10 + 1))}
                                                        </Typography>
                                                        </Grid>
                                                        :
                                                        suiState.Code === 'T9' && sidx === 0?
                                                        <Grid container justify="space-evenly">
                                                            <Grid item xs={12}> 
                                                            <Typography variant="subtitle1" color="primary">
                                                                {'Parametrización subsidios por mercado'}
                                                            </Typography>                                                            
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        null                                                        
                                                    }    
                                                    {
                                                        suiState.Code === 'T9' && marketsSubsidy !== null?
                                                            sidx > 0 && sidx < 5 &&
                                                            Object.keys(marketsSubsidy).length > 0 ?
                                                            Object.keys(marketsSubsidy).map((market, midx) => (
                                                            <Grid item xs={6}>                                                                                                                                                                               
                                                                <Fragment>                                                           
                                                                    <Typography variant="subtitle2" color="primary">
                                                                        {input.Label + ' ' + market}
                                                                    </Typography>
                                                                    <TextField                                                            
                                                                        size="small"                                                           
                                                                        value={marketsSubsidy[market][sidx-1]}                               
                                                                        InputProps={{                                                                                                                        
                                                                            classes: {                                        
                                                                                input: classes.formText
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            let aux = marketsSubsidy;
                                                                            aux[market][sidx-1] = e.target.value;
                                                                            setMarketsSubsidy({...aux});
                                                                            setReportInputs([...reportInputs], reportInputs[pidx][sidx] = null);}}
                                                                        variant="outlined"
                                                                        className={classes.formRow} 
                                                                        select={input.Select}     
                                                                        //type={input.Type}                                                                                                                
                                                                    >
                                                                        {
                                                                            input.Select ?                                                                    
                                                                            input.Values.map((value, vidx) => (
                                                                                <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>                                
                                                                            ))
                                                                            :
                                                                            []
                                                                        }
                                                                    </TextField>
                                                                </Fragment>                                                                                                                                                       
                                                            </Grid>
                                                         ))
                                                            :        
                                                            sidx === 0 ? 
                                                            <Fragment>                                                        
                                                            <Grid item xs={6}>
                                                            {
                                                            input.Type !== 'Date' ? 
                                                            <Fragment>                                                           
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <TextField                                                            
                                                                    size="small"                                                           
                                                                    value={reportInputs[pidx][sidx]}                               
                                                                    InputProps={{                                                                                                                        
                                                                        classes: {                                        
                                                                            input: classes.formText
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = e.target.value);}}
                                                                    variant="outlined"
                                                                    className={classes.formRow} 
                                                                    select={input.Select}     
                                                                    type={input.Type}                                                                                                                
                                                                >
                                                                    {
                                                                        input.Select ?                                                                    
                                                                        input.Values.map((value, vidx) => (
                                                                            <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>                                
                                                                        ))
                                                                        :
                                                                        []
                                                                    }
                                                                </TextField>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <DatePicker           
                                                                    size="small"
                                                                    className={classes.formRow}                                                                                                             
                                                                    inputVariant="outlined"                                                                                 
                                                                    okLabel={""}
                                                                    cancelLabel={""}
                                                                    
                                                                    format="dd-MM-yyyy"
                                                                    //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                                                    //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                                                    //views={["year","month",]}                                
                                                                    value={reportInputs[pidx][sidx]}                                                        
                                                                    onChange={(date) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = date);}}
                                                                    error={false}
                                                                    InputProps={{                                            
                                                                                classes: {
                                                                                    input: classes.formText,                                    
                                                                                }
                                                                            }}
                                                                
                                                                    autoOk                                                                                  
                                                                />
                                                            </Fragment>
                                                            
                                                            }
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                            <Button color='primary' variant='contained' startIcon={<AddIcon/>} onClick={(e) => {addMarketSubsidy(reportInputs[pidx][0]); setReportInputs([...reportInputs], reportInputs[pidx][0] = null)}}> Añadir </Button>
                                                            </Grid>
                                                            </Fragment>
                                                            :
                                                            sidx >= 5 ? 
                                                            <Fragment>
                                                            {
                                                                sidx === 5 ?
                                                                <Grid item xs={12}>
                                                                <Typography variant="subtitle1" color="primary">
                                                                    {'Parametrización de comercializador'}
                                                                </Typography>
                                                                </Grid>
                                                                :
                                                                null
                                                            }
                                                            <Grid item xs={6}>
                                                        {
                                                            input.Type !== 'Date' ? 
                                                            <Fragment>                                                           
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <TextField                                                            
                                                                    size="small"                                                           
                                                                    value={sidx > 5 && reportInputs[pidx][5] ? reportInputs[pidx][5].includes('1') ? '100' : reportInputs[pidx][sidx] : reportInputs[pidx][sidx]}                               
                                                                    InputProps={{                                                                                                                        
                                                                        classes: {                                        
                                                                            input: classes.formText
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = e.target.value); 
                                                                                     if (sidx === 5) {setReportInputs([...reportInputs], reportInputs[pidx][6] = "100");
                                                                                        setReportInputs([...reportInputs], reportInputs[pidx][7] = "100");}}}
                                                                    variant="outlined"
                                                                    className={classes.formRow} 
                                                                    select={input.Select}     
                                                                    type={input.Type}                                                                                                                
                                                                >
                                                                    {
                                                                        input.Select ?                                                                    
                                                                        input.Values.map((value, vidx) => (
                                                                            <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>                                
                                                                        ))
                                                                        :
                                                                        []
                                                                    }
                                                                </TextField>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <DatePicker           
                                                                    size="small"
                                                                    className={classes.formRow}                                                                                                             
                                                                    inputVariant="outlined"                                                                                 
                                                                    okLabel={""}
                                                                    cancelLabel={""}
                                                                    
                                                                    format="dd-MM-yyyy"
                                                                    //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                                                    //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                                                    //views={["year","month",]}                                
                                                                    value={reportInputs[pidx][sidx]}                                                        
                                                                    onChange={(date) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = date);}}
                                                                    error={false}
                                                                    InputProps={{                                            
                                                                                classes: {
                                                                                    input: classes.formText,                                    
                                                                                }
                                                                            }}
                                                                
                                                                    autoOk                                                                                  
                                                                />
                                                            </Fragment>
                                                            
                                                        }
                                                            </Grid>
                                                            </Fragment>
                                                            :
                                                            null
                                                        :
                                                        <Grid item xs={6}>                                                        
                                                        {
                                                            input.Type !== 'Date' ? 
                                                            <Fragment>                                                           
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <TextField                                                            
                                                                    size="small"                                                           
                                                                    value={reportInputs[pidx][sidx]}                               
                                                                    InputProps={{                                                                                                                        
                                                                        classes: {                                        
                                                                            input: classes.formText
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = e.target.value);}}
                                                                    variant="outlined"
                                                                    className={classes.formRow} 
                                                                    select={input.Select}     
                                                                    type={input.Type}                                                                                                                
                                                                >
                                                                    {
                                                                        input.Select ?                                                                    
                                                                        input.Values.map((value, vidx) => (
                                                                            <MenuItem value={value} className={classes.formItem}>{value}</MenuItem>                                
                                                                        ))
                                                                        :
                                                                        []
                                                                    }
                                                                </TextField>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <Typography variant="subtitle2" color="primary">
                                                                    {input.Label}
                                                                </Typography>
                                                                <DatePicker           
                                                                    size="small"
                                                                    className={classes.formRow}                                                                                                             
                                                                    inputVariant="outlined"                                                                                 
                                                                    okLabel={""}
                                                                    cancelLabel={""}
                                                                    
                                                                    format="dd-MM-yyyy"
                                                                    //minDate={new Date(`${selectedYear1.getFullYear()}-01-02`)}
                                                                    //maxDate={new Date(`${selectedYear1.getFullYear()}-12-31`)}
                                                                    //views={["year","month",]}                                
                                                                    value={reportInputs[pidx][sidx]}                                                        
                                                                    onChange={(date) => {setReportInputs([...reportInputs], reportInputs[pidx][sidx] = date);}}
                                                                    error={false}
                                                                    InputProps={{                                            
                                                                                classes: {
                                                                                    input: classes.formText,                                    
                                                                                }
                                                                            }}
                                                                
                                                                    autoOk                                                                                  
                                                                />
                                                            </Fragment>
                                                            
                                                        }
                                                        </Grid>
                                                }                                                                                            
                                                </Fragment>
                                            ))
                                        }
                                        {
                                            suiState.Code === 'T2' ? 
                                                suiState.Inputs.length <= 10 ?
                                                <Grid item xs={4}>
                                                <Button color='primary' variant='contained' startIcon={<AddIcon/>} onClick={addT2Garanty}> Añadir </Button>
                                                </Grid>
                                                :
                                                <Fragment>
                                                <Grid item xs={4}>
                                                <Button color='primary' variant='contained' startIcon={<AddIcon/>} onClick={addT2Garanty}> Añadir </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                <Button color='primary' variant='contained' startIcon={<ClearIcon/>} onClick={deleteT2Garanty}> Eliminar </Button>
                                                </Grid>
                                                </Fragment>
                                            :                                            
                                            null
                                        }
                                        </Grid>
                                        </Fragment>
                                    :
                                        null
                                    }                                                                        
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Acciones</Typography>
                                    <Grid container justify="space-evenly">
                                        <Grid item xs={4}>
                                            {
                                                processing ? 
                                                <CircularProgress color="primary"/>
                                                :
                                                <Button color='primary' disabled={!internalReportStatus['process_sui']} variant='contained' startIcon={<DonutLargeIcon/>} onClick={processReportInformation(suiState.Code, pidx)}> Procesar </Button>
                                            }
                                            
                                        </Grid>
                                        <Grid item xs={4}>
                                            {
                                                downloading ?
                                                <CircularProgress color="primary"/>
                                                :
                                                <Button color='primary' variant='contained' disabled={!suiState.Processed} startIcon={<ArrowDropDownCircleIcon/>} onClick={downloadReport(suiState.Code)}> Descargar </Button>
                                            }                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="subtitle1" className={classes.componentPrincipalTitle}>Datos procesados</Typography>
                                    {
                                        <DataTable columns={reportColumns[pidx]} rows={suiState.Data}/>                                                 
                                    }
                                </Grid>                                                             
                            </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>                            
                ))
            }
        </Grid>  
    )

    useEffect (() => {
        document.title ="Reportes SUI";  
        authFetch("/api/check-billing-sui-reports?username=" + props.username +
            "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )
            ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.sui_reports){                        
                        let suiReportsInputs = new Array()                                                                 
                        response.sui_reports.forEach(report => { let auxArray = new Array(); report.Inputs.forEach(input => auxArray.push(input.Value ? input.Value : null)); suiReportsInputs.push( auxArray );} );   
                        let suiReportColumns =  new Array()
                        response.sui_reports.forEach(report => {let reportColNames = new Array(); report.DataLabels.forEach(
                        (label,lidx) => {reportColNames.push({name: label, key: report.DataDbLabels[lidx], resizable: true})}
                        ); suiReportColumns.push(reportColNames);} );        
                        setReportColumns([...suiReportColumns])                                                     
                        setReportInputs([...suiReportsInputs])
                        setSuiReportState([...response.sui_reports])                                               
                    }
                }) 
        authFetch("/api/check-internal-reports-status?username=" + String(props.username)+
        "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
                return response.json()
            }).then(response => {
                if (response && response.reports_status){                    
                    setInternalReportStatus(response.reports_status)                                          
                }
            })
    }, [props.billingDate])

    
    return(
        <div className={classes.root}>            
            <Grid container justify="space-evenly">
                <Zoom in timeout={800}>
                    <Grid item xs={12}>      
                        {showFeedbackDialog()}                                                                                                              
                        {                                                   
                            <Fragment>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>
                            Listado de reportes</Typography>
                            {principalComponent()}
                            </Fragment>                                                                                  
                        }                                                                                                                              
                    </Grid>            
                </Zoom>

            </Grid>
        </div>
    )
}