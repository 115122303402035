import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../auth';

import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 14,
      color: theme.palette.primary.dark,
      padding: 5
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,      
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));
  
export default function Summary(props) {
    const classes = useStyles();
    var actualDate = new Date() 
    const [contractDialog, setContractDialog] = useState(false) 
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    const [contractList, setContractList] = useState([])
       
    const [contractNumber, setContractNumber] = useState(0)
    const [seller, setSeller] = useState("")
    const [detail, setDetail] = useState("")
    const [market, setMarket] = useState("")
    const [calcType, setCalcType] = useState("")
    const [contractType, setContractType] = useState("")
    const [baseValue, setBaseValue] = useState(0)
    const [indexationType, setIndexationType] = useState(null)
    const [dbIpc, setDbIpc] = useState(0)
    const [dbIpp, setDbIpp] = useState(0)

    const [indexedValue, setIndexedValue] = useState(0)
    const [actualIpp, setActualIpp] = useState(0); 
    const [baseIpp, setBaseIpp] = useState(0);     
    const [initialDate, setInitialDate] = useState(actualDate)   
    const [endDate, setEndDate] = useState(actualDate)
    const [active, setActive] = useState(true)
    const [downloading, setDownloading] = useState(false)

    const [balanceConcepts, setBalanceConcepts] = useState([])

    const handleContractDialogOpen = () => {
        setContractDialog(true);
      };
    
    const handleContractDialogClose = () => {
        setContractDialog(false);
    };
    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const downloadEnergyBalance = () => {        
        setDownloading(true)   
        let month = props.periodDate.getMonth() + 1
        month = String(month).length === 1 ? '0' + month : month
        let year = props.periodDate.getFullYear()        
        authFetch("/api/enery-balance-download?"
            + "username=" + props.username + "&timestamp=" + new Date().toISOString().slice(0,19) 
            + "&month=" + month + "&year=" + year,
            {
                method: 'post',
                body: JSON.stringify(balanceConcepts),
                responseType: 'blob'
            }       
            ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                else if (response.status === 200){
                    return response.blob()
                }                    
                }).then(blob => {                         
                    setDownloading(false)                   
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = 'Legacy_Balance_'+props.username+'.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);                                    
                })
    } 

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}       
            className={classes.formText}            
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
     
    useEffect (() => {
        document.title ="Balance energético";        
        authFetch("/api/calc-energy-balance?username=" + props.username + 
            '&period=' +  String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()                
            + "&version=" + props.tariffCalcVersion).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            return response.json()
        }).then(response => {
            if (response && response.Concepts){
                ////console.log('Response', response)                
                setBalanceConcepts(response.Concepts)                          
            }
        })
    }, [props.periodDate, props.tariffCalcVersion])

    return (
        <div className={classes.root}>            
            {showFeedbackDialog()}
            
                <Zoom in timeout={800}>
                    <Grid container justify="space-evenly" spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" className={classes.sectionTitle}>{'Resumen del balance energético: '+ props.periodDate.toISOString().slice(0,7)}</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="markets table">
                                    <TableHead>
                                    <TableRow>                                    
                                        <StyledTableCell align="center" style={{width: "25%"}}>Concepto</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Compras kWh</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Compras $/kWh</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>Compras $</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}><br/>Ventas kWh </StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>$/kWh DSPC<br/>Ventas $/kWh</StyledTableCell>
                                        <StyledTableCell align="center" style={{width: "12%"}}>$ DSPC<br/>Ventas $</StyledTableCell>                                        
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>                                           
                                        {balanceConcepts.map((concept,idx) => (                                        
                                            <StyledTableRow key={idx}>
                                                <StyledTableCell align="center" style={{height: 55}}>{concept.Name}</StyledTableCell>
                                                {
                                                    concept.Values.map((val, sidx) => (
                                                        <StyledTableCell align="center">{val > 0 ? val.toLocaleString('en-US', {minimumFractionDigits: sidx === 0 ? 2 : sidx === 1 ?  5 : sidx > 1 ? 2 : 2}) : '-'}</StyledTableCell>
                                                    ))
                                                }                                                                                                                                               
                                            </StyledTableRow>                                            
                                        ))}                                            
                                    </TableBody>
                                </Table>
                            </TableContainer>                             
                        </Grid>  
                        <Grid item xs={5}>
                            {downloading ?
                            <CircularProgress color="primary"/>
                            :
                            <Button color='primary' variant='contained' startIcon={<ArrowDropDownCircleIcon/>} onClick={downloadEnergyBalance}> Descargar </Button>
                            }
                        </Grid>      
                    </Grid>                                                       
                </Zoom>                
            
        </div>
    );
}