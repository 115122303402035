import {React, useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Typography, TextField, Menu, MenuItem, Zoom, Table, TableBody, TableHead, TableContainer, TableCell, TableRow, 
        Fab, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Accordion, AccordionSummary, AccordionDetails, Box, IconButton, CircularProgress} from '@material-ui/core/'
import {DatePicker} from '@material-ui/pickers';        
import AddIcon from '@material-ui/icons/Add';
import {authFetch, logout} from '../../auth';

import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { EmojiFlagsSharp, TrainRounded } from '@material-ui/icons';
import SyncIcon from '@material-ui/icons/Sync';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import GetAppIcon from '@material-ui/icons/GetApp';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: 3
    },
    body: {
      fontSize: 13,
      color: theme.palette.primary.dark,
      padding: 5,
      background: 'white'
    },
  }))(TableCell);

const StyledTableCellMin = withStyles((theme) => ({
head: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: 3
},
body: {
    fontSize: 11,
    color: theme.palette.primary.dark,
    padding: 5,
    background: 'white'
},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2)
    },
    accordionStyle:{
        backgroundColor: theme.palette.secondary.light,
        color:  theme.palette.primary.dark,
        flexWrap: 'wrap',
    },
    darkText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    contentPaper:{
        background: theme.palette.secondary.light,
        padding: 10,
    },
    contentPaperWithPadding:{
        background: theme.palette.secondary.light,
        paddingTop: 30,
        paddingBottom: 30
    },
    infoNumber:{
      background: theme.palette.primary.main, 
      padding:8, 
      borderRadius: 5,
      fontWeight: 'bold',
      color: theme.palette.secondary.light
    },
    infoText:{      
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    searchButton:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 8,
        fontSize: 29,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,            
        },                
    },
    sectionTitle: {
        marginBottom: 10,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        color: theme.palette.primary.dark
    },
    componentTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 10,
        marginBottom: theme.spacing(1)
    },
    formPaper: {
        padding: theme.spacing(3),
        color: theme.palette.primary.main,
        "> * + *": {
            marginTop: theme.spacing(5)
        }
    }, 
    formRow: {
        width: "100%",       
        maxHeight: "50%",        
    },
    labelText: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16 
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 'normal'
    },
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: 'white',        
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formItem: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 'normal',
        color: theme.palette.primary.dark
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    formItemNoPadding: {
        padding: 0
    },
    uploadButton : {        
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
    }
  }));


  
export default function Summary(props) {
    const classes = useStyles();
    let billingDate = props.billingDate 
    
    const [feedbackDialog, setFeedbackDialog] = useState(false) 
    const [feedbackMsg, setFeedbackMsg] = useState("")
    let optionMapping = {
        CONSUMO_AÑO_ANT: 'Consumos año anterior',
        CONSUMO_MES_SIM: 'Consumos mes igual días',
        CONSUMO_MES_ACT: 'Consumos mes actual',
        CONSUMO_0: 'Consumos faltantes en 0',
        LECTURAS_MES_ANT: 'Lecturas partiendo de última conocida',
        LECTURAS_IGUAL_ADELANTE: 'Lecturas iguales hacia delante',
        LECTURAS_IGUAL_ATRAS: 'Lecturas iguales hacia atras',
        LECTURAS_MES_ACT: 'Lecturas mes actual'
    }
    let month = billingDate.getMonth()+1
    let year = billingDate.getFullYear()
    let monthHours = new Date(year, month, 0).getDate()*24
    const [isLoading, setIsLoading] = useState(true)
    const [refetch, setRefetch] = useState(false)
    const [uploadedUsers, setUploadedUsers] = useState(0)
    const [processedUsers, setProcessedUsers] = useState(0)
    const [clientCodes, setClientCodes] = useState([])
    const [clientMeasuringTypes, setClientMeasuringTypes] = useState({})
    const [clientStatus, setClientStatus] = useState({})
    const [expanded, setExpanded] = useState(false);
    const [filesUploading, setFilesUploading] = useState(false)
    const [filesUploadingMsg, setFilesUploadingMsg] = useState("")  
    const [filesProcessing, setFilesProcessing] = useState(false)
    const [filesProcessingMsg, setFilesProcessingMsg] = useState("")  
    const [userPreprocessing, setUserPreprocessing] = useState(false)
    const [approvationInProgress, setApprovationInProgress] = useState(false)
    const [incompleteMatrixDialog, setIncompleteMatrixDialog] = useState(false) 
    const [completeMatrixDialog, setCompleteMatrixDialog] = useState(false)
    const [encounteredValuesMatrix, setEncounteredValuesMatrix] = useState([])
    const [completeValuesMatrix, setCompleteValuesMatrix] = useState([])
    const [preprocessMenu, setPreprocessMenu] = useState(null)
    const [preprocessIdx, setPreprocessIdx] = useState(-1)
    const [postprocessList, setPostprocessList] = useState([])
    const [menuCode, setMenuCode] = useState(-1)
    const [downloading, setDownloading] = useState(false)
    const [liquidationApprovedFlag, setLiquidationApprovedFlag] = useState(false)
    const [consumptionApprovedFlag, setConsumptionApprovedFlag] = useState(false)
    
    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleClick = (event) => {
        setPreprocessMenu(event.currentTarget);
    };
    
    const handleClose = () => {
        setPreprocessMenu(null);
    };

    const handleIncompleteMatrixDialogOpen = () => {
        setIncompleteMatrixDialog(true);
      };
    
    const handleIncompleteMatrixDialogClose = () => {
        setIncompleteMatrixDialog(false);
    };

    const handleCompleteMatrixDialogOpen = () => {
        setCompleteMatrixDialog(true);
      };
    
    const handleCompleteMatrixDialogClose = () => {
        setCompleteMatrixDialog(false);
    };

    const handleFeedbackDialogOpen = () => {
        setFeedbackDialog(true);
      };
    
    const handleFeedbackDialogClose = () => {
        setFeedbackDialog(false);
    };

    const getSignedURL = (filename, contentType) => {        
        let opts = {
            'filename': filename,      
            'contentType': contentType,              
        }
        return new Promise((resolve, reject) => {
            authFetch(
                "/api/get-gcs-signed-url?username="+props.username+
                                "&period="+String( billingDate.getMonth() + 1 )+                              
                                "&year="+ billingDate.getFullYear()+                        
                                "&files_from="+ 'ag',
                {
                    method: 'post',                
                    //body: formData,
                    body: JSON.stringify(opts)
                }
            )
            .then(r => {
                if (r.status === 422){
                    logout()
                    return null
                }
                else if (r.status === 200){
                    return r.json()
                }
                else {
                    reject('error')
                }
            })
            .then(response => {
                if (response.url){
                    resolve(response.url)                    
                }                
            })
        })
    }

    const putFileinURL = (url, file, contentType) => {
        return new Promise((resolve, reject) => {
            fetch(
                url,
                {
                    method: 'put',
                    body: file,
                    headers: {
                        'Content-Type': contentType
                    }
                }
            )
            .then(response => {
                if (response.status === 200){
                    resolve(true)
                }
                else{
                    reject(false)
                }
            })
        })
    }

    const tryFileProcessing = (filename) => {
        let opts = {
            'filename': filename,                  
        }
        return new Promise((resolve, reject) => {
            authFetch(
                "/api/try-file-processing?username="+props.username+
                                "&month="+String( billingDate.getMonth() + 1 )+                              
                                "&year="+ billingDate.getFullYear(),
                {
                    method: 'post',                
                    //body: formData,
                    body: JSON.stringify(opts)
                }
            )
            .then(r => {
                if (r.status === 422){
                    logout()
                    return null
                }
                else if (r.status === 200){
                    return r.json()
                }
                else {
                    reject('error')
                    return null
                }
            })
            .then(response => {                
                if (response && response.Upload === 'OK'){
                    resolve(true)                    
                }                
            })
        })
    }

    async function uploadFiles(files){        
        let urlRequest = null
        let uploadRequest = null
        let urlData = []
        let uploadFlags = []
        let file = {}
        let filesTotal = files.length        
        let currFile = 0   
        let codes = []     
        let filenames = []
        // Validar los nombres de los archivos
        for (file of files){           
            let filenameSplit = file.name.split('.')
            let code = filenameSplit[0]
            codes.push(code)
            filenames.push(file.name)            
            if ( !clientCodes.includes(code) ){
                setFeedbackDialog(true)
                setFeedbackMsg('El archivo con nombre ' + file.name + ' no tiene el código interno correcto.')
                return [[false], [], []]
            }            
        }
        // Obtener signed urls
        for (file of files){   
            currFile = currFile + 1         
            urlRequest = (getSignedURL(file.name, file.type))
            await urlRequest.then((url) => {
                urlData.push(url)
                setFilesUploadingMsg('Preparando archivos... ' + String((100*currFile/filesTotal).toFixed(0)) + '%')
            })
            .catch( (err) => {
                setFeedbackDialog(true)
                setFeedbackMsg('Error firmando URLs archivos.')     
                uploadFlags.push(false)       
            })
        }
        // Subir cada archivo
        currFile = 0
        for (file of files){
            currFile = currFile + 1
            uploadRequest = (putFileinURL(urlData[currFile - 1], file, file.type))
            await uploadRequest.then((flag) => {
                uploadFlags.push(flag)
                setFilesUploadingMsg('Subiendo archivos... ' + String((100*currFile/filesTotal).toFixed(0)) + '%')
            })
            .catch( (err) => {
                setFeedbackDialog(true)
                setFeedbackMsg('Error subiendo archivos.')  
                uploadFlags.push(false)           
            })
        }                
        
        return [uploadFlags, codes, filenames]
    
    }

    async function processFiles(files){
        setFilesProcessingMsg('Procesando archivos... ')
        let processRequest = null
        let filesTotal = files.length        
        let currFile = 0
        let processingFlags = []
        let filename = ''
        for (filename of files){
            currFile = currFile + 1
            processRequest = (tryFileProcessing(filename))
            await processRequest.then((flag) => {                
                if (flag){                    
                    setFilesProcessingMsg('Procesando archivos... ' + String((100*currFile/filesTotal).toFixed(0)) + '%')
                    processingFlags.push(flag)
                }
            })
            .catch((err) => {                
                setFeedbackDialog(true)
                setFeedbackMsg('Error procesando archivo ' + filename)
                processingFlags.push(false)             
            })
        }
        return processingFlags
    }

    const handleUploadFiles = async (e) => {  
        setFilesUploading(true)      
        setFilesUploadingMsg('Preparando archivos...')
        e.preventDefault()
        
        if (e.target.files.length > 0){
            let uploadRequest =  null            
            uploadRequest = (uploadFiles(e.target.files))
            await uploadRequest.then(([flags, codes, filenames]) => {
                if ( flags.some((flag) => !flag) ){                    
                    setFilesUploading(false)                                                
                }
                else{
                    setFeedbackDialog(true)
                    setFeedbackMsg('Archivos subidos exitosamente.')
                    setFilesUploading(false)
                    let opts = {
                        codes: codes,
                        filenames: filenames,
                    }                    
                    authFetch(
                        "/api/update-status-after-upload?username="+props.username+
                                        "&month="+String( billingDate.getMonth() + 1 )+                              
                                        "&year="+ billingDate.getFullYear(),
                        {
                            method: 'post',                
                            //body: formData,
                            body: JSON.stringify(opts)
                        }
                    )
                    .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
                    .then(response => {
                        if (response && response.Upload === 'OK'){                     
                            let actual_flag = refetch
                            setRefetch(!actual_flag)
                        }            
                    })
                } 
            })
                       
        }
        else {
            setFeedbackDialog(true)
            setFeedbackMsg('No ha seleccionado ningún archivo.')
        }
    }

    const handleProcessingFiles = async (files) => {  
        setFilesProcessing(true)      
        setFilesProcessingMsg('Preparando archivos...')
               
        if (files.length > 0){
            let processRequest =  null            
            processRequest = (processFiles(files))
            await processRequest.then((flags) => {               
                if ( flags.some((flag) => !flag) ){                    
                    setFilesProcessing(false)                                            
                }
                else{
                    setFeedbackDialog(true)
                    setFeedbackMsg('Archivos procesados exitosamente.')
                    setFilesProcessing(false)
                    let actual_flag = refetch
                    setRefetch(!actual_flag)
                } 
            })
            
            let actual_flag = refetch
            setRefetch(!actual_flag)     
        }        
    }

    const showFeedbackDialog = () => (
        <Dialog
            open={feedbackDialog}
            onClose={handleFeedbackDialogClose}                              
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Aviso</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                {feedbackMsg}
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleFeedbackDialogClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
    )
 
    const showIncompleteMatrix = () => (
        <Dialog
            open={incompleteMatrixDialog}
            onClose={handleIncompleteMatrixDialogClose}                   
            scroll={'paper'}
            maxWidth={'lg'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>
        <Grid container justify="center">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1} >{'Matrices de energía - datos encontrados'}</Box>
                <Box>
                    <IconButton onClick={handleIncompleteMatrixDialogClose}>
                          <HighlightOffIcon color="primary"/>
                    </IconButton>
                </Box>
          </Box>
        </Grid>
        
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>                     
            <Grid container spacing={1} alignItems="flex-start" justify="flex-end">                                
                <Grid item xs={12}>
                    {
                        encounteredValuesMatrix.length > 0 ?
                        <Fragment>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 4} onChange={handlePanelChange(4)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía activa demandada'}</AccordionSummary>
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            encounteredValuesMatrix[0].slice(0,25).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            encounteredValuesMatrix.slice(1,encounteredValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    row.slice(0,25).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>
                            
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 5} onChange={handlePanelChange(5)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía activa generada'}</AccordionSummary>
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(encounteredValuesMatrix[0].slice(25,49)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        encounteredValuesMatrix.slice(1,encounteredValuesMatrix.length).map((row) => (
                                            <TableRow>
                                            {
                                                [row[0]].concat(row.slice(25,49)).map((val) => (
                                                    <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                ))
                                            }                                            
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>                            
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 6} onChange={handlePanelChange(6)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía inductiva'}</AccordionSummary>
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(encounteredValuesMatrix[0].slice(49,73)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            encounteredValuesMatrix.slice(1,encounteredValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    [row[0]].concat(row.slice(49,73)).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 7} onChange={handlePanelChange(7)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía capacitiva'}</AccordionSummary>
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(encounteredValuesMatrix[0].slice(73,97)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            encounteredValuesMatrix.slice(1,encounteredValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    [row[0]].concat(row.slice(73,97)).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        </Fragment>
                        :
                        null
                    }
                    
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const showCompleteMatrix = () => (
        <Dialog
            open={completeMatrixDialog}
            onClose={handleCompleteMatrixDialogClose}                   
            scroll={'paper'}
            maxWidth={'lg'}
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>
        <Grid container justify="center">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1} >{'Matrices de energía - datos completos'}</Box>
                <Box>
                    <IconButton onClick={handleCompleteMatrixDialogClose}>
                          <HighlightOffIcon color="primary"/>
                    </IconButton>
                </Box>
          </Box>
        </Grid>
        
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>                   
            <Grid container spacing={1} alignItems="flex-start" justify="flex-end">                                
                <Grid item xs={12}>
                    {
                        completeValuesMatrix.length > 0 ?
                        <Fragment>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 0} onChange={handlePanelChange(0)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía activa demandada'}</AccordionSummary>
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            completeValuesMatrix[0].slice(0,25).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            completeValuesMatrix.slice(1,completeValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    row.slice(0,25).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 1} onChange={handlePanelChange(1)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía activa generada'}</AccordionSummary>                            
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(completeValuesMatrix[0].slice(25,49)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        completeValuesMatrix.slice(1,completeValuesMatrix.length).map((row) => (
                                            <TableRow>
                                            {
                                                [row[0]].concat(row.slice(25,49)).map((val) => (
                                                    <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                ))
                                            }                                            
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>                            
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 2} onChange={handlePanelChange(2)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía inductiva'}</AccordionSummary>                            
                            <AccordionDetails>
                            <TableContainer>                            
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(completeValuesMatrix[0].slice(49,73)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            completeValuesMatrix.slice(1,completeValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    [row[0]].concat(row.slice(49,73)).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.accordionStyle} expanded={expanded === 3} onChange={handlePanelChange(3)}>
                            <AccordionSummary expandIcon={<AddIcon color="primary"/>}>{'Energía capacitiva'}</AccordionSummary>                            
                            <AccordionDetails>
                            <TableContainer>
                            <Table>
                                <TableHead>                        
                                    <TableRow>
                                        {
                                            ['Fecha'].concat(completeValuesMatrix[0].slice(73,97)).map(title => (
                                                <StyledTableCellMin align="center">{title}</StyledTableCellMin>
                                            ))
                                        }                                                                           
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        {
                                            completeValuesMatrix.slice(1,completeValuesMatrix.length).map((row) => (
                                                <TableRow>
                                                {
                                                    [row[0]].concat(row.slice(73,97)).map((val) => (
                                                        <StyledTableCellMin align="center" style={val !== null ? {}:{background: '#F79080'}}>{val !== null ? val.toLocaleString('en-US', {maximumFractionDigits: 2}): '-'}</StyledTableCellMin>
                                                    ))
                                                }                                            
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                            </Table>
                            </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        </Fragment>
                        :
                        null
                    }
                    
                </Grid>
            </Grid>            
        </DialogContent>        
      </Dialog>
    )

    const preprocessCode = (clientCode, method) => {
        setUserPreprocessing(true)
        authFetch(
            "/api/preprocess-user-file?username="+props.username+
                            "&month="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear()+
                            "&client_code="+ clientCode+
                            "&method="+ method
        )
        .then(r => {
            if (r.status === 422){
                logout()
                return null
            }
            else if (r.status === 200){
                return r.json()
            }
            else{
                setFeedbackDialog(true)
                setFeedbackMsg('Error al postprocesar consumos.')
                setUserPreprocessing(false)
            }
        })
        .then(response => {
            if (response && response.Upload === 'OK'){                     
                setFeedbackDialog(true)
                setFeedbackMsg('Consumos completados exitosamente.')
                setUserPreprocessing(false)
                let actual_flag = refetch
                setRefetch(!actual_flag)
            }            
        })
    }

    const postprocessOptions = (code) => (
        <Menu 
            id={'postprocess_menu'} 
            anchorEl={preprocessMenu}             
            open={Boolean(preprocessMenu)}
            onClose={handleClose}
        >
            {
                postprocessList.map((option) => (
                    <MenuItem 
                        value={option} 
                        disabled={!(clientStatus[code] && 
                            clientStatus[code].preprocess_options &&
                            clientStatus[code].preprocess_options.includes(option))
                        }
                        onClick={(e) => {                                                                                        
                            preprocessCode(code, option);                             
                            handleClose();
                        }}
                    >
                        {optionMapping[option]}
                    </MenuItem>
                ))                
            }            
        </Menu>
    )

    const downloadConsumption = (clientCode, matrixName) => {
        setDownloading(true)
        authFetch("/api/user-consumption-download"+ "?timestamp=" + new Date().toISOString().slice(0,19)
            +"&month="+String( billingDate.getMonth() + 1 )+                              
            "&year="+ billingDate.getFullYear()+
            "&client_code="+ clientCode+
            "&matrix_name="+ matrixName,
            { responseType: 'blob' } 
        ).then(response => {
            setDownloading(false);
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status === 200){
                return response.blob()
          } 
          }).then(blob => {                              
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Consumos_'+clientCode+'_'+String( billingDate.getMonth() + 1 )+'_'+billingDate.getFullYear()+'.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);   
            setDownloading(false);                                            
        })
    }

    const approveConsumption = () => {
        setApprovationInProgress(true)
        authFetch("/api/-approve-ag-consumption?username=" + String(props.username)+
                "&year="+billingDate.getFullYear()+"&month="+String( billingDate.getMonth() + 1 )).then(response => {
            if (response.status === 422){
                    logout()
                    return null
                }
            else if (response.status !== 200){
                handleFeedbackDialogOpen()
                setFeedbackMsg("Error en el proceso.")    
                setApprovationInProgress(false)             
                return null
            }
                return response.json()
            }).then(response => {
                if (response && response.Upload === 'OK'){                                                      
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Información de archivos aprobada.")    
                    setApprovationInProgress(false)     
                    setConsumptionApprovedFlag(true)                                                
                }
                else{
                    handleFeedbackDialogOpen()
                    setFeedbackMsg("Error en el proceso.")         
                    setApprovationInProgress(false)              
                }
            })
    }

    useEffect (() => {        
        document.title ="Panel de archivos | Autogeneradores Cedenar"; 
        setIsLoading(true)
        authFetch(
            "/api/check-ag-liquidation-status?username="+props.username+
                            "&month="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear()
        )
        .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            if (response && 'liquidation_approved' in response){             
                                        
                setLiquidationApprovedFlag(response.liquidation_approved[0])  
                setConsumptionApprovedFlag(response.liquidation_approved[2])              
            }            
        })
        authFetch(
            "/api/check-users-consumption-status?username="+props.username+
                            "&month="+String( billingDate.getMonth() + 1 )+                              
                            "&year="+ billingDate.getFullYear()
        )
        .then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
        .then(response => {
            ////console.log(response)
            if (response && response.client_codes){                     
                let client_codes = response.client_codes
                let client_status = response.client_status
                //console.log(client_status)
                setClientCodes(client_codes)
                setClientStatus(client_status)
                setClientMeasuringTypes(response.client_measuring_types)
                let uploaded_users = 0
                let processed_users = 0            
                client_codes.forEach(code => {
                    if (code in client_status && client_status[code].uploaded_files && client_status[code].uploaded_files > 0){
                        uploaded_users = uploaded_users + 1
                    }
                    if (code in client_status && client_status[code].consumption_calculated){
                        processed_users = processed_users + 1
                    }
                });
                setUploadedUsers(uploaded_users)
                setProcessedUsers(processed_users)
                setIsLoading(false)
                //alert('Entro')
            }            
        })       
    }, [props.billingDate, refetch])

    return (
        <div className={classes.root}>
            {showIncompleteMatrix()}
            {showCompleteMatrix()}
            {showFeedbackDialog()}
            {postprocessOptions(menuCode)}
            <Zoom in timeout={800}>
            {
                isLoading ? 
                <CircularProgress style={{marginTop: '20%', marginLeft: '45%'}}/>
                :
                <Grid container justify="space-evenly" spacing={1} alignItems='center'>                
                    <Grid item xs={4}>                    
                        <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                        <Grid container alignItems="center" justify="center" spacing={1}>
                            {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>SUBIDOS:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>18</Typography>                          
                            </Grid> */}
                            <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>USUARIOS SUBIDOS:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>{(100*uploadedUsers/clientCodes.length).toFixed(0) + '%'}</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="subtitle1" align="center" className={classes.infoText}>{uploadedUsers + ' de ' + clientCodes.length + ' Usuarios'}</Typography>                          
                            </Grid>                        
                        </Grid>
                        </Paper>                                                                
                    </Grid>
                    <Grid item xs={4}>                    
                        <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                        <Grid container alignItems="center" justify="center" spacing={1}>   
                            {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>PROCESADOS:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>15</Typography>                          
                            </Grid>                      */}
                            <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>USUARIOS PROCESADOS:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>{(100*processedUsers/clientCodes.length).toFixed(0) + '%'}</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="subtitle1" align="center" className={classes.infoText}>{processedUsers + ' de ' + clientCodes.length + ' Usuarios'}</Typography>                          
                            </Grid>
                        </Grid>
                        </Paper>                    
                    </Grid>                            
                    <Grid item xs={4}>                    
                        <Paper className={classes.contentPaperWithPadding} elevation={0} variant="outlined">
                        <Grid container alignItems="center" justify="center" spacing={1}>  
                            {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>TOTAL DIAGNOSTICADOS:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>3</Typography>                          
                            </Grid>                       */}
                            <Grid item xs={12}>
                            <Typography variant="subtitle1" align="center" className={classes.darkText}>USUARIOS PENDIENTES:</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="h5" align="center" className={classes.infoNumber}>{(100*(clientCodes.length-processedUsers)/clientCodes.length).toFixed(0) + '%'}</Typography>                          
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant="subtitle1" align="center" className={classes.infoText}>{(clientCodes.length-processedUsers) + ' de ' + clientCodes.length + ' Usuarios'}</Typography>                          
                            </Grid>
                        </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        {
                            <form action={"#"}>
                                <label htmlFor={'consumption_files'}>
                                <input
                                    hidden
                                    id={'consumption_files'}
                                    name={'consumption_files'}
                                    type="file"   
                                    accept={['.xlsx','.xls','.csv']} 
                                    onClick={(e) => {e.target.value = null;}}   
                                    onChange={(e) => {handleUploadFiles(e); }}            
                                    multiple={true}  
                                />
                                {
                                    filesUploading?
                                    <Grid container justify="center" style={{marginTop: 20}}>
                                        <CircularProgress/>
                                        <Typography variant="body1" color="primary">{filesUploadingMsg}</Typography>
                                    </Grid>                                
                                    :
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        component="span"      
                                        fullWidth                                                      
                                        classes={{root: classes.uploadButton}}
                                        //onClick={(e) => {handleClientInfoDialogClose(); uploadClientInfo();}}
                                        style={{marginTop: 20}}
                                        startIcon={<AddIcon />}
                                        disabled={liquidationApprovedFlag}
                                    > 
                                    Subir                                                 
                                    </Button>
                                }                                    
                                </label>
                            </form> 
                        }
                    </Grid>
                    <Grid item xs={2}>
                        {
                            filesProcessing?
                            <Grid container justify="center" style={{marginTop: 20}}>
                                <CircularProgress/>
                                <Typography variant="body1" color="primary">{filesProcessingMsg}</Typography>
                            </Grid>                                
                            :
                            <Button
                                color="primary"
                                variant="contained"                        
                                classes={{root: classes.uploadButton}}
                                fullWidth
                                onClick={(e) => {handleProcessingFiles(clientStatus['0'].last_uploaded_files);}}
                                style={{marginTop: 20}}                                
                                disabled={(! ('0' in clientStatus)) || (!clientStatus['0'].last_uploaded_files) || (clientStatus['0'].last_uploaded_files.length === 0) || liquidationApprovedFlag}
                                startIcon={<SyncIcon />}
                            > 
                                Procesar                                                 
                            </Button> 
                        }
                    </Grid>
                    <Grid item xs={2}>
                        {
                            approvationInProgress?
                            <Grid container justify="center" style={{marginTop: 20}}>
                                <CircularProgress/>
                                <Grid item xs={12}>
                                <Typography variant="body1" color="primary">{'Aprobando..'}</Typography>
                                </Grid>
                            </Grid>                                
                            :
                            <Button
                                color="primary"
                                variant="contained"                        
                                classes={{root: classes.uploadButton}}
                                fullWidth
                                onClick={(e) => {approveConsumption();}}
                                style={{marginTop: 20}}                                
                                disabled={(! ('0' in clientStatus)) || (processedUsers === 0) || liquidationApprovedFlag || consumptionApprovedFlag}
                                startIcon={<DoneIcon />}
                            > 
                                Aprobar                                                 
                            </Button> 
                        }
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={7} style={{marginTop: 30}}>
                        <Typography variant="subtitle1" className={classes.sectionTitle}>Estado de la información</Typography>
                    </Grid>
                    <Grid item xs={12} >                    
                        <Grid container justify="center" spacing={1}>                           
                            <Grid item xs={12}>
                                <Paper className={classes.contentPaper} elevation={0} variant="outlined">
                                    <TableContainer>
                                        <Table aria-label="markets table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center" style={{width: "9%"}}>Código interno</StyledTableCell>
                                                <StyledTableCell align="center" style={{width: "9%"}}>Tipo medición</StyledTableCell>
                                                <StyledTableCell align="center" style={{width: "15%"}}>Consumos</StyledTableCell>                                
                                                <StyledTableCell align="center" style={{width: "8%"}}>Archivos subidos</StyledTableCell>
                                                <StyledTableCell align="center" style={{width: "8%"}}>Archivos procesados</StyledTableCell>                               
                                                <StyledTableCell align="center" style={{width: "8%"}}>Registros esperados</StyledTableCell>                                 
                                                <StyledTableCell align="center" style={{width: "8%"}}>Registros encontrados</StyledTableCell>                                
                                                <StyledTableCell align="center" style={{width: "13%"}}>Diagnóstico</StyledTableCell>
                                                <StyledTableCell align="center" style={{width: "12%"}}>Postprocesamiento</StyledTableCell>
                                                {/* <StyledTableCell align="center" style={{width: "10%"}}>Exportar</StyledTableCell> */}
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>    
                                                {
                                                    clientCodes.map((code, idx) => (
                                                        <TableRow>
                                                            <StyledTableCell align="center">{code}</StyledTableCell>
                                                            <StyledTableCell align="center">{clientMeasuringTypes[code]}</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].consumption_calculated ? 
                                                                <Grid container alignItems="flex-start" justify="center" spacing={1}>
                                                                    <Grid item>
                                                                        <DoneIcon fontSize="small" color="success"/>
                                                                    </Grid>
                                                                    <Grid item>                                            
                                                                        Listo
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Grid container alignItems="flex-start" justify="center" spacing={1}>
                                                                    <Grid item>
                                                                        <HourglassEmptyIcon fontSize="small" color="error"/>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        Pendiente
                                                                    </Grid>                                            
                                                                </Grid> 
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].uploaded_files?
                                                                clientStatus[code].uploaded_files 
                                                                :
                                                                0                                                            
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].processed_files?
                                                                clientStatus[code].processed_files 
                                                                :
                                                                0                                                            
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].consumption_calculated?
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12}>
                                                                        <IconButton onClick={(e) => {
                                                                            if(clientStatus[code] && clientStatus[code].file_processing_info.complete_matrix){                                                                                
                                                                                handleCompleteMatrixDialogOpen();                                                                                
                                                                                setCompleteValuesMatrix(clientStatus[code].file_processing_info.complete_matrix);
                                                                            }
                                                                            else if (clientStatus[code] && (monthHours === clientStatus[code].encountered_values) ){   
                                                                                handleCompleteMatrixDialogOpen();                                                                                                                                                                 
                                                                                setCompleteValuesMatrix(clientStatus[code].file_processing_info.encountered_matrix);                                                                                                                                       
                                                                            }
                                                                        }}>
                                                                            <ContactSupportIcon color="primary"/>                                                                        
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {monthHours}
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                monthHours
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].encountered_values?
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12}>
                                                                        <IconButton onClick={(e) => {
                                                                            if(clientStatus[code] && clientStatus[code].encountered_values){
                                                                                handleIncompleteMatrixDialogOpen();
                                                                                if(clientStatus[code] && (monthHours === clientStatus[code].encountered_values) && clientMeasuringTypes[code] == 'Consumos' ){                                                                                    
                                                                                    setEncounteredValuesMatrix(clientStatus[code].file_processing_info.complete_matrix);                                                                                                                                                                       
                                                                                }
                                                                                else{
                                                                                    setEncounteredValuesMatrix(clientStatus[code].file_processing_info.encountered_matrix);
                                                                                }                                                                                                                                                                                                                                                      
                                                                            }
                                                                           
                                                                        }}>
                                                                            <ContactSupportIcon color="primary"/>                                                                        
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {clientStatus[code].encountered_values }
                                                                    </Grid>
                                                                </Grid>                                                                        
                                                                :
                                                                0                                                            
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                clientStatus[code] && clientStatus[code].consumption_calculated ?
                                                                'Consumos calculados'
                                                                :
                                                                clientStatus[code] && clientStatus[code].processed_files && clientStatus[code].processed_files > 0?                                                            
                                                                clientMeasuringTypes[code] + ' incompletos/as. Postprocesar para estimar.'
                                                                :
                                                                clientStatus[code] && clientStatus[code].uploaded_files && clientStatus[code].uploaded_files > 0 ?
                                                                'Archivos subidos. Por procesar.'
                                                                :
                                                                'Subir archivos.'
                                                                
                                                            }</StyledTableCell>
                                                            <StyledTableCell align="center">{
                                                                userPreprocessing && idx === preprocessIdx?
                                                                <Grid container justify="center">
                                                                    <CircularProgress/>
                                                                    <Typography variant="body2" color="primary">{'Postprocesando usuario...'}</Typography>
                                                                </Grid>                                
                                                                :
                                                                <Fragment>                                                                    
                                                                    <Button 
                                                                        // aria-controls={"postprocess_menu_"+code}
                                                                        // aria-haspopup="true"
                                                                        color="primary" 
                                                                        variant="outlined" 
                                                                        style={{fontSize: 12}} 
                                                                        disabled={(clientStatus[code] && clientStatus[code].consumption_calculated) || !(clientStatus[code] && clientStatus[code].encountered_values)}
                                                                        onClick={(e) => {
                                                                            handleClick(e);
                                                                            setMenuCode(code);
                                                                            setPreprocessIdx(idx);                                                                            
                                                                            if(clientMeasuringTypes[code] !== 'Lecturas'){

                                                                                setPostprocessList(['CONSUMO_0', 'CONSUMO_MES_ACT'])
                                                                            }
                                                                            else{
                                                                                setPostprocessList(['LECTURAS_MES_ANT', 'LECTURAS_IGUAL_ADELANTE', 'LECTURAS_IGUAL_ATRAS'])
                                                                            }
                                                                        }}
                                                                    >
                                                                        Opciones
                                                                    </Button>
                                                                    
                                                                </Fragment>
                                                            }</StyledTableCell>
                                                            {/* <StyledTableCell align="center">{
                                                                downloading && idx === preprocessIdx? 
                                                                <CircularProgress/>
                                                                :
                                                                <IconButton 
                                                                    disabled={!(clientStatus[code] && clientStatus[code].consumption_calculated)}
                                                                    onClick={(e) =>{
                                                                        setPreprocessIdx(idx);
                                                                        let matrix_name = ""
                                                                        if (clientMeasuringTypes[code] === 'Lecturas'){
                                                                            matrix_name = "consumption_matrix"
                                                                        }
                                                                        else{
                                                                            if (clientStatus[code].encountered_values === monthHours){
                                                                                matrix_name = "encountered_matrix"
                                                                            }
                                                                            else{
                                                                                matrix_name = "complete_matrix"
                                                                            }
                                                                        }
                                                                        downloadConsumption(code, matrix_name);
                                                                    }}
                                                                >
                                                                    <GetAppIcon color={!(clientStatus[code] && clientStatus[code].consumption_calculated) ? "secondary" : "primary"}/>
                                                                </IconButton>
                                                            }</StyledTableCell> */}
                                                        </TableRow>
                                                    ))                                                    
                                                }                                                                                                                  
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>                     
                        </Grid>
                    </Grid>                                                                                                   
                </Grid>
            }
               
            </Zoom>    
        </div>
    );
}