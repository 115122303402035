import React from 'react'
import {useEffect, useState, Fragment} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {TextField, Switch, Grid, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Paper, Fab, Button,  Dialog, DialogActions, DialogContent, Typography, DialogContentText, DialogTitle, Snackbar} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import {authFetch, logout} from '../../auth';
import getPeriods from '../../const/yearPeriods';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import getMonths from '../../const/monthPeriods';
import getAbbMonths from '../../const/abbMonthsPeriods';
import validateInput from '../../const/validation';
import NumberFormat from "react-number-format";
import { FlashOffOutlined } from '@material-ui/icons';

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, required, rows, autoComplete, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowNegative={rows < 0 ? true : false}      
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}      
      thousandSeparator
      isNumericString      
      suffix={name}
      isAllowed={(values) => {
        const {floatValue} = values;
        if (required) {                
          return floatValue >= rows &&  floatValue <= Number(autoComplete);
        }          
        else {return true}
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  selector: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  uploadButton : {
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 9,
    }
  },
  cellRow: {
    width: "100%",       
    maxHeight: "50%",        
  },
  cellText: {
    fontSize: 13,
    color: theme.palette.primary.dark,
    textAlign: "center",
    //marginLeft: theme.spacing(1)
  },
  formDefined: {
    backgroundColor: theme.palette.secondary.light,        
  },
  saveButton : {
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: 9,
    },
    padding: theme.spacing(5)
  },
  saveIcon : {
    margin: theme.spacing(1)
  },
  yearTitle: {
    color: theme.palette.secondary.light,
    background: theme.palette.primary.main,
    borderRadius: 2,
    padding: 5,  
    marginBottom: 15,      
  }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      fontSize: theme.typography.subtitle2.fontSize,
      // '&:nth-of-type(1)': {
      //   width: "10%"
      // },
      // width: "auto",
      padding: 2
    },
    body: {
      fontSize: 13,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.action.hover}`,
      padding: 2
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}))(TableRow);

export default function AnualTable (props) {
    const periods = getPeriods(String(props.periodDate.getFullYear()))
    const months = getMonths()
    const abbMonths = getAbbMonths()
    const classes = useStyles()
    const [modificationDialog, setModificationDialog] = useState(false)
    const [recDialog, setRecDialog] = useState(false)
    const [zerosDialog, setZerosDialog] = useState(false)    
    const [uploadDialog, setUploadDialog] = useState(false)
    const [replicableAlert, setReplicableAlert] = useState(props.replicable)
    const [uploadMessage, setUploadMessage] = useState('')
    const [inputMethodFlag, setInputMethodFlag] = useState(true)
    const [tableData, setTableData] = useState([])

    const handleSwitchChange = (e) => {
      setInputMethodFlag(e.target.checked)
      if(e.target.checked){handleRecDialogOpen()}
    }

    const handleUploadDialogClose = () => {
      setUploadDialog(false)
    }

    const handleModificationDialogClose = () => {
      setModificationDialog(false)
    }

    const handleModificationDialogOpen = () => {
      setModificationDialog(true)
    }

    const handleRecDialogClose = () => {
      setRecDialog(false)
    }

    const handleRecDialogOpen = () => {
      setRecDialog(true)
    }

    const handleZerosDialogClose = () => {
      setZerosDialog(false)
    }

    const handleZerosDialogOpen = () => {
      setZerosDialog(true)
    }

    const handleReplicableClose = () => {
      setReplicableAlert(false)
    }

    const handleReplicableOpen = () => {
      setReplicableAlert(true)
    }

    const handleUploadFile = (id) => (e) => {
      e.preventDefault()
      if (e.target.files.length > 0){
        let validationFlags = validateInput( props.filename, 
                                          e.target.files,  
                                          props.validations, 
                                          months[props.periodDate.getMonth()],
                                          abbMonths[props.periodDate.getMonth()],
                                          props.quantity,                                
                                          props.periodDate
        )        
        let validationConfirm = false
        let validation = ""
        for (validation of props.validations){
          if (validationFlags[validation].includes(false)){
            setUploadDialog(true)
            validationConfirm = false
            switch(validation){
              case 'Name':
                if (  props.quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el nombre designado: ' + props.filename + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el nombre designado: ' + props.filename + ' para ser procesado/s.')
                }
                break;
              case 'Month':
                if ( props.quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  months[props.periodDate.getMonth()] + ' para ser procesado/s.')
                }
                break;
              case 'abbMonth':
                if ( props.quantity === "1" ){
                  setUploadMessage('El archivo seleccionado no contiene el periodo correcto: ' +  abbMonths[props.periodDate.getMonth()] + ' para ser procesado.')
                }
                else {
                  setUploadMessage('Dentro de los archivos seleccionados existen alguno/s que no contiene/n el periodo correcto: ' +  abbMonths[props.periodDate.getMonth()] + ' para ser procesado/s.')
                }
                break;
              case 'Quantity':
                setUploadMessage('Cantidad incorrecta de archivos seleccionados.')
                break;
            }
            break
          }
          else{
            validationConfirm = true
          }
        }
        if ( validationConfirm ) {                   
          const formData = new FormData()
          let i = 0
          let file = {}
          for (file of e.target.files){
            formData.append('file' + i, file)
            i = i + 1
          }          
          authFetch(
            "/api/upload-parameter?username="+props.username+
                              '&period=' +  String( props.periodDate.getMonth() + 1 )
                              + "&year=" + props.periodDate.getFullYear()+
                              "&filename="+ props.filename
                              + "&market=" + props.market
                              + "&version=" + props.tariffCalcVersion+
                              "&reliq=" + props.reliqFinished +
                              "&reliquidations=" + props.usedXmVersions.length+
                              "&userType=" + props.userType+
                              "&user=" + props.user,
            {
              method: 'post',
              body: formData,
            }          
          ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(response => {
              if (response.Upload === 'OK'){
                setUploadMessage('Subiendo archivo. Verifique la información reflejada en la tabla.')
                setUploadDialog(true)
                authFetch("/api/check-parameters?username=" + props.username +  
                        '&period=' +  String( props.periodDate.getMonth() + 1 )
                        + "&year=" + props.periodDate.getFullYear()
                        + "&market="+ props.market + "&version=" + props.tariffCalcVersion + "&calcType=" + props.calcType+
                        "&reliq=" + props.reliqFinished +
                        "&reliquidations=" + props.usedXmVersions.length+
                        "&userType=" + props.userType
                ).then(response => {
                  if (response.status === 422){
                      logout()
                      return null
                  }
                      return response.json()
                  }).then(response => {
                      if (response && response.parameters_state){
                          ////console.log('Response', response)
                          let inputParametersStatusAux = response.parameters_state.filter(param => props.userType === 'Regulados' || props.role === 'comer' ? true : param.Filename === 'IPP' || param.Filename === 'IPC'
                          ||  param.Filename === 'VkWUF' || param.Filename === 'CT_PR_NR'
                          ) 
                          props.setInputParametersStatus(inputParametersStatusAux)
                          setTableData(inputParametersStatusAux[id]['Calc_Values'])
                          ////console.log('Periodo', props.period)
                      }
                })
              }
              else {
                setUploadDialog(true)
                setUploadMessage('Error al subir archivos.')
              }
            })
        } 
      } 
      else {
        setUploadDialog(true)
        setUploadMessage('No ha seleccionado ningún archivo.')
      }
    }

    const checkZeros = (arrayIdx) => (e) => {
      e.preventDefault()
      let values = tableData[arrayIdx]
      ////console.log('TABLE', tableData[arrayIdx])
      let foundZero = false
      for (let value of values){
        if (!Number(value) > 0) {
          setZerosDialog(true)
          foundZero = true          
          break;
        }
      }
      if (!foundZero){
        handleUploadInputData(arrayIdx)
      }      
    }

    const handleUploadInputData = (arrayIdx) => {
      //e.preventDefault()
      let opts = {
        'data': tableData[arrayIdx],
      }            
      authFetch(
        "/api/upload-parameter?username="+props.username+
                          '&period=' +  String( props.periodDate.getMonth() + 1 )
                          + "&year=" + props.periodDate.getFullYear()+
                          "&filename="+ props.filename  + "&market=" + props.market+ "&version=" + props.tariffCalcVersion+
                          "&reliq=" + props.reliqFinished +
                          "&reliquidations=" + props.usedXmVersions.length+
                          "&userType=" + props.userType+
                          "&user=" + props.user,
        {
          method: 'post',
          body: JSON.stringify(opts),
        }
        ).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
          .then(response => {
            if (response.Upload === 'OK'){
              setUploadMessage('Datos guardados. Verifique la información reflejada en la tabla.')
              setUploadDialog(true)
              authFetch("/api/check-parameters?username=" + props.username 
                        + '&period=' +  String( props.periodDate.getMonth() + 1 )
                        + "&year=" + props.periodDate.getFullYear()
                        + "&market="+ props.market + "&version=" + props.tariffCalcVersion + "&calcType=" + props.calcType+
                        "&reliq=" + props.reliqFinished +
                        "&reliquidations=" + props.usedXmVersions.length+
                        "&userType=" + props.userType
                ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.parameters_state){
                        ////console.log('Response', response)
                        let inputParametersStatusAux = response.parameters_state.filter(param => props.userType === 'Regulados' ? true : param.Filename === 'IPP' || param.Filename === 'IPC'
                          ||  param.Filename === 'VkWUF' || param.Filename === 'CT_PR_NR'
                          ) 
                        props.setInputParametersStatus(inputParametersStatusAux)                        
                        ////console.log('Periodo', props.period)
                    }
              })
            }
            else {
              setUploadDialog(true)
              setUploadMessage('Error al guardar datos.')
            }
          })                  
    }

    const handleCellValueChange = (idxArray, idx) => (e) => {     
      setTableData([...tableData], tableData[idxArray][idx] = parseFloat(e.target.value))
    }

    const uploadForm = (filename, id, arrayIdx) => (
      !inputMethodFlag ? 
      <form action={"#"}>
         <label htmlFor={filename}>
          <input
            hidden     
            id={filename}
            name={filename}       
            type="file"   
            accept={props.extension}    
            onInput={handleUploadFile(id)} 
          />
          <Fab
            color="primary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"                        
            classes={{root: classes.uploadButton}}
          > 
            <AddIcon /> {'Subir Archivo'}                      
          </Fab>        
        </label>
      </form>     
      :
      <Fab
            color="primary"
            size="small"
            component="span"
            variant="extended"                        
            classes={{root: classes.uploadButton}}    
            onClick={checkZeros(arrayIdx)}              
          > 
            <React.Fragment><SaveIcon/> {' Guardar '}</React.Fragment>                         
      </Fab> 
    ) 

    const uploadConfirm = () => (
      <Dialog
        open={uploadDialog}
        onClose={handleUploadDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Información"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uploadMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadDialogClose} color="primary" autoFocus>
            Aceptar            
          </Button>
        </DialogActions>
      </Dialog>
    )

    const modificationConfirm = () => (
      <Dialog
        open={modificationDialog}
        onClose={handleModificationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Desea modificar la información relacionada al insumo?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si desea modificar la información haga click en ACEPTAR.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModificationDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={ props.handleModification(props.id) } color="primary" autoFocus>
            <a onClick={() => {handleModificationDialogClose(); setInputMethodFlag(true);}}>Aceptar</a>                        
          </Button>
        </DialogActions>
      </Dialog>
    )

    const recommendationDialog = () => (
      <Dialog
        open={recDialog}
        onClose={handleRecDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Aviso importante"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          La funcionalidad de ingresar valores por teclado estará habilitada los 6 primeros meses. Se recomienda usar 
          un archivo con la información a cargar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRecDialogClose} color="primary" autoFocus>
            Aceptar        
          </Button>
        </DialogActions>
      </Dialog>
    )

    const existentZerosDialog = (arrayIdx) => (
      <Dialog
        open={zerosDialog}
        onClose={handleZerosDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Aviso importante"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Existen valores 0 dentro de los parámetros a ingresar. ¿ Desea continuar ?	
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleZerosDialogClose} color="primary" autoFocus>
            Cancelar        
          </Button>
          <Button onClick={() => {handleZerosDialogClose(); handleUploadInputData(arrayIdx);}} color="primary" autoFocus>
            Aceptar        
          </Button>
        </DialogActions>
      </Dialog>
    )

    const firstColumnByParam = (dataArray) => {
      switch(props.filename){
        case 'VkWUF':
          return(<StyledTableCell align="center">{(Number(dataArray[0]) + Number(dataArray[1])).toFixed(2)} kWh</StyledTableCell>)
        case 'VROTmax':
        case 'VROTmed':
        case 'VROTmin':
        case 'VROTmon':
          return(<StyledTableCell align="center">{(Number(dataArray[0]) + Number(dataArray[1]) +
                                                    Number(dataArray[2]) + Number(dataArray[3]) + 
                                                    Number(dataArray[4]) + Number(dataArray[5])).toFixed(2)} kWh</StyledTableCell>)
        default: 
          return(<React.Fragment></React.Fragment>)
          
      }
    }

    useEffect (() => {
      setTableData(props.data)
    }, [props.data])

    return (
        <Grid container justify="space-evenly">
          <Grid classes={{root: !props.entry ? classes.selector : null}} container item xs={4} justify="center">
            {
              props.fileEnabled ?
              <Fragment>
                <Grid item xs={3} hidden={props.entry}>
                {
                  "Carga por archivo (" + props.filename + ")"
                }
                </Grid>
                <Grid item xs={!props.entry ? 6 : 12}>
                  {
                    !props.entry ? 
                    <Switch color="primary" onChange={handleSwitchChange} checked={inputMethodFlag}/>
                    :
                    !props.noEdit ? 
                      <Fab
                        color="primary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleModificationDialogOpen}                        
                      > 
                            <React.Fragment><ClearAllIcon /> {' Modificar '}</React.Fragment>                         
                      </Fab>
                    :
                    null
                  }                            
                </Grid>
                <Grid item xs={3} hidden={props.entry}>
                  Carga por entrada manual
                </Grid>  
              </Fragment>
              :
              <Fragment>
                <Grid item xs={!props.entry ? 6 : 12}>
                  {
                    !props.entry ? 
                    null
                    :
                    !props.noEdit ?
                      <Fab
                        color="primary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"                        
                        classes={{root: classes.uploadButton}}
                        onClick={handleModificationDialogOpen}
                      > 
                            <React.Fragment><ClearAllIcon /> {' Modificar '}</React.Fragment>                         
                      </Fab> 
                    :
                    null
                  }                            
                </Grid>
              </Fragment>
            }
            
          </Grid>
          <Grid classes={{root: classes.selector}} item xs={12}>
            {
              !props.entry ? 
               uploadForm(props.filename, props.id, props.periodDate.getMonth()) : null                          
            }
          </Grid>
          <Grid item xs={2}>
          <Typography variant="subtitle2" className={classes.yearTitle}>{'Año: ' + props.periodDate.getFullYear()}</Typography>
          </Grid>
          <Grid item xs={11}>
            {uploadConfirm()}
            {modificationConfirm()}
            {recommendationDialog()}
            {existentZerosDialog(props.periodDate.getMonth())}
            
            <TableContainer component={Paper}>
              <Table style={{ tableLayout: 'fixed' }} stickyHeader>                
                <TableHead>
                  <TableRow>
                      { 
                        props.orientation === 'ver' ?
                          props.processHeaders.map(
                            (header, idx) => (
                              <StyledTableCell key={idx} align="center">
                                {
                                  ['CGCUj','Cf'].some((el) => header.includes(el)) ? header+props.market : header
                                }
                              </StyledTableCell>
                            )
                          )
                        :
                          <React.Fragment>
                            <StyledTableCell align="center">{props.processHeaders[0]}</StyledTableCell>
                            {
                              tableData.map((dataArray, idxArr) => (
                                <StyledTableCell key={idxArr} align="center">{periods[idxArr]}</StyledTableCell>
                              ))
                            }
                          </React.Fragment>                         
                      }                                                              
                  </TableRow>
                </TableHead>
                <TableBody>                            
                  {
                    props.orientation === 'ver' ?
                    tableData.map(
                      (dataArray, idxArray) => (
                        <StyledTableRow key={idxArray}>
                            <StyledTableCell align="center">{periods[idxArray]}</StyledTableCell>
                            {
                              firstColumnByParam(dataArray)
                            }                           
                            {dataArray.map(
                                (data, idx) => (
                                  <StyledTableCell align="center" key={idx}>
                                      <TextField
                                          InputProps={{
                                              readOnly: 
                                                props.entry || !inputMethodFlag || idxArray != props.periodDate.getMonth() || (props.filename==='CFM' && idx < 3) || (props.filename==='CDI' && idx < 1),                                             
                                              className: props.entry || !inputMethodFlag || idxArray != props.periodDate.getMonth() || (props.filename==='CFM' && idx < 3) || (props.filename==='CDI' && idx < 1) ? classes.formDefined : null,
                                              classes: {
                                                  input: classes.cellText
                                              },                                             
                                              inputComponent: NumberFormatCustom                                          
                                          }}
                                          disabled={props.entry || !inputMethodFlag || idxArray != props.periodDate.getMonth() || (props.filename==='CFM' && idx < 3)}
                                          name={props.unit[idx]}                                        
                                          variant="standard"
                                          className={classes.cellRow}
                                          value={ 
                                              props.filename === 'CER' ? 
                                                idx === 2 ? 
                                                parseFloat((dataArray[0]+dataArray[1])/12).toFixed(2) 
                                                : 
                                                dataArray[idx] 
                                              :
                                              props.filename === 'CFS' ?
                                                // idx === 1 ?
                                                // parseFloat(100*(Math.pow(1+dataArray[0]/100,1/12)-1)).toFixed(6)
                                                // :
                                                // idx === 3 ?
                                                // parseFloat(100*(Math.pow(1+dataArray[2]/100,1/12)-1)).toFixed(6)
                                                // :
                                                dataArray[idx] 
                                              :
                                              props.filename === 'CG' ?                                                 
                                                idx === 4 ? 
                                                parseFloat((props.market === 'AIR-E' || props.market === 'AFINIA' ? dataArray[2] : dataArray[3])*props.marketDemand).toFixed(2)       
                                                :
                                                idx === 6 ? 
                                                parseFloat(dataArray[4] + dataArray[5]).toFixed(2)       
                                                :
                                                dataArray[idx]
                                              :   
                                              props.filename === 'VkWUF' ? 
                                                idx === 2 ? 
                                                parseFloat(dataArray[0] - (dataArray[3]+dataArray[4]+dataArray[5])).toFixed(2) 
                                                : 
                                                dataArray[idx]
                                              :       
                                              props.filename === 'TASA_AD' ?
                                                idx === 1 ?
                                                parseFloat(100*(Math.pow(1+dataArray[0]/100,1/12)-1)).toFixed(6)
                                                :    
                                                dataArray[idx]
                                              :                                                                                                                    
                                              dataArray[idx]                                              
                                          } 
                                          onChange={handleCellValueChange(idxArray, idx)}    
                                          required={props.requiredLimits} 
                                          autoComplete={props.requiredLimits ? props.limits[idx][1] : 1}                                     
                                          rows={props.requiredLimits ? props.limits[idx][0] : 1} 
                                          multiline={false}                                                                                               
                                      />                                      
                                  </StyledTableCell>
                                )
                              )
                            }                            
                        </StyledTableRow>
                      )
                    )
                    :
                    <StyledTableRow>
                      {
                        <React.Fragment>
                            <StyledTableCell align="center">{props.processHeaders[1]}</StyledTableCell>
                            {
                              tableData.map((dataArray, idxArr) => (
                                <StyledTableCell key={idxArr} align="center">                                 
                                  <TextField
                                      InputProps={{
                                          readOnly: props.entry || !inputMethodFlag || idxArr != props.periodDate.getMonth(),
                                          className: props.entry || !inputMethodFlag || idxArr != props.periodDate.getMonth() ? classes.formDefined : null,
                                          classes: {
                                              input: classes.cellText
                                          },
                                          inputComponent: NumberFormatCustom
                                      }}
                                      disabled={props.entry || !inputMethodFlag || idxArr != props.periodDate.getMonth()}
                                      variant="standard"
                                      name={props.unit}
                                      className={classes.cellRow}
                                      value={
                                        props.filename === 'PV' ?
                                        dataArray[0].toFixed(4)
                                        :
                                        dataArray[0]
                                      } 
                                      onChange={handleCellValueChange(idxArr, 0)}
                                      required={props.requiredLimits}                                           
                                      autoComplete={props.limits[1]}                                     
                                      rows={props.limits[0]} 
                                      multiline={false}                                                                                          
                                  />                         
                                </StyledTableCell>
                              ))
                            }
                        </React.Fragment>  
                      }
                    </StyledTableRow>
                  }
                </TableBody>
             </Table>                        
            </TableContainer>            
          </Grid> 
          <Grid item xs={11}>          
            <Snackbar open={replicableAlert} autoHideDuration={8500} onClose={handleReplicableClose}>
              <Alert onClose={handleReplicableClose} severity="info">
                Valor(es) de parámetro(s) replicandose mes a mes. Es responsabilidad del operador alterar su valor cuando sea necesario.
                {props.limitsMsg}
              </Alert>
            </Snackbar>
          </Grid>         
        </Grid>
    )
}